export const listOfCourts = {
  courts: [
    'Court or Arbitration',
    'Alingsås tingsrätt',
    'Attunda tingsrätt',
    'Blekinge tingsrätt',
    'Borås tingsrätt',
    'Eksjö tingsrätt',
    'Eskilstuna tingsrätt',
    'Falu tingsrätt',
    'Gotlands tingsrätt',
    'Gällivare tingsrätt',
    'Gävle tingsrätt',
    'Göteborgs tingsrätt',
    'Halmstads tingsrätt',
    'Haparanda tingsrätt',
    'Helsingborgs tingsrätt',
    'Hudiksvalls tingsrätt',
    'Hässleholms tingsrätt',
    'Jönköping tingsrätt',
    'Kalmar tingsrätt',
    'Kristanstads tingsrätt',
    'Linköpings tingsrätt',
    'Luleå tingsrätt',
    'Lunds tingsrätt',
    'Lycksele tingsrätt',
    'Malmö tingsrätt',
    'Mora tingsrätt',
    'Nacka tingsrätt',
    'Norrköpings tingsrätt',
    'Norrtälje tingsrätt',
    'Nyköpings tingsrätt',
    'Skaraborgs tingsrätt',
    'Solna tingsrätt',
    'Stockholms tingsrätt',
    'Sundsvalls tingsrätt',
    'Södertälje tingsrätt',
    'Södertörns tingsrätt',
    'Uddevalla tingsrätt',
    'Umeå tingsrätt',
    'Uppsala tingsrätt',
    'Varbergs tingsrätt',
    'Vänersborgs tingsrätt',
    'Värlmlands tingsrätt',
    'Västmanlands tingsrätt',
    'Växjö tingsrätt',
    'Ystad tingsrätt',
    'Ångermanlands tingsrätt',
    'Örebro tingsrätt',
    'Östersunds tingsrätt',
  ],
  courtsOfAppeal: [
    'Second Instance',
    'Svea hovrätt',
    'Göta hovrätt',
    'Hovrätten för Övre Norrland',
    'Hovrätten för Nedre Norrland',
    'Hovrätten över Skåne och Blekinge',
    'Hovrätten för västra Sverige',
    'Arbetsdomstolen',
    'Supreme Court',
  ],
}

export const alternativeOutcomes = [
  { value: '0', title: 'Full Success' },
  { value: '5', title: 'Complete Loss' },
  { value: '1', title: 'Full Success or Complete Loss' },
  { value: '2', title: 'Full Success, 1 Partially Success or Complete Loss' },
  { value: '3', title: 'Full Success, 2 Partially Successes or Complete Loss' },
  { value: '4', title: 'Full Success, 3 Partially Successes or Complete Loss' },
]

export const requirementDataList = [
  {
    value: '100',
    title: 'Certain (100.0%)',
  },
  {
    value: '92.5',
    title: 'Very High (92.5%)',
  },
  {
    value: '77.5',
    title: 'High (77.5%)',
  },
  {
    value: '65',
    title: 'Average High (65.0%)',
  },
  {
    value: '50',
    title: 'Average (50.0%)',
  },
  {
    value: '35',
    title: 'Average Low (35.0%)',
  },
  {
    value: '22.5',
    title: 'Low (22.5%)',
  },
  {
    value: '7.5',
    title: 'Very Low (7.5%)',
  },
]

export const tableTitleTooltips = [
  'Identifies the possible outcomes.',
  'Describes the outcomes using text.',
  'The total amount awarded on the merits of the case – can be positive or negative.',
  'The fraction of the disputed amount in which the client has been successful.',
  'The interest that is coupled with the amount awarded – can be positive or negative.',
  'The legal costs paid by the client during the proceedings – always a negative value that could vary depending on if the case has passed through one or several proceedings.',
  'The legal costs awarded to the client by the order of the court – this value can be zero or positive.',
  'The legal costs awarded to the opposing by the order of the court – this value can be zero or negative.',
  'The legal costs that will be covered by the insurance – this is always a positive value.',
  'The out of court financial value',
  'This value consists of the amount awarded, interests, the legal costs for the client, the legal costs awarded to the client or to be paid to the opposing party.',
  'The probability for this possible outcome to occur.',
  "The fraction of the client's legal costs that will be covered by the other party – this varies depending on the success of client and the settings for legal costs distribution.",
  "The fraction of the opposing party's legal costs that that will be covered by the other party – this varies depending on the success of client and the settings for legal costs distribution.",
  'The net outcome multiplied by its probability.',
]

export const outOfCourtAmounts = [
  'FullSuccessOutOfCourtAmount',
  'Alt1OutOfCourtAmount',
  'Alt2OutOfCourtAmount',
  'Alt3OutOfCourtAmount',
  'FullLossOutOfCourtAmount',
]
export const outOfCourtSignums = [
  'FullSuccessOutOfCourtSignum',
  'Alt1OutOfCourtSignum',
  'Alt2OutOfCourtSignum',
  'Alt3OutOfCourtSignum',
  'FullLossOutOfCourtSignum',
]

export const amountNames = [
  'FullSuccessAmount',
  'Alt1Amount',
  'Alt2Amount',
  'Alt3Amount',
  'FullLossAmount',
]
export const probabilityNames = [
  'FullSuccessProbability',
  'Alt1Probability',
  'Alt2Probability',
  'Alt3Probability',
  'FullLossProbability',
]

export const percentagesColors = [
  '#47743d', //infantryWest
  '#76B9E3', //quantum2
  '#D0A7F8', //quantum3
  '#F6A072', //quantum4
  '#EF7066', //quantum5
  '#D0DDF0', //fiatDusk
]

export const menuTitles = [
  'Case Details',
  'Claims',
  'Proceedings',
  'Legal Costs',
  'Enforcement Risk',
  'Legal cost distribution',
]
