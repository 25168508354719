import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { activeTabsState } from '../../states/ActiveTabsState'
import { doValidationState } from '../../states/DoValidationState'
import { editTreeNodeFromUndoState } from '../../states/EditTreeNodeFromUndo'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { onlineState } from '../../states/OnlineState'
import { pageDepthState } from '../../states/PageDepthState'
import { reportMenuState } from '../../states/ReportMenuState'
import { reportState } from '../../states/ReportState'
import { savedReportState } from '../../states/SavedReportState'
import { savedState } from '../../states/SavedState'
import { scenarioIdentityState } from '../../states/ScenarioIdentityState'
import { scenarioSnapshotState } from '../../states/ScenarioSnapshotState'
import { treeTablesState } from '../../states/TreeTablesState'
import { userState } from '../../states/UserState'
import { nodesMovedForEditModeState } from '../../states/NodesMovedForEditModeState'
import { viewerState } from '../../states/ViewerState'
import { featuresState } from '../../states/FeaturesState'
import { allowShortcutsState } from '../../states/AllowShortcutsState'
import { recoveryModeState } from '../../states/RecoveryModeState'
import { globalPopUpsState } from '../../states/GlobalPopUps'
import { freemiumState } from '../../states/FreemiumState'
import { krogerusColorsState } from '../../states/KrogerusColorsState'
import { resultsSettingsState } from '../../states/ResultsSettingsState'
import { evershedsReportState } from '../../states/EvershedsReportState'

export default function DebugComponent() {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const savedSnapshot = useRecoilValue(savedState)
  const activeTabs = useRecoilValue(activeTabsState)
  const treeTables = useRecoilValue(treeTablesState)
  const pageDepth = useRecoilValue(pageDepthState)
  const report = useRecoilValue(reportState)
  const savedReport = useRecoilValue(savedReportState)
  const handlingErrors = useRecoilValue(handlingErrorsState)
  const online = useRecoilValue(onlineState)
  const reportMenu = useRecoilValue(reportMenuState)
  const doValidation = useRecoilValue(doValidationState)
  const editTreeNodeFromUndo = useRecoilValue(editTreeNodeFromUndoState)
  const nodesMovedForEditMode = useRecoilValue(nodesMovedForEditModeState)
  const isViewer = useRecoilValue(viewerState)
  const features = useRecoilValue(featuresState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)
  const recoveryMode = useRecoilValue(recoveryModeState)
  const globalPopUps = useRecoilValue(globalPopUpsState)
  const freemium = useRecoilValue(freemiumState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const evershedsReport = useRecoilValue(evershedsReportState)

  const setState = useState(scenarioSnapshot)[1]
  const setIdentityState = useState(scenarioIdentity)[1]
  const setUserState = useState(user)[1]
  const setSavedSnapshot = useState(savedSnapshot)[1]
  const setActiveTabs = useState(activeTabs)[1]
  const setTreeTables = useState(treeTables)[1]
  const setPageDepth = useState(pageDepth)[1]
  const setReport = useState(report)[1]
  const setSavedReport = useState(savedReport)[1]
  const setHandlingErrors = useState(handlingErrors)[1]
  const setOnline = useState(online)[1]
  const setReportMenu = useState(reportMenu)[1]
  const setDoValidation = useState(doValidation)[1]
  const setEditTreeNodeFromUndo = useState(editTreeNodeFromUndo)[1]
  const setNodesMovedForEditMode = useState(nodesMovedForEditMode)[1]
  const setIsViewer = useState(isViewer)[1]
  const setFeatures = useState(features)[1]
  const setAllowShortcuts = useState(allowShortcuts)[1]
  const setRecoveryMode = useState(recoveryMode)[1]
  const setGlobalPopUps = useState(globalPopUps)[1]
  const setFreemium = useState(freemium)[1]
  const setKrogerusColors = useState(krogerusColors)[1]
  const setResultsSettings = useState(resultsSettings)[1]
  const setEvershedsReport = useState(evershedsReport)[1]

  useEffect(() => {
    setState(scenarioSnapshot)
    // eslint-disable-next-line
  }, [scenarioSnapshot])

  useEffect(() => {
    setIdentityState(scenarioIdentity)
    // eslint-disable-next-line
  }, [scenarioIdentity])
  useEffect(() => {
    setUserState(user)
    // eslint-disable-next-line
  }, [user])
  useEffect(() => {
    setSavedSnapshot(savedSnapshot)
    // eslint-disable-next-line
  }, [savedSnapshot])
  useEffect(() => {
    setActiveTabs(activeTabs)
    // eslint-disable-next-line
  }, [activeTabs])
  useEffect(() => {
    setTreeTables(treeTables)
    // eslint-disable-next-line
  }, [treeTables])
  useEffect(() => {
    setPageDepth(pageDepth)
    // eslint-disable-next-line
  }, [pageDepth])
  useEffect(() => {
    setReport(report)
    // eslint-disable-next-line
  }, [report])
  useEffect(() => {
    setSavedReport(savedReport)
    // eslint-disable-next-line
  }, [savedReport])
  useEffect(() => {
    setHandlingErrors(handlingErrors)
    // eslint-disable-next-line
  }, [handlingErrors])
  useEffect(() => {
    setOnline(online)
    // eslint-disable-next-line
  }, [online])
  useEffect(() => {
    setReportMenu(reportMenu)
    // eslint-disable-next-line
  }, [reportMenu])
  useEffect(() => {
    setDoValidation(doValidation)
    // eslint-disable-next-line
  }, [doValidation])
  useEffect(() => {
    setEditTreeNodeFromUndo(editTreeNodeFromUndo)
    // eslint-disable-next-line
  }, [editTreeNodeFromUndo])
  useEffect(() => {
    setNodesMovedForEditMode(nodesMovedForEditMode)
    // eslint-disable-next-line
  }, [nodesMovedForEditMode])
  useEffect(() => {
    setIsViewer(isViewer)
    // eslint-disable-next-line
  }, [isViewer])
  useEffect(() => {
    setFeatures(features)
    // eslint-disable-next-line
  }, [features])
  useEffect(() => {
    setAllowShortcuts(allowShortcuts)
    // eslint-disable-next-line
  }, [allowShortcuts])
  useEffect(() => {
    setRecoveryMode(recoveryMode)
    // eslint-disable-next-line
  }, [recoveryMode])
  useEffect(() => {
    setGlobalPopUps(globalPopUps)
    // eslint-disable-next-line
  }, [globalPopUps])
  useEffect(() => {
    setFreemium(freemium)
    // eslint-disable-next-line
  }, [freemium])
  useEffect(() => {
    setKrogerusColors(krogerusColors)
    // eslint-disable-next-line
  }, [krogerusColors])
  useEffect(() => {
    setResultsSettings(resultsSettings)
    // eslint-disable-next-line
  }, [resultsSettings])
  useEffect(() => {
    setEvershedsReport(evershedsReport)
    // eslint-disable-next-line
  }, [evershedsReport])

  return <div className="emptyDiv"></div>
}
