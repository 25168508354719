import CaptchaHOC from './HOCs/CaptchaHOC'
import FullScreenActionMessagesHOC from './HOCs/FullScreenActionMessagesHOC'
import FullScreenPopUpsHOC from './HOCs/FullScreenPopUpsHOC'
import LowScreenMessagesHOC from './HOCs/LowerScreenMessagesHOC'
import NotSupportedBrowserHOC from './HOCs/NotSupportedBrowserHOC'
import MaterialUiThemeHOC from './HOCs/MaterialUiThemeHOC'
import UserInitHOC from './HOCs/UserInitHOC'

type Props = {
  children: React.ReactElement
  setFirstTimeLogin: (value: boolean) => void
  zoomMessage: {
    showPopUp: boolean
    closedByUser: boolean
  }
  setZoomMessage: (value: { showPopUp: boolean; closedByUser: boolean }) => void
}

const AppHOC = (props: Props) => {
  return (
    <CaptchaHOC>
      <MaterialUiThemeHOC>
        <NotSupportedBrowserHOC>
          <FullScreenActionMessagesHOC>
            <LowScreenMessagesHOC>
              <FullScreenPopUpsHOC
                zoomMessage={props.zoomMessage}
                setZoomMessage={props.setZoomMessage}
              >
                <UserInitHOC setFirstTimeLogin={props.setFirstTimeLogin}>
                  {props.children}
                </UserInitHOC>
              </FullScreenPopUpsHOC>
            </LowScreenMessagesHOC>
          </FullScreenActionMessagesHOC>
        </NotSupportedBrowserHOC>
      </MaterialUiThemeHOC>
    </CaptchaHOC>
  )
}

export default AppHOC
