import { createTheme, Theme, ThemeProvider } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: {
        main: string
      }
      secondary: {
        main: string
      }
      text: {
        primary: string
      }
    }
  }
}

type Props = {
  children: React.ReactElement
}

const MaterialUiThemeHOC = (props: Props) => {
  const theme: Theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#462777', //lakersUniformHover
      },
      secondary: {
        main: '#ff7336',
        light: '#ff8048',
        dark: '#ff7336',
        contrastText: '#201A2D',
      },
      text: {
        primary: '#201A2D',
        secondary: '#383C59',
      },
      error: {
        main: '#ac2828', //greekEaster
        light: '#ffdddd',
      },
      success: {
        main: '#47743D',
      },
      background: {
        default: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'poppins-local',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 600,
    },
  })

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

export default MaterialUiThemeHOC
