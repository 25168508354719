import React, { useState, useEffect, useRef } from 'react'

import HoverComponent from '../InfoHover/HoverComponent/HoverComponent'
import CancelCaseWindow from '../CancelCaseWindow/CancelCaseWindow'
import { SnapshotStatus } from '../../models/enums'
import { ScenarioIdentity } from '../../models/generalTypes'
import { getText } from '../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'

type Props = {
  stopAnalysis: (scenarioId: string, snapshotId: string) => void
  cancellingMessage: string | undefined
  setCancellingMessage: (param1: string | undefined) => void
  scenarioIdentity: ScenarioIdentity
  inForm?: boolean
  cancelBecauseOfUpdateMessage: boolean
}

export default function ProgressBar(props: Props) {
  const user = useRecoilValue(userState)

  const [isCancelCase, setIsCancelCase] = useState(false)
  const [hoverElement, setHoverElement] = useState('')
  const cancelMessageRef = useRef<HTMLHeadingElement>(null!)
  const [secondHoverElement, setSecondHoverElement] = useState('')
  const [cancelCaseWindowCoordinates, setCancelWindowCoordinates] = useState([
    0, 0,
  ])
  const secondaryHover = useRef('open')

  const onMouseDown = (e: any) => {
    if (cancelMessageRef.current) {
      if (cancelMessageRef && !cancelMessageRef.current.contains(e.target)) {
        setSecondHoverElement('')
        setIsCancelCase(false)
        props.setCancellingMessage(undefined)
      }
    }

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function cancelJobPopUpOn() {
    let cancelButton = document.getElementById(
      `${props.scenarioIdentity.snapshotId}cancelButton`,
    )
    var viewportOffset = cancelButton!.getBoundingClientRect()
    // these are relative to the viewport, i.e. the window
    var top = viewportOffset.top - 38
    var left = viewportOffset.left - 200
    var h = window.innerHeight
    if (h - top < 200) {
      top -= 130
    }
    secondaryHover.current = 'close'
    setCancelWindowCoordinates([top, left])
    setSecondHoverElement('')
    setIsCancelCase(true)
  }

  return (
    <>
      <div
        className="progressBarContainer"
        onMouseEnter={() => {
          setHoverElement('showCancelButton')
        }}
        onMouseLeave={() => {
          setHoverElement('')
        }}
      >
        {props.cancelBecauseOfUpdateMessage ? (
          <p
            className="cancelBecauseOfUpdateMessageContainer"
            id="cancelBecauseOfUpdateMessage-myScenarios"
            data-textattribute="message-66"
          >
            {getText('message-66', user.settings)}
          </p>
        ) : null}
        {props.scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
        props.scenarioIdentity.snapshotStatus ===
          SnapshotStatus.ProcessingReversed ? (
          <>
            <p
              className="progressText"
              id="progressText-progress"
              data-textattribute="description-5a, description-5b"
            >
              {getText('description-5a', user.settings)}{' '}
              {props.scenarioIdentity.snapshotProgress || 0}
              {getText('description-5b', user.settings)}
            </p>
            <div className="progressBarBackground">
              <div
                className="progressBar"
                style={{
                  width: `${props.scenarioIdentity.snapshotProgress}%`,
                }}
              ></div>
            </div>
          </>
        ) : (
          <p
            className="progressText"
            id="progressText-queued"
            data-textattribute="description-6"
          >
            {getText('description-6', user.settings)}
          </p>
        )}
        {hoverElement === 'showCancelButton' ? (
          <div
            className="cancelContainer"
            style={
              (props.inForm &&
                props.scenarioIdentity.snapshotStatus ===
                  SnapshotStatus.Processing) ||
              props.scenarioIdentity.snapshotStatus ===
                SnapshotStatus.ProcessingReversed
                ? { right: '-20px' }
                : undefined
            }
            onMouseEnter={() => {
              secondaryHover.current = 'cancelButton'
              setTimeout(() => {
                if (secondaryHover.current === 'cancelButton') {
                  setSecondHoverElement('cancelButton')
                }
              }, 1000)
            }}
            onMouseLeave={() => {
              secondaryHover.current = 'close'
              setSecondHoverElement('')
            }}
          >
            {secondHoverElement === 'cancelButton' ? (
              <div
                className="hoverComponentContainer"
                style={{ top: '55px', left: '-40px' }}
              >
                <HoverComponent
                  hoverText={getText('hover-3', user.settings)}
                  textAttribute="hover-3"
                  id="stopAnalysis"
                  style={{ width: '112px' }}
                />
              </div>
            ) : null}
            <div
              className="cancelCase"
              onClick={cancelJobPopUpOn}
              id={`${props.scenarioIdentity.snapshotId}cancelButton`}
            >
              <div
                className="cancelCaseSquare"
                onClick={cancelJobPopUpOn}
              ></div>
            </div>

            {isCancelCase ? (
              <div
                className="transparent-Background"
                style={{ cursor: 'default' }}
              >
                <CancelCaseWindow
                  stopAnalysis={() => {
                    props.stopAnalysis(
                      props.scenarioIdentity.scenarioId,
                      props.scenarioIdentity.snapshotId,
                    )
                  }}
                  cancelFunction={() => {
                    setIsCancelCase(false)
                    setSecondHoverElement('')
                  }}
                  position={[
                    cancelCaseWindowCoordinates[0],
                    cancelCaseWindowCoordinates[1],
                  ]}
                  reference={cancelMessageRef}
                  cancellingMessage={props.cancellingMessage}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}
