import React from 'react'
import { Text, StyleSheet, Font } from '@react-pdf/renderer'
import poppins from '../../../../../../../../resources/fonts/Poppins/Poppins-Medium.ttf'
import poppinsLight from '../../../../../../../../resources/fonts/Poppins/Poppins-Light.ttf'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { User } from '../../../../../../../../models/user'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { getText } from '../../../../../../../../services/textFunctions'
import { stringAndRoundTo2Decimals } from '../../../../../../../../services/commonFunctions'

type Props = {
  reportWhitelabel?: ReportWhitelabel
  resultsFromBackend: ResultsObject
  user: User
  currentSnapshot: ScenarioSnapshot
  type:
    | 'selectionGraph'
    | 'distributionGraph'
    | 'getPayGraph'
    | 'expectedValueBreakdown'
}
Font.register({
  family: 'Poppins',
  src: poppins,
})
Font.register({
  family: 'Poppins-Light',
  src: poppinsLight,
})

export default function AwaLegendDocument(props: Props) {
  const styles = StyleSheet.create({
    asteriskText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: '#8b8b8b',
      marginTop: '4px',
    },
    asteriskTextNumber: {
      fontSize: '8px',
      fontFamily: 'Roboto-Light',
      color: '#8b8b8b',
      marginTop: '4px',
    },
    asteriskTextSelectionGraph: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: '#8b8b8b',
      marginTop: '4px',
      marginLeft: '-36px',
    },
    asteriskTextSelectionGraphNumber: {
      fontSize: '8px',
      fontFamily: 'Roboto-Light',
      color: '#8b8b8b',
      marginTop: '4px',
      marginLeft: '-36px',
    },
  })

  return props.resultsFromBackend &&
    props.resultsFromBackend.model_used === 'statistical' ? (
    props.type === 'expectedValueBreakdown' ? (
      <Text
        style={styles.asteriskText}
        id="asteriskParagraphForPdfText"
        data-textattribute={'description-276'}
      >
        {getText('description-276', props.user.settings)}
      </Text>
    ) : (
      <Text
        style={
          props.type === 'selectionGraph'
            ? styles.asteriskTextSelectionGraph
            : styles.asteriskText
        }
        id="asteriskParagraphForPdfText"
        data-textattribute={'description-176'}
      >
        {getText('description-176', props.user.settings)}{' '}
        <Text
          style={
            props.type === 'selectionGraph'
              ? styles.asteriskTextSelectionGraphNumber
              : styles.asteriskTextNumber
          }
        >
          {stringAndRoundTo2Decimals(
            props.resultsFromBackend.difference_from_value ?? 0,
            props.user.settings,
          )}{' '}
          {props.currentSnapshot.currency}
        </Text>
      </Text>
    )
  ) : null
}
