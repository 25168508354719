import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AccessRole,
  AvailableLanguages,
  ClaimType,
  ClaimsPreviewStyle,
  DanishInterestViewOption,
  InterestViewOption,
  NorwegianInterestViewOption,
  SweedishInterestViewOption,
  UndoRedoType,
  VideoPostName,
  translateInterestViewOption,
} from '../../../../../models/enums'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
  TreeAnalysisResults,
} from '../../../../../models/generalTypes'
import {
  claimPreviewIdArrayFunction,
  getClientAwardedAmountSum,
  getOpposingPartyAwardedAmountSum,
} from '../../../../../services/claimFunctions'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../states/UserState'
import EmptyClaimsComponent from './EmptyClaimsComponent'
import ClaimTile from './ClaimTile'
import tilesView from '../../../../../resources/images/249-tiles_view.svg'
import listView from '../../../../../resources/images/250-list_view.svg'
import listViewExpanded from '../../../../../resources/images/251-list_view_expanded.svg'
import rearrangeIcon from '../../../../../resources/images/252-rearrange_icon.svg'
import rearrangeAscendingIcon from '../../../../../resources/images/253-rearrange_ascending.svg'
import rearrangeDescendingIcon from '../../../../../resources/images/254-rearrange_descending.svg'
import noInterestDropdownImg from '../../../../../resources/images/276-NoInterestDropdown.svg'
import interestFirstDropdownImg from '../../../../../resources/images/277-interestFirstDropdown.svg'
import interestSecondDropdownImg from '../../../../../resources/images/278-interestSecondDropdown.svg.svg'
import { formattedNumToString } from '../../../../../services/formatNum'
import {
  calculateWeightedValues,
  deepCloneObject,
  generateId,
  isJSON,
  logActivity,
  roundNumberTo,
} from '../../../../../services/commonFunctions'
import fitty from 'fitty'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import Button from '../../../../../Components/Buttons/Button/Button'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import { getText } from '../../../../../services/textFunctions'
import { WhiteTooltipMoreDelay } from '../../../../../Components/tooltip/TooltipComponent'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { treeTablesState } from '../../../../../states/TreeTablesState'
import { TreeId } from '../../../../../models/treeModels/treeTypes'
import { replaceEventAndNodeIds } from '../../../../../services/treeFunctions/treeBasicFunctions'
import { freemiumState } from '../../../../../states/FreemiumState'
import DropDown from '../../../../../Components/Inputs/DropDown/DropDown'
import { valuesIn } from 'lodash'
import { atLeastOneClaimHasInterest } from '../../../../../services/resultsFunctions'
import AddClaims from './addClaim/AddClaims'
import { krogerusColorsState } from '../../../../../states/KrogerusColorsState'

type Props = {
  activeClaim: number
  setActiveClaim: (claimIndex: number, noRerender?: boolean) => void
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  errors: Record<number, number> | undefined
  doValidation: boolean
  treeDirectCalculation: (
    treeIndex: number,
    message: string,
  ) => Promise<TreeAnalysisResults[][] | undefined>
  setVideoPostName: (param: VideoPostName | undefined) => void
  ownRole: AccessRole
  forceTiles: boolean
  calculateAllTrees: () => Promise<void>
}

type Sorting = {
  option: 'ascending' | 'descending' | 'none'
  value: 'title' | 'client' | 'opposing' | 'weighted' | 'none'
}

export default function ClaimTilesContainer(props: Props) {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)

  const [claimPreviewIdArray, setClaimPreviewIdArray] = useState<string[]>([])
  const [claimPositionHighlight, setClaimPositionHighlight] = useState<
    number | undefined
  >(undefined)
  const [claimIsDragging, setClaimIsDragging] = useState<number | undefined>(
    undefined,
  )
  const [weightedValues, setWeightedValues] = useState(
    calculateWeightedValues(scenarioSnapshot.currentSnapshot),
  )
  const [sorting, setSorting] = useState<Sorting>({
    option: 'none',
    value: 'none',
  })

  const [hoveredTitle, setHoveredTitle] = useState<
    'title' | 'client' | 'opposing' | 'weighted' | undefined
  >(undefined)
  const [nothingToPaste, setNothingToPaste] = useState(false)
  const [addClaimMenu1On, setAddClaimMenu1On] = useState(false)
  const [addClaimMenu2On, setAddClaimMenu2On] = useState(false)
  const [addClaimMenu3On, setAddClaimMenu3On] = useState(true)

  const outsideSortingChange = useRef(true)

  const interestViewOption =
    user.settings.language === AvailableLanguages.norwegian
      ? NorwegianInterestViewOption
      : user.settings.language === AvailableLanguages.swedish
      ? SweedishInterestViewOption
      : user.settings.language === AvailableLanguages.danish
      ? DanishInterestViewOption
      : InterestViewOption

  const initializeExpandedList = () =>
    scenarioSnapshot.currentSnapshot.claims
      .filter((c) => c.type !== ClaimType.tree)
      .map((c) => ({
        id: c.id,
        expanded:
          scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
          ClaimsPreviewStyle.expanded,
      }))

  const initializeClaimMenus = () => {
    return scenarioSnapshot.currentSnapshot.claims.map((claim) => false)
  }

  const [expandedTiles, setExpandedTiles] = useState<
    { id: string; expanded: boolean }[]
  >(initializeExpandedList())
  const [claimMenus, setClaimMenus] = useState<boolean[]>(
    initializeClaimMenus(),
  )

  const changeExpanded = (value: boolean, id?: string) => {
    let newList = [...expandedTiles]

    if (id !== undefined) {
      let index = newList.findIndex((eT) => eT.id === id)

      if (index > -1) {
        newList[index].expanded = value
      }
    } else {
      for (let tile of newList) {
        tile.expanded = value
      }
    }

    setExpandedTiles([...newList])
  }

  useEffect(() => {
    let newList = initializeExpandedList()

    for (let item of newList) {
      let indexInPrev = expandedTiles.findIndex((eT) => eT.id === item.id)
      if (indexInPrev > -1) {
        item.expanded = expandedTiles[indexInPrev].expanded
      }
    }

    setExpandedTiles([...newList])
    changeSortingOption('none', true)

    //With timeout to make sure that the initializeClaimMenus comes after the changeClaimMenus in duplicate
    setTimeout(() => {
      setClaimMenus(initializeClaimMenus())
    }, 100)

    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.claims.length])

  useEffect(() => {
    if (outsideSortingChange.current) {
      changeSortingOption('none', true)
    }
    outsideSortingChange.current = true
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(
      scenarioSnapshot.currentSnapshot.claims.map((claim) => claim.id),
    ),
  ])

  useEffect(() => {
    setExpandedTiles(initializeExpandedList())
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.claimsPreviewStyle])

  useEffect(() => {
    let tempClaimPreviewIdArray = claimPreviewIdArrayFunction(
      scenarioSnapshot.currentSnapshot,
      user.settings,
    )
    setClaimPreviewIdArray(tempClaimPreviewIdArray)
    setWeightedValues(calculateWeightedValues(scenarioSnapshot.currentSnapshot))
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.claims])

  useEffect(() => {
    fitty('.listPreviewTableName', {
      minSize: 8,
      maxSize: 16,
    })
  })

  function showValues() {
    for (let claim of scenarioSnapshot.currentSnapshot.claims) {
      if (
        claim.type === ClaimType.tree &&
        ((claim as TreeClaim).totalClaimedAmount === undefined ||
          (claim as TreeClaim).totalClaimedAmountInterest1st === undefined ||
          (claim as TreeClaim).totalClaimedAmountInterest2nd === undefined ||
          (claim as TreeClaim).totalCounterClaimedAmount === undefined ||
          (claim as TreeClaim).totalCounterClaimedAmountInterest1st ===
            undefined ||
          (claim as TreeClaim).totalCounterClaimedAmountInterest2nd ===
            undefined ||
          (claim as TreeClaim).treeWeightedValue === undefined ||
          (claim as TreeClaim).treeWeightedValueInterest1st === undefined ||
          (claim as TreeClaim).treeWeightedValueInterest2nd === undefined)
      ) {
        return false
      }
    }

    return true
  }

  function changeClaimMenus(value: boolean, index: number) {
    let newValues = [...claimMenus]
    newValues[index] = value
    setClaimMenus([...newValues])

    const trueIndex = newValues.findIndex((value) => value)
    if (trueIndex > -1) {
      for (let i = 0; i < scenarioSnapshot.currentSnapshot.claims.length; i++) {
        if (
          i !== trueIndex &&
          document.getElementById(`singleClaimPreviewContainer-${i}`)
        ) {
          document.getElementById(
            `singleClaimPreviewContainer-${i}`,
          )!.style.zIndex = '0'
        }
      }
    }
  }

  const onScroll = (e: any) => {
    const topHeight =
      document.getElementById('claimsPreviewContainer') &&
      document.getElementById('tabsContainer')
        ? document.getElementById('claimsPreviewContainer')!.offsetHeight +
          document.getElementById('tabsContainer')!.offsetHeight +
          53 +
          33
        : undefined
    if (window.innerWidth < 836) {
      document.getElementById(
        `tabsContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (
      document.getElementById(`claimsPreviewContainer`) &&
      topHeight !== undefined &&
      topHeight - window.scrollY < 0
    ) {
      document.getElementById(
        `claimsPreviewContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (document.getElementById(`claimsPreviewContainer`)) {
      document.getElementById(
        `claimsPreviewContainer`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }

    if (document.getElementById('claimsPreviewDisplayOptions')) {
      document.getElementById(
        `claimsPreviewDisplayOptions`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }

    if (document.getElementById('claimsPreviewInterestOptionsContainer')) {
      document.getElementById(
        `claimsPreviewInterestOptionsContainer`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line
  }, [])

  const changeClaimsPreviewStyle = (value: ClaimsPreviewStyle) => {
    if (value === scenarioSnapshot.currentSnapshot.claimsPreviewStyle) {
      changeExpanded(value === ClaimsPreviewStyle.expanded)
      return
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId:
        value === ClaimsPreviewStyle.tiles
          ? 'tilesPreviewButton'
          : value === ClaimsPreviewStyle.list
          ? 'listPreviewButton'
          : 'expandedPreviewButton',
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'claimsPreviewStyle',
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    //Mixpanel 168 (All)
    logActivity(
      freemium.isFreemium,
      `Changed claims preview style to ${value.toString()}`,
    )
  }

  const changeInterestViewOption = (value: InterestViewOption) => {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'claimsInterestViewOptionDropdown',
      undoRedoType: UndoRedoType.button,
      value: translateInterestViewOption(
        value,
        user.settings.language,
        AvailableLanguages.english,
      ),
      key: 'interestViewOption',
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    //Mixpanel 182 (All)
    logActivity(
      freemium.isFreemium,
      `Changed interest view option to ${value.toString()}`,
    )
  }

  function changeSortingOption(
    newValue: 'title' | 'client' | 'opposing' | 'weighted' | 'none',
    remove?: boolean,
  ) {
    if (scenarioSnapshot.currentSnapshot.claims.length === 1) {
      return
    }

    outsideSortingChange.current = false
    let newSort: Sorting

    if (remove) {
      newSort = {
        value: 'none',
        option: 'none',
      }
    } else if (newValue === sorting.value) {
      newSort = {
        value: newValue,
        option: sorting.option === 'ascending' ? 'descending' : 'ascending',
      }
    } else {
      newSort = {
        value: newValue,
        option:
          newValue === 'client' || newValue === 'weighted'
            ? 'descending'
            : 'ascending',
      }
    }

    if (newSort.option !== 'none') {
      const snapshotSelectorObject: SnapshotSelectorObject = {
        targetId: `${
          newSort.value !== 'none' ? newSort.value : sorting.value
        }ListTitle`,
        undoRedoType: UndoRedoType.div,
        value: newSort,
        key: 'claimsPreviewStyle',
        key2: 'sort',
      }

      const activeClaimId =
        scenarioSnapshot.currentSnapshot.claims[props.activeClaim].id

      let tempScenarioSnapshot: DefaultSnapshotState =
        deepCloneObject(scenarioSnapshot)
      tempScenarioSnapshot = changeGlobalSnapshot(
        snapshotSelectorObject,
        tempScenarioSnapshot,
      )

      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.findIndex(
          (claim) => claim.id === activeClaimId,
        ),
        true,
      )

      setScenarioSnapshot(tempScenarioSnapshot)
    }

    setSorting(newSort)
  }

  const getSortImg = (value: 'title' | 'client' | 'opposing' | 'weighted') => {
    if (scenarioSnapshot.currentSnapshot.claims.length === 1) {
      return undefined
    }

    if (hoveredTitle === value && sorting.value !== value) {
      return (
        <img
          className="listPreviewSortImg"
          src={rearrangeIcon}
          alt="rearrangeIcon"
          onClick={() => changeSortingOption(value)}
        />
      )
    } else if (sorting.value === value && sorting.option === 'ascending') {
      return (
        <img
          className="listPreviewSortImg"
          src={rearrangeAscendingIcon}
          alt="rearrangeAscendingIcon"
          onClick={() => changeSortingOption(value)}
        />
      )
    } else if (sorting.value === value && sorting.option === 'descending') {
      return (
        <img
          className="listPreviewSortImg"
          src={rearrangeDescendingIcon}
          alt="rearrangeDescendingIcon"
          onClick={() => changeSortingOption(value)}
        />
      )
    }

    return undefined
  }

  async function handlePasteClaim() {
    let claimFromClipboard = await navigator.clipboard.readText()
    if (claimFromClipboard && isJSON(claimFromClipboard)) {
      let parsedClaimFromClipboard = JSON.parse(claimFromClipboard)

      if (parsedClaimFromClipboard && parsedClaimFromClipboard.copiedClaim) {
        let takeResults = false
        const pastedClaim: TreeClaim | IndependentClaim =
          parsedClaimFromClipboard.copiedClaim

        let treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
          (treeTableInfo) => treeTableInfo.treeId === pastedClaim.id,
        )

        let pastedClipIndex = scenarioSnapshot.currentSnapshot.claims.findIndex(
          (claim) => claim.id === pastedClaim.id,
        )

        if (
          treeTableInfoIndex >= 0 &&
          pastedClipIndex >= 0 &&
          JSON.stringify(
            scenarioSnapshot.currentSnapshot.claims[pastedClipIndex],
          ) === JSON.stringify(pastedClaim)
        ) {
          takeResults = true
        }

        pastedClaim.id = generateId('tree') as TreeId

        const snapshotSelectorObject: SnapshotSelectorObject = {
          targetId: 'addClaimContainer',
          undoRedoType: UndoRedoType.button,
          value: pastedClaim,
          key: 'claims',
          key2: 'add',
        }

        let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
        tempScenarioSnapshot = changeGlobalSnapshot(
          snapshotSelectorObject,
          tempScenarioSnapshot,
        )

        if (pastedClaim.type === 'tree') {
          ;(pastedClaim as TreeClaim).treeDetails = replaceEventAndNodeIds(
            (pastedClaim as TreeClaim).treeDetails,
          )

          let results =
            takeResults === true
              ? treeTables.treeTablesInfo[treeTableInfoIndex].results
              : undefined
          let currentValueOfTree =
            takeResults === true
              ? treeTables.treeTablesInfo[treeTableInfoIndex].currentValueOfTree
              : undefined
          let showSelectionGraph =
            takeResults === true
              ? treeTables.treeTablesInfo[treeTableInfoIndex].showSelectionGraph
              : false
          let showTreeTable =
            takeResults === true
              ? treeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable
              : false
          let interestSwitch =
            takeResults === true
              ? treeTables.treeTablesInfo[treeTableInfoIndex]
                  .treeInterestViewOption
              : InterestViewOption.interest1st

          setTreeTables({
            ...treeTables,
            treeTablesInfo: [
              ...treeTables.treeTablesInfo,
              {
                treeId: pastedClaim.id,
                results: results,
                currentValueOfTree: currentValueOfTree,
                previousValueOfTree: undefined,
                showSelectionGraph: showSelectionGraph,
                showTreeTable: showTreeTable,
                treeInterestViewOption: interestSwitch,
              },
            ],
          })
        }

        setScenarioSnapshot(tempScenarioSnapshot)

        setAddClaimMenu2On(false)
        setAddClaimMenu1On(false)

        setTimeout(() => {
          props.setActiveClaim(
            tempScenarioSnapshot.currentSnapshot.claims.length - 1,
          )
          setAddClaimMenu3On(true)
        }, 30)

        //Mixpanel 164 (All)
        logActivity(freemium.isFreemium, 'Pasted claim from clipboard')
      } else {
        setNothingToPaste(true)
        setTimeout(() => {
          setNothingToPaste(false)
        }, 1000)
      }
    } else {
      setNothingToPaste(true)
      setTimeout(() => {
        setNothingToPaste(false)
      }, 1000)
    }
  }

  return (
    <>
      <div
        className={`claimsPreviewMain ${
          scenarioSnapshot.currentSnapshot.claims.length === 0 ? 'noClaims' : ''
        }`}
      >
        {scenarioSnapshot.currentSnapshot.claims.length > 0 && (
          <>
            {!props.forceTiles && (
              <div className="claimsPreviewTopMenuContainer">
                <div
                  id={'claimsPreviewDisplayOptions'}
                  className={`claimsPreviewDisplayOptions ${
                    scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
                    ClaimsPreviewStyle.tiles
                      ? 'on-list'
                      : ''
                  }`}
                >
                  <WhiteTooltipMoreDelay
                    textAttribute={'tooltip-47'}
                    id={'tilesPreviewButton-tooltip'}
                    title={getText('tooltip-47', user.settings)}
                  >
                    <div
                      id="tilesPreviewButton"
                      className={`claimsPreviewOptionContainer ${
                        scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
                        ClaimsPreviewStyle.tiles
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <img
                        src={tilesView}
                        onClick={() =>
                          changeClaimsPreviewStyle(ClaimsPreviewStyle.tiles)
                        }
                        alt="tilesViewImg"
                      />
                    </div>
                  </WhiteTooltipMoreDelay>
                  <WhiteTooltipMoreDelay
                    textAttribute={'tooltip-48'}
                    id={'listPreviewButton-tooltip'}
                    title={getText('tooltip-48', user.settings)}
                  >
                    <div
                      id="listPreviewButton"
                      className={`claimsPreviewOptionContainer ${
                        scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
                        ClaimsPreviewStyle.list
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <img
                        src={listView}
                        onClick={() =>
                          changeClaimsPreviewStyle(ClaimsPreviewStyle.list)
                        }
                        alt="listViewImg"
                      />
                    </div>
                  </WhiteTooltipMoreDelay>
                  <WhiteTooltipMoreDelay
                    textAttribute={'tooltip-49'}
                    id={'expandedPreviewButton-tooltip'}
                    title={getText('tooltip-49', user.settings)}
                  >
                    <div
                      id="expandedPreviewButton"
                      className={`claimsPreviewOptionContainer ${
                        scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
                        ClaimsPreviewStyle.expanded
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <img
                        src={listViewExpanded}
                        onClick={() =>
                          changeClaimsPreviewStyle(ClaimsPreviewStyle.expanded)
                        }
                        alt="listViewExpandedImg"
                      />
                    </div>
                  </WhiteTooltipMoreDelay>
                </div>
                {scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
                  'tiles' &&
                  !props.forceTiles && (
                    <div
                      className="claimsPreviewInterestOptionsContainer"
                      id="claimsPreviewInterestOptionsContainer"
                    >
                      <DropDown
                        id={'claimsInterestViewOptionDropdown'}
                        label={getText('label-94', user.settings)}
                        labelTextAttribute={'label-94'}
                        name={'claimsInterestViewOptionDropdown'}
                        value={
                          translateInterestViewOption(
                            scenarioSnapshot.currentSnapshot.interestViewOption,
                            AvailableLanguages.english,
                            user.settings.language,
                          ) ?? interestViewOption.noInterest
                        }
                        width={'200px'}
                        height={'33px'}
                        options={
                          scenarioSnapshot.currentSnapshot.secondTrialDate
                            ? [...valuesIn(interestViewOption)]
                            : [...valuesIn(interestViewOption)].filter(
                                (value) =>
                                  value !== interestViewOption.interest2nd,
                              )
                        }
                        onChange={(e: any) => {
                          changeInterestViewOption(e.target.value)
                        }}
                        interestDropdownImg={[
                          noInterestDropdownImg,
                          interestFirstDropdownImg,
                          interestSecondDropdownImg,
                        ]}
                        disabled={
                          props.ownRole === AccessRole.VIEWER ||
                          scenarioSnapshot.currentSnapshot.firstTrialDate ===
                            undefined ||
                          !atLeastOneClaimHasInterest(
                            scenarioSnapshot.currentSnapshot,
                          )
                            ? true
                            : false
                        }
                        disabledMessage={
                          props.ownRole === AccessRole.VIEWER ? undefined : ''
                        }
                      />
                    </div>
                  )}
              </div>
            )}
            <div
              className={
                scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
                  'tiles' || props.forceTiles
                  ? 'claimsPreviewContainer'
                  : 'claimsPreviewContainerList'
              }
              id="claimsPreviewContainer"
            >
              {scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
                'tiles' &&
                !props.forceTiles && (
                  <div className="listPreviewTableNamesDiv">
                    <div className="listPreviewTitleDiv">
                      <p
                        className="listPreviewTableNameClickable"
                        id="titleListTitle"
                        onMouseEnter={() => setHoveredTitle('title')}
                        onMouseLeave={() => setHoveredTitle(undefined)}
                        onClick={() => changeSortingOption('title')}
                        data-textattribute="title-222"
                      >
                        {getText('title-222', user.settings)}
                      </p>
                      {getSortImg('title')}
                    </div>
                    <div className="listPreviewOtherTableNamesContainer">
                      <div className="listPreviewOtherTableNamesDiv">
                        <p
                          className="listPreviewTableNameClickable"
                          id="clientListTitle"
                          onMouseEnter={() => setHoveredTitle('client')}
                          onMouseLeave={() => setHoveredTitle(undefined)}
                          onClick={() => changeSortingOption('client')}
                          data-textattribute="title-223"
                          data-openreplay-obscured
                        >
                          {getText('title-223', user.settings, partiesFormat)}
                        </p>
                        {getSortImg('client')}
                      </div>
                      <div className="listPreviewOtherTableNamesDiv">
                        <p
                          className="listPreviewTableNameClickable"
                          onMouseEnter={() => setHoveredTitle('opposing')}
                          onMouseLeave={() => setHoveredTitle(undefined)}
                          onClick={() => changeSortingOption('opposing')}
                          id="opposingListTitle"
                          data-textattribute="title-224"
                          data-openreplay-obscured
                        >
                          {getText('title-224', user.settings, partiesFormat)}
                        </p>
                        {getSortImg('opposing')}
                      </div>
                      <div className="listPreviewOtherTableNamesDiv">
                        <WhiteTooltipMoreDelay
                          textAttribute={'tooltip-50'}
                          id={'weightedListTitle-tooltip'}
                          title={getText(
                            'tooltip-50',
                            user.settings,
                            partiesFormat,
                          )}
                        >
                          <p
                            className="listPreviewTableNameClickable"
                            onMouseEnter={() => setHoveredTitle('weighted')}
                            onMouseLeave={() => setHoveredTitle(undefined)}
                            onClick={() => changeSortingOption('weighted')}
                            id="weightedListTitle"
                            data-textattribute="title-225"
                          >
                            {getText('title-225', user.settings)}
                          </p>
                        </WhiteTooltipMoreDelay>
                        {getSortImg('weighted')}
                      </div>
                    </div>
                  </div>
                )}
              {scenarioSnapshot.currentSnapshot.claims.map((claim, index) => (
                <ClaimTile
                  key={`SingleClaim${index}`}
                  claimInfo={claim}
                  claimIndex={index}
                  claimPreviewId={claimPreviewIdArray[index]}
                  claimType={claim.type}
                  currency={scenarioSnapshot.currentSnapshot.currency}
                  activeClaim={props.activeClaim}
                  setActiveClaim={props.setActiveClaim}
                  removeClaim={props.removeClaim}
                  errors={props.errors ? props.errors[index] : 0}
                  treeDirectCalculation={props.treeDirectCalculation}
                  ownRole={props.ownRole}
                  claimPositionHighlight={claimPositionHighlight}
                  setClaimPositionHighlight={setClaimPositionHighlight}
                  claimIsDragging={claimIsDragging}
                  setClaimIsDragging={setClaimIsDragging}
                  calculateAllTrees={props.calculateAllTrees}
                  forceTiles={props.forceTiles}
                  setExpanded={changeExpanded}
                  changeSortingOption={changeSortingOption}
                  sorting={sorting}
                  expanded={
                    claim.type !== ClaimType.tree
                      ? expandedTiles[
                          expandedTiles.findIndex((t) => t.id === claim.id)
                        ] !== undefined
                        ? expandedTiles[
                            expandedTiles.findIndex((t) => t.id === claim.id)
                          ].expanded
                        : false
                      : false
                  }
                  setIsClaimMenuOn={changeClaimMenus}
                  isClaimMenuOn={claimMenus.filter((isOn) => isOn).length > 0}
                />
              ))}
              {scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
                'tiles' &&
                !props.forceTiles && (
                  <div className={`listPreviewTableNamesDiv`}>
                    <div
                      className={`listPreviewTitleDiv forSum ${
                        !showValues() ? 'noSums' : ''
                      }`}
                    >
                      <p
                        className="listPreviewTableName forSum"
                        id="listPreviewTableName"
                        data-textattribute={'title-226'}
                      >
                        {getText('title-226', user.settings)}
                      </p>
                    </div>
                    <div className="listPreviewOtherTableNamesContainer">
                      {showValues() ? (
                        <>
                          <div
                            className="listPreviewOtherTableNamesDiv"
                            data-openreplay-obscured
                          >
                            <p
                              className={`listPreviewTableName client selectable robotoNumbersBold ${
                                krogerusColors ? 'krogerus' : ''
                              }`}
                              id="clientAmountValue"
                            >
                              {getClientAwardedAmountSum(
                                scenarioSnapshot.currentSnapshot,
                                user.settings,
                              )}{' '}
                              {scenarioSnapshot.currentSnapshot.currency}
                            </p>
                          </div>
                          <div
                            className="listPreviewOtherTableNamesDiv"
                            data-openreplay-obscured
                          >
                            <p
                              className={`listPreviewTableName opposing selectable robotoNumbersBold ${
                                krogerusColors ? 'krogerus' : ''
                              }`}
                              id="opposingAmountValue"
                            >
                              {getOpposingPartyAwardedAmountSum(
                                scenarioSnapshot.currentSnapshot,
                                user.settings,
                              )}{' '}
                              {scenarioSnapshot.currentSnapshot.currency}
                            </p>
                          </div>
                          <div
                            className="listPreviewOtherTableNamesDiv"
                            data-openreplay-obscured
                          >
                            <p
                              className={`listPreviewTableName forSum noMargin selectable robotoNumbersBold`}
                              id="weightedAmountValue"
                            >
                              {formattedNumToString(
                                roundNumberTo(weightedValues, 2),
                                user.settings,
                              ) +
                                ' ' +
                                scenarioSnapshot.currentSnapshot.currency.toString()}
                            </p>
                          </div>
                        </>
                      ) : (
                        <Button
                          id="calculateAllTreesButton"
                          buttonText={getText('button-27', user.settings)}
                          buttonTextAttribute="button-27"
                          type="button"
                          buttonType="outlined"
                          className="calculateButton"
                          onClick={props.calculateAllTrees}
                          side="left"
                          NoUpperCase={true}
                          small={false}
                        />
                      )}
                    </div>
                  </div>
                )}
              {scenarioSnapshot.currentSnapshot.claims.length > 0 &&
                props.ownRole !== AccessRole.VIEWER && (
                  <AddClaims
                    setActiveClaim={props.setActiveClaim}
                    setVideoPostName={props.setVideoPostName}
                    addClaimMenu1On={addClaimMenu1On}
                    addClaimMenu2On={addClaimMenu2On}
                    addClaimMenu3On={addClaimMenu3On}
                    setAddClaimMenu1On={setAddClaimMenu1On}
                    setAddClaimMenu2On={setAddClaimMenu2On}
                    setAddClaimMenu3On={setAddClaimMenu3On}
                    nothingToPaste={nothingToPaste}
                    setNothingToPaste={setNothingToPaste}
                    handlePasteClaim={handlePasteClaim}
                  />
                )}
            </div>
          </>
        )}
      </div>
      {scenarioSnapshot.currentSnapshot.claims.length === 0 && (
        <EmptyClaimsComponent
          ownRole={props.ownRole}
          error={props.doValidation}
          setVideoPostName={props.setVideoPostName}
          setActiveClaim={props.setActiveClaim}
          handlePasteClaim={handlePasteClaim}
          nothingToPaste={nothingToPaste}
        />
      )}
    </>
  )
}
