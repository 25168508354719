import { useRecoilValue } from 'recoil'
import {
  LegalCostsParty,
  LegalCostsProceedings,
} from '../../../../../../../../models/enums'
import PartyClaimsAndLegalCosts from './PartyClaimsAndLegalCosts'
import ProceedingsDate from './ProceedingsDate'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ExecutiveSummaryVisibleElementsEnum } from '../../../../../../../../models/reportModels/reportEnums'
import IncludeOptionComponent from '../../IncludeOptionComponent'

type Props = {
  resultsViewParty: 'client' | 'opposing'
  reportWhitelabel?: ReportWhitelabel
  visibleElements?: ExecutiveSummaryVisibleElements
  setShowIncludeOption?: (
    showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined
  handleIncludeExcludeElement: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
}

export default function ClaimsAndLegalCosts(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const hasSecondTrial = scenarioSnapshot.currentSnapshot.probSecondTrial! > 0

  const fade =
    props.visibleElements &&
    !props.visibleElements[
      ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
    ]

  return (
    <div
      className={`claimsAndLegalCostsContainer ${
        props.visibleElements ? 'forReport' : ''
      }`}
      onMouseEnter={
        props.visibleElements
          ? () =>
              props.setShowIncludeOption!(
                ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts,
              )
          : undefined
      }
      onMouseLeave={
        props.visibleElements
          ? () => props.setShowIncludeOption!(undefined)
          : undefined
      }
    >
      {props.visibleElements &&
      props.showIncludeOption ===
        ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts ? (
        <IncludeOptionComponent
          includeElement={
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
            ]
          }
          includeElementName={
            ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
          }
          handleIncludeExcludeElement={props.handleIncludeExcludeElement}
        />
      ) : null}
      <div
        className={`partyClaimsAndLegalCostsContainer ${
          props.visibleElements ? 'forReport' : ''
        }`}
        style={
          props.resultsViewParty === 'opposing'
            ? { flexDirection: 'row-reverse', justifyContent: 'flex-end' }
            : {}
        }
      >
        <PartyClaimsAndLegalCosts
          party={LegalCostsParty.clientLegalCosts}
          hasSecondTrial={hasSecondTrial}
          resultsViewParty={props.resultsViewParty}
          visibleElements={props.visibleElements}
          reportWhitelabel={props.reportWhitelabel}
          fade={fade}
        />
        <PartyClaimsAndLegalCosts
          party={LegalCostsParty.opposingPartyLegalCosts}
          hasSecondTrial={hasSecondTrial}
          resultsViewParty={props.resultsViewParty}
          visibleElements={props.visibleElements}
          reportWhitelabel={props.reportWhitelabel}
          fade={fade}
        />
      </div>
      <div className="proceedingsDateContainer">
        {scenarioSnapshot.currentSnapshot.firstTrialDate !== undefined && (
          <ProceedingsDate
            proceedings={LegalCostsProceedings.firstProceedings}
            hasSecondTrial={hasSecondTrial}
            date={scenarioSnapshot.currentSnapshot.firstTrialDate}
            reportWhitelabel={props.reportWhitelabel}
            fade={fade}
            visibleElements={props.visibleElements}
          />
        )}
        {hasSecondTrial &&
          scenarioSnapshot.currentSnapshot.secondTrialDate !== undefined && (
            <ProceedingsDate
              proceedings={LegalCostsProceedings.additionalProceedings}
              hasSecondTrial={hasSecondTrial}
              date={scenarioSnapshot.currentSnapshot.secondTrialDate}
              reportWhitelabel={props.reportWhitelabel}
              visibleElements={props.visibleElements}
              fade={fade}
            />
          )}
      </div>
    </div>
  )
}
