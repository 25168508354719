import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  ReportStateType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  getText,
  translateDate,
} from '../../../../../../../../services/textFunctions'
import { ScenarioIdentity } from '../../../../../../../../models/generalTypes'
import { User } from '../../../../../../../../models/user'
import {
  getClientDomain,
  isDevEnv,
} from '../../../../../../../../services/commonFunctions'
import eperotoLogo from '../../../../../../../../resources/images/216-EperotoLogo_Black_Big.jpg'
import { DateTime } from 'luxon'

type Props = {
  scenarioIdentity: ScenarioIdentity
  user: User
  reportDetails: ReportStateType
  evershedsReport: boolean
  reportWhitelabel?: ReportWhitelabel
}

export default function ReportTitleContainerDocument(props: Props) {
  /* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */

  const styles = StyleSheet.create({
    caseDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minHeight: '76px',
    },
    caseDetailsContainerEversheds: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minHeight: '106px',
      alignItems: 'flex-end',
    },
    caseNameTitle: {
      fontSize: '18px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      maxWidth: '420px',
      textAlign: props.evershedsReport ? 'right' : 'left',
      lineHeight: 1.1,
    },
    caseNameSubtitle: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.lightItalic,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light-Italic',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    scenarioNameTitle: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      marginTop: '4px',
      maxWidth: '395px',
    },
    eperotoLogoAndDateContainerEversheds: {
      position: 'absolute',
      left: '-60px',
      top: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    eperotoLogoEversheds: {
      width: '125px',
      height: '90px',
      objectFit: 'contain',
      marginBottom: '1px',
      transform: 'rotate(-90deg)',
    },
    eperotoLogoAndDateContainer: {
      position: 'absolute',
      right: '0px',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    eperotoLogo: {
      width: '95px',
      height: '61px',
      objectFit: 'contain',
      marginBottom: '1px',
    },
    currentDate: {
      fontSize: '8px',
      marginTop: '3px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    currentDateEversheds: {
      fontSize: '8px',
      marginTop: '3px',
      textAlign: 'right',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
  })

  function wrapText(text: string, fontSize: number, maxWidth: number) {
    let words = text.split(' ')
    let lines = []
    let line = ''

    for (let word of words) {
      if (word.length * fontSize > maxWidth) {
        // If the word is too long to fit within the max width,
        // split it into smaller pieces and add each piece to a new line
        let chars = word.split('')
        let charLine = ''

        for (let char of chars) {
          charLine += char
          if (charLine.length * fontSize > maxWidth) {
            lines.push(charLine)
            charLine = ''
          }
        }

        if (charLine.length > 0) {
          lines.push(charLine)
        }
      } else {
        if (line.length + word.length <= maxWidth / fontSize) {
          line += `${word} `
        } else {
          lines.push(line)
          line = `${word} `
        }
      }
    }

    if (line.length > 0) {
      lines.push(line)
    }

    return lines.join('\n')
  }

  return (
    <>
      <View
        wrap={false}
        style={
          props.evershedsReport
            ? styles.caseDetailsContainerEversheds
            : styles.caseDetailsContainer
        }
      >
        <View>
          <Text style={styles.caseNameTitle}>
            {wrapText(props.scenarioIdentity.caseName, 17, 800)}
          </Text>
        </View>
        <Text
          style={styles.scenarioNameTitle}
          id="scenarioNameTitle"
          data-textattribute="description-94"
        >
          {getText('description-94', props.user.settings)}{' '}
          {props.scenarioIdentity.scenarioName}
        </Text>
        <Text
          style={styles.caseNameSubtitle}
          id="caseNameSubtitle"
          data-textattribute="description-93"
        >
          {getText('description-93', props.user.settings)}
        </Text>
        {props.evershedsReport && (
          <Text style={styles.currentDateEversheds}>
            {props.reportDetails.reportData.date === 'currentDate'
              ? translateDate(
                  DateTime.now().toFormat('dd LLL yyyy'),
                  props.user.settings.language,
                )
              : props.reportDetails.reportData.date}
          </Text>
        )}
        <View
          style={
            props.evershedsReport
              ? styles.eperotoLogoAndDateContainerEversheds
              : styles.eperotoLogoAndDateContainer
          }
        >
          <Image
            src={
              props.user.companyLogo && props.reportWhitelabel
                ? isDevEnv()
                  ? `http://localhost:8000${props.user.companyLogo}`
                  : `${'https://'}${getClientDomain()}${props.user.companyLogo}`
                : eperotoLogo
            }
            style={
              props.evershedsReport
                ? styles.eperotoLogoEversheds
                : styles.eperotoLogo
            }
          />
          {!props.evershedsReport && (
            <Text style={styles.currentDate}>
              {props.reportDetails.reportData.date === 'currentDate'
                ? translateDate(
                    DateTime.now().toFormat('dd LLL yyyy'),
                    props.user.settings.language,
                  )
                : props.reportDetails.reportData.date}
            </Text>
          )}
        </View>
      </View>
    </>
  )
}
