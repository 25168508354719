import {
  dropDownValidation,
  isProperDate,
  validateProbs,
} from '../Modules/AppModules/ValidationFunctions'
import {
  AmountSignum,
  ClaimType,
  LegalCostsSwitchButtonType,
  ToolTabs,
} from '../models/enums'
import { TabsErrors } from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { TreeEventClass } from '../models/treeModels/treeEvent'
import { EventId } from '../models/treeModels/treeTypes'
import { getAbs } from './formatNum'
import { atLeastOneClaimHasInterest } from './resultsFunctions'

const minDate = '1950-01-01'
const maxDate = '2099-12-31'

const proceedingsErrors = (
  currentSnapshot: ScenarioSnapshot,
  onlyTrials: boolean,
): [number, string[], string[]] => {
  let tabErrors = 0
  const errorsIds: string[] = []
  const errorsTabs: string[] = []

  if (!onlyTrials) {
    if (currentSnapshot.partyFormatOwn.trim() === '') {
      tabErrors++
      errorsIds.push(getIdFromSnapshotKey('customPartyFormatOwn'))
      errorsTabs.push(ToolTabs.proceedings)
    }
    if (currentSnapshot.partyFormatOther.trim() === '') {
      tabErrors++
      errorsIds.push(getIdFromSnapshotKey('customPartyFormatOther'))
      errorsTabs.push(ToolTabs.proceedings)
    }
    if (
      currentSnapshot.partyFormatOwn.trim() !== '' &&
      currentSnapshot.partyFormatOwn.trim() ===
        currentSnapshot.partyFormatOther.trim()
    ) {
      tabErrors += 2
      errorsIds.push(getIdFromSnapshotKey('partyFormatOwn'))
      errorsIds.push(getIdFromSnapshotKey('partyFormatOther'))
      errorsTabs.push(ToolTabs.proceedings)
      errorsTabs.push(ToolTabs.proceedings)
    }
    if (
      !dropDownValidation(
        currentSnapshot.court ? currentSnapshot.court.toString() : '',
      )
    ) {
      tabErrors++
      errorsIds.push(getIdFromSnapshotKey('court'))
      errorsTabs.push(ToolTabs.proceedings)
    }
  }

  if (
    currentSnapshot.firstTrialDate &&
    currentSnapshot.secondTrialDate &&
    currentSnapshot.firstTrialDate >= currentSnapshot.secondTrialDate
  ) {
    tabErrors++
    errorsIds.push(`${getIdFromSnapshotKey('secondTrialDate')}:validation`)
    errorsTabs.push(ToolTabs.proceedings)
  }

  if (atLeastOneClaimHasInterest(currentSnapshot)) {
    if (
      !currentSnapshot.firstTrialDate ||
      currentSnapshot.firstTrialDate > maxDate ||
      currentSnapshot.firstTrialDate < minDate
    ) {
      tabErrors++
      errorsIds.push(getIdFromSnapshotKey('firstTrialDate'))
      errorsTabs.push(ToolTabs.proceedings)
    }
    if (currentSnapshot.hasSecondTrial) {
      if (
        !currentSnapshot.secondTrialDate ||
        currentSnapshot.secondTrialDate > maxDate ||
        currentSnapshot.secondTrialDate < minDate
      ) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('secondTrialDate'))
        errorsTabs.push(ToolTabs.proceedings)
      }
    }
  }

  if (currentSnapshot.hasSecondTrial) {
    if (!onlyTrials) {
      if (currentSnapshot.probSecondTrialstep1 === undefined) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('probSecondTrialstep1'))
        errorsTabs.push(ToolTabs.proceedings)
      }
      if (currentSnapshot.probSecondTrialstep2 === undefined) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('probSecondTrialstep2'))
        errorsTabs.push(ToolTabs.proceedings)
      }
    }
  }

  return [tabErrors, errorsIds, errorsTabs]
}

const treeErrors = (
  index: number,
  events: {
    [eventId: EventId]: TreeEventClass
  },
  firstTrialDate: string | undefined,
): [number, string[], string[]] => {
  let errorsNum = 0
  const errorsIds = []
  const errorsTabs = []

  for (let eventId of Object.keys(events)) {
    const event = events[eventId as EventId]
    if (event.eventDetails.hasInterest && !event.eventDetails.interestDate) {
      errorsNum++
      errorsIds.push(
        getIdFromSnapshotKey(
          'claims',
          index,
          'interestDate',
          ClaimType.tree,
          event.eventIndex,
          event.nodeOfEventId,
        ),
      )
      errorsTabs.push(`${ToolTabs.claims}:${index}`)
    } else if (
      event.eventDetails.hasInterest &&
      event.eventDetails.interestDate &&
      firstTrialDate &&
      (event.eventDetails.interestDate >= firstTrialDate ||
        event.eventDetails.interestDate > maxDate ||
        event.eventDetails.interestDate < minDate)
    ) {
      errorsNum++
      errorsIds.push(
        `${getIdFromSnapshotKey(
          'claims',
          index,
          'interestDate',
          ClaimType.tree,
          event.eventIndex,
          event.nodeOfEventId,
        )}:validation`,
      )
      errorsTabs.push(`${ToolTabs.claims}:${index}`)
    }
  }

  return [errorsNum, errorsIds, errorsTabs]
}

const claimsErrors = (
  currentSnapshot: ScenarioSnapshot,
  treeIndex?: number,
): [Record<number, number> | number, string[], string[]] => {
  if (currentSnapshot.claims.length === 0) {
    return [1, [], []]
  }

  let tabErrors: Record<number, number> = {}
  let errorsIds: string[] = []
  let errorsTabs: string[] = []

  currentSnapshot.claims.forEach((claim, index) => {
    tabErrors[index] = 0
    if (
      (claim.type === 'claim' || claim.type === 'counterclaim') &&
      treeIndex === undefined
    ) {
      const indepedentClaim = claim as IndependentClaim
      if (!indepedentClaim.name) {
        tabErrors[index]++
        errorsIds.push(
          getIdFromSnapshotKey('claims', index, 'name', ClaimType.claim),
        )
        errorsTabs.push(`${ToolTabs.claims}:${index}`)
      }
      if (indepedentClaim.amount === undefined) {
        tabErrors[index]++
        errorsIds.push(
          getIdFromSnapshotKey('claims', index, 'amount', ClaimType.claim),
        )
        errorsTabs.push(`${ToolTabs.claims}:${index}`)
      }
      if (indepedentClaim.isInterest) {
        if (indepedentClaim.interestRate === undefined) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'interestRate',
              ClaimType.claim,
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        } else if (!validateProbs(indepedentClaim.interestRate)) {
          tabErrors[index]++
          errorsIds.push(
            `${getIdFromSnapshotKey(
              'claims',
              index,
              'interestRate',
              ClaimType.claim,
            )}:validation`,
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }

        if (!indepedentClaim.interestDate) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'interestDate',
              ClaimType.claim,
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        } else if (
          !isProperDate(
            indepedentClaim.interestDate,
            currentSnapshot.firstTrialDate,
          )
        ) {
          tabErrors[index]++
          errorsIds.push(
            `${getIdFromSnapshotKey(
              'claims',
              index,
              'interestDate',
              ClaimType.claim,
            )}:validation`,
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }
      }

      indepedentClaim.liabilityRequirements.forEach((req, index2) => {
        if (!req.title) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'liabilityRequirements',
              ClaimType.claim,
              index2,
              'title',
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }
        if (!validateProbs(req.probability)) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'liabilityRequirements',
              ClaimType.claim,
              index2,
              'probability',
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }
      })

      indepedentClaim.quantumScenarios.forEach((scenario, index2) => {
        if (scenario.amount === undefined && index2 > 0) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'quantumScenarios',
              ClaimType.claim,
              index2,
              'amount',
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        } else if (
          indepedentClaim.amount !== undefined &&
          scenario.amount !== undefined &&
          getAbs(indepedentClaim.amount) < getAbs(scenario.amount)
        ) {
          tabErrors[index]++
          errorsIds.push(
            `${getIdFromSnapshotKey(
              'claims',
              index,
              'quantumScenarios',
              ClaimType.claim,
              index2,
              'amount',
            )}:validation`,
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }

        if (
          scenario.outOfCourtSignum !== AmountSignum.off &&
          scenario.outOfCourtAmount === undefined
        ) {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey(
              'claims',
              index,
              'quantumScenarios',
              ClaimType.claim,
              index2,
              'outOfCourtAmount',
            ),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }
      })
    } else {
      if (
        (treeIndex === undefined || treeIndex === index) &&
        claim.type === 'tree'
      ) {
        if ((claim as TreeClaim).name === '') {
          tabErrors[index]++
          errorsIds.push(
            getIdFromSnapshotKey('claims', index, 'name', ClaimType.tree),
          )
          errorsTabs.push(`${ToolTabs.claims}:${index}`)
        }

        tabErrors[index] += treeErrors(
          index,
          (claim as TreeClaim).treeDetails.events,
          (claim as TreeClaim).trialDates.firstTrialDate,
        )[0]

        errorsIds = [
          ...errorsIds,
          ...treeErrors(
            index,
            (claim as TreeClaim).treeDetails.events,
            (claim as TreeClaim).trialDates.firstTrialDate,
          )[1],
        ]

        errorsTabs = [
          ...errorsTabs,
          ...treeErrors(
            index,
            (claim as TreeClaim).treeDetails.events,
            (claim as TreeClaim).trialDates.firstTrialDate,
          )[2],
        ]
      }
    }
  })

  return [tabErrors, errorsIds, errorsTabs]
}

const legalCostsErrors = (
  currentSnapshot: ScenarioSnapshot,
): [number, string[], string[]] => {
  let tabErrors = 0
  const errorsIds: string[] = []
  const errorsTabs: string[] = []
  if (currentSnapshot.legalCosts.included) {
    if (
      currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .estimatedCosts === undefined
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey(
          'estimatedCosts-clientLegalCosts-firstProceedings',
        ),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }
    if (
      currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .recoverableCosts.value === undefined ||
      (currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .recoverableCosts.type === LegalCostsSwitchButtonType.currency &&
        currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
          .recoverableCosts.value >
          currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
            .estimatedCosts!)
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey(
          'recoverableCosts-clientLegalCosts-firstProceedings',
        ),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }
    if (
      currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .incurredCosts.value === undefined ||
      (currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .incurredCosts.type === LegalCostsSwitchButtonType.currency &&
        currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
          .incurredCosts.value >
          currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
            .estimatedCosts!)
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey('incurredCosts-clientLegalCosts-firstProceedings'),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }
    if (
      currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .estimatedCosts === undefined
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey(
          'estimatedCosts-opposingPartyLegalCosts-firstProceedings',
        ),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }
    if (
      currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .recoverableCosts.value === undefined ||
      (currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .recoverableCosts.type === LegalCostsSwitchButtonType.currency &&
        currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
          .recoverableCosts.value >
          currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
            .estimatedCosts!)
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey(
          'recoverableCosts-opposingPartyLegalCosts-firstProceedings',
        ),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }
    if (
      currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .incurredCosts.value === undefined ||
      (currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .incurredCosts.type === LegalCostsSwitchButtonType.currency &&
        currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
          .incurredCosts.value >
          currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
            .estimatedCosts!)
    ) {
      tabErrors++
      errorsIds.push(
        getIdFromSnapshotKey(
          'incurredCosts-opposingPartyLegalCosts-firstProceedings',
        ),
      )
      errorsTabs.push(ToolTabs.legalCosts)
    }

    if (currentSnapshot.hasSecondTrial) {
      if (
        currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings!
          .estimatedCosts === undefined
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'estimatedCosts-clientLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (
        currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
          ?.recoverableCosts.value === undefined ||
        (currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
          ?.recoverableCosts.type === LegalCostsSwitchButtonType.currency &&
          currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
            ?.recoverableCosts.value >
            currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
              ?.estimatedCosts!)
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'recoverableCosts-clientLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (
        currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
          ?.incurredCosts.value === undefined ||
        (currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
          ?.incurredCosts.type === LegalCostsSwitchButtonType.currency &&
          currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
            ?.incurredCosts.value >
            currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
              ?.estimatedCosts!)
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'incurredCosts-clientLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (
        currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings!.estimatedCosts === undefined
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'estimatedCosts-opposingPartyLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (
        currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings?.recoverableCosts.value === undefined ||
        (currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings?.recoverableCosts.type ===
          LegalCostsSwitchButtonType.currency &&
          currentSnapshot.legalCosts.opposingPartyLegalCosts
            ?.additionalProceedings?.recoverableCosts.value >
            currentSnapshot.legalCosts.opposingPartyLegalCosts
              ?.additionalProceedings?.estimatedCosts!)
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'recoverableCosts-opposingPartyLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (
        currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings?.incurredCosts.value === undefined ||
        (currentSnapshot.legalCosts.opposingPartyLegalCosts
          ?.additionalProceedings?.incurredCosts.type ===
          LegalCostsSwitchButtonType.currency &&
          currentSnapshot.legalCosts.opposingPartyLegalCosts
            ?.additionalProceedings?.incurredCosts.value >
            currentSnapshot.legalCosts.opposingPartyLegalCosts
              ?.additionalProceedings?.estimatedCosts!)
      ) {
        tabErrors++
        errorsIds.push(
          getIdFromSnapshotKey(
            'incurredCosts-opposingPartyLegalCosts-additionalProceedings',
          ),
        )
        errorsTabs.push(ToolTabs.legalCosts)
      }
    }

    if (currentSnapshot.hasInsurance) {
      if (currentSnapshot.maxInsurance === undefined) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('maxInsurance'))
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (currentSnapshot.insuranceExcessPercentage === undefined) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('insuranceExcessPercentage'))
        errorsTabs.push(ToolTabs.legalCosts)
      }
      if (currentSnapshot.minimumExcessFee === undefined) {
        tabErrors++
        errorsIds.push(getIdFromSnapshotKey('minimumExcessFee'))
        errorsTabs.push(ToolTabs.legalCosts)
      }
    }
  }

  return [tabErrors, errorsIds, errorsTabs]
}

const enforcementErrors = (
  currentSnapshot: ScenarioSnapshot,
): [number, string[], string[]] => {
  let tabErrors = 0
  const errorsIds: string[] = []
  const errorsTabs: string[] = []

  if (
    currentSnapshot.hasBankruptcy &&
    currentSnapshot.bankruptcyPercentage === undefined
  ) {
    tabErrors++
    errorsIds.push(getIdFromSnapshotKey('bankruptcyPercentage'))
    errorsTabs.push(ToolTabs.advanced)
  }

  return [tabErrors, errorsIds, errorsTabs]
}

export const validateGlobalState = (
  currentSnapshot: ScenarioSnapshot,
  treeIndex?: number,
): [TabsErrors, string[], string[]] => {
  if (treeIndex === undefined) {
    let allTabsErrors: TabsErrors = {
      [ToolTabs.proceedings]: proceedingsErrors(currentSnapshot, false)[0],
      [ToolTabs.claims]: claimsErrors(currentSnapshot)[0],
      [ToolTabs.legalCosts]: legalCostsErrors(currentSnapshot)[0],
      [ToolTabs.advanced]: enforcementErrors(currentSnapshot)[0],
    }

    const allErrorsIds = [
      ...proceedingsErrors(currentSnapshot, false)[1],
      ...claimsErrors(currentSnapshot)[1],
      ...legalCostsErrors(currentSnapshot)[1],
      ...enforcementErrors(currentSnapshot)[1],
    ]

    const allErrorsTabs = [
      ...proceedingsErrors(currentSnapshot, false)[2],
      ...claimsErrors(currentSnapshot)[2],
      ...legalCostsErrors(currentSnapshot)[2],
      ...enforcementErrors(currentSnapshot)[2],
    ]

    return [allTabsErrors, allErrorsIds, allErrorsTabs]
  } else {
    const treeHasInterest = checkTreeHasInterest(
      currentSnapshot.claims[treeIndex] as TreeClaim,
    )

    let allTabsErrors: TabsErrors = {
      [ToolTabs.proceedings]: treeHasInterest
        ? proceedingsErrors(currentSnapshot, true)[0]
        : 0,
      [ToolTabs.claims]: claimsErrors(currentSnapshot, treeIndex)[0],
      [ToolTabs.legalCosts]: 0,
      [ToolTabs.advanced]: 0,
    }

    const allErrorsIds = treeHasInterest
      ? [
          ...proceedingsErrors(currentSnapshot, true)[1],
          ...claimsErrors(currentSnapshot, treeIndex)[1],
        ]
      : [...claimsErrors(currentSnapshot, treeIndex)[1]]

    const allErrorsTabs = treeHasInterest
      ? [
          ...proceedingsErrors(currentSnapshot, true)[2],
          ...claimsErrors(currentSnapshot, treeIndex)[2],
        ]
      : [...claimsErrors(currentSnapshot, treeIndex)[2]]

    return [allTabsErrors, allErrorsIds, allErrorsTabs]
  }
}

export const checkTreeHasInterest = (tree: TreeClaim) => {
  for (let treeEvent of Object.values(tree.treeDetails.events)) {
    if (treeEvent.eventDetails.hasInterest) {
      return true
    }
  }

  return false
}

export const hasTabsErrors = (tabsErros: TabsErrors) => {
  return (
    Object.values(tabsErros).filter((value) => {
      if (typeof value === 'number') {
        return value > 0
      } else {
        return Object.values(value).filter((value2) => value2 > 0).length > 0
      }
    }).length > 0
  )
}

export const firstTabWithErrors = (
  tabsErrors: TabsErrors,
  firstClaimTab?: boolean,
) => {
  if (firstClaimTab && firstClaimWithError(tabsErrors) !== undefined) {
    return [ToolTabs.claims, firstClaimWithError(tabsErrors)]
  }

  const errorTab = Object.keys(tabsErrors).find((key) => {
    const correctKey = key as keyof TabsErrors

    if (typeof tabsErrors[correctKey] === 'number') {
      return (tabsErrors[correctKey] as number) > 0
    } else {
      return (
        Object.values(tabsErrors[correctKey]).filter((value2) => value2 > 0)
          .length > 0
      )
    }
  }) as ToolTabs

  if (
    errorTab === ToolTabs.claims &&
    typeof tabsErrors[errorTab] !== 'number'
  ) {
    const claimIndex = parseInt(
      Object.keys(tabsErrors[errorTab]).find(
        (key) =>
          (tabsErrors[errorTab] as Record<number, number>)[parseInt(key)] > 0,
      )!,
    )

    return [errorTab, claimIndex]
  }

  return [errorTab]
}

export const firstClaimWithError = (tabsErrors: TabsErrors) => {
  if (typeof tabsErrors[ToolTabs.claims] === 'number') {
    return undefined
  }

  const claimIndex = Object.keys(tabsErrors[ToolTabs.claims]).find(
    (key) =>
      (tabsErrors[ToolTabs.claims] as Record<number, number>)[parseInt(key)] >
      0,
  )

  return claimIndex !== undefined ? parseInt(claimIndex) : undefined
}

export const getIdFromSnapshotKey = (
  key: string,
  index?: number,
  key2?: string,
  type?: ClaimType,
  index2?: number,
  key3?: string,
) => {
  switch (key) {
    case 'court':
      return 'typeOfInstance'
    case 'partyFormatOwn':
      return 'partyFormatOwnDropdown'
    case 'partyFormatOther':
      return 'partyFormatOtherDropdown'
    case 'customPartyFormatOwn':
      return 'customPartyFormatOwn'
    case 'customPartyFormatOther':
      return 'customPartyFormatOther'
    case 'firstTrialDate':
      return 'firstTrialDate'
    case 'secondTrialDate':
      return 'secondTrialDate'
    case 'probSecondTrialstep1':
      return 'probSecondTrialstep1'
    case 'probSecondTrialstep2':
      return 'probSecondTrialstep2'
    case 'estimatedCosts-clientLegalCosts-firstProceedings':
      return 'estimatedCosts-clientLegalCosts-firstProceedings'
    case 'recoverableCosts-clientLegalCosts-firstProceedings':
      return 'recoverableCosts-clientLegalCosts-firstProceedings'
    case 'incurredCosts-clientLegalCosts-firstProceedings':
      return 'incurredCosts-clientLegalCosts-firstProceedings'
    case 'estimatedCosts-clientLegalCosts-additionalProceedings':
      return 'estimatedCosts-clientLegalCosts-additionalProceedings'
    case 'recoverableCosts-clientLegalCosts-additionalProceedings':
      return 'recoverableCosts-clientLegalCosts-additionalProceedings'
    case 'incurredCosts-clientLegalCosts-additionalProceedings':
      return 'incurredCosts-clientLegalCosts-additionalProceedings'
    case 'estimatedCosts-opposingPartyLegalCosts-firstProceedings':
      return 'estimatedCosts-opposingPartyLegalCosts-firstProceedings'
    case 'recoverableCosts-opposingPartyLegalCosts-firstProceedings':
      return 'recoverableCosts-opposingPartyLegalCosts-firstProceedings'
    case 'incurredCosts-opposingPartyLegalCosts-firstProceedings':
      return 'incurredCosts-opposingPartyLegalCosts-firstProceedings'
    case 'estimatedCosts-opposingPartyLegalCosts-additionalProceedings':
      return 'estimatedCosts-opposingPartyLegalCosts-additionalProceedings'
    case 'recoverableCosts-opposingPartyLegalCosts-additionalProceedings':
      return 'recoverableCosts-opposingPartyLegalCosts-additionalProceedings'
    case 'incurredCosts-opposingPartyLegalCosts-additionalProceedings':
      return 'incurredCosts-opposingPartyLegalCosts-additionalProceedings'
    case 'minimumExcessFee':
      return 'minimumExcessFee'
    case 'insuranceExcessPercentage':
      return 'insuranceExcessPercentage'
    case 'maxInsurance':
      return 'maxInsurance'
    case 'bankruptcyPercentage':
      return 'bankruptcyPercentage'
    case 'claims':
      if (key2 === undefined || index === undefined || type === undefined) {
        return ''
      } else if (key2 === 'name' && type !== ClaimType.tree) {
        return `claimName-${index}`
      } else if (key2 === 'amount') {
        return `claimAmount-${index}`
      } else if (key2 === 'interestRate') {
        return `claimInterestRate-${index}`
      } else if (key2 === 'interestDate' && type !== ClaimType.tree) {
        return `claimInterestDate-${index}`
      } else if (key2 === 'liabilityRequirements') {
        if (key3 === undefined || index2 === undefined) {
          return ''
        }

        if (key3 === 'title') {
          return `requirementText-${index}_${index2}`
        } else if (key3 === 'probability') {
          return `requirementLikelihood-${index}_${index2}`
        } else {
          return ''
        }
      } else if (key2 === 'quantumScenarios') {
        if (key3 === undefined || index2 === undefined) {
          return ''
        }

        if (key3 === 'probability') {
          return `claimQuantumProbability-${index}_${index2}`
        } else if (key3 === 'amount') {
          return `claimAltAmount-${index}_${index2}`
        } else if (key3 === 'outOfCourtAmount') {
          return `claimOutOfCourtAmount-${index}_${index2}`
        } else {
          return ''
        }
      } else if (key2 === 'name' && type === ClaimType.tree) {
        return `treeNameInput-${index}`
      } else if (key2 === 'interestDate' && type === ClaimType.tree) {
        return `treeEventInterestDateInput-${index}_${key3}!${index2}`
      }
      break
    default:
      return ''
  }

  return ''
}

export const focusOnFirstError = (
  errors: string[],
  errorsIdTabs: string[],
  activeTabs: { tab: ToolTabs; claim: number },
) => {
  if (errors.length > 0) {
    const index = errorsIdTabs.findIndex((tab) => {
      if (activeTabs.tab === ToolTabs.claims) {
        return (
          tab.includes(':') &&
          tab.split(':')[0] === activeTabs.tab &&
          tab.split(':')[1] === activeTabs.claim.toString()
        )
      } else {
        return tab === activeTabs.tab.toString()
      }
    })

    if (index > -1) {
      const id = errors[index].includes('validation')
        ? errors[index].split(':')[0]
        : errors[index]
      if (id.includes('Date') || id.includes('date')) {
        if (document.getElementById(`container-${id}`)) {
          document
            .getElementById(`container-${id}`)!
            .getElementsByTagName('input')[0]
            .focus()
        }
      } else {
        if (document.getElementById(id)) {
          document.getElementById(id)!.focus()
        }
      }
    }
  }
}

export function secondTrialDateIsValid(currentSnapshot: ScenarioSnapshot) {
  return (
    currentSnapshot['secondTrialDate'] !== undefined &&
    currentSnapshot['firstTrialDate'] !== undefined &&
    currentSnapshot['firstTrialDate'] < currentSnapshot['secondTrialDate']
  )
}
