import { JSX } from 'react'
import { TutorialTileObject } from '../models/generalTypes'
import parse from 'html-react-parser'

export function getTextFromParsedArray(array: JSX.Element) {
  let text = ''
  if (array.props.children.props) {
    if (typeof array.props.children.props.children === 'string') {
      text = array.props.children.props.children
    } else {
      array.props.children.props.children.forEach((child: any) => {
        if (typeof child === 'string') {
          text += child
        } else if (child.props) {
          text += child.props.children
        }
      })
    }
  } else {
    if (typeof array.props.children === 'string') {
      text = array.props.children
    } else {
      array.props.children.forEach((child: any) => {
        if (typeof child === 'string') {
          text += child
        } else if (child.props) {
          text += child.props.children
        }
      })
    }
  }
  return text
}

export function initiliazeListOfTutorials(listFromWordpress: any[]) {
  let tempListOfTutorials: TutorialTileObject[] = []

  for (let i in listFromWordpress) {
    let tutorial: TutorialTileObject = {
      title: listFromWordpress[i]['title']['rendered'].replace('Private: ', ''),
      slug: listFromWordpress[i]['slug'],
      summary: '',
      thumbnail: '',
      parsedMainText: [],
      estimatedTime: '',
    }
    let tutorialContentArray = parse(
      listFromWordpress[i]['content']['rendered'],
    ) as JSX.Element[]

    for (let j = 0; j < tutorialContentArray.length; j++) {
      if (tutorialContentArray[j].type === 'h5') {
        tutorial.summary = getTextFromParsedArray(tutorialContentArray[j])
        tutorialContentArray.splice(j, 1)
        break
      }
    }
    for (let j = 0; j < tutorialContentArray.length; j++) {
      if (tutorialContentArray[j].type === 'h6') {
        tutorial.estimatedTime = getTextFromParsedArray(tutorialContentArray[j])
        tutorialContentArray.splice(j, 1)
        break
      }
    }

    for (let j = 0; j < tutorialContentArray.length; j++) {
      if (tutorialContentArray[j].type === 'figure') {
        if (tutorialContentArray[j].props.children) {
          if (
            tutorialContentArray[j].props.children.props.src.includes('.svg')
          ) {
            tutorial.thumbnail =
              tutorialContentArray[j].props.children.props.src
          }
          tutorialContentArray.splice(j, 1)
        }

        break
      }
    }
    tutorial.parsedMainText = tutorialContentArray
    tempListOfTutorials.push(tutorial)
  }
  return tempListOfTutorials
}
