import { DateTime } from 'luxon'
import { manosVersion } from '../ManosVersion'
import { roundNumberTo } from '../services/commonFunctions'
import { tikasVersion } from '../TikasVersion'
import {
  ClaimsPreviewStyle,
  Currency,
  InterestViewOption,
  PartyFormat,
  TypeOfInstance,
} from './enums'
import { IndependentClaim } from './independentClaim'
import { LegalFeesDistributionObject } from './legalFeesDistributionObject'
import { TreeClaim } from './treeModels/treeClaim'
import { LegalCostsObject } from './legalCostsModels/legalCosts'
import { LegalCostsDistributionSettings } from './legalCostsDistributionSettings'

export class ScenarioSnapshot {
  version: number
  currency: Currency
  court: TypeOfInstance | undefined
  partyFormatOwn: keyof typeof PartyFormat | string
  partyFormatOther: keyof typeof PartyFormat | string
  firstTrialDate: string | undefined
  secondTrialDate: string | undefined
  legalCosts: LegalCostsObject
  hasInsurance: boolean
  maxInsurance: number | undefined
  insuranceExcessPercentage: number | undefined
  minimumExcessFee: number | undefined
  hasBankruptcy: boolean
  bankruptcyPercentage: number | undefined
  hasSecondTrial: boolean
  probSecondTrialstep1: number | undefined
  probSecondTrialstep2: number | undefined
  probSecondTrial: number | undefined
  expectedResults: number | undefined
  legalFeesDistribution: LegalFeesDistributionObject[]
  legalCostsDistributionSettings: LegalCostsDistributionSettings
  includeTopLFDLimit: boolean
  includeBottomLFDLimit: boolean
  claims: (IndependentClaim | TreeClaim)[]
  claimsPreviewStyle: ClaimsPreviewStyle
  interestViewOption: InterestViewOption

  constructor(
    version: number,
    currency: Currency,
    court: TypeOfInstance | undefined,
    partyFormatOwn: keyof typeof PartyFormat | string,
    partyFormatOther: keyof typeof PartyFormat | string,
    firstTrialDate: string | undefined,
    secondTrialDate: string | undefined,
    legalCosts: LegalCostsObject,
    hasInsurance: boolean,
    maxInsurance: number | undefined,
    insuranceExcessPercentage: number | undefined,
    minimumExcessFee: number | undefined,
    hasBankruptcy: boolean,
    bankruptcyPercentage: number | undefined,
    hasSecondTrial: boolean,
    probSecondTrialstep1: number | undefined,
    probSecondTrialstep2: number | undefined,
    probSecondTrial: number | undefined,
    expectedResults: number,
    legalFeesDistribution: LegalFeesDistributionObject[],
    legalCostsDistributionSettings: LegalCostsDistributionSettings,
    includeTopLFDLimit: boolean,
    includeBottomLFDLimit: boolean,
    claims: (IndependentClaim | TreeClaim)[],
    claimsPreviewStyle: ClaimsPreviewStyle,
    interestViewOption: InterestViewOption,
  ) {
    this.version = version
    this.currency = currency
    this.court = court
    this.partyFormatOwn = partyFormatOwn
    this.partyFormatOther = partyFormatOther
    this.firstTrialDate = firstTrialDate
    this.secondTrialDate = secondTrialDate
    this.legalCosts = legalCosts
    this.hasInsurance = hasInsurance
    this.maxInsurance = maxInsurance
    this.insuranceExcessPercentage = insuranceExcessPercentage
    this.minimumExcessFee = minimumExcessFee
    this.hasBankruptcy = hasBankruptcy
    this.bankruptcyPercentage = bankruptcyPercentage
    this.hasSecondTrial = hasSecondTrial
    this.probSecondTrialstep1 = probSecondTrialstep1
    this.probSecondTrialstep2 = probSecondTrialstep2
    this.probSecondTrial = probSecondTrial
    this.expectedResults = expectedResults
    this.legalFeesDistribution = [...legalFeesDistribution]
    this.legalCostsDistributionSettings = legalCostsDistributionSettings
    this.includeTopLFDLimit = includeTopLFDLimit
    this.includeBottomLFDLimit = includeBottomLFDLimit
    this.claims = [...claims]
    this.claimsPreviewStyle = claimsPreviewStyle
    this.interestViewOption = interestViewOption
  }

  static EmptySnapshot(
    partyFormatOwn: keyof typeof PartyFormat,
    partyFormatOther: keyof typeof PartyFormat,
    currency: Currency,
  ): ScenarioSnapshot {
    let tempSnapshot = new ScenarioSnapshot(
      roundNumberTo(manosVersion + tikasVersion, 2),
      currency,
      undefined,
      partyFormatOwn,
      partyFormatOther,
      undefined,
      undefined,
      LegalCostsObject.DefaultLegalCosts(),
      false,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      0,
      [],
      LegalCostsDistributionSettings.DefaultSettings(),
      true,
      true,
      [],
      ClaimsPreviewStyle.tiles,
      InterestViewOption.noInterest,
    )

    tempSnapshot.legalFeesDistribution = [
      ...ScenarioSnapshot.CreateStandardLCDObject(),
    ]

    return tempSnapshot
  }

  static EmptySnapshotWithDateAndCourt(
    partyFormatOwn: keyof typeof PartyFormat,
    partyFormatOther: keyof typeof PartyFormat,
    currency: Currency,
  ): ScenarioSnapshot {
    let tempSnapshot = new ScenarioSnapshot(
      roundNumberTo(manosVersion + tikasVersion, 2),
      currency,
      TypeOfInstance.Public_court,
      partyFormatOwn,
      partyFormatOther,
      DateTime.local().toFormat('yyyy-MM-dd'),
      undefined,
      LegalCostsObject.DefaultLegalCosts(),
      false,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      0,
      [],
      LegalCostsDistributionSettings.DefaultSettings(),
      true,
      true,
      [],
      ClaimsPreviewStyle.tiles,
      InterestViewOption.noInterest,
    )

    tempSnapshot.legalFeesDistribution = [
      ...ScenarioSnapshot.CreateStandardLCDObject(),
    ]

    return tempSnapshot
  }

  static EmptySnapshotFreemium(
    partyFormatOwn: keyof typeof PartyFormat,
    partyFormatOther: keyof typeof PartyFormat,
    currency: Currency,
  ): ScenarioSnapshot {
    let tempSnapshot = new ScenarioSnapshot(
      roundNumberTo(manosVersion + tikasVersion, 2),
      currency,
      TypeOfInstance.Public_court,
      partyFormatOwn,
      partyFormatOther,
      DateTime.local().plus({ years: 1 }).toFormat('yyyy-MM-dd'),
      undefined,
      LegalCostsObject.DefaultLegalCosts(),
      false,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      0,
      [],
      LegalCostsDistributionSettings.DefaultSettings(),
      true,
      true,
      [],
      ClaimsPreviewStyle.tiles,
      InterestViewOption.noInterest,
    )

    tempSnapshot.legalFeesDistribution = [
      ...ScenarioSnapshot.CreateStandardLCDObject(),
    ]

    return tempSnapshot
  }

  static CreateDefaultCustomLCDObject() {
    let legalFeesDistribution = []
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(0, -10, 0, 1, [0, 0]),
    )

    legalFeesDistribution.push(
      new LegalFeesDistributionObject(1, -10, 0, 1, [0, 33.3333333333333]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(
        2,
        0,
        0,
        0,
        [33.3333333333333, 66.6666666666666],
      ),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(3, 10, 1, 0, [66.6666666666666, 100]),
    )

    legalFeesDistribution.push(
      new LegalFeesDistributionObject(4, 10, 1, 0, [100, 100]),
    )

    return legalFeesDistribution
  }

  static CreateStandardLCDObject() {
    let legalFeesDistribution = []
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(0, -10, 0, 1, [0, 0]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(1, -10, 0, 1, [0, 20]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(2, -5, 0, 0.5, [20, 40]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(3, 0, 0, 0, [40, 60]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(4, 5, 0.5, 0, [60, 80]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(5, 10, 1, 0, [80, 100]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(6, 10, 1, 0, [100, 100]),
    )

    return legalFeesDistribution
  }

  static CreateEachPartyPaysLCDObject() {
    let legalFeesDistribution = []
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(0, 0, 0, 0, [0, 0]),
    )

    legalFeesDistribution.push(
      new LegalFeesDistributionObject(1, 0, 0, 0, [0, 33.3333333333333]),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(
        0,
        0,
        0,
        0,
        [33.3333333333333, 66.6666666666666],
      ),
    )
    legalFeesDistribution.push(
      new LegalFeesDistributionObject(3, 0, 0, 0, [66.6666666666666, 100]),
    )

    legalFeesDistribution.push(
      new LegalFeesDistributionObject(4, 0, 0, 0, [100, 100]),
    )

    return legalFeesDistribution
  }

  static toJson(data: ScenarioSnapshot): ScenarioSnapshot {
    return {
      version: data.version,
      currency: data.currency.toString() as Currency,
      court: (data.court ? data.court.toString() : undefined) as TypeOfInstance,
      partyFormatOwn: data.partyFormatOwn,
      partyFormatOther: data.partyFormatOther,
      firstTrialDate: data.firstTrialDate,
      secondTrialDate: data.secondTrialDate,
      legalCosts: data.legalCosts,
      hasInsurance: data.hasInsurance,
      maxInsurance: data.maxInsurance,
      insuranceExcessPercentage: data.insuranceExcessPercentage,
      minimumExcessFee: data.minimumExcessFee,
      hasBankruptcy: data.hasBankruptcy,
      bankruptcyPercentage: data.bankruptcyPercentage,
      hasSecondTrial: data.hasSecondTrial,
      probSecondTrialstep1: data.probSecondTrialstep1,
      probSecondTrialstep2: data.probSecondTrialstep2,
      probSecondTrial: data.probSecondTrial,
      expectedResults: data.expectedResults,
      legalFeesDistribution: data.legalFeesDistribution
        ? data.legalFeesDistribution.map((lfdo) =>
            LegalFeesDistributionObject.toJson(lfdo),
          )
        : [],
      legalCostsDistributionSettings: data.legalCostsDistributionSettings,
      includeTopLFDLimit: data.includeTopLFDLimit,
      includeBottomLFDLimit: data.includeBottomLFDLimit,
      claims: data.claims
        ? data.claims.map((claim) => {
            if (claim instanceof IndependentClaim) {
              return IndependentClaim.toJson(claim)
            } else {
              return JSON.stringify(claim)
            }
          })
        : [],
      claimsPreviewStyle:
        data.claimsPreviewStyle.toString() as ClaimsPreviewStyle,
      interestViewOption: data.interestViewOption,
    }
  }
}
