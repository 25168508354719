import React from 'react'
import {
  ResultsObject,
  ResultsSettings,
  ValuesBreakdown,
} from '../../../../../../models/generalTypes'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'

import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import useWindowSize from '../../../../../../customHooks/useWindowSize'
import { breakDownValueFontSize } from '../../../../../../services/commonFunctions'
import { includedIncurredCosts } from '../../../../../../services/resultsFunctions'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'

type Props = {
  values: ValuesBreakdown
  type: 'positive' | 'negative'
  resultsSettings: ResultsSettings
  highestValue: number
  resultsFromBackend: ResultsObject
  sortedValues: string[] | undefined
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  resultsSnapshot: ScenarioSnapshot
}

export default function ValueBreakdownGraph(props: Props) {
  const user = useRecoilValue(userState)
  const { width } = useWindowSize()

  return (
    <>
      {props.sortedValues && (
        <>
          <div
            className="breakdownValuesContainer"
            id={`breakdownValuesContainer-${props.type}${
              props.pdfPreview ? '-forReport' : ''
            }`}
          >
            {props.sortedValues!.map((valueBreakdownKey) => {
              return (
                props.values[valueBreakdownKey as keyof ValuesBreakdown] && (
                  <p
                    className={`breakdownValue ${props.type} robotoNumbers`}
                    key={`breakdownValue-${valueBreakdownKey}`}
                    id={`breakdownValue-${valueBreakdownKey}${
                      props.pdfPreview ? '-forReport' : ''
                    }`}
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionTitle,
                        FontLabels.regular,
                        'roboto',
                      ),
                      ...breakDownValueFontSize(
                        `${stringAndRoundBigNumbers(
                          valueBreakdownKey === 'valueOfLegalCosts'
                            ? props.values[
                                valueBreakdownKey as keyof ValuesBreakdown
                              ]! +
                                includedIncurredCosts(
                                  'client',
                                  'both',
                                  props.resultsSettings.settings
                                    .includeIncurredCosts,
                                  props.resultsSnapshot,
                                )
                            : props.values[
                                valueBreakdownKey as keyof ValuesBreakdown
                              ]!,
                          user.settings,
                          props.resultsSettings.settings.roundedResults,
                        )}${
                          props.resultsFromBackend.model_used === 'statistical'
                            ? '*'
                            : ''
                        } ${props.resultsSnapshot.currency}`,
                        width,
                      ),
                    }}
                  >
                    {stringAndRoundBigNumbers(
                      valueBreakdownKey === 'valueOfLegalCosts'
                        ? props.values[
                            valueBreakdownKey as keyof ValuesBreakdown
                          ]! +
                            includedIncurredCosts(
                              'client',
                              'both',
                              props.resultsSettings.settings
                                .includeIncurredCosts,
                              props.resultsSnapshot,
                            )
                        : props.values[
                            valueBreakdownKey as keyof ValuesBreakdown
                          ]!,
                      user.settings,
                      props.resultsSettings.settings.roundedResults,
                    )}
                    {props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''}{' '}
                    {props.resultsSnapshot.currency}
                  </p>
                )
              )
            })}
          </div>
          <div className="breakdownValuesDivider"></div>
          <div className="breakdownBarsContainer">
            {props.sortedValues!.map((valuesBreakdownKey) => {
              return (
                props.values[valuesBreakdownKey as keyof ValuesBreakdown] !==
                  undefined && (
                  <div
                    className={`breakdownBarComponent ${props.type}`}
                    key={`breakdownBarComponent-${valuesBreakdownKey}`}
                    id={`breakdownBarComponent-${valuesBreakdownKey}${
                      props.pdfPreview ? '-forReport' : ''
                    }`}
                  >
                    <div
                      className={`breakdownBar ${props.type}`}
                      id={`breakdownBar-${valuesBreakdownKey}${
                        props.pdfPreview ? '-forReport' : ''
                      }`}
                      style={{
                        width: `${
                          props.highestValue !== 0
                            ? (Math.abs(
                                props.values[
                                  valuesBreakdownKey as keyof ValuesBreakdown
                                ]!,
                              ) /
                                props.highestValue) *
                              100
                            : 0
                        }%`,
                      }}
                    ></div>
                  </div>
                )
              )
            })}
          </div>
        </>
      )}
    </>
  )
}
