import { useEffect, useRef } from 'react'
import useWindowSize from '../../../../../../customHooks/useWindowSize'

type Props = {
  canvasRef: React.RefObject<HTMLDivElement>
  selectionBox: {
    startX: number
    startY: number
    width: number
    height: number
  }

  setSelectionBox: (
    selectionBox:
      | {
          startX: number
          startY: number
          width: number
          height: number
        }
      | undefined,
  ) => void
  fixed: boolean
}

export default function TreeSelectionBox(props: Props) {
  const windowSize = useWindowSize()

  const scrollInterval = useRef<any>(null)
  const handleScroll = (direction?: 'left' | 'right' | 'top' | 'bottom') => {
    let x = direction === 'left' ? -10 : direction === 'right' ? 10 : 0
    let y = direction === 'top' ? -10 : direction === 'bottom' ? 10 : 0

    scrollInterval.current = setInterval(() => {
      window.scrollBy(x, y) // Adjust the scroll speed as desired
    }, 40) // Adjust the interval as desired
  }

  const stopScrolling = () => {
    clearInterval(scrollInterval.current)
  }

  useEffect(() => {
    return () => {
      clearInterval(scrollInterval.current)
    }
    // eslint-disable-next-line
  }, [scrollInterval])

  useEffect(() => {
    if (props.selectionBox === undefined) {
      stopScrolling()
    }
    // eslint-disable-next-line
  }, [props.selectionBox])

  return (
    <>
      <div
        style={{
          position: 'absolute',
          border: '1px dashed black',
          backgroundColor: '#e1d6f4',
          pointerEvents: 'none',
          left: Math.min(
            props.selectionBox.startX,
            props.selectionBox.startX + props.selectionBox.width,
          ),
          top: Math.min(
            props.selectionBox.startY,
            props.selectionBox.startY + props.selectionBox.height,
          ),
          width: Math.abs(props.selectionBox.width),
          height: Math.abs(props.selectionBox.height),
          right: Math.max(
            props.selectionBox.startX,
            props.selectionBox.startX + props.selectionBox.width,
          ),
          bottom: Math.max(
            props.selectionBox.startY,
            props.selectionBox.startY + props.selectionBox.height,
          ),
        }}
      />
      <div
        className="fakelayer"
        style={{
          width: 3000,
          height: 10000,
          position: 'absolute',
          zIndex: 3000,
        }}
      ></div>
      <div
        className="fakelayer"
        style={{
          width: 50,
          height: 10000,
          left: 0,
          top: 0,

          position: 'fixed',
          zIndex: 3000,
        }}
        onMouseEnter={() => handleScroll('left')}
        onMouseLeave={stopScrolling}
      ></div>
      <div
        className="fakelayer"
        style={{
          width: 10000,
          height: 50,
          bottom: 0,
          left: 0,

          position: 'fixed',
          zIndex: 3000,
        }}
        onMouseEnter={() => handleScroll('bottom')}
        onMouseLeave={stopScrolling}
      ></div>
      <div
        className="fakelayer"
        style={{
          width: 10000,
          height: 50,
          top: 0,
          left: 0,

          position: 'fixed',
          zIndex: 3000,
        }}
        onMouseEnter={() => handleScroll('top')}
        onMouseLeave={stopScrolling}
      ></div>
      <div
        className="fakelayer"
        style={{
          width: 50,
          height: 10000,
          top: 0,
          left: windowSize.width - 50,

          position: 'fixed',
          zIndex: 3000,
        }}
        onMouseEnter={() => handleScroll('right')}
        onMouseLeave={stopScrolling}
      ></div>
    </>
  )
}
