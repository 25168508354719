import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { userState } from '../../../../../../../../states/UserState'
import { calculateGetPayArray } from '../../../../../../../../services/resultsFunctions'
import {
  GREEK_EASTER,
  INFANTRY_WEST,
} from '../../../../../../../../services/constants'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getText } from '../../../../../../../../services/textFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  resultsSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}
export default function GetPayGraph(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const fewResults = props.resultsFromBackend.result.graph.length <= 8

  const getPayGraphArray = calculateGetPayArray(
    props.resultsFromBackend,
    resultsSettings.settings.includeIncurredCosts,
    props.resultsSnapshot,
    resultsSettings.settings.roundedResults,
  )

  return (
    <>
      <div className={'getPayChart'} data-openreplay-obscured>
        <div className="payGetGraphContainer" id="getPayGraphContainer">
          <div className="getPayGraphDivider"></div>
          {getPayGraphArray.map((getPayGraphRow, index) => (
            <div
              className="getPayGraphRow"
              id={`getPayGraphRow-${index}`}
              key={`getPayGraphRow-${index}`}
            >
              <div
                className="getPayGraphRowLeftPart"
                id={`getPayGraphRowLeftPart-${index}`}
                data-textattribute="description-250, description-251, description-252, description-253"
              >
                {getPayGraphRow[0] > 0 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {fewResults
                      ? getText('description-251', user.settings)
                      : getText('description-250', user.settings)}{' '}
                    <span
                      className="robotoNumbers"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                    >
                      {stringAndRoundBigNumbers(
                        getPayGraphRow[0],
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.resultsSnapshot.currency}
                    </span>
                  </p>
                ) : getPayGraphRow[0] === 0 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {fewResults ? (
                      <>
                        {getText('description-251', user.settings)}{' '}
                        <span
                          style={getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                            false,
                            false,
                          )}
                          className="robotoNumbers"
                        >
                          0
                          {props.resultsFromBackend &&
                          props.resultsFromBackend.model_used === 'statistical'
                            ? '*'
                            : ''}{' '}
                          {props.resultsSnapshot.currency}
                        </span>
                      </>
                    ) : (
                      <>
                        {getText('description-251', user.settings)}{' '}
                        <span
                          style={getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                            false,
                            false,
                          )}
                          className="robotoNumbers"
                        >
                          0
                          {props.resultsFromBackend &&
                          props.resultsFromBackend.model_used === 'statistical'
                            ? '*'
                            : ''}{' '}
                          {props.resultsSnapshot.currency}{' '}
                        </span>
                        {getText('description-252', user.settings)}
                      </>
                    )}
                  </p>
                ) : getPayGraphRow[0] === -0.00001234567 ? (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                  >
                    {getText('description-253', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                      className="robotoNumbers"
                    >
                      0
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.resultsSnapshot.currency}
                    </span>
                  </p>
                ) : (
                  <p
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                      false,
                      false,
                    )}
                    data-textattribute={
                      fewResults ? 'description-287' : 'description-253'
                    }
                  >
                    {fewResults
                      ? getText('description-287', user.settings)
                      : getText('description-253', user.settings)}{' '}
                    <span
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                        false,
                        false,
                      )}
                      className="robotoNumbers"
                    >
                      {stringAndRoundBigNumbers(
                        Math.abs(getPayGraphRow[0]),
                        user.settings,
                        resultsSettings.settings.roundedResults,
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.resultsSnapshot.currency}
                    </span>
                  </p>
                )}
              </div>
              <div className="getPayGraphRowRightPart">
                <div
                  className="getPayBar"
                  id={`getPayBar-${index}`}
                  style={{
                    width: getPayGraphRow[1] * 330,
                    backgroundColor:
                      getPayGraphRow[0] >= 0 ? INFANTRY_WEST : GREEK_EASTER,
                  }}
                ></div>
                <p
                  className="getPayBarPercentage robotoNumbers"
                  id={`getPayBarPercentage-${index}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    false,
                  )}
                >
                  {stringAndRoundBigNumbers(
                    getPayGraphRow[1] * 100,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    false,
                    true,
                  )}
                  %
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
