import { View, StyleSheet, Text, Image } from '@react-pdf/renderer'
import { User } from '../../../../../../../../../models/user'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getText } from '../../../../../../../../../services/textFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  user: User
  reportWhitelabel?: ReportWhitelabel
  zopaGraphImg: string
  firstElement: boolean
}

export default function ZopaDocument(props: Props) {
  const styles = StyleSheet.create({
    newZopaContainer: {
      marginTop: props.firstElement ? 20 : 30,
    },
    newZopaTitle: {
      fontSize: '11px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    newZopaDescription: {
      marginBottom: '4px',
    },
    newZopaDescriptionText: {
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
    },
    zopaGraphOuterContainer: {},
    zopaGraphImg: {
      width: '530px',
      marginTop: 8,
    },
  })

  return (
    <>
      <View style={styles.newZopaContainer}>
        <Text style={styles.newZopaTitle}>
          {getText('title-272', props.user.settings)}
        </Text>
        <View style={styles.newZopaDescription}>
          <Text style={{ ...styles.newZopaDescriptionText, marginTop: 2 }}>
            {getText('description-263', props.user.settings)}{' '}
            {getText('description-263b', props.user.settings)}
          </Text>
        </View>
        <View style={styles.zopaGraphOuterContainer}>
          <Image src={props.zopaGraphImg} style={styles.zopaGraphImg} />
        </View>
      </View>
    </>
  )
}
