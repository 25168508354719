import { AccessRole, LCDMenuChoice } from '../../../../../models/enums'
import englishTexts from '../../../../../resources/texts/englishTexts.json'

type Props = {
  selected: boolean
  id: LCDMenuChoice
  text: string
  textAttribute: keyof typeof englishTexts
  onChange: (id: LCDMenuChoice) => void
  ownRole: AccessRole
}

export default function LCDChip(props: Props) {
  return (
    <div
      className={`lcdChip ${props.selected ? 'selected' : ''} ${
        props.ownRole === AccessRole.VIEWER ? 'viewer' : 'notViewer'
      }`}
      id={`lcdChip-${props.id}`}
      onClick={() => props.onChange(props.id)}
    >
      <p
        className={`lcdChipText ${
          props.ownRole === AccessRole.VIEWER ? 'viewer' : 'notViewer'
        }`}
        id={`lcdChipText-${props.id}`}
      >
        {props.text}
      </p>
    </div>
  )
}
