import { DateTime } from 'luxon'
import {
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  getText,
  translateDate,
} from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'

type Props = {
  proceedings: LegalCostsProceedings
  hasSecondTrial: boolean
  date: string | undefined
  reportWhitelabel?: ReportWhitelabel
  fade: boolean | undefined
  visibleElements?: ExecutiveSummaryVisibleElements
}

export default function ProceedingsDate(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  //data-textattribute='description-268'
  //data-textattribute='description-269'
  //data-textattribute='description-270'
  const proceedingsText = props.hasSecondTrial
    ? props.proceedings === LegalCostsProceedings.firstProceedings
      ? getText('description-268', user.settings)
      : scenarioSnapshot.currentSnapshot.court === TypeOfInstance.Public_court
      ? getText('description-269b', user.settings)
      : getText('description-269', user.settings)
    : getText('description-270', user.settings)

  return (
    <div
      className="proceedingsDateComponent"
      data-openreplay-obscured
      id={`proceedingsDateComponent-${props.proceedings}${
        props.visibleElements ? '-forReport' : ''
      }`}
    >
      <p
        className={`proceedingsDateText ${props.fade ? 'fade' : ''}`}
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.regular,
          'noRoboto',
        )}
        id={`proceedingsDateText-${props.proceedings}${
          props.visibleElements ? '-forReport' : ''
        }`}
      >
        {proceedingsText}
      </p>

      <p
        className={`proceedingsDate ${props.fade ? 'fade' : ''}`}
        style={{
          fontFamily: getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
          ).fontFamily,
          color: getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionTitle,
            FontLabels.bold,
            'noRoboto',
          ).color,
        }}
        id={`proceedingsDate-${props.proceedings}${
          props.visibleElements ? '-forReport' : ''
        }`}
      >
        {translateDate(
          DateTime.fromISO(props.date as string).toFormat('dd LLL yyyy'),
          user.settings.language,
        )}
        <sup
          style={{
            ...getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.bold,
              'roboto',
            ),
            fontSize: '8px',
          }}
          className={`${props.fade ? 'fade' : ''} robotoNumbersBold`}
          data-openreplay-obscured
        >
          2
        </sup>
      </p>
    </div>
  )
}
