import { useEffect, useState } from 'react'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { calculateValueOfIndependentClaim } from '../../../../../services/claimFunctions'
import infoCircle from '../../../../../resources/images/062-infoCircleGray.svg'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import ValueOfIndependentClaimSection from './ValueOfIndependentClaimSection'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import firstTrialImg from '../../../../../resources/images/137-first.svg'
import secondTrialImg from '../../../../../resources/images/140-second.svg'
import { getText } from '../../../../../services/textFunctions'
import { InterestViewOption } from '../../../../../models/enums'
import { stringAndRoundTo2Decimals } from '../../../../../services/commonFunctions'

type Props = {
  claimIndex: number
  claimInfo: IndependentClaim
  firstTrialDate?: string
  secondTrialDate?: string
}

export default function ValueOfIndependentClaim(props: Props) {
  const [valueOfClaimNoInterest, setValueOfClaimNoInterest] = useState(0)
  const [valueOfClaimFirst, setValueOfClaimFirst] = useState(0)
  const [valueOfClaimSecond, setValueOfClaimSecond] = useState(0)
  const [valuOfClaimInfoButton, setValuOfClaimInfoButton] = useState(false)
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [valuOfClaimInfoButton])

  function onMouseDown(e: any) {
    const div = document.getElementById('valuOfIndependentClaimContainer') // Replace 'your-div-id' with the actual ID of your div
    const clickedElement = e.target as HTMLElement

    // Check if the clicked element is not the specified div or its children
    if (div && !div.contains(clickedElement)) {
      setValuOfClaimInfoButton(false)
    }
  }

  useEffect(() => {
    let tempValueOfClaimNoInterest = calculateValueOfIndependentClaim(
      props.claimInfo,
      InterestViewOption.noInterest,
    )
    let tempValueOfClaimFirst = calculateValueOfIndependentClaim(
      props.claimInfo,
      InterestViewOption.interest1st,
      props.firstTrialDate,
    )
    let tempValueOfClaimSecond = calculateValueOfIndependentClaim(
      props.claimInfo,
      InterestViewOption.interest2nd,
      props.firstTrialDate,
      props.secondTrialDate,
    )

    setValueOfClaimNoInterest(tempValueOfClaimNoInterest)
    setValueOfClaimFirst(tempValueOfClaimFirst)
    setValueOfClaimSecond(tempValueOfClaimSecond)
    // eslint-disable-next-line
  }, [props.claimInfo, props.claimIndex])

  return (
    <div
      className="valuOfIndependentClaimContainer"
      id={`valuOfIndependentClaimContainer`}
    >
      <div
        className="valuOfIndependentClaimText"
        data-textattribute="title-245"
      >
        {getText('title-245', user.settings)}:
      </div>
      <div className="valuOfIndependentClaimAmount robotoNumbers">
        {stringAndRoundTo2Decimals(valueOfClaimNoInterest, user.settings)}{' '}
        {scenarioSnapshot.currentSnapshot.currency}
      </div>
      <div className="valueOfIndependentClaimInfoButtonContainer">
        {valueOfClaimFirst !== valueOfClaimNoInterest && (
          <img
            src={infoCircle}
            alt="infoCircle"
            className="valueOfIndependentClaimInfoButton"
            onClick={() => setValuOfClaimInfoButton(true)}
            id={'valueOfIndependentClaimInfoButton'}
          />
        )}
        {valuOfClaimInfoButton && (
          <div
            className="valueOfIndependentClaimInfoButtonPopUpContainer"
            id={'valueOfIndependentClaimInfoButtonPopUpContainer'}
          >
            <p
              className="title"
              data-textattribute="title-245"
              id={'valueOfIndependentClaimInfoButtonPopUpTitle'}
            >
              {getText('title-245', user.settings)}
            </p>
            <ValueOfIndependentClaimSection
              //data-textattribute="title-89"
              title={getText('title-89', user.settings)}
              value={stringAndRoundTo2Decimals(
                valueOfClaimNoInterest,
                user.settings,
              )}
              id="noInterest"
              currency={scenarioSnapshot.currentSnapshot.currency}
            />
            {valueOfClaimFirst !== valueOfClaimNoInterest && (
              <ValueOfIndependentClaimSection
                //data-textattribute="title-246"
                title={getText('title-246b', user.settings)}
                image={firstTrialImg}
                value={stringAndRoundTo2Decimals(
                  valueOfClaimFirst,
                  user.settings,
                )}
                id="firstTrial"
                currency={scenarioSnapshot.currentSnapshot.currency}
              />
            )}
            {valueOfClaimSecond !== valueOfClaimNoInterest && (
              <ValueOfIndependentClaimSection
                //data-textattribute="title-246"
                title={getText('title-246c', user.settings)}
                image={secondTrialImg}
                id="secondTrial"
                value={stringAndRoundTo2Decimals(
                  valueOfClaimSecond,
                  user.settings,
                )}
                currency={scenarioSnapshot.currentSnapshot.currency}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
