import { useEffect, useState } from 'react'
import NotSupportedBrowser from '../pages/notSupportedBrowser/NotSupportedBrowser'

type Props = {
  children: React.ReactElement
}

const NotSupportedBrowserHOC = (props: Props) => {
  const [falseBrowser, setFalseBrowser] = useState(false)

  useEffect(() => {
    if (
      !window.navigator.userAgent.includes('Chromium') &&
      window.navigator.userAgent.includes('Chrome') &&
      !window.navigator.userAgent.includes('Mobile')
    ) {
      console.log('CHROMIUM BASED')
      console.log(window.navigator.userAgent)

      setFalseBrowser(false)
    } else {
      console.log('NOT CHROMIUM BASED')
      console.log(window.navigator.userAgent)
      setFalseBrowser(true)
    }

    // eslint-disable-next-line
  }, [])

  return <>{falseBrowser ? <NotSupportedBrowser /> : props.children}</>
}

export default NotSupportedBrowserHOC
