import React, { useState, useEffect } from 'react'

import LegalFeesRange from './SingleSegmentComponents/LegalFeesRange'
import PossibleOutcomeRange from './SingleSegmentComponents/PossibleOutcomeRange'

import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { useRecoilValue } from 'recoil'
import { formattedNumToString } from '../../../services/formatNum'
import { roundNumberTo } from '../../../services/commonFunctions'
import { userState } from '../../../states/UserState'
import { AccessRole } from '../../../models/enums'
import { ReportWhitelabel } from '../../../models/reportModels/reportGeneralTypes'

type Props = {
  singleSegmentIndex: number
  totalCounterClaimedAmount: number
  disputedAmount: number
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  pdfPreview?: boolean
  ownRole?: AccessRole
  reportWhitelabel?: ReportWhitelabel
  forNewLCD?: boolean
}

export default function SingleSegment(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const feeDistributions = currentSnapshot.legalFeesDistribution

  const [segmentMax, setsegmentMax] = useState<string>('') // the maximum amount of this single segment -- number
  const [segmentMaxPercentage, setsegmentMaxPercentage] = useState<number>() // the percentage of the disputed amount that corresponds in the maximum amount of this single segment -- number between 0 and 100
  const [segmentMin, setsegmentMin] = useState<string>('') // the minimum amount of this single segment -- number
  const [segmentMinPercentage, setsegmentMinPercentage] = useState<number>() // the percentage of the disputed amount that corresponds in the minimum amount of this single segment -- number between 0 and 100
  const [changeEffect, setChangeEffect] = useState(false) // a boolean that is responsible for the animation effect when the range is changing -- bool
  const [roundingNumber, setRoundingNumber] = useState<number>(2) // a number that defines the number of decimals, depending on the currency settings -- number 100 or 1
  const user = useRecoilValue(userState)

  useEffect(() => {
    let nonMillionCurrnecy = currentSnapshot.currency.length === 3

    if (nonMillionCurrnecy) {
      setRoundingNumber(2)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let maxPercentage = roundNumberTo(
      feeDistributions[props.singleSegmentIndex].range[1],
      10,
    )
    let minPercentage = roundNumberTo(
      feeDistributions[props.singleSegmentIndex].range[0],
      10,
    )

    setsegmentMaxPercentage(maxPercentage)
    setsegmentMinPercentage(minPercentage)

    let tempsegmentMax = formattedNumToString(
      roundNumberTo(
        props.totalCounterClaimedAmount +
          (maxPercentage * props.disputedAmount) / 100,
        roundingNumber,
      ),
      user.settings,
    )

    let tempSegmentMin = formattedNumToString(
      roundNumberTo(
        props.totalCounterClaimedAmount +
          (minPercentage * props.disputedAmount) / 100,
        roundingNumber,
      ),
      user.settings,
    )
    setsegmentMax(tempsegmentMax)
    setsegmentMin(tempSegmentMin)

    // eslint-disable-next-line
  }, [
    feeDistributions,
    props.disputedAmount,
    props.totalCounterClaimedAmount,
    roundingNumber,
  ])

  return (
    <>
      <div
        className={
          props.pdfPreview && !props.forNewLCD
            ? 'singleSegmentContainer pdfPreviewSingleSegment'
            : 'singleSegmentContainer pdfPreviewSingleSegment forNewLCD'
        }
      >
        <PossibleOutcomeRange
          segmentMax={segmentMax}
          segmentMaxPercentage={segmentMaxPercentage}
          segmentMin={segmentMin}
          segmentMinPercentage={segmentMinPercentage}
          openZero={props.singleSegmentIndex === 1}
          singleSegmentIndex={props.singleSegmentIndex}
          reportWhitelabel={props.reportWhitelabel}
          totalCounterClaimedAmount={props.totalCounterClaimedAmount}
          disputedAmount={props.disputedAmount}
        />
        <LegalFeesRange
          singleSegmentIndex={props.singleSegmentIndex}
          feeDistributions={feeDistributions}
          changeEffect={changeEffect}
          setChangeEffect={setChangeEffect}
          pdfPreview={props.pdfPreview}
          isFirstLoad={props.isFirstLoad}
          setIsFirstLoad={props.setIsFirstLoad}
          ownRole={props.ownRole}
          reportWhitelabel={props.reportWhitelabel}
          forNewLCD={props.forNewLCD}
        />
      </div>
    </>
  )
}
