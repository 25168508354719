import bracketImg from '../../../../../../../../resources/images/259-prob2ndcourt.png'
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { TypeOfInstance } from '../../../../../../../../models/enums'
import { getText } from '../../../../../../../../services/textFunctions'
import { User } from '../../../../../../../../models/user'
import { formattedNumToString } from '../../../../../../../../services/formatNum'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'

type Props = {
  colors: { [key: string]: string }
  currentSnapshot: ScenarioSnapshot
  user: User
  reportWhitelabel?: ReportWhitelabel
}

export default function SecondTrialProbsComponentDocument(props: Props) {
  const styles = StyleSheet.create({
    secondTrialProbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '8px',
    },
    leftPart: {
      marginTop: '0px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    text: {
      marginLeft: '3px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    probability: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Bold',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          'noRoboto',
          true,
        ).color ?? props.colors.ageanMidnight,
      fontSize: '8px',
      fontWeight: 600,
    },
    middlePart: {
      marginTop: '0px',
    },
    rightPart: {
      minWidth: '200px',
    },
    bracketImg: {
      marginLeft: '7px',
      marginRight: '7px',
      marginTop: '0px',
      width: '9px',
    },
  })
  return (
    <View style={styles.secondTrialProbsContainer}>
      <View style={styles.leftPart}>
        <View style={styles.row}>
          <Text style={styles.probability}>
            {formattedNumToString(
              props.currentSnapshot.probSecondTrialstep1!,
              props.user.settings,
            )}{' '}
            %
          </Text>
          <Text
            style={styles.text}
            id="secondTrialProbsForPdfTitle1"
            data-textattribute={
              props.currentSnapshot.court === TypeOfInstance.Public_court
                ? 'title-145a'
                : 'title-145b'
            }
          >
            {props.currentSnapshot.court === TypeOfInstance.Public_court
              ? getText('title-145a', props.user.settings)
              : getText('title-145b', props.user.settings)}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.probability}>
            {formattedNumToString(
              props.currentSnapshot.probSecondTrialstep2!,
              props.user.settings,
            )}{' '}
            %
          </Text>
          <Text
            style={styles.text}
            id="secondTrialProbsForPdfTitle2"
            data-textattribute="title-146"
          >
            {getText('title-146', props.user.settings)}
          </Text>
        </View>
      </View>
      <View style={styles.middlePart}>
        <Image style={styles.bracketImg} source={bracketImg} />
      </View>
      <View style={styles.rightPart}>
        <View style={styles.row}>
          <Text style={styles.probability}>
            {formattedNumToString(
              props.currentSnapshot.probSecondTrial!,
              props.user.settings,
            )}{' '}
            %
          </Text>
          <Text
            style={styles.text}
            id="secondTrialProbsForPdfTitle3"
            data-textattribute={
              props.currentSnapshot.court === TypeOfInstance.Public_court
                ? 'title-147'
                : 'title-148'
            }
          >
            {props.currentSnapshot.court === TypeOfInstance.Public_court
              ? getText('title-147', props.user.settings)
              : getText('title-148', props.user.settings)}
          </Text>
        </View>
      </View>
    </View>
  )
}
