import { Font } from '@react-pdf/renderer'
import poppins from '../../../../../../../../resources/fonts/Poppins/Poppins-Regular.ttf'
import poppinsItalic from '../../../../../../../../resources/fonts/Poppins/Poppins-Italic.ttf'
import poppinsLight from '../../../../../../../../resources/fonts/Poppins/Poppins-Light.ttf'
import poppinsLightItalic from '../../../../../../../../resources/fonts/Poppins/Poppins-LightItalic.ttf'
import poppinsBold from '../../../../../../../../resources/fonts/Poppins/Poppins-Bold.ttf'
import poppinsMedium from '../../../../../../../../resources/fonts/Poppins/Poppins-Medium.ttf'
import poppinsSemiBold from '../../../../../../../../resources/fonts/Poppins/Poppins-SemiBold.ttf'

////////SoinSansNeue////////////
import SoinSansNeue from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Medium.otf'
import SoinSansNeueItalic from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Italic.otf'
import SoinSansNeueLight from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Light.otf'
import SoinSansNeueLightItalic from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-LightItalic.otf'
import SoinSansNeueBold from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Heavy.otf'
import SoinSansNeueMedium from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Medium.otf'
import SoinSansNeueSemiBold from '../../../../../../../../resources/fonts/SoinSansNeue/SoinSansNeue-Bold.otf'
////////////////////////////

////////EBGaramond////////////
import EBGaramond from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-Medium.ttf'
import EBGaramondItalic from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-Italic.ttf'
import EBGaramondLight from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-VariableFont_wght.ttf'
import EBGaramondLightItalic from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-Italic-VariableFont_wght.ttf'
import EBGaramondBold from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-Bold.ttf'
import EBGaramondMedium from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-Medium.ttf'
import EBGaramondSemiBold from '../../../../../../../../resources/fonts/EBGaramond/EBGaramond-SemiBold.ttf'
////////////////////////////

////////Prompt////////////
import Prompt from '../../../../../../../../resources/fonts/Prompt/Prompt-Medium.ttf'
import PromptItalic from '../../../../../../../../resources/fonts/Prompt/Prompt-Italic.ttf'
import PromptLight from '../../../../../../../../resources/fonts/Prompt/Prompt-Light.ttf'
import PromptLightItalic from '../../../../../../../../resources/fonts/Prompt/Prompt-LightItalic.ttf'
import PromptBold from '../../../../../../../../resources/fonts/Prompt/Prompt-Bold.ttf'
import PromptMedium from '../../../../../../../../resources/fonts/Prompt/Prompt-Medium.ttf'
import PromptSemiBold from '../../../../../../../../resources/fonts/Prompt/Prompt-SemiBold.ttf'
////////////////////////////

////////Arial////////////
import Arial from '../../../../../../../../resources/fonts/Arial/Arial-Medium.ttf'
import ArialItalic from '../../../../../../../../resources/fonts/Arial/Arial-Italic.ttf'
import ArialLight from '../../../../../../../../resources/fonts/Arial/Arial-Light.ttf'
import ArialLightItalic from '../../../../../../../../resources/fonts/Arial/Arial-LightItalic.otf'
import ArialBold from '../../../../../../../../resources/fonts/Arial/Arial-Bold.ttf'
import ArialMedium from '../../../../../../../../resources/fonts/Arial/Arial-Medium.ttf'
import ArialSemiBold from '../../../../../../../../resources/fonts/Arial/Arial-Bold.ttf'
////////////////////////////

//////Gotham////////////
import Gotham from '../../../../../../../../resources/fonts/Gotham/Gotham-Thin.otf'
import GothamItalic from '../../../../../../../../resources/fonts/Gotham/Gotham-ThinItalic.otf'
import GothamLight from '../../../../../../../../resources/fonts/Gotham/Gotham-Light.otf'
import GothamLightItalic from '../../../../../../../../resources/fonts/Gotham/Gotham-LightItalic.otf'
import GothamBold from '../../../../../../../../resources/fonts/Gotham/Gotham-Bold.otf'
import GothamMedium from '../../../../../../../../resources/fonts/Gotham/Gotham-Medium.otf'
import GothamSemiBold from '../../../../../../../../resources/fonts/Gotham/Gotham-Bold.otf'
//////////////////////////

////////Heebo////////////
import Heebo from '../../../../../../../../resources/fonts/Heebo/Heebo-Medium.ttf'
import HeeboItalic from '../../../../../../../../resources/fonts/Heebo/Heebo-Regular.ttf'
import HeeboLight from '../../../../../../../../resources/fonts/Heebo/Heebo-Light.ttf'
import HeeboLightItalic from '../../../../../../../../resources/fonts/Heebo/Heebo-Regular.ttf'
import HeeboBold from '../../../../../../../../resources/fonts/Heebo/Heebo-Bold.ttf'
import HeeboMedium from '../../../../../../../../resources/fonts/Heebo/Heebo-Medium.ttf'
import HeeboSemiBold from '../../../../../../../../resources/fonts/Heebo/Heebo-SemiBold.ttf'
////////////////////////////

////////Rubik////////////
import Rubik from '../../../../../../../../resources/fonts/Rubik/Rubik-Medium.ttf'
import RubikItalic from '../../../../../../../../resources/fonts/Rubik/Rubik-Italic.ttf'
import RubikLight from '../../../../../../../../resources/fonts/Rubik/Rubik-Light.ttf'
import RubikLightItalic from '../../../../../../../../resources/fonts/Rubik/Rubik-LightItalic.ttf'
import RubikBold from '../../../../../../../../resources/fonts/Rubik/Rubik-Bold.ttf'
import RubikMedium from '../../../../../../../../resources/fonts/Rubik/Rubik-Medium.ttf'
import RubikSemiBold from '../../../../../../../../resources/fonts/Rubik/Rubik-SemiBold.ttf'
////////////////////////////

////////Calibri////////////
import Calibri from '../../../../../../../../resources/fonts/Calibri/Calibri-Regular.ttf'
import CalibriItalic from '../../../../../../../../resources/fonts/Calibri/Calibri-Italic.ttf'
import CalibriLight from '../../../../../../../../resources/fonts/Calibri/Calibri-Light.ttf'
import CalibriLightItalic from '../../../../../../../../resources/fonts/Calibri/Calibri-LightItalic.ttf'
import CalibriBold from '../../../../../../../../resources/fonts/Calibri/Calibri-Bold.ttf'
import CalibriMedium from '../../../../../../../../resources/fonts/Calibri/Calibri-Regular.ttf'
import CalibriSemiBold from '../../../../../../../../resources/fonts/Calibri/Calibri-Bold.ttf'
////////////////////////////

////////PlayfairDisplay////////////
import PlayfairDisplay from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf'
import PlayfairDisplayItalic from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf'
import PlayfairDisplayLight from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf'
import PlayfairDisplayLightItalic from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf'
import PlayfairDisplayBold from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf'
import PlayfairDisplayMedium from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf'
import PlayfairDisplaySemiBold from '../../../../../../../../resources/fonts/PlayfairDisplay/PlayfairDisplay-SemiBold.ttf'
////////////////////////////

////////Roboto////////////
import Roboto from '../../../../../../../../resources/fonts/Roboto/Roboto-Regular.ttf'
import RobotoItalic from '../../../../../../../../resources/fonts/Roboto/Roboto-Italic.ttf'
import RobotoLight from '../../../../../../../../resources/fonts/Roboto/Roboto-Light.ttf'
import RobotoLightItalic from '../../../../../../../../resources/fonts/Roboto/Roboto-LightItalic.ttf'
import RobotoBold from '../../../../../../../../resources/fonts/Roboto/Roboto-Bold.ttf'
import RobotoMedium from '../../../../../../../../resources/fonts/Roboto/Roboto-Medium.ttf'
import RobotoSemiBold from '../../../../../../../../resources/fonts/Roboto/Roboto-Medium.ttf'
////////////////////////////

////////Chap////////////////
import ChapMedium from '../../../../../../../../resources/fonts/Chap/ChapMedium.otf'
import ChapLight from '../../../../../../../../resources/fonts/Chap/ChapLight.otf'
////////////////////////////

////////EBGaramond////////////
Font.register({
  family: 'EBGaramond',
  src: EBGaramond,
})
Font.register({
  family: 'EBGaramond-Italic',
  src: EBGaramondItalic,
})
Font.register({
  family: 'EBGaramond-Light',
  src: EBGaramondLight,
})
Font.register({
  family: 'EBGaramond-Light-Italic',
  src: EBGaramondLightItalic,
})
Font.register({
  family: 'EBGaramond-Bold',
  src: EBGaramondBold,
})
Font.register({
  family: 'EBGaramond-Medium',
  src: EBGaramondMedium,
})
Font.register({
  family: 'EBGaramond-SemiBold',
  src: EBGaramondSemiBold,
})
///////////////////////////

////////SoinSansNeue////////////
Font.register({
  family: 'SoinSansNeue',
  src: SoinSansNeue,
})
Font.register({
  family: 'SoinSansNeue-Italic',
  src: SoinSansNeueItalic,
})
Font.register({
  family: 'SoinSansNeue-Light',
  src: SoinSansNeueLight,
})
Font.register({
  family: 'SoinSansNeue-Light-Italic',
  src: SoinSansNeueLightItalic,
})
Font.register({
  family: 'SoinSansNeue-Bold',
  src: SoinSansNeueBold,
})
Font.register({
  family: 'SoinSansNeue-Medium',
  src: SoinSansNeueMedium,
})
Font.register({
  family: 'SoinSansNeue-SemiBold',
  src: SoinSansNeueSemiBold,
})
///////////////////////////

////////Roboto////////////
Font.register({
  family: 'Roboto',
  src: Roboto,
})
Font.register({
  family: 'Roboto-Italic',
  src: RobotoItalic,
})
Font.register({
  family: 'Roboto-Light',
  src: RobotoLight,
})
Font.register({
  family: 'Roboto-Light-Italic',
  src: RobotoLightItalic,
})
Font.register({
  family: 'Roboto-Bold',
  src: RobotoBold,
})
Font.register({
  family: 'Roboto-Medium',
  src: RobotoMedium,
})
Font.register({
  family: 'Roboto-SemiBold',
  src: RobotoSemiBold,
})
///////////////////////////

////////PlayfairDisplay////////////
Font.register({
  family: 'PlayfairDisplay',
  src: PlayfairDisplay,
})
Font.register({
  family: 'PlayfairDisplay-Italic',
  src: PlayfairDisplayItalic,
})
Font.register({
  family: 'PlayfairDisplay-Light',
  src: PlayfairDisplayLight,
})
Font.register({
  family: 'PlayfairDisplay-Light-Italic',
  src: PlayfairDisplayLightItalic,
})
Font.register({
  family: 'PlayfairDisplay-Bold',
  src: PlayfairDisplayBold,
})
Font.register({
  family: 'PlayfairDisplay-Medium',
  src: PlayfairDisplayMedium,
})
Font.register({
  family: 'PlayfairDisplay-SemiBold',
  src: PlayfairDisplaySemiBold,
})
///////////////////////////

////////Calibri////////////
Font.register({
  family: 'Calibri',
  src: Calibri,
})
Font.register({
  family: 'Calibri-Italic',
  src: CalibriItalic,
})
Font.register({
  family: 'Calibri-Light',
  src: CalibriLight,
})
Font.register({
  family: 'Calibri-Light-Italic',
  src: CalibriLightItalic,
})
Font.register({
  family: 'Calibri-Bold',
  src: CalibriBold,
})
Font.register({
  family: 'Calibri-Medium',
  src: CalibriMedium,
})
Font.register({
  family: 'Calibri-SemiBold',
  src: CalibriSemiBold,
})
///////////////////////////

/////////Heebo/////////////
Font.register({
  family: 'Heebo',
  src: Heebo,
})
Font.register({
  family: 'Heebo-Italic',
  src: HeeboItalic,
})
Font.register({
  family: 'Heebo-Light',
  src: HeeboLight,
})
Font.register({
  family: 'Heebo-Light-Italic',
  src: HeeboLightItalic,
})
Font.register({
  family: 'Heebo-Bold',
  src: HeeboBold,
})
Font.register({
  family: 'Heebo-Medium',
  src: HeeboMedium,
})
Font.register({
  family: 'Heebo-SemiBold',
  src: HeeboSemiBold,
})
///////////////////////////

/////////Rubik/////////////
Font.register({
  family: 'Rubik',
  src: Rubik,
})
Font.register({
  family: 'Rubik-Italic',
  src: RubikItalic,
})
Font.register({
  family: 'Rubik-Light',
  src: RubikLight,
})
Font.register({
  family: 'Rubik-Light-Italic',
  src: RubikLightItalic,
})
Font.register({
  family: 'Rubik-Bold',
  src: RubikBold,
})
Font.register({
  family: 'Rubik-Medium',
  src: RubikMedium,
})
Font.register({
  family: 'Rubik-SemiBold',
  src: RubikSemiBold,
})
///////////////////////////

////////Prompt ////////////
Font.register({
  family: 'Prompt',
  src: Prompt,
})
Font.register({
  family: 'Prompt-Italic',
  src: PromptItalic,
})
Font.register({
  family: 'Prompt-Light',
  src: PromptLight,
})
Font.register({
  family: 'Prompt-Light-Italic',
  src: PromptLightItalic,
})
Font.register({
  family: 'Prompt-Bold',
  src: PromptBold,
})
Font.register({
  family: 'Prompt-Medium',
  src: PromptMedium,
})
Font.register({
  family: 'Prompt-SemiBold',
  src: PromptSemiBold,
})
//////////////////////////////

////////Arial ////////////
Font.register({
  family: 'Arial',
  src: Arial,
})
Font.register({
  family: 'Arial-Italic',
  src: ArialItalic,
})
Font.register({
  family: 'Arial-Light',
  src: ArialLight,
})
Font.register({
  family: 'Arial-Light-Italic',
  src: ArialLightItalic,
})
Font.register({
  family: 'Arial-Bold',
  src: ArialBold,
})
Font.register({
  family: 'Arial-Medium',
  src: ArialMedium,
})
Font.register({
  family: 'Arial-SemiBold',
  src: ArialSemiBold,
})
//////////////////////////////

////////Gotham ////////////
Font.register({
  family: 'Gotham',
  src: Gotham,
})
Font.register({
  family: 'Gotham-Italic',
  src: GothamItalic,
})
Font.register({
  family: 'Gotham-Light',
  src: GothamLight,
})
Font.register({
  family: 'Gotham-Light-Italic',
  src: GothamLightItalic,
})
Font.register({
  family: 'Gotham-Bold',
  src: GothamBold,
})
Font.register({
  family: 'Gotham-Medium',
  src: GothamMedium,
})
Font.register({
  family: 'Gotham-SemiBold',
  src: GothamSemiBold,
})
//////////////////////////////

////////Chap/////////////////
Font.register({
  family: 'Chap',
  src: ChapMedium,
})
Font.register({
  family: 'Chap-Italic',
  src: ChapLight,
})
Font.register({
  family: 'Chap-Light',
  src: ChapLight,
})
Font.register({
  family: 'Chap-Light-Italic',
  src: ChapLight,
})
Font.register({
  family: 'Chap-Bold',
  src: ChapMedium,
})
Font.register({
  family: 'Chap-Medium',
  src: ChapMedium,
})
Font.register({
  family: 'Chap-SemiBold',
  src: ChapMedium,
})
//////////////////////////////

Font.register({
  family: 'Poppins',
  src: poppins,
  fontWeight: 400,
})
Font.register({
  family: 'Poppins-Italic',
  src: poppinsItalic,
})
Font.register({
  family: 'Poppins-Light',
  src: poppinsLight,
})
Font.register({
  family: 'Poppins-Light-Italic',
  src: poppinsLightItalic,
})
Font.register({
  family: 'Poppins-Bold',
  src: poppinsBold,
})
Font.register({
  family: 'Poppins-Medium',
  src: poppinsMedium,
})
Font.register({
  family: 'Poppins-SemiBold',
  src: poppinsSemiBold,
})
