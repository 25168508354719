import { View, StyleSheet } from '@react-pdf/renderer'
import PartyClaimsAndLegalCostsDocument from './PartyClaimsAndLegalCostsDocument'
import {
  LegalCostsParty,
  LegalCostsProceedings,
} from '../../../../../../../../../models/enums'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import ProceedingsDateDocument from './ProceedingsDateDocument'
import { ResultsSettings } from '../../../../../../../../../models/generalTypes'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  user: User
  currentSnapshot: ScenarioSnapshot
  legalCostsDescriptions: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
  resultsSettings: ResultsSettings
}

export default function ClaimsAndLegalCostsDocument(props: Props) {
  const styles = StyleSheet.create({
    claimsAndLegalCostsContainer: {
      marginTop: 20,
    },
    partyClaimsAndLegalCostsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '10px',
    },
    proceedingsDateContainer: {
      position: 'relative',
      marginTop: '1px',
    },
  })

  const hasSecondTrial = props.currentSnapshot.probSecondTrial! > 0

  return (
    <>
      <View style={styles.claimsAndLegalCostsContainer} wrap={false}>
        <View style={styles.partyClaimsAndLegalCostsContainer}>
          <PartyClaimsAndLegalCostsDocument
            party={LegalCostsParty.clientLegalCosts}
            user={props.user}
            currentSnapshot={props.currentSnapshot}
            legalCostsDescriptions={props.legalCostsDescriptions}
            hasSecondTrial={hasSecondTrial}
            reportWhitelabel={props.reportWhitelabel}
            resultsSettings={props.resultsSettings}
          />
          <PartyClaimsAndLegalCostsDocument
            party={LegalCostsParty.opposingPartyLegalCosts}
            user={props.user}
            currentSnapshot={props.currentSnapshot}
            legalCostsDescriptions={props.legalCostsDescriptions}
            hasSecondTrial={hasSecondTrial}
            reportWhitelabel={props.reportWhitelabel}
            resultsSettings={props.resultsSettings}
          />
        </View>
        <View style={styles.proceedingsDateContainer}>
          {props.currentSnapshot.firstTrialDate !== undefined && (
            <ProceedingsDateDocument
              proceedings={LegalCostsProceedings.firstProceedings}
              hasSecondTrial={hasSecondTrial}
              date={props.currentSnapshot.firstTrialDate}
              reportWhitelabel={props.reportWhitelabel}
              user={props.user}
              currentSnapshot={props.currentSnapshot}
            />
          )}
          {hasSecondTrial &&
            props.currentSnapshot.secondTrialDate !== undefined && (
              <ProceedingsDateDocument
                proceedings={LegalCostsProceedings.additionalProceedings}
                hasSecondTrial={hasSecondTrial}
                date={props.currentSnapshot.secondTrialDate}
                reportWhitelabel={props.reportWhitelabel}
                user={props.user}
                currentSnapshot={props.currentSnapshot}
              />
            )}
        </View>
      </View>
    </>
  )
}
