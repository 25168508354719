import React, { useEffect, useRef, useState } from 'react'
import {
  AccessRole,
  ClaimType,
  ClaimsPreviewStyle,
  Currency,
} from '../../../../../../models/enums'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { editTreeNodeFromUndoState } from '../../../../../../states/EditTreeNodeFromUndo'
import { formattedNumToString } from '../../../../../../services/formatNum'
import {
  findClaimedAmountOfClaim,
  findCounterClaimedAmountOfClaim,
  findWeightedValueOfClaim,
  roundNumberTo,
  stringAndRoundTo2Decimals,
} from '../../../../../../services/commonFunctions'
import ClaimTile3DotsMenu from '../ClaimTile3DotsMenu'
import DeleteClaimWindowForPreview from '../../../../../../Components/DeleteWindow/DeleteWindowForPreview'
import fitty from 'fitty'
import Button from '../../../../../../Components/Buttons/Button/Button'
import Draggable from 'react-draggable'
import { getClaimWeightedValue } from '../../../../../../services/claimFunctions'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import { getText } from '../../../../../../services/textFunctions'
import treeNonSelectedImg from '../../../../../../resources/images/256-tree-non-selected.svg'
import treeSelectedImg from '../../../../../../resources/images/257-tree-selected.svg'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Sorting = {
  option: 'ascending' | 'descending' | 'none'
  value: 'title' | 'client' | 'opposing' | 'weighted' | 'none'
}

type Props = {
  claimInfo: TreeClaim
  claimIndex: number
  currency: Currency
  isActive: boolean
  setActiveClaim: (claimIndex: number, noRerender?: boolean) => void
  claimPreviewId: string
  errors: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  ownRole?: AccessRole
  claimPositionHighlight: number | undefined
  claimIsDragging: number | undefined
  handleDraggingClaim: (data: any) => void
  handleChangeClaimOrder: () => void
  calculateAllTrees: () => Promise<void>
  sorting: Sorting
  changeSortingOption: (
    newValue: 'title' | 'client' | 'opposing' | 'weighted' | 'none',
    remove?: boolean,
  ) => void
  isClaimMenuOn: boolean
  setIsClaimMenuOn: (value: boolean, index: number) => void
  expanded: boolean
}

const TreeList = (props: Props) => {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)
  const [hoverClaimTile, setHoverClaimTile] = useState(false)
  const [threeDotsMenuOn, setThreeDotsMenuOn] = useState(false)
  const [removeClaimMenuOn, setRemoveClaimMenuOn] = useState(false)
  const nodeRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [oldClaimInfo, setOldClaimInfo] = useState(props.claimInfo)
  const [totalClaimedAmountValue, setTotalClaimedAmountValue] = useState(0)
  const [totalCounterClaimedAmountValue, setTotalCounterClaimedAmountValue] =
    useState(0)
  const [treeWeigthedValue, setTreeWeightedValue] = useState(0)

  useEffect(() => {
    props.setIsClaimMenuOn(
      threeDotsMenuOn || removeClaimMenuOn,
      props.claimIndex,
    )

    // eslint-disable-next-line
  }, [threeDotsMenuOn, removeClaimMenuOn])

  useEffect(() => {
    setTotalClaimedAmountValue(
      findClaimedAmountOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    setTotalCounterClaimedAmountValue(
      findCounterClaimedAmountOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    setTreeWeightedValue(
      findWeightedValueOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.interestViewOption, props.claimInfo])

  const onSelectClaim = (e: any) => {
    const classesToIgnore = [
      'threeDotsButton',
      'threeDotsButtonContainerList',
      'threeDotsMenuRowList',
      'threeDotsMenuRowTextList',
      'collapse',
      'non-collapse',
      'expandImgText',
      'expandImg',
      'calculateButton',
      'button-text',
      'button-container',
    ]

    const includesClassToIgnore =
      e.target &&
      classesToIgnore.filter((className) =>
        e.target.className.includes(className),
      ).length > 0

    if (!includesClassToIgnore && !isDragging) {
      props.setActiveClaim(props.claimIndex)
      setEditTreeNodeFromUndo(undefined)
    }

    if (
      document.getElementById(`singleClaimPreviewContainer-${props.claimIndex}`)
    ) {
      document.getElementById(
        `singleClaimPreviewContainer-${props.claimIndex}`,
      )!.style.zIndex = '1'
    }
  }

  const unHoverClaim = () => {
    setHoverClaimTile(false)
  }

  const hasClaimAmount =
    props.claimInfo.totalClaimedAmount !== undefined &&
    props.claimInfo.totalClaimedAmount !== 0
  const hasCounterClaimAmount =
    props.claimInfo.totalCounterClaimedAmount !== undefined &&
    props.claimInfo.totalCounterClaimedAmount !== 0

  fitty('.listPreviewInfoValue', {
    minSize: 8,
    maxSize: 16,
  })

  useEffect(() => {
    if (
      props.claimInfo.name !== oldClaimInfo.name &&
      props.claimInfo.id === oldClaimInfo.id &&
      props.sorting.value === 'title'
    ) {
      props.changeSortingOption('none', true)
    }

    if (
      getClaimWeightedValue(props.claimInfo) !==
        getClaimWeightedValue(oldClaimInfo) &&
      props.claimInfo.id === oldClaimInfo.id &&
      (props.sorting.value === 'client' ||
        props.sorting.value === 'opposing' ||
        props.sorting.value === 'weighted')
    ) {
      props.changeSortingOption('none', true)
    }

    setOldClaimInfo(props.claimInfo)

    // eslint-disable-next-line
  }, [props.claimInfo])

  return (
    <Draggable
      nodeRef={nodeRef as React.RefObject<HTMLElement>}
      handle=".moveTileTransparentCoverList, .listPreviewNameContainer"
      onDrag={(e: any, data) => {
        if (data.x !== 0 || data.y !== 0) {
          setIsDragging(true)
        }

        props.handleDraggingClaim(data)
      }}
      onStop={() => {
        setTimeout(() => {
          setIsDragging(false)
        }, 20)

        props.handleChangeClaimOrder()
      }}
      defaultPosition={{ x: 0, y: 0 }}
      position={{ x: 0, y: 0 }}
    >
      <div
        className={`listPreviewMainContainer`}
        onClick={onSelectClaim}
        onMouseEnter={() => setHoverClaimTile(!props.isClaimMenuOn)}
        onMouseLeave={() => setHoverClaimTile(false)}
        id={`singleClaimPreviewContainer-${props.claimIndex}`}
        ref={nodeRef}
        style={{
          zIndex:
            (hoverClaimTile && !props.isClaimMenuOn) ||
            threeDotsMenuOn ||
            removeClaimMenuOn
              ? 1
              : 0,
        }}
        data-openreplay-obscured
      >
        <div className="listTreeAddedContainer">
          <div className="listPreviewNumber">{props.claimIndex + 1}</div>
          <div
            className={`listPreviewNameContainer tree ${
              props.isActive ? 'selected' : ''
            } ${props.expanded ? 'treeExpanded' : ''}  ${
              krogerusColors ? 'krogerus' : ''
            }`}
          >
            <p
              className={`listPreviewName`}
              id={`treeNamePreview-${props.claimIndex}`}
            >
              <img
                src={props.isActive ? treeSelectedImg : treeNonSelectedImg}
                className="titleImg"
                alt={props.isActive ? 'treeSelectedImg' : 'treeNonSelectedImg'}
              />
              {props.claimInfo.name}
            </p>
          </div>
        </div>
        <div className="listTreeContectLine"></div>
        <div
          className={`listPreviewInfoContainer tree ${
            hoverClaimTile ? 'isHovered' : ''
          } ${props.expanded ? 'treeExpanded' : ''}`}
        >
          <div className={`listPreviewInfoRow tree`}>
            {props.claimInfo.totalClaimedAmount === undefined ||
            props.claimInfo.totalClaimedAmountInterest1st === undefined ||
            props.claimInfo.totalClaimedAmountInterest2nd === undefined ||
            props.claimInfo.totalCounterClaimedAmount === undefined ||
            props.claimInfo.totalCounterClaimedAmountInterest1st ===
              undefined ||
            props.claimInfo.totalCounterClaimedAmountInterest2nd ===
              undefined ||
            props.claimInfo.treeWeightedValue === undefined ||
            props.claimInfo.treeWeightedValueInterest1st === undefined ||
            props.claimInfo.treeWeightedValueInterest2nd === undefined ? (
              <Button
                id={`calculateTreesButton-${props.claimIndex}`}
                buttonText={getText('button-27', user.settings)}
                buttonTextAttribute="button-27"
                type="button"
                buttonType="outlined"
                className="calculateButton"
                onClick={props.calculateAllTrees}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            ) : (
              <>
                <div className="listPreviewSingleInfo">
                  <p
                    className={`listPreviewInfoValue robotoNumbers ${
                      hasClaimAmount ? 'client' : ''
                    } ${krogerusColors ? 'krogerus' : ''}`}
                    id={`treeClaimAmount-${props.claimIndex}`}
                  >
                    {hasClaimAmount
                      ? stringAndRoundTo2Decimals(
                          totalClaimedAmountValue as number,
                          user.settings,
                        ) +
                        ' ' +
                        props.currency.toString()
                      : '-'}
                  </p>
                </div>
                <div className="listPreviewSingleInfo">
                  <p
                    className={`listPreviewInfoValue robotoNumbers ${
                      hasCounterClaimAmount ? 'opposing' : ''
                    } ${krogerusColors ? 'krogerus' : ''}`}
                    id={`treeCounterClaimAmount-${props.claimIndex}`}
                  >
                    {hasCounterClaimAmount
                      ? stringAndRoundTo2Decimals(
                          totalCounterClaimedAmountValue as number,
                          user.settings,
                          true,
                        ) +
                        ' ' +
                        props.currency.toString()
                      : '-'}
                  </p>
                </div>
                <div className="listPreviewSingleInfo">
                  <p
                    className={`listPreviewInfoValue robotoNumbers`}
                    id={`treeWeightedValue-${props.claimIndex}`}
                  >
                    {formattedNumToString(
                      roundNumberTo(treeWeigthedValue, 2),
                      user.settings,
                    ) +
                      ' ' +
                      props.currency.toString()}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        {props.errors > 0 && <div className="errorsList">{props.errors}</div>}
        {(hoverClaimTile || threeDotsMenuOn) &&
          !removeClaimMenuOn &&
          props.ownRole !== AccessRole.VIEWER && (
            <ClaimTile3DotsMenu
              claimIndex={props.claimIndex}
              threeDotsMenuOn={threeDotsMenuOn}
              setThreeDotsMenuOn={setThreeDotsMenuOn}
              setRemoveClaimMenuOn={setRemoveClaimMenuOn}
              claimType={ClaimType.tree}
              setActiveClaim={props.setActiveClaim}
              onList
              unHoverClaim={unHoverClaim}
            />
          )}
        {props.claimPositionHighlight === props.claimIndex && (
          <div
            className={`claimPositionHighlightList ${
              props.claimPositionHighlight > props.claimIsDragging!
                ? 'bottom'
                : 'top'
            }`}
            id={`claimPositionHighlight-${props.claimIndex}`}
          ></div>
        )}
        {removeClaimMenuOn && (
          <div
            className="claimRemoveMenuContainer"
            id={`claimRemoveMenuContainer-${props.claimIndex}`}
          >
            <DeleteClaimWindowForPreview
              claimIndex={props.claimIndex}
              claimType={ClaimType.tree}
              deleteFunction={() => {
                props.removeClaim(
                  props.claimIndex,
                  `removeClaimImgPreview-${props.claimIndex}`,
                  `removeClaimImgPreview-${props.claimIndex}`,
                )
                setRemoveClaimMenuOn(false)
              }}
              cancelFunction={() => {
                setRemoveClaimMenuOn(false)
              }}
              previewStyle={ClaimsPreviewStyle.list}
            />
          </div>
        )}
      </div>
    </Draggable>
  )
}

export default TreeList
