import { useState, useEffect } from 'react'

import linearImg from '../../resources/images/286-linearDistribution.svg'
import krogerusLinearImg from '../../resources/images/315-krogerusLinearDistribution.svg'
import resetImg from '../../resources/images/288-eachPaysOwnCost.svg'
import krogerusResetImg from '../../resources/images/316-krogerusResetLegalCostRanges.svg'
import resetRangesImg from '../../resources/images/287-resetLegalCostRanges.svg'
import krogerusResetRangesImg from '../../resources/images/311-krogerusResetLegalCostRanges.svg'

import './NewLegalFeesDistributionApp.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../states/ScenarioSnapshotState'
import { SnapshotSelectorObject } from '../../models/generalTypes'
import { AccessRole, UndoRedoType } from '../../models/enums'
import { deepCloneObject, logActivity } from '../../services/commonFunctions'
import {
  findDisputedAmount,
  findTotalCounterClaimedAmount,
} from '../../services/disputedAmountFunctions'
import { changeGlobalSnapshot } from '../../services/changeGlobalSnapshot'
import { WhiteTooltip } from '../tooltip/TooltipComponent'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import LegalCostsDistributionTool from './LegalFeesDistributionComponents/LegalCostsDistributionTool'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import { freemiumState } from '../../states/FreemiumState'
import { krogerusColorsState } from '../../states/KrogerusColorsState'

type Props = {
  ownRole: AccessRole
}

export default function NewLegalFeesDistributionApp(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  const [totalCounterClaimedAmount, setTotalCounterClaimedAmount] = useState(0) // it is the total amount asked in opposing party's claims and 'pays' in tree -- number <=0
  const [disputedAmount, setDisputedAmount] = useState(0) // it is the disputed amount of the case (totalClaimedAmount - totalCounterClaimedAmount) -- number >=0
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  /* const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ] */
  const currentSnapshot = scenarioSnapshot.currentSnapshot as ScenarioSnapshot
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    setTotalCounterClaimedAmount(findTotalCounterClaimedAmount(currentSnapshot))
    setDisputedAmount(findDisputedAmount(currentSnapshot))
    // eslint-disable-next-line
  }, [currentSnapshot.claims])

  function handleDistribution(action: 'linear' | 'reset') {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `${action}DistributionButton`,
      undoRedoType: UndoRedoType.button,
      value: action,
      key: 'legalFeesDistribution',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)
    if (isFirstLoad) {
      //Mixpanel 30 (All)
      logActivity(freemium.isFreemium, 'Changed legal costs distribution')
      setIsFirstLoad(false)
    }
  }

  function handleResetRanges() {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `resetRangesButton`,
      undoRedoType: UndoRedoType.button,
      value: 'resetRanges',
      key: 'legalFeesDistribution',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)
    if (isFirstLoad) {
      //Mixpanel 30 (All)
      logActivity(freemium.isFreemium, 'Reset legal costs ranges')
      setIsFirstLoad(false)
    }
  }

  return (
    <div className="lFDAppContainer">
      <div className="lFDAppNew" data-openreplay-obscured>
        <div className="lFDAppHeader">
          <p
            className="lFDAppTitle"
            id="lFDAppTitleLeft"
            data-textattribute="title-218"
            data-openreplay-obscured
          >
            {`${getText('title-218', user.settings)} ${
              currentSnapshot.currency
            }`}
          </p>
          <p
            className="lFDAppTitle"
            id="lFDAppTitleRight"
            data-textattribute="title-219"
            data-openreplay-obscured
          >
            {getText('title-219', user.settings)}
          </p>
          {props.ownRole !== AccessRole.VIEWER && (
            <>
              <div className="distributionButtonsContainer">
                <WhiteTooltip
                  title={getText('tooltip-45', user.settings)}
                  textAttribute="tooltip-45"
                  id="resetSegmentTooltip"
                >
                  <div className="imgContainer column">
                    <img
                      src={
                        krogerusColors ? krogerusResetRangesImg : resetRangesImg
                      }
                      alt="resetRanges"
                      className="linearImg"
                      onClick={() => handleResetRanges()}
                      id="resetRangesButton"
                    />
                  </div>
                </WhiteTooltip>
                <WhiteTooltip
                  title={getText('tooltip-1', user.settings)}
                  textAttribute="tooltip-1"
                  id="linearDistributionTooltip"
                >
                  <div className="imgContainer first">
                    <img
                      src={krogerusColors ? krogerusLinearImg : linearImg}
                      alt="linearImg"
                      className="linearImg"
                      onClick={() => handleDistribution('linear')}
                      id="linearDistributionButton"
                    />
                  </div>
                </WhiteTooltip>
                <WhiteTooltip
                  title={getText('tooltip-2', user.settings)}
                  textAttribute="tooltip-2"
                  id="noDistributionTooltip"
                >
                  <div className="imgContainer">
                    <img
                      src={krogerusColors ? krogerusResetImg : resetImg}
                      alt="resetImg"
                      className="resetImg"
                      id="resetDistributionButton"
                      onClick={() => handleDistribution('reset')}
                    />
                  </div>
                </WhiteTooltip>
              </div>
            </>
          )}
        </div>
        <div
          className="lFDAppBodyNew"
          style={
            props.ownRole === AccessRole.VIEWER
              ? currentSnapshot.includeTopLFDLimit
                ? { marginTop: 100 }
                : { marginTop: 50 }
              : undefined
          }
        >
          <LegalCostsDistributionTool
            totalCounterClaimedAmount={totalCounterClaimedAmount}
            disputedAmount={disputedAmount}
            isFirstLoad={isFirstLoad}
            setIsFirstLoad={setIsFirstLoad}
          />
        </div>
      </div>
    </div>
  )
}
