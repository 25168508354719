import { useState } from 'react'

import { AccessRole } from '../../../models/enums'
import { LegalFeesDistributionObject } from '../../../models/legalFeesDistributionObject'
import NewLegalFeesRange from './SingleSegmentComponents/NewLegalFeesRange'
import limitSegmentArrowImg from '../../../resources/images/239-arrow.svg'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'

type Props = {
  feeDistributions: LegalFeesDistributionObject[]
  singleSegmentIndex: number
  totalCounterClaimedAmount: number
  disputedAmount: number
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  pdfPreview?: boolean
  ownRole?: AccessRole
  limitSegment: 'top' | 'bottom'
  awardedTo: 'hover-62' | 'hover-63'
}

export default function LimitSegment(props: Props) {
  const [changeEffect, setChangeEffect] = useState(false) // a boolean that is responsible for the animation effect when the range is changing -- bool

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const limitSegmentIsOn =
    props.limitSegment === 'top'
      ? scenarioSnapshot.currentSnapshot.includeTopLFDLimit === true
      : scenarioSnapshot.currentSnapshot.includeBottomLFDLimit === true

  return (
    <div
      className="limitSegmentContainer"
      style={
        limitSegmentIsOn ? undefined : { position: 'absolute', zIndex: -1 }
      }
      data-openreplay-obscured
    >
      <div
        className={`limitSegmentHoverArea ${
          props.limitSegment === 'top' ? 'top' : ''
        }`}
        id={`limitSegmentHoverArea-${props.limitSegment}`}
      ></div>

      <>
        <div
          className={`limitSegmentArrowImgContainer ${
            props.limitSegment === 'top' ? '' : 'bottom'
          }`}
          data-openreplay-obscured
        >
          <img
            src={limitSegmentArrowImg}
            alt="limitSegmentArrowImg"
            className={`limitSegmentArrowImg ${
              props.limitSegment === 'top' ? '' : 'bottom'
            }`}
            id={`limitSegmentArrowImg-${props.limitSegment}`}
          />
        </div>
        <div
          className={`singleSegmentContainerNew ${
            props.pdfPreview ? 'pdfPreviewSingleSegment' : ''
          } limit ${props.limitSegment === 'top' ? '' : 'bottom'}`}
          style={{ height: 50 }}
        >
          <NewLegalFeesRange
            singleSegmentIndex={
              props.limitSegment === 'top'
                ? props.feeDistributions.length - 1
                : 0
            }
            feeDistributions={props.feeDistributions}
            changeEffect={changeEffect}
            setChangeEffect={setChangeEffect}
            pdfPreview={props.pdfPreview}
            isFirstLoad={props.isFirstLoad}
            setIsFirstLoad={props.setIsFirstLoad}
            ownRole={props.ownRole}
            disputedAmount={props.disputedAmount}
            totalCounterClaimedAmount={props.totalCounterClaimedAmount}
          />
        </div>
      </>
    </div>
  )
}
