import { useRecoilValue } from 'recoil'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'

type Props = {}

export default function LCDEachPartyPaysOwnInfo(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <p className="LCDEachPartyPaysText" data-textattribute="description-290">
        {getText('description-290', user.settings)}
      </p>
    </>
  )
}
