import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { useRecoilValue } from 'recoil'
import {
  AccessRole,
  LCDMenuChoice,
  VideoPostName,
} from '../../../../models/enums'
import { getText } from '../../../../services/textFunctions'
import VideoInfoButton from '../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { userState } from '../../../../states/UserState'
import LegalCostsDistributionChips from './legalCostsDistributionComponents/LegalCostsDistributionChips'
import LCDStandardDistributionInfo from './legalCostsDistributionComponents/LCDStandardDistributionInfo'
import LCDEachPartyPaysOwnInfo from './legalCostsDistributionComponents/LCDEachPartyPaysOwnInfo'
import LCDAdvancedDistributionInfo from './legalCostsDistributionComponents/LCDAdvancedDistributionInfo'
import {
  hasRecoverableLegalCosts,
  legalFeesZero,
} from '../../../../services/legalCostsFunctions'
import NoLegalCostsIncluded from '../../../../Components/LegalFeesDistribution/LegalFeesDistributionComponents/NoLegalCostsIncluded'

type Props = {
  calculateAllTrees: () => Promise<void>
  setVideoPostName: (param: VideoPostName | undefined) => void
  ownRole: AccessRole
}

export default function LegalFeesDistribution(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  return (
    <div
      className="tool-component"
      id="legalFeesDistributionComp"
      style={
        legalFeesZero(scenarioSnapshot.currentSnapshot) ||
        hasRecoverableLegalCosts(scenarioSnapshot.currentSnapshot) === false
          ? { minHeight: '500px' }
          : {}
      }
    >
      <h2
        className="tool-component-title"
        id="legalCostsDistribution-title"
        data-textattribute="title-37"
      >
        {getText('title-37', user.settings)}
        <VideoInfoButton
          textAttribute="tooltip-19"
          postName={VideoPostName.legalCostsDistribution}
          setVideoPostName={props.setVideoPostName}
        />
      </h2>
      <LegalCostsDistributionChips ownRole={props.ownRole} />
      {scenarioSnapshot.currentSnapshot.legalCostsDistributionSettings
        .menuChoice === LCDMenuChoice.standardDistribution ? (
        <LCDStandardDistributionInfo
          calculateAllTrees={props.calculateAllTrees}
        />
      ) : scenarioSnapshot.currentSnapshot.legalCostsDistributionSettings
          .menuChoice === LCDMenuChoice.eachPartyPaysOwn ? (
        <LCDEachPartyPaysOwnInfo />
      ) : scenarioSnapshot.currentSnapshot.legalCostsDistributionSettings
          .menuChoice === LCDMenuChoice.advancedDistribution ? (
        <LCDAdvancedDistributionInfo
          ownRole={props.ownRole}
          calculateAllTrees={props.calculateAllTrees}
        />
      ) : null}
      {legalFeesZero(scenarioSnapshot.currentSnapshot) ||
      hasRecoverableLegalCosts(scenarioSnapshot.currentSnapshot) === false ? (
        <NoLegalCostsIncluded />
      ) : null}
    </div>
  )
}
