import { useState } from 'react'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import { ClaimType } from '../../../../../models/enums'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import LCDPreviewContainer from './LCDPreviewContainer'
import { logActivity } from '../../../../../services/commonFunctions'
import { freemiumState } from '../../../../../states/FreemiumState'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'

type Props = {
  calculateAllTrees: () => Promise<void>
}

export default function LCDStandardDistributionInfo(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const freemium = useRecoilValue(freemiumState)
  const user = useRecoilValue(userState)

  const [showDetails, setShowDetails] = useState(false)

  const hasAllDisputedAmounts =
    scenarioSnapshot.currentSnapshot.claims.filter(
      (claim) =>
        claim.type === ClaimType.tree &&
        ((claim as TreeClaim).totalClaimedAmount === undefined ||
          (claim as TreeClaim).totalCounterClaimedAmount === undefined ||
          (claim as TreeClaim).treeWeightedValue === undefined),
    ).length === 0

  return (
    <>
      <p className="LCDStandardText" data-textattribute="description-291">
        {getText('description-291', user.settings)}{' '}
        <span onClick={() => setShowDetails(!showDetails)}>
          {showDetails
            ? getText('button-172', user.settings)
            : getText('button-171', user.settings)}
        </span>
      </p>

      {showDetails && (
        <>
          {hasAllDisputedAmounts ? (
            <LCDPreviewContainer />
          ) : (
            <button
              type="button"
              className="legalFeesDistributionAnalysisButton"
              onClick={() => {
                props.calculateAllTrees()
                //Mixpanel 16 (All)
                logActivity(
                  freemium.isFreemium,
                  'Calculated disputed amount for legal cost distribution',
                )
              }}
            >
              <p
                className={`legalFeesDistributionAnalysisButtonText`}
                id="legalFeesDistributionAnalysisButtonText"
                data-textattribute="button-32"
              >
                {getText('button-32', user.settings)}
              </p>
            </button>
          )}
        </>
      )}
    </>
  )
}
