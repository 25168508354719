import { useRecoilState, useRecoilValue } from 'recoil'
import { AnimationDots } from '../Components/Animations/AnimationDots'
import FullScreenPopMessage from '../Components/popUps/FullScreenPopMessage'
import { ANIMATION_DOTS_SPEED } from '../services/constants'
import { getText } from '../services/textFunctions'
import { userState } from '../states/UserState'
import { onlineState } from '../states/OnlineState'
import { viewerState } from '../states/ViewerState'
import FreemiumMessage from '../Components/popUps/FreemiumMessage'
import { freemiumState } from '../states/FreemiumState'
import AppScalingPopUp from '../Components/popUps/AppScalingPopUp'
import { logActivity } from '../services/commonFunctions'

type Props = {
  children: React.ReactElement
  zoomMessage: {
    showPopUp: boolean
    closedByUser: boolean
  }
  setZoomMessage: (value: { showPopUp: boolean; closedByUser: boolean }) => void
}

const FullScreenPopUpsHOC = (props: Props) => {
  const user = useRecoilValue(userState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  return (
    <>
      {online.showPopUp && !online.networkOn && (
        <FullScreenPopMessage
          id="noInternet"
          warningTitle={getText('title-74', user.settings)}
          titleTextAttribute="title-74"
          zIndex={2000}
          backGroundColor="#6f6f6f" //tuesdayGray
          blur
          warningTextContainer={
            <>
              <div
                className="warningText"
                id="popUpWarningText-noInternet"
                data-textattribute="description-11"
              >
                {getText('description-11', user.settings)}
                <AnimationDots speed={ANIMATION_DOTS_SPEED} />
              </div>
            </>
          }
          warningSubTextContainer={
            <p
              className="warningSubText"
              id="popUpWarningSubText-noInternet"
              data-textattribute="description-12a"
            >
              {getText('description-12a', user.settings)}{' '}
              <a
                href="mailto:support@eperoto.com"
                className="link"
                id={'popUp11'}
                data-textattribute="description-12b"
              >
                {getText('description-12b', user.settings)}
              </a>
            </p>
          }
          cancelFunction={() => setOnline({ ...online, showPopUp: false })}
          noConnection
        />
      )}
      {isViewer.showPopUp && isViewer.isViewer && (
        <FullScreenPopMessage
          id="isViewerPopUp"
          zIndex={2000}
          backGroundColor="#6f6f6f" //tuesdayGray
          blur
          titleTextAttribute="title-162"
          warningTitle={getText('title-162', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-noInternet"
                data-textattribute="description-127"
              >
                {getText('description-127', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <p
              className="warningSubText"
              id="popUpWarningSubText-noInternet"
              data-textattribute="description-128a, description-128b"
            >
              {getText('description-128a', user.settings)}{' '}
              <a
                href="mailto:support@eperoto.com"
                className="link"
                id={'popUp11'}
                data-textattribute="description-12b"
              >
                {getText('description-12b', user.settings)}
              </a>{' '}
              {getText('description-128b', user.settings)}
            </p>
          }
          cancelFunction={() => setIsViewer({ ...isViewer, showPopUp: false })}
        />
      )}
      {freemium.showMessage && (
        <FreemiumMessage
          messageType={freemium.showMessage}
          onClose={() => {
            setFreemium({ ...freemium, showMessage: undefined })
          }}
        />
      )}
      {props.zoomMessage.showPopUp && !props.zoomMessage.closedByUser && (
        <AppScalingPopUp
          onClose={() => {
            props.setZoomMessage({ ...props.zoomMessage, closedByUser: true })
            //Mixpanel 183 (All)
            logActivity(freemium.isFreemium, 'Closed zoom PopUp')
          }}
        />
      )}
      {props.children}
    </>
  )
}

export default FullScreenPopUpsHOC
