import { useRecoilState, useRecoilValue } from 'recoil'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import { freemiumState } from '../../../../../states/FreemiumState'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { recoveryModeState } from '../../../../../states/RecoveryModeState'
import { viewerState } from '../../../../../states/ViewerState'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import { AccessRole, ClaimType } from '../../../../../models/enums'
import NewLegalFeesDistributionApp from '../../../../../Components/LegalFeesDistribution/NewLegalFeesDistributionApp'
import { logActivity } from '../../../../../services/commonFunctions'

type Props = {
  ownRole: AccessRole
  calculateAllTrees: () => Promise<void>
}

export default function LCDAdvancedDistributionInfo(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)

  const hasAllDisputedAmounts =
    scenarioSnapshot.currentSnapshot.claims.filter(
      (claim) =>
        claim.type === ClaimType.tree &&
        ((claim as TreeClaim).totalClaimedAmount === undefined ||
          (claim as TreeClaim).totalCounterClaimedAmount === undefined ||
          (claim as TreeClaim).treeWeightedValue === undefined),
    ).length === 0

  // FIX the hasAllTreesOutcomes - First check that the results are less than 8

  return (
    <>
      <p
        className="tool-component-subtitle marginLeft1"
        id="legalCostsDistribution-description"
        data-textattribute="description-43"
      >
        {getText('description-43', user.settings)}
      </p>
      {hasAllDisputedAmounts ? (
        <>
          {props.ownRole === AccessRole.VIEWER && (
            <div
              className="legalFeesDistributionTransparentLayer"
              onClick={() => {
                if (recoveryMode.recoveryPreview !== 'none') {
                  setRecoveryMode({ ...recoveryMode, shaking: true })
                } else {
                  setIsViewer({ ...isViewer, shaking: true })
                }
              }}
            ></div>
          )}
          <div className="legalFeesDistributionDetails">
            <NewLegalFeesDistributionApp ownRole={props.ownRole} />
          </div>
        </>
      ) : (
        <button
          type="button"
          className="legalFeesDistributionAnalysisButton"
          onClick={() => {
            props.calculateAllTrees()
            //Mixpanel 16 (All)
            logActivity(
              freemium.isFreemium,
              'Calculated disputed amount for legal cost distribution',
            )
          }}
        >
          <p
            className={`legalFeesDistributionAnalysisButtonText`}
            id="legalFeesDistributionAnalysisButtonText"
            data-textattribute="button-32"
          >
            {getText('button-32', user.settings)}
          </p>
        </button>
      )}{' '}
    </>
  )
}
