import { LCDMenuChoice } from './enums'
import { LegalFeesDistributionObject } from './legalFeesDistributionObject'
import { ScenarioSnapshot } from './scenarioSnapshot'

export class LegalCostsDistributionSettings {
  menuChoice: LCDMenuChoice
  advancedDistributionMemory: LegalFeesDistributionObject[]

  constructor(
    menuChoice: LCDMenuChoice,
    advancedDistributionMemory: LegalFeesDistributionObject[],
  ) {
    this.menuChoice = menuChoice
    this.advancedDistributionMemory = [...advancedDistributionMemory]
  }

  static DefaultSettings(): LegalCostsDistributionSettings {
    return {
      menuChoice: LCDMenuChoice.standardDistribution,
      advancedDistributionMemory: [
        ...ScenarioSnapshot.CreateDefaultCustomLCDObject(),
      ],
    }
  }
}
