import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../states/UserState'
import { getText } from '../../../../services/textFunctions'
import {
  AccessRole,
  LegalCostInputType,
  LegalCostsParty,
  LegalCostsProceedings,
  LegalCostsSwitchButtonType,
} from '../../../../models/enums'
import LegalCostsInputs from './LegalCostsInputs'
import clientSymbolImg from '../../../../resources/images/279-clientSymbol.svg'
import krogerusClientImg from '../../../../resources/images/303-clientSymbolKrogerus.svg'
import opposingPartySymbolImg from '../../../../resources/images/280-opposingPartySymbol.svg'
import krogerusOpposingPartyImg from '../../../../resources/images/304-krogerusOpposingPartySymbol.svg'
import { krogerusColorsState } from '../../../../states/KrogerusColorsState'

type Props = {
  handleChangeLegalCostValue: (
    e: any,
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
    legalCostInputType: LegalCostInputType,
    value: number | LegalCostsSwitchButtonType,
  ) => void
  party: LegalCostsParty
  errors: string[]
  ownRole: AccessRole
}

export default function PartyLegalCostsComponent(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const user = useRecoilValue(userState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const krogerusColors = useRecoilValue(krogerusColorsState)

  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  const estimatedCostsValueFirst =
    currentSnapshot.legalCosts[props.party]!.firstProceedings.estimatedCosts
  const estimatedCostsValueSecond = currentSnapshot.hasSecondTrial
    ? currentSnapshot.legalCosts[props.party]!.additionalProceedings!
        .estimatedCosts
    : undefined
  const recoverableCostsTypeFirst =
    currentSnapshot.legalCosts[props.party]!.firstProceedings.recoverableCosts!
      .type
  const recoverableCostsTypeSecond = currentSnapshot.hasSecondTrial
    ? currentSnapshot.legalCosts[props.party]!.additionalProceedings!
        .recoverableCosts!.type
    : LegalCostsSwitchButtonType.currency
  const recoverableCostsFirst =
    currentSnapshot.legalCosts[props.party]!.firstProceedings.recoverableCosts!
      .value
  const recoverableCostsSecond = currentSnapshot.hasSecondTrial
    ? currentSnapshot.legalCosts[props.party]!.additionalProceedings!
        .recoverableCosts!.value
    : undefined
  const incurredCostsTypeFirst =
    currentSnapshot.legalCosts[props.party]!.firstProceedings.incurredCosts!
      .type
  const incurredCostsTypeSecond = currentSnapshot.hasSecondTrial
    ? currentSnapshot.legalCosts[props.party]!.additionalProceedings!
        .incurredCosts!.type
    : LegalCostsSwitchButtonType.currency
  const incurredCostsFirst =
    currentSnapshot.legalCosts[props.party]!.firstProceedings.incurredCosts!
      .value
  const incurredCostsSecond = currentSnapshot.hasSecondTrial
    ? currentSnapshot.legalCosts[props.party]!.additionalProceedings!
        .incurredCosts!.value
    : undefined

  return (
    <div
      className={
        currentSnapshot.hasSecondTrial
          ? 'partyLegalCostsComponentContainer'
          : 'noSecondTrial partyLegalCostsComponentContainer'
      }
      id={`partyLegalCostsComponentContainer-${props.party}`}
    >
      <div
        className="partyLegalCostsTitle"
        id={`partyLegalCostsTitle-${props.party}`}
        data-textattribute={
          props.party === LegalCostsParty.clientLegalCosts
            ? 'title-97'
            : 'title-98'
        }
      >
        <p id={`partyLegalCostsTitleText-${props.party}`}>
          {props.party === LegalCostsParty.clientLegalCosts
            ? getText('title-97', user.settings, partiesFormat)
            : getText('title-98', user.settings, partiesFormat)}
        </p>
        <img
          src={
            props.party === LegalCostsParty.clientLegalCosts
              ? krogerusColors
                ? krogerusClientImg
                : clientSymbolImg
              : krogerusColors
              ? krogerusOpposingPartyImg
              : opposingPartySymbolImg
          }
          style={{ marginLeft: '9px' }}
          alt="partyLegalCostsSymbolImg"
          className="partyLegalCostsSymbolImg"
          id={`partyLegalCostsSymbolImg-${props.party}`}
        />
      </div>

      <LegalCostsInputs
        party={props.party}
        proceedings={LegalCostsProceedings.firstProceedings}
        estimatedCostsValue={estimatedCostsValueFirst}
        recoverableCostsType={recoverableCostsTypeFirst}
        recoverableCostsValue={recoverableCostsFirst}
        incurredCostsType={incurredCostsTypeFirst}
        incurredCostsValue={incurredCostsFirst}
        handleChangeLegalCostValue={props.handleChangeLegalCostValue}
        errors={props.errors}
        ownRole={props.ownRole}
      />
      {currentSnapshot.hasSecondTrial ? (
        <LegalCostsInputs
          party={props.party}
          proceedings={LegalCostsProceedings.additionalProceedings}
          estimatedCostsValue={estimatedCostsValueSecond}
          recoverableCostsType={recoverableCostsTypeSecond}
          recoverableCostsValue={recoverableCostsSecond}
          incurredCostsType={incurredCostsTypeSecond}
          incurredCostsValue={incurredCostsSecond}
          handleChangeLegalCostValue={props.handleChangeLegalCostValue}
          errors={props.errors}
          ownRole={props.ownRole}
        />
      ) : null}
    </div>
  )
}
