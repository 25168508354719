import {
  AccessRole,
  AmountSignum,
  InterestViewOption,
  PartySignum,
  UndoRedoType,
} from '../../models/enums'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
  TreeAnalysisResults,
} from '../../models/generalTypes'
import { TreeClaim, TreeDetails } from '../../models/treeModels/treeClaim'
import { changeGlobalSnapshot } from '../changeGlobalSnapshot'
import { v4 as uuid } from 'uuid'
import {
  changeNodesPosition,
  changeNodesPositionsMinMax,
  defaultExtraSpace,
} from './treePositioningFunctions'
import { deepCloneObject, generateId, roundNumberTo } from '../commonFunctions'
import {
  EventId,
  HeightOfNodeState,
  NodeId,
  NodeMode,
  NodesMovedForEditMode,
  RootNodeType,
} from '../../models/treeModels/treeTypes'
import {
  findLeftMostNodeOfMainTree,
  findLimitsOfNode,
  findRightMostNodeOfTree,
  widthOfNode,
} from './treeXandYFunctions'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import { secondTrialDateIsValid } from '../validateGlobalState'
import {
  roundTo2Decimals,
  roundTo4Decimals,
} from '../../Modules/DisputeModules/AppFunctions'
import { TreeNodeClass } from '../../models/treeModels/treeNode'
import { TreeEventClass } from '../../models/treeModels/treeEvent'
import { EventDetails } from '../../models/treeModels/eventDetails'
import { formattedNumToString } from '../formatNum'
import { UserSettings } from '../../models/userSettings'
import { findArrayIndexesWithValue } from '../treeNumberOfScenariosCalculation'
import { snapshotChangeKillsResults } from '../killingTheResultsFunctions'
import { checkIfTreeHasInterest } from '../resultsFunctions'

export function findNodePosition(NodeId: string): [number, number] {
  return [0, 0]
}

export function changeTreeClaim(
  tempScenarioSnapshot: DefaultSnapshotState,
  currentSnapshot: ScenarioSnapshot,
  tempTreeClaim: TreeClaim,
  targetId: string | string[],
  undoRedoType: UndoRedoType,
  treeIndex: number,
  analysisResults: boolean,
  ownRole: AccessRole,
  nodesMovedForEditMode?: NodesMovedForEditMode,
): DefaultSnapshotState {
  if (ownRole === AccessRole.VIEWER) {
    return tempScenarioSnapshot
  }

  const snapshotSelectorObject: SnapshotSelectorObject = {
    targetId: targetId,
    undoRedoType: undoRedoType,
    value: tempTreeClaim,
    key: 'claims',
    key2: 'tree',
    claimIndex: treeIndex,
  }

  tempTreeClaim.analysisResults = analysisResults
  if (
    tempTreeClaim.analysisResults === false &&
    snapshotChangeKillsResults(
      currentSnapshot,
      tempScenarioSnapshot.currentSnapshot,
    )
  ) {
    tempTreeClaim.totalClaimedAmount = undefined
    tempTreeClaim.totalClaimedAmountInterest1st = undefined
    tempTreeClaim.totalClaimedAmountInterest2nd = undefined
    tempTreeClaim.totalCounterClaimedAmount = undefined
    tempTreeClaim.totalCounterClaimedAmountInterest1st = undefined
    tempTreeClaim.totalCounterClaimedAmountInterest2nd = undefined
    tempTreeClaim.treeWeightedValue = undefined
    tempTreeClaim.treeWeightedValueInterest1st = undefined
    tempTreeClaim.treeWeightedValueInterest2nd = undefined
    tempTreeClaim.maxAmountIndex = undefined
    tempTreeClaim.minAmountIndex = undefined
    tempTreeClaim.awardedAmountsArray = []
  }

  tempScenarioSnapshot = changeGlobalSnapshot(
    snapshotSelectorObject,
    tempScenarioSnapshot,
    nodesMovedForEditMode,
  )
  return tempScenarioSnapshot
}

export function deleteTreeEvent(
  tempScenarioSnapshot: DefaultSnapshotState,
  tempTreeClaim: TreeClaim,
  treeIndex: number,
  eventId: EventId,
  withChildren: boolean,
) {
  let tempTreeEvents = tempTreeClaim.treeDetails.events
  let tempTreeNodes = tempTreeClaim.treeDetails.nodes

  if (withChildren) {
    let childrenNodes = findChildrenNodesFromEvent(
      eventId,
      tempTreeClaim.treeDetails,
      [],
      true,
    )

    for (let childNode of childrenNodes) {
      let eventsOfChildNode = findEventsOfNode(
        childNode as NodeId,
        tempTreeClaim.treeDetails,
      )
      for (let eventId of eventsOfChildNode) {
        //erase the event object of each event of the child
        delete tempTreeEvents[eventId]
      }
      //erase the node object of each child
      delete tempTreeNodes[childNode as NodeId]
    }
  } else {
    for (let childNodeId of tempTreeEvents[eventId].childrenNodes) {
      const node = tempTreeClaim.treeDetails.nodes[childNodeId]

      const siblingIndex = node.nodeSiblingIndex as {
        [parentEventId: string]: number
      }

      if (Object.keys(siblingIndex).length > 1) {
        delete siblingIndex[eventId]
      } else {
        node.root = RootNodeType.orphanTreeRoot
        node.nodeSiblingIndex = {}
      }
    }
  }
  delete tempTreeEvents[eventId]
  //remove the nodeIds from childrenNodes of all other events, since a node can have multiple parents
  for (let eventId of Object.keys(tempTreeEvents)) {
    tempTreeClaim.treeDetails.events[eventId as EventId].childrenNodes =
      tempTreeClaim.treeDetails.events[eventId as EventId].childrenNodes.filter(
        (nodeId) => Object.keys(tempTreeNodes).includes(nodeId),
      )
  }

  tempScenarioSnapshot.currentSnapshot.claims[treeIndex] = tempTreeClaim

  return tempScenarioSnapshot
}

export function deleteTreeNode(
  tempScenarioSnapshot: DefaultSnapshotState,
  tempTreeClaim: TreeClaim,
  treeIndex: number,
  nodeId: NodeId,
  withChildren: boolean,
) {
  /* console.log('deleting nodeId', nodeId) */

  let tempTreeNodes = tempTreeClaim.treeDetails.nodes
  let tempTreeEvents = tempTreeClaim.treeDetails.events
  let eventsOfNode = findEventsOfNode(nodeId, tempTreeClaim.treeDetails)

  if (withChildren) {
    let [, newMainTree, orphanTrees] = findMainTreeAndOrphanTrees(
      tempTreeClaim.treeDetails,
      true,
    )
    if (newMainTree.includes('node123')) return

    let trees = [newMainTree].concat(orphanTrees)
    let childrenNodes = findNodesOfTreeFromRoot(
      nodeId,
      tempTreeClaim.treeDetails,
      [],
      trees,
      true,
    )
    // console.log(childrenNodes)

    for (let childNode of childrenNodes) {
      let eventsOfChildNode = findEventsOfNode(
        childNode as NodeId,
        tempTreeClaim.treeDetails,
      )
      for (let eventId of eventsOfChildNode) {
        //erase the event object of each event of the child
        delete tempTreeEvents[eventId]
      }
      //erase the node object of each child
      delete tempTreeNodes[childNode as NodeId]
    }
  } else {
    let directChildrenNodesFromNode = findDirectChildrenNodesOfNode(
      nodeId,
      tempTreeClaim.treeDetails,
    )

    for (let childNodeId of directChildrenNodesFromNode) {
      const node = tempTreeClaim.treeDetails.nodes[childNodeId as NodeId]

      const siblingIndex = node.nodeSiblingIndex as {
        [parentEventId: string]: number
      }

      if (Object.keys(siblingIndex).length > 1) {
        for (let eventId of eventsOfNode) {
          if (Object.keys(siblingIndex).includes(eventId)) {
            delete siblingIndex[eventId]
          }
        }
        if (Object.keys(siblingIndex).length === 0) {
          node.root = RootNodeType.orphanTreeRoot
        }
      } else {
        node.root = RootNodeType.orphanTreeRoot
        node.nodeSiblingIndex = {}
      }
    }
  }
  delete tempTreeNodes[nodeId]

  for (let eventId of eventsOfNode) {
    delete tempTreeEvents[eventId]
  }
  //remove the nodeIds from childrenNodes of all other events, since a node can have multiple parents
  for (let eventId of Object.keys(tempTreeEvents)) {
    tempTreeClaim.treeDetails.events[eventId as EventId].childrenNodes =
      tempTreeClaim.treeDetails.events[eventId as EventId].childrenNodes.filter(
        (nodeId) => Object.keys(tempTreeNodes).includes(nodeId),
      )
  }

  tempScenarioSnapshot.currentSnapshot.claims[treeIndex] = tempTreeClaim

  return tempScenarioSnapshot
}

export function findNewEventIndex(nodeId: NodeId, treeClaim: TreeClaim) {
  return Object.keys(treeClaim.treeDetails.events).filter(
    (eventId) =>
      treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId === nodeId,
  ).length
}

export function findNewNodeSiblingIndex(
  eventId: EventId,
  treeClaim: TreeClaim,
) {
  return {
    [eventId]: treeClaim.treeDetails.events[eventId].childrenNodes.length,
  }
}

export function resetNodeSiblingsIndex(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  let parentEventIds = findParentEvents(nodeId, treeDetails)
  treeDetails.nodes[nodeId].nodeSiblingIndex = {}

  if (parentEventIds !== '') {
    for (let parentEventId of parentEventIds) {
      for (
        let i = 0;
        i < treeDetails.events[parentEventId].childrenNodes.length;
        i++
      ) {
        let nodeSiblingIndexObject = treeDetails.nodes[
          treeDetails.events[parentEventId].childrenNodes[i] as NodeId
        ].nodeSiblingIndex as { [parentEventId: EventId]: number }

        nodeSiblingIndexObject[parentEventId] = i
        Object.assign(
          treeDetails.nodes[
            treeDetails.events[parentEventId].childrenNodes[i] as NodeId
          ].nodeSiblingIndex,
          nodeSiblingIndexObject,
        )
      }
    }
  }
  return treeDetails
}

export function findEventsOfNode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): EventId[] {
  return Object.keys(treeDetails.events).filter(
    (eventId) =>
      treeDetails.events[eventId as EventId].nodeOfEventId === nodeId,
  ) as EventId[]
}

export function findEventsOfAllNodes(treeDetails: TreeDetails): {
  [nodes: NodeId]: EventId[]
} {
  const eventsOfAllNodes: { [nodes: NodeId]: EventId[] } = {}
  for (let nodeId of Object.keys(treeDetails.nodes)) {
    eventsOfAllNodes[nodeId as NodeId] = Object.keys(treeDetails.events).filter(
      (eventId) =>
        treeDetails.events[eventId as EventId].nodeOfEventId === nodeId,
    ) as EventId[]
  }
  return eventsOfAllNodes
}

export function checkIfEventBelongsToNode(
  eventId: EventId,
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  return treeDetails.events[eventId as EventId].nodeOfEventId === nodeId
}

export function findParentEvents(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): EventId[] | '' {
  let parentEvents = []
  for (let eventId of Object.keys(treeDetails.events)) {
    if (treeDetails.events[eventId as EventId].childrenNodes.includes(nodeId)) {
      parentEvents.push(eventId)
    }
  }
  if (parentEvents.length === 0) return ''

  return parentEvents as EventId[]
}

export function findParentNodes(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): NodeId[] | undefined {
  let parentNodes: NodeId[] = []
  let parentEvents = findParentEvents(nodeId, treeDetails)

  if (parentEvents.length > 0) {
    for (let parentEvent of parentEvents) {
      parentNodes.push(treeDetails.events[parentEvent as EventId].nodeOfEventId)
    }
    return parentNodes
  } else {
    return undefined
  }
}

export function changeProbabilities(
  tempNodeProbabilities: number[],
  eventIndex: number,
  value: number,
) {
  const numberOfScenarios = tempNodeProbabilities.length
  //Step 1: finds the sum of probabilities before the scenarioIndex
  var sumOfProbabilitiesBefore = 0
  for (let i = 0; i < eventIndex; i++) {
    sumOfProbabilitiesBefore += tempNodeProbabilities[i]
  }

  //Rule 1:
  if (value > 1 - sumOfProbabilitiesBefore) {
    tempNodeProbabilities[eventIndex] = 1 - sumOfProbabilitiesBefore
    for (let i = eventIndex + 1; i < numberOfScenarios; i++) {
      tempNodeProbabilities[i] = 0
    }
  }
  //Rule 2:
  else if (value > tempNodeProbabilities[eventIndex]) {
    for (let i = eventIndex + 1; i < numberOfScenarios; i++) {
      if (tempNodeProbabilities[i] > 0) {
        if (value - tempNodeProbabilities[eventIndex] === 0) {
          break
        } else if (
          value - tempNodeProbabilities[eventIndex] >
          tempNodeProbabilities[i]
        ) {
          tempNodeProbabilities[eventIndex] += tempNodeProbabilities[i]
          tempNodeProbabilities[i] = 0
        } else if (
          value - tempNodeProbabilities[eventIndex] <=
          tempNodeProbabilities[i]
        ) {
          tempNodeProbabilities[i] -= value - tempNodeProbabilities[eventIndex]
          tempNodeProbabilities[eventIndex] = value
          break
        }
      }
    }
  }
  //Rule 3:
  else if (value < tempNodeProbabilities[eventIndex]) {
    tempNodeProbabilities[eventIndex + 1] +=
      tempNodeProbabilities[eventIndex] - value
    tempNodeProbabilities[eventIndex] = value
  }
  for (let i in tempNodeProbabilities) {
    tempNodeProbabilities[i] = roundNumberTo(tempNodeProbabilities[i], 2)
  }

  return tempNodeProbabilities
}

export function redistributeNodeProbabilities(
  tempScenarioSnapshot: DefaultSnapshotState,
  tempTreeClaim: TreeClaim,
  treeIndex: number,
  tempNodeProbabilities: number[],
  eventIndex: number,
  nodeId: NodeId,
) {
  tempNodeProbabilities = findNewNodeProbabilities(
    tempNodeProbabilities,
    eventIndex,
  )
  let eventsOfNode = findEventsOfNode(nodeId, tempTreeClaim.treeDetails)

  for (let eventId of eventsOfNode) {
    if (tempTreeClaim.treeDetails.events[eventId].eventIndex > eventIndex) {
      tempTreeClaim.treeDetails.events[eventId].eventIndex--
    }
    tempTreeClaim.treeDetails.events[eventId].eventDetails.probability =
      tempNodeProbabilities[
        tempTreeClaim.treeDetails.events[eventId].eventIndex
      ]
  }
  tempScenarioSnapshot.currentSnapshot.claims[treeIndex] = tempTreeClaim

  return tempScenarioSnapshot
}

export function findNewNodeProbabilities(
  tempNodeProbabilities: number[],
  eventIndex: number,
) {
  const removedProbability = tempNodeProbabilities[eventIndex]
  tempNodeProbabilities.splice(eventIndex, 1)
  if (eventIndex === 0) {
    tempNodeProbabilities[0] += removedProbability
  } else {
    tempNodeProbabilities[eventIndex! - 1] += removedProbability
  }
  return tempNodeProbabilities
}

export function findMainTreeAndOrphanTrees(
  treeDetails: TreeDetails,
  allowDuplicates?: boolean,
): [TreeDetails, NodeId[], NodeId[][]] {
  const mainTreeRoot = findMainTreeRoot(treeDetails)
  const allTreeRoots = findAllTreeRoots(treeDetails)
  let trees = []

  for (let nodeId of allTreeRoots) {
    let nodesOfTreeFromRoot = findNodesOfTreeFromRoot(
      nodeId as NodeId,
      treeDetails,
      [],
      [],
      false,
    )
    if (!nodesOfTreeFromRoot.includes('node123')) {
      trees.push(nodesOfTreeFromRoot)
    } else {
      return [treeDetails, ['node123'], [['node123']]]
    }
  }

  let mainTree: NodeId[] = []
  for (let tree of trees) {
    if (tree.length >= mainTree.length) {
      mainTree = tree
    }
  }
  //console.log('mainTree-1', JSON.stringify(mainTree))
  let mainTrees: NodeId[][] = [mainTree]

  for (let tree of trees) {
    if (tree.length === mainTree.length) {
      mainTrees.push(tree)
    }
  }

  for (let tempMainTree of mainTrees) {
    //console.log('currentMainRoot', currentMainRoot)
    //console.log('tempMainTree', tempMainTree)

    if (tempMainTree.includes(mainTreeRoot as NodeId)) {
      mainTree = tempMainTree
      break
    }
  }

  //console.log('mainTree-2', JSON.stringify(mainTree))

  trees.splice(
    trees.findIndex((tree) => tree === mainTree),
    1,
  )

  let orphanTrees = trees

  for (let nodeId of allTreeRoots) {
    if (mainTree.includes(nodeId as NodeId)) {
      treeDetails.nodes[nodeId as NodeId].root = RootNodeType.mainTreeRoot
    } else {
      for (let orphanTree of orphanTrees) {
        if (orphanTree.includes(nodeId as NodeId)) {
          treeDetails.nodes[nodeId as NodeId].root = RootNodeType.orphanTreeRoot
          treeDetails.nodes[nodeId as NodeId].nodeSiblingIndex = {}
        }
      }
    }
  }

  if (!allowDuplicates) {
    for (let orphanTree of orphanTrees) {
      for (let i = orphanTree.length - 1; i >= 0; i--) {
        if (mainTree.includes(orphanTree[i])) {
          orphanTree.splice(i, 1)
        }
      }
    }
  }
  //console.log(orphanTrees)
  return [treeDetails, mainTree, orphanTrees]
}

export function findNodesOfTreeFromRoot(
  nodeIdToCheck: NodeId,
  treeDetails: TreeDetails,
  treeNodes: NodeId[] = [],
  trees: NodeId[][],
  excludeNodesThatExistToOtherTrees: boolean,
) {
  try {
    if (excludeNodesThatExistToOtherTrees) {
      let nodeTreeIndexInTrees = findArrayIndexesWithValue(trees, nodeIdToCheck)

      if (nodeTreeIndexInTrees.length === 1) {
        treeNodes.push(nodeIdToCheck)
      }
    } else {
      treeNodes.push(nodeIdToCheck)
    }
    let eventsOfNode = findEventsOfNode(nodeIdToCheck, treeDetails)
    for (let eventId of eventsOfNode) {
      for (let childNode of treeDetails.events[eventId].childrenNodes) {
        treeNodes = findNodesOfTreeFromRoot(
          childNode,
          treeDetails,
          treeNodes,
          trees,
          excludeNodesThatExistToOtherTrees,
        )
      }
    }
  } catch (error) {
    return ['node123'] as NodeId[]
  }
  return [...new Set(treeNodes)]
}

export function findChildrenNodesFromEvent(
  eventId: EventId,
  treeDetails: TreeDetails,
  treeNodes: NodeId[] = [],
  excludeNodesThatExistToOtherTrees: boolean,
): NodeId[] {
  let trees: NodeId[][] = []
  if (excludeNodesThatExistToOtherTrees) {
    let [, newMainTree, orphanTrees] = findMainTreeAndOrphanTrees(
      treeDetails,
      true,
    )
    if (orphanTrees.includes(['node123'])) return ['node123']

    trees = [newMainTree].concat(orphanTrees)
  }

  for (let childNode of treeDetails.events[eventId].childrenNodes) {
    treeNodes = treeNodes.concat(
      findNodesOfTreeFromRoot(
        childNode,
        treeDetails,
        [],
        trees,
        excludeNodesThatExistToOtherTrees,
      ),
    )
  }

  return treeNodes
}

export function duplicateNodes(
  nodes: NodeId[],
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  treeIndex: number,
  nodesMovedForEditMode: NodesMovedForEditMode | undefined,
  editMode: NodeId | undefined,
): [TreeDetails, NodeId[], NodesMovedForEditMode | undefined] {
  const duplicatedNodes: { [key: NodeId]: NodeId } = {}
  const duplicatedEvents: { [key: EventId]: EventId } = {}
  let newSelectedNodes: NodeId[] = []
  const sortedInitialNodes = nodes.sort((nodeA, nodeB) =>
    treeDetails.nodes[nodeA].properties.position[0] >=
    treeDetails.nodes[nodeB].properties.position[0]
      ? 1
      : -1,
  )

  for (let nodeId of sortedInitialNodes) {
    //create a duplicatedNodeId
    const duplicatedNodeId: NodeId = `node${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    //create the pair in duplicatedNodes
    duplicatedNodes[nodeId] = duplicatedNodeId
    newSelectedNodes.push(duplicatedNodeId)
    //copy the details of the node and add them to the treeDetails under the newNodeId
    treeDetails.nodes[duplicatedNodeId] = deepCloneObject(
      treeDetails.nodes[nodeId],
    )

    //define the properDuplicatedNodePosition using duplicatedNodeY = originalNodeY and duplicatedNodeX = findLimitsOfNode(originalNodeX, treeDetails, 'x')[1] + 95
    treeDetails.nodes[duplicatedNodeId].root = RootNodeType.orphanTreeRoot
    treeDetails.nodes[duplicatedNodeId].nodeSiblingIndex = {}
    treeDetails.nodes[duplicatedNodeId].properties.position[0] =
      findLimitsOfNode(
        nodeId,
        treeDetails,
        'x',
        editMode === nodeId ? NodeMode.maximised : nodeMode,
        treeIndex,
        false,
      )[1] + defaultExtraSpace

    let eventsOfNode = findEventsOfNode(nodeId, treeDetails)
    for (let eventId of eventsOfNode) {
      const duplicatedEventId: EventId = `event${uuid().substring(
        0,
        8,
      )}${parseInt(Date.now().toString())}`
      duplicatedEvents[eventId] = duplicatedEventId
      treeDetails.events[duplicatedEventId] = deepCloneObject(
        treeDetails.events[eventId],
      )
      treeDetails.events[duplicatedEventId].nodeOfEventId = duplicatedNodeId
      treeDetails.events[duplicatedEventId].childrenNodes = []
    }
    treeDetails = changeNodesPosition(
      duplicatedNodes[nodeId as NodeId],
      treeDetails,
      nodeMode,
      'duplicateNodes',
      treeIndex,
      undefined,
      nodes.length > 1,
    )
  }

  for (let eventId of Object.keys(duplicatedEvents)) {
    for (let childNode of treeDetails.events[eventId as EventId]
      .childrenNodes) {
      if (nodes.includes(childNode as NodeId)) {
        treeDetails.events[
          duplicatedEvents[eventId as EventId] as EventId
        ].childrenNodes.push(duplicatedNodes[childNode as NodeId])
        treeDetails.nodes[duplicatedNodes[childNode as NodeId] as NodeId].root =
          RootNodeType.noRoot
        treeDetails.nodes[
          duplicatedNodes[childNode as NodeId] as NodeId
        ].nodeSiblingIndex = {}
      }
    }
  }

  //resetNodeSiblingsIndex and define orphans
  for (let nodeId of Object.keys(duplicatedNodes)) {
    treeDetails = resetNodeSiblingsIndex(
      duplicatedNodes[nodeId as NodeId],
      treeDetails,
    )
  }

  if (nodesMovedForEditMode) {
    let xDifference = 0
    for (let nodeId of Object.keys(nodesMovedForEditMode)) {
      if (
        treeDetails.nodes[nodeId as NodeId].properties.position[0] !==
        nodesMovedForEditMode[nodeId].newPosition[0]
      ) {
        xDifference =
          treeDetails.nodes[nodeId as NodeId].properties.position[0] -
          nodesMovedForEditMode[nodeId].newPosition[0]
        nodesMovedForEditMode[nodeId].newPosition[0] += xDifference
        nodesMovedForEditMode[nodeId].previousPosition[0] += xDifference
      }
    }

    for (let nodeId of Object.keys(duplicatedNodes)) {
      let newDuplicatedNode = duplicatedNodes[nodeId as NodeId]
      nodesMovedForEditMode[newDuplicatedNode] = {
        newPosition: deepCloneObject(
          treeDetails.nodes[newDuplicatedNode].properties.position,
        ),
        previousPosition: deepCloneObject(
          treeDetails.nodes[newDuplicatedNode].properties.position,
        ),
      }

      nodesMovedForEditMode[newDuplicatedNode].previousPosition[0] =
        nodesMovedForEditMode[newDuplicatedNode].previousPosition[0] -
        xDifference
    }
  }

  return [treeDetails, newSelectedNodes, nodesMovedForEditMode]
}

export function copyNodes(
  nodes: NodeId[],
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
) {
  const copiedNodes: { [key: NodeId]: TreeNodeClass } = {}
  const copiedEvents: { [key: EventId]: TreeEventClass } = {}

  const sortedInitialNodes = nodes.sort((nodeA, nodeB) =>
    treeDetails.nodes[nodeA].properties.position[0] >=
    treeDetails.nodes[nodeB].properties.position[0]
      ? 1
      : -1,
  )

  const leftMostNode = findLeftMostNodeOfMainTree(
    treeDetails,
    sortedInitialNodes,
  )
  const offSetX =
    treeDetails.nodes[leftMostNode as NodeId].properties.position[0]

  for (let nodeId of sortedInitialNodes) {
    copiedNodes[nodeId] = deepCloneObject(treeDetails.nodes[nodeId])
    copiedNodes[nodeId].root = RootNodeType.orphanTreeRoot
    copiedNodes[nodeId].nodeSiblingIndex = {}
    copiedNodes[nodeId].properties.position[0] =
      copiedNodes[nodeId].properties.position[0] - offSetX

    let eventsOfNode = findEventsOfNode(nodeId, treeDetails)
    for (let eventId of eventsOfNode) {
      copiedEvents[eventId] = deepCloneObject(treeDetails.events[eventId])
      copiedEvents[eventId].nodeOfEventId = nodeId
      copiedEvents[eventId].childrenNodes = []
    }
  }

  for (let eventId of Object.keys(copiedEvents)) {
    for (let childNode of treeDetails.events[eventId as EventId]
      .childrenNodes) {
      if (nodes.includes(childNode as NodeId)) {
        copiedEvents[eventId as EventId].childrenNodes.push(childNode as NodeId)
        copiedNodes[childNode as NodeId].root = RootNodeType.noRoot
      }
    }
  }

  for (let nodeId of Object.keys(copiedNodes)) {
    let parentEventIds = findParentEvents(nodeId as NodeId, treeDetails)

    if (parentEventIds !== '') {
      for (let parentEventId of parentEventIds) {
        if (Object.keys(copiedEvents).includes(parentEventId)) {
          for (
            let i = 0;
            i < copiedEvents[parentEventId].childrenNodes.length;
            i++
          ) {
            let nodeSiblingIndexObject = copiedNodes[
              copiedEvents[parentEventId].childrenNodes[i] as NodeId
            ].nodeSiblingIndex as { [parentEventId: EventId]: number }

            nodeSiblingIndexObject[parentEventId] = i
            Object.assign(
              copiedNodes[
                copiedEvents[parentEventId].childrenNodes[i] as NodeId
              ].nodeSiblingIndex,
              nodeSiblingIndexObject,
            )
          }
        }
      }
    }
  }

  return {
    copiedEvents: copiedEvents,
    copiedNodes: copiedNodes,
    nodeMode: nodeMode,
  }
}

export function pasteNodes(
  copiedNodes: { [nodeId: NodeId]: TreeNodeClass },
  copiedEvents: { [eventId: EventId]: TreeEventClass },
  copiedNodeMode: NodeMode,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  nodesMovedForEditMode: NodesMovedForEditMode | undefined,
): [TreeDetails, NodesMovedForEditMode | undefined, NodeId[]] {
  const oldToNewNodeIds: { [key: NodeId]: NodeId } = {}
  const changeNodeMode = copiedNodeMode !== nodeMode
  if (changeNodeMode) {
    treeDetails = changeNodesPositionsMinMax(copiedNodeMode, treeDetails)
  }
  const rightestNodeId = findRightMostNodeOfTree(treeDetails)
  const offsetX =
    treeDetails.nodes[rightestNodeId as NodeId].properties.position[0] +
    widthOfNode(rightestNodeId as NodeId, copiedNodeMode, treeDetails) +
    1.5 * defaultExtraSpace

  for (const nodeId of Object.keys(copiedNodes)) {
    const newNodeId: NodeId = `node${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    oldToNewNodeIds[nodeId as NodeId] = newNodeId
    treeDetails.nodes[newNodeId] = copiedNodes[nodeId as NodeId]
    treeDetails.nodes[newNodeId].properties.position[0] =
      copiedNodes[nodeId as NodeId].properties.position[0] + offsetX
    treeDetails.nodes[newNodeId].properties.position[1] =
      copiedNodes[nodeId as NodeId].properties.position[1]
  }

  for (const eventId of Object.keys(copiedEvents)) {
    const newEventId: EventId = `event${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    treeDetails.events[newEventId] = copiedEvents[eventId as EventId]
    treeDetails.events[newEventId].nodeOfEventId =
      oldToNewNodeIds[treeDetails.events[newEventId].nodeOfEventId]

    for (let oldNodeId of treeDetails.events[newEventId].childrenNodes) {
      const tempValue =
        treeDetails.nodes[oldToNewNodeIds[oldNodeId]].nodeSiblingIndex[
          eventId as EventId
        ]

      delete treeDetails.nodes[oldToNewNodeIds[oldNodeId]].nodeSiblingIndex[
        eventId as EventId
      ]

      treeDetails.nodes[oldToNewNodeIds[oldNodeId]].nodeSiblingIndex[
        newEventId
      ] = tempValue
    }

    for (
      let i = 0;
      i < treeDetails.events[newEventId].childrenNodes.length;
      i++
    ) {
      treeDetails.events[newEventId].childrenNodes[i] =
        oldToNewNodeIds[treeDetails.events[newEventId].childrenNodes[i]]
    }
  }

  if (nodesMovedForEditMode) {
    let xDifference = 0
    for (let nodeId of Object.keys(nodesMovedForEditMode)) {
      if (
        treeDetails.nodes[nodeId as NodeId].properties.position[0] !==
        nodesMovedForEditMode[nodeId].newPosition[0]
      ) {
        xDifference =
          treeDetails.nodes[nodeId as NodeId].properties.position[0] -
          nodesMovedForEditMode[nodeId].newPosition[0]
        nodesMovedForEditMode[nodeId].newPosition[0] += xDifference
        nodesMovedForEditMode[nodeId].previousPosition[0] += xDifference
      }
    }

    for (let nodeId of Object.values(oldToNewNodeIds)) {
      nodesMovedForEditMode[nodeId] = {
        newPosition: deepCloneObject(
          treeDetails.nodes[nodeId as NodeId].properties.position,
        ),
        previousPosition: deepCloneObject(
          treeDetails.nodes[nodeId as NodeId].properties.position,
        ),
      }

      nodesMovedForEditMode[nodeId].previousPosition[0] =
        nodesMovedForEditMode[nodeId].previousPosition[0] - xDifference
    }
  }
  if (changeNodeMode) {
    treeDetails = changeNodesPositionsMinMax(nodeMode, treeDetails)
  }

  return [treeDetails, nodesMovedForEditMode, Object.values(oldToNewNodeIds)]
}

export function findDirectChildrenNodesOfNode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  let eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  let directChildrenNodes: NodeId[] = []

  for (let eventId of eventsOfNode) {
    directChildrenNodes = directChildrenNodes.concat(
      treeDetails.events[eventId].childrenNodes,
    )
  }

  return [...new Set(directChildrenNodes)]
}

export function showAvailableEventsForLine(
  nodeCreatingLine: NodeId,
  treeDetails: TreeDetails,
): EventId[] {
  const eventsOfNode = findEventsOfNode(nodeCreatingLine, treeDetails)
  const parentEvents = findParentEvents(nodeCreatingLine, treeDetails)

  const availableEventsForLine: EventId[] = Object.keys(
    treeDetails.events,
  ).filter(
    (eventId) =>
      !eventsOfNode.includes(eventId as EventId) &&
      !parentEvents.includes(eventId as EventId),
  ) as EventId[]

  return availableEventsForLine
}

export function isEventEligibleToConnect(
  eventId: EventId,
  connectionNodeId: NodeId,
  treeDetails: TreeDetails,
): boolean {
  const nodesOfTheSameTree = findNodesOfTheSameTree(
    connectionNodeId,
    treeDetails,
  )

  const nodesOfTreeFromRoot = findNodesOfTreeFromRoot(
    connectionNodeId as NodeId,
    treeDetails,
    [],
    [],
    false,
  )

  if (
    ((treeDetails.nodes[connectionNodeId].root === RootNodeType.mainTreeRoot ||
      treeDetails.nodes[connectionNodeId].root ===
        RootNodeType.orphanTreeRoot) &&
      nodesOfTheSameTree.includes(treeDetails.events[eventId].nodeOfEventId)) ||
    nodesOfTreeFromRoot.includes(treeDetails.events[eventId].nodeOfEventId)
  ) {
    return false
  }
  return true
}

export function showAvailableNodesForLine(
  eventCreatingLine: EventId,
  treeDetails: TreeDetails,
): NodeId[] {
  let availableNodesForLine = Object.keys(treeDetails.nodes)
    .filter(
      (nodeId) =>
        treeDetails.events[eventCreatingLine].nodeOfEventId !== nodeId,
    )
    .filter(
      (nodeId) =>
        !treeDetails.events[eventCreatingLine].childrenNodes.includes(
          nodeId as NodeId,
        ),
    ) as NodeId[]

  return availableNodesForLine
}

export function findNodesOfTheSameTree(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): NodeId[] {
  //WARNING
  //WARNING
  //Might create a loop - INVESTIGATE
  let startingNode: NodeId | undefined = nodeId
  let rootOfTheTree = nodeId
  while (startingNode !== undefined) {
    rootOfTheTree = startingNode

    startingNode =
      findParentNodes(startingNode, treeDetails) !== undefined
        ? findParentNodes(startingNode, treeDetails)![0]
        : undefined
  }
  return findNodesOfTreeFromRoot(rootOfTheTree, treeDetails, [], [], false)
}

export function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index
}

export function findDirectChildrenInOldTree(
  scenarioName: string,
  nodesFromOldTree: { [nodeId: NodeId]: string },
): NodeId[] {
  let directChildren: NodeId[] = []

  for (let nodeId in nodesFromOldTree) {
    if (
      nodesFromOldTree[nodeId as NodeId].includes(scenarioName) &&
      nodesFromOldTree[nodeId as NodeId].length === scenarioName.length + 5
    ) {
      directChildren.push(nodeId as NodeId)
    }
  }

  return directChildren
}

export function oneOfTheSelectedNodesHasChildren(
  selectedNodes: NodeId[],
  treeDetails: TreeDetails,
) {
  for (let tempNodeId of selectedNodes) {
    if (findDirectChildrenNodesOfNode(tempNodeId, treeDetails).length > 0) {
      return true
    }
  }
  return false
}

export function findMainTreeRoot(treeDetails: TreeDetails): NodeId {
  return Object.keys(treeDetails.nodes).filter(
    (nodeId) =>
      treeDetails.nodes[nodeId as NodeId].root === RootNodeType.mainTreeRoot,
  )[0] as NodeId
}

export function findAllTreeRoots(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).filter(
    (nodeId) =>
      treeDetails.nodes[nodeId as NodeId].root !== RootNodeType.noRoot,
  ) as NodeId[]
}

export function findOrphanTreeRoots(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).filter(
    (nodeId) =>
      treeDetails.nodes[nodeId as NodeId].root === RootNodeType.orphanTreeRoot,
  ) as NodeId[]
}

export function findEditModePositionsOfTree(
  scenarioSnapshot: DefaultSnapshotState,
  nodesMovedForEditMode: NodesMovedForEditMode,
  undoId: string | string[],
): DefaultSnapshotState {
  let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
  let tempId = undoId as string
  if (typeof undoId !== 'string') {
    tempId = undoId[0] as string
  }
  let treeIndex = parseInt(tempId.split('-')[1].split('_')[0])
  let nodeId = tempId.split('_')[1].split('!')[0]

  let tempTreeDetails = (
    tempScenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
  ).treeDetails as TreeDetails
  let tempNodeMode = (
    tempScenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
  ).nodeMode

  for (let tempNodeId of Object.keys(nodesMovedForEditMode)) {
    if (Object.keys(tempTreeDetails.nodes).includes(tempNodeId)) {
      tempTreeDetails.nodes[tempNodeId as NodeId].properties.position =
        nodesMovedForEditMode[tempNodeId].newPosition
    }
  }

  ;(
    tempScenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
  ).treeDetails = changeNodesPosition(
    nodeId as NodeId,
    tempTreeDetails,
    tempNodeMode,
    'addNode',
    treeIndex,
    nodeId as NodeId,
  )

  return tempScenarioSnapshot
}

export function changeTextAreaHeight(element: HTMLElement | Element) {
  if (element) {
    ;(element as HTMLElement).style.height = 'auto'
    ;(element as HTMLElement).style.height = element.scrollHeight + 'px'
    let top = -(element as HTMLElement).style.height + 25
    ;(element as HTMLElement).style.top = top + 'px'
  }
}

export function findZeroProbabilityEventsInMainTree(
  treeDetails: TreeDetails,
  orhanTrees: NodeId[],
) {
  return Object.keys(treeDetails.events).filter(
    (eventId) =>
      treeDetails.events[eventId as EventId].eventDetails.probability === 0 &&
      !orhanTrees.includes(
        treeDetails.events[eventId as EventId].nodeOfEventId,
      ),
  )
}

export function findHeightOfNodeState(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): HeightOfNodeState {
  //There are 12 possible combinations
  if (
    !checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 1.awardedAmount: Off, interest: Off, reducedAmount: Off, externalValue: Off [0 0 0 0]
  ) {
    return HeightOfNodeState.noAmounts_1
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 2.awardedAmount: On, interest: Off, reducedAmount: Off, externalValue: Off [1 0 0 0]
  ) {
    return HeightOfNodeState.awardedAmountOnly_2
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 3.awardedAmount: On, interest: On, reducedAmount: Off, externalValue: Off [1 1 0 0]
  ) {
    return HeightOfNodeState.awardedAmountAndInterestOnly_3
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 4.awardedAmount: On, interest: Off, reducedAmount: On, externalValue: Off [1 0 1 0]
  ) {
    return HeightOfNodeState.awardedAmountAndReduced_4
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 5.awardedAmount: On, interest: On, reducedAmount: On, externalValue: Off [1 1 1 0]
  ) {
    return HeightOfNodeState.awardedAmountAndInterestAndReduced_5
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 6.awardedAmount: On, interest: Off, reducedAmount: Off, externalValue: On [1 0 0 1]
  ) {
    return HeightOfNodeState.awardedAmountAndExternal_6
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 7.awardedAmount: On, interest: On, reducedAmount: Off, externalValue: On [1 1 0 1]
  ) {
    return HeightOfNodeState.awardedAmountAndInterestAndExternal_7
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 8.awardedAmount: On, interest: Off, reducedAmount: On, externalValue: On [1 0 1 1]
  ) {
    return HeightOfNodeState.awardedAmountAndReducedAndExternal_8
  } else if (
    !checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 9.awardedAmount: Off, interest: Off, reducedAmount: On, externalValue: Off [0 0 1 0]
  ) {
    return HeightOfNodeState.reducedAmountOnly_9
  } else if (
    !checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 10.awardedAmount: Off, interest: Off, reducedAmount: On, externalValue: On [0 0 1 1]
  ) {
    return HeightOfNodeState.reducedAmountAndExternal_10
  } else if (
    !checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    !checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 11.awardedAmount: Off, interest: Off, reducedAmount: Off, externalValue: On [0 0 0 1]
  ) {
    return HeightOfNodeState.externalOnly_11
  } else if (
    checkIfNodeHasAtLeastOneAwardedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneInterestMenuOn(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneReducedAmount(nodeId, treeDetails) &&
    checkIfNodeHasAtLeastOneOutOfCourtAmount(nodeId, treeDetails)
    // 12.awardedAmount: On, interest: On, reducedAmount: On, externalValue: On [1 1 1 1]
  ) {
    return HeightOfNodeState.fullAmounts_12
  }
  return HeightOfNodeState.noAmounts_1
}

export function focusOnEditMode(id: string) {
  if (id.includes('node') && id.includes('!')) {
    const nodeIdForId = id.split('_')[1].split('!')[0]
    const eventIndexForId = id.split('_')[1].split('!')[1]
    const treeIndex = id.split('-')[1].split('_')[0]
    if (id.includes('treeEventTitleContainerMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventTitleInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventTitleInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventProbabilityMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventProbabilityInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventProbabilityInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventAwardedAmountTextMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventAwardedAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventAwardedAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventInterestDateTextMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `container-treeEventInterestDateInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `container-treeEventInterestDateInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .getElementsByTagName('input')[0]
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventInterestRateTextMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventInterestRateInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventInterestRateInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventReducedAmountTextMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventReducedAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventReducedAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
    if (id.includes('treeEventOutOfCourtAmountTextMinimised')) {
      setTimeout(() => {
        if (
          document.getElementById(
            `treeEventOutOfCourtAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
          )
        ) {
          document
            .getElementById(
              `treeEventOutOfCourtAmountInput-${treeIndex}_${nodeIdForId}!${eventIndexForId}`,
            )!
            .focus()
        }
      }, 100)
    }
  }
}

export function shouldEditModeToggle(
  id: string,
  editTreeNodeFromUndo: NodeId | undefined,
  editMode: NodeId | undefined,
  classList: string,
) {
  return (
    //duplicatePressedFromDifferentNode(id, editMode, classList) ||
    (!id.includes('treeNodeMoveHandle') &&
      !id.includes('deleteNodeOrEventMenu') &&
      !id.includes('deleteNodeButton') &&
      !id.includes('treeAddEvent') &&
      !id.includes('treeAddNodeDot') &&
      !id.includes('selectNode') &&
      !id.includes('duplicateNode') &&
      !id.includes('copyNode') &&
      !id.includes('createLine') &&
      !id.includes('treeEventProbabilityInput') &&
      !id.includes('connectionCircle') &&
      !id.includes('treeEventAddNodeButton') &&
      !id.includes('deleteTreeEventImg') &&
      !id.includes('deleteLine') &&
      !id.includes('treeEventIncludeInterest') &&
      !id.includes('treeEventRemoveAmountInterestSection') &&
      !id.includes('treeEventRemoveReducedAmountSection') &&
      !id.includes('treeEventRemoveOutOfCourtAmountSection') &&
      !id.includes('treeEventAddSectionButton') &&
      !id.toLowerCase().includes('addscenario') &&
      !id.includes('zoomIn') &&
      !id.includes('zoomOut') &&
      !id.includes('Line-') &&
      !id.includes('treeEventAddNodeButton')) ||
    undoRedoPressed(id, editTreeNodeFromUndo)
  )
}

export function undoRedoPressed(
  id: string,
  editTreeNodeFromUndo: NodeId | undefined,
) {
  return (
    (id.includes('undoButton') || id.includes('redoButton')) &&
    editTreeNodeFromUndo === undefined
  )
}

export function duplicatePressedFromDifferentNode(
  id: string,
  editMode: NodeId | undefined,
  classList: string,
) {
  return (
    editMode !== undefined &&
    !JSON.stringify(classList).includes(editMode) &&
    !id.includes(editMode)
  )
}

export function checkIfNodeHasAtLeastOneEventTitle(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const eventsOfNode = findEventsOfNode(nodeId, treeDetails)

  return (
    eventsOfNode.filter(
      (eventId: EventId) =>
        treeDetails.events[eventId].eventDetails.eventTitle.length > 0,
    ).length > 0
  )
}

export function checkIfNodeHasAtLeastOneAwardedAmount(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  return (
    eventsOfNode.filter(
      (eventId: EventId) =>
        treeDetails.events[eventId].eventDetails.awardedAmountSignum !==
        AmountSignum.off,
    ).length > 0
  )
}

export function checkIfNodeHasAtLeastOneReducedAmount(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  return (
    eventsOfNode.filter(
      (eventId: EventId) =>
        treeDetails.events[eventId].eventDetails.reducedAwardedAmountParty !==
        PartySignum.off,
    ).length > 0
  )
}

export function checkIfNodeHasAtLeastOneOutOfCourtAmount(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  return (
    eventsOfNode.filter(
      (eventId) =>
        treeDetails.events[eventId].eventDetails.outOfCourtAmountSignum !==
        AmountSignum.off,
    ).length > 0
  )
}

export function checkIfNodeHasAtLeastOneInterestMenuOn(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  return (
    eventsOfNode.filter(
      (eventId) =>
        treeDetails.events[eventId].eventDetails.hasInterest === true,
    ).length > 0
  )
}

export function calculateValueOfTree(
  currentSnapshot: ScenarioSnapshot,
  treeResults: TreeAnalysisResults[][],
) {
  let tempValueOfTreeNoInterest = 0
  for (let treeScenario of treeResults[0]) {
    tempValueOfTreeNoInterest +=
      (treeScenario[4] - treeScenario[2]) * treeScenario[5]
  }
  let tempValueOfTreeFirst = undefined
  const treeHasInterest = checkIfTreeHasInterest(
    treeResults[0],
    currentSnapshot,
  )

  if (currentSnapshot.firstTrialDate && treeHasInterest) {
    tempValueOfTreeFirst = 0
    for (let treeScenario of treeResults[0]) {
      tempValueOfTreeFirst += treeScenario[4] * treeScenario[5]
    }
  }
  let tempValueOfTreeSecond = undefined
  if (secondTrialDateIsValid(currentSnapshot) && treeHasInterest) {
    tempValueOfTreeSecond = 0
    for (let treeScenario of treeResults[1]) {
      tempValueOfTreeSecond += treeScenario[4] * treeScenario[5]
    }
  }
  // console.log({
  //   'No interest': tempValueOfTreeNoInterest,
  //   'Interest 1st': tempValueOfTreeFirst,
  //   'Interest 2nd': tempValueOfTreeSecond,
  // })

  return {
    'No interest': tempValueOfTreeNoInterest,
    'Interest 1st': tempValueOfTreeFirst,
    'Interest 2nd': tempValueOfTreeSecond,
  }
}

export function calculateWeightOfTree(
  treeResults: TreeAnalysisResults[][],
  interest: InterestViewOption,
) {
  let res = 0
  switch (interest) {
    case InterestViewOption.noInterest:
      for (let treeScenario of treeResults[0]) {
        res += (treeScenario[1] + treeScenario[3]) * treeScenario[5]
      }
      break
    case InterestViewOption.interest1st:
      for (let treeScenario of treeResults[0]) {
        res +=
          (treeScenario[1] + treeScenario[2] + treeScenario[3]) *
          treeScenario[5]
      }

      break
    case InterestViewOption.interest2nd:
      if (treeResults[1]) {
        for (let treeScenario of treeResults[1]) {
          res +=
            (treeScenario[1] + treeScenario[2] + treeScenario[3]) *
            treeScenario[5]
        }
      }
      break
    default:
      break
  }

  return roundTo4Decimals(res)
}

export function textForValueOfTreePercentageChange(
  valueOfTreePercentageChange: number,
  userSettings: UserSettings,
) {
  if (
    valueOfTreePercentageChange !== 0 &&
    valueOfTreePercentageChange < 0.01 &&
    valueOfTreePercentageChange > -0.01
  ) {
    return '~0'
  }
  return formattedNumToString(
    roundTo2Decimals(Math.abs(valueOfTreePercentageChange)),
    userSettings,
  )
}

export function createTreeSelectionGraph(
  treeResults: TreeAnalysisResults[][],
  treeInterestViewOption: InterestViewOption,
): [number, number][] {
  let tempTreeSelectionGraph: [number, number][] = []

  switch (treeInterestViewOption) {
    case InterestViewOption.noInterest:
      for (let treeScenario of treeResults[0]) {
        if (treeScenario[5] !== 0) {
          let financialOutcome = treeScenario[4] - treeScenario[2]
          tempTreeSelectionGraph.push([financialOutcome, treeScenario[5]])
        }
      }
      break
    case InterestViewOption.interest1st:
      for (let treeScenario of treeResults[0]) {
        if (treeScenario[5] !== 0) {
          tempTreeSelectionGraph.push([treeScenario[4], treeScenario[5]])
        }
      }
      break
    case InterestViewOption.interest2nd:
      if (treeResults[1]) {
        for (let treeScenario of treeResults[1]) {
          if (treeScenario[5] !== 0) {
            tempTreeSelectionGraph.push([treeScenario[4], treeScenario[5]])
          }
        }
      }
      break
    default:
      break
  }

  // Sort the array by amount in descending order
  tempTreeSelectionGraph.sort((a, b) => b[0] - a[0])
  tempTreeSelectionGraph.unshift([tempTreeSelectionGraph[0][0], 0])

  // Calculate the total probability
  const totalProb = tempTreeSelectionGraph.reduce(
    (acc, [_, prob]) => acc + prob,
    0,
  )

  // Calculate the cumulative probability and aggregate the array
  let cumProb = 0
  const tempTreeSelectionGraphAggregated: [number, number][] =
    tempTreeSelectionGraph.map(([amount, prob]) => {
      cumProb += prob / totalProb
      return [amount, cumProb]
    })

  return tempTreeSelectionGraphAggregated
}

export function fillGaps(
  tempTreeSelectionGraph: [number, number][],
): [number, number][] {
  const result: [number, number][] = []

  for (let i = 0; i < tempTreeSelectionGraph.length; i++) {
    const current = tempTreeSelectionGraph[i]
    const next = tempTreeSelectionGraph[i + 1]

    // Check if the next item exists and has the same percentage but different value
    if (next && current[1] === next[1] && current[0] !== next[0]) {
      result.push([current[0], parseFloat((current[1] - 0.005).toFixed(3))])
      result.push(current)
      result.push(next)
      result.push([next[0], parseFloat((next[1] + 0.005).toFixed(3))])
      i++ // Skip the next item since it's already handled
    } else {
      result.push(current)
    }
  }

  return result
}

export function replaceEventAndNodeIds(treeDetails: TreeDetails) {
  // replace events
  let eventIdsObj: { [eventId: string]: string } = {}

  for (let eventId of Object.keys(treeDetails.events)) {
    eventIdsObj[eventId] = generateId('event')
  }
  let nodeIdsObj: { [nodeId: string]: string } = {}
  for (let nodeId of Object.keys(treeDetails.nodes)) {
    nodeIdsObj[nodeId] = generateId('node')
  }
  let tempTreeDetailsJson = JSON.stringify(treeDetails)
  for (let eventId of Object.keys(treeDetails.events)) {
    tempTreeDetailsJson = tempTreeDetailsJson.replaceAll(
      eventId,
      eventIdsObj[eventId],
    )
  }
  for (let nodeId of Object.keys(treeDetails.nodes)) {
    tempTreeDetailsJson = tempTreeDetailsJson.replaceAll(
      nodeId,
      nodeIdsObj[nodeId],
    )
  }

  treeDetails = JSON.parse(tempTreeDetailsJson) as TreeDetails

  return treeDetails
}

export function createNewNode(treeClaim: TreeClaim, eventId: EventId) {
  const newNodeId: NodeId = generateId('node') as NodeId
  const newNodeSiblingIndex = findNewNodeSiblingIndex(eventId, treeClaim)
  treeClaim.treeDetails.events[eventId].childrenNodes.push(newNodeId)
  treeClaim.treeDetails.nodes[newNodeId] = TreeNodeClass.defaultTreeNode(
    newNodeSiblingIndex,
    [0, 0],
    RootNodeType.noRoot,
  )
  const newEventId1: EventId = generateId('event') as EventId
  treeClaim.treeDetails.events[newEventId1] = TreeEventClass.defaultTreeEvent(
    newNodeId,
    0,
    EventDetails.defaultEventDetails(0.5),
  )
  const newEventId2: EventId = generateId('event') as EventId
  treeClaim.treeDetails.events[newEventId2] = TreeEventClass.defaultTreeEvent(
    newNodeId,
    1,
    EventDetails.defaultEventDetails(0.5),
  )
  return treeClaim
}

export function findTreeClaimedAndWeightedValues(
  treeResults: TreeAnalysisResults[][],
) {
  const weightedValue = calculateWeightOfTree(
    treeResults,
    InterestViewOption.noInterest,
  )
  const weightedValueInterest1st = calculateWeightOfTree(
    treeResults,
    InterestViewOption.interest1st,
  )
  const weightedValueInterest2nd = calculateWeightOfTree(
    treeResults,
    InterestViewOption.interest2nd,
  )

  let totalClaimedAmount = 0
  let totalCounterClaimedAmount = 0
  let totalClaimedAmountInterest1st = 0
  let totalCounterClaimedAmountInterest1st = 0
  let totalClaimedAmountInterest2nd = 0
  let totalCounterClaimedAmountInterest2nd = 0

  for (let i = 0; i < treeResults[0].length; i++) {
    if (treeResults[0][i][1] > totalClaimedAmount) {
      totalClaimedAmount = treeResults[0][i][1]
    }
    if (
      treeResults[0][i][1] + treeResults[0][i][2] >
      totalClaimedAmountInterest1st
    ) {
      totalClaimedAmountInterest1st =
        treeResults[0][i][1] + treeResults[0][i][2]
    }
    if (
      treeResults[1] &&
      treeResults[1][i][1] + treeResults[1][i][2] >
        totalClaimedAmountInterest2nd
    ) {
      totalClaimedAmountInterest2nd =
        treeResults[1][i][1] + treeResults[1][i][2]
    }
    if (treeResults[0][i][1] < totalCounterClaimedAmount) {
      totalCounterClaimedAmount = treeResults[0][i][1]
    }
    if (
      treeResults[0][i][1] + treeResults[0][i][2] <
      totalCounterClaimedAmountInterest1st
    ) {
      totalCounterClaimedAmountInterest1st =
        treeResults[0][i][1] + treeResults[0][i][2]
    }
    if (
      treeResults[1] &&
      treeResults[1][i][1] + treeResults[1][i][2] <
        totalCounterClaimedAmountInterest2nd
    ) {
      totalCounterClaimedAmountInterest2nd =
        treeResults[1][i][1] + treeResults[1][i][2]
    }
  }

  let treeClaimedAndWeightedValues = {
    totalClaimedAmount: totalClaimedAmount,
    totalClaimedAmountInterest1st: totalClaimedAmountInterest1st,
    totalClaimedAmountInterest2nd: totalClaimedAmountInterest2nd,
    totalCounterClaimedAmount: totalCounterClaimedAmount,
    totalCounterClaimedAmountInterest1st: totalCounterClaimedAmountInterest1st,
    totalCounterClaimedAmountInterest2nd: totalCounterClaimedAmountInterest2nd,
    weightedValue: weightedValue,
    weightedValueInterest1st: weightedValueInterest1st,
    weightedValueInterest2nd: weightedValueInterest2nd,
  }

  return treeClaimedAndWeightedValues
}

export function treeHasInterestUsingSnapshot(
  snapshot: ScenarioSnapshot,
  treeIndex: number,
) {
  let treeEvents = (snapshot.claims[treeIndex] as TreeClaim).treeDetails.events
  for (let event of Object.keys(treeEvents)) {
    if (
      treeEvents[event as EventId].eventDetails.hasInterest &&
      treeEvents[event as EventId].eventDetails.interestDate !== undefined &&
      treeEvents[event as EventId].eventDetails.interestRate > 0
    ) {
      return true
    }
  }
  return false
}
