import { useState } from 'react'

import dotsMenuImg from '../../../../../resources/images/231-Three_dots_claim.svg'
import duplicateClaimImg from '../../../../../resources/images/227-duplicate.svg'
import deleteClaimImg from '../../../../../resources/images/226-delete_icon.svg'
import copyToClipboardImg from '../../../../../resources/images/225-copy_to_clipboard.svg'
import convertToTreeImg from '../../../../../resources/images/224-convert_to_tree.svg'
import moveTileImg from '../../../../../resources/images/230-move_tile.svg'
import { useEffect } from 'react'
import {
  AvailableLanguages,
  ClaimType,
  InterestViewOption,
  UndoRedoType,
} from '../../../../../models/enums'
import {
  deepCloneObject,
  generateId,
  logActivity,
} from '../../../../../services/commonFunctions'
import { SnapshotSelectorObject } from '../../../../../models/generalTypes'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import {
  convertClaimToTree,
  findDuplicationNameOfClaim,
} from '../../../../../services/claimFunctions'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import { replaceEventAndNodeIds } from '../../../../../services/treeFunctions/treeBasicFunctions'
import { treeTablesState } from '../../../../../states/TreeTablesState'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import { AnimationDots } from '../../../../../Components/Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../../../../services/constants'
import { freemiumState } from '../../../../../states/FreemiumState'

type Props = {
  claimIndex: number
  threeDotsMenuOn: boolean
  claimType: ClaimType
  setThreeDotsMenuOn: (threeDotsMenuOn: boolean) => void
  setRemoveClaimMenuOn: (removeClaimMenuOn: boolean) => void
  setActiveClaim: (claimIndex: number) => void
  onList?: boolean
  unHoverClaim?: () => void
}

export default function ClaimTile3DotsMenu(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const [claimCopied, setClaimCopied] = useState<boolean | 'copying'>(false)
  useEffect(() => {
    window.addEventListener('mousedown', closeThreeDotsMenu)

    return () => {
      window.removeEventListener('mousedown', closeThreeDotsMenu)
    }
    // eslint-disable-next-line
  }, [props.threeDotsMenuOn])

  function closeThreeDotsMenu(e: any) {
    if (!e.target.id.includes(`threeDotsMenu-${props.claimIndex}`)) {
      props.setThreeDotsMenuOn(false)
    }
  }

  function handleDuplicateClaim() {
    props.setThreeDotsMenuOn(false)
    if (props.unHoverClaim) {
      props.unHoverClaim()
    }
    const duplicatedClaim = deepCloneObject(
      scenarioSnapshot.currentSnapshot.claims[props.claimIndex],
    )

    duplicatedClaim.id = generateId(
      duplicatedClaim.type === 'tree' ? 'tree' : 'claim',
    )
    duplicatedClaim.name = findDuplicationNameOfClaim(
      duplicatedClaim.name,
      scenarioSnapshot.currentSnapshot,
    )
    if (duplicatedClaim.type === 'tree') {
      ;(duplicatedClaim as TreeClaim).treeDetails = replaceEventAndNodeIds(
        (duplicatedClaim as TreeClaim).treeDetails,
      )
      let treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.claimIndex].id,
      )
      setTreeTables({
        ...treeTables,
        treeTablesInfo: [
          ...treeTables.treeTablesInfo,
          {
            treeId: duplicatedClaim.id,
            results: treeTables.treeTablesInfo[treeTableInfoIndex].results,
            currentValueOfTree:
              treeTables.treeTablesInfo[treeTableInfoIndex].currentValueOfTree,
            previousValueOfTree:
              treeTables.treeTablesInfo[treeTableInfoIndex].previousValueOfTree,
            showSelectionGraph:
              treeTables.treeTablesInfo[treeTableInfoIndex].showSelectionGraph,
            showTreeTable:
              treeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable,
            treeInterestViewOption:
              treeTables.treeTablesInfo[treeTableInfoIndex]
                .treeInterestViewOption,
          },
        ],
      })
    }

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addClaimContainer',
      undoRedoType: UndoRedoType.button,
      value: [props.claimIndex + 1, duplicatedClaim],
      key: 'claims',
      key2: 'duplicate',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    setTimeout(() => {
      props.setActiveClaim(props.claimIndex + 1)
    }, 30)

    //Mixpanel 160 (All)
    logActivity(freemium.isFreemium, 'Duplicated a claim', {
      typeOfClaim: duplicatedClaim.type,
    })
  }

  function handleConvertClaimToTree() {
    props.setThreeDotsMenuOn(false)
    let claimTobeConverted = deepCloneObject(
      scenarioSnapshot.currentSnapshot.claims[props.claimIndex],
    )

    claimTobeConverted = convertClaimToTree(
      claimTobeConverted as IndependentClaim,
      scenarioSnapshot.currentSnapshot,
      user.settings,
    )

    setTreeTables({
      ...treeTables,
      treeTablesInfo: [
        ...treeTables.treeTablesInfo,
        {
          treeId: claimTobeConverted.id,
          results: undefined,
          currentValueOfTree: undefined,
          previousValueOfTree: undefined,
          showSelectionGraph: false,
          showTreeTable: false,
          treeInterestViewOption: InterestViewOption.interest1st,
        },
      ],
    })

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `singleClaimPreviewContainer-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: [props.claimIndex, claimTobeConverted],
      key: 'claims',
      key2: 'convertToTree',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    setTimeout(() => {
      props.setActiveClaim(props.claimIndex)
    }, 30)

    //Mixpanel 161 (All)
    logActivity(freemium.isFreemium, 'Converted a claim to tree')
  }

  async function handleCopyToClipboard() {
    setClaimCopied('copying')
    let claimJson = JSON.stringify({
      copiedClaim: scenarioSnapshot.currentSnapshot.claims[props.claimIndex],
    })

    await navigator.clipboard.writeText(claimJson)
    setClaimCopied(true)
    setTimeout(() => {
      setClaimCopied(false)
      props.setThreeDotsMenuOn(false)
      //Mixpanel 163 (All)
      logActivity(freemium.isFreemium, 'Copied a claim to clipboard')
    }, 1000)
  }

  return (
    <>
      {props.threeDotsMenuOn ? (
        <div
          className={
            props.onList
              ? 'threeDotsMenuContainerList'
              : 'threeDotsMenuContainer'
          }
          id={`threeDotsMenu-${props.claimIndex}-container`}
          style={
            user.settings.language === AvailableLanguages.swedish
              ? props.claimType === ClaimType.tree
                ? { width: 190, height: 90, zIndex: 1 }
                : { width: 245, zIndex: 1 }
              : user.settings.language === AvailableLanguages.norwegian ||
                user.settings.language === AvailableLanguages.danish
              ? props.claimType === ClaimType.tree
                ? { width: 215, height: 90, zIndex: 1 }
                : { width: 230, zIndex: 1 }
              : props.claimType === ClaimType.tree
              ? { height: 90, zIndex: 1 }
              : undefined
          }
        >
          {claimCopied !== false ? (
            <div
              className={
                props.onList
                  ? 'copyingClaimMessageContainerList'
                  : 'copyingClaimMessageContainer'
              }
            >
              {claimCopied !== true ? (
                <p data-textattribute="message-104">
                  {getText('message-104', user.settings)}
                  <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                </p>
              ) : (
                <p data-textattribute="message-105">
                  {getText('message-105', user.settings)}
                </p>
              )}
            </div>
          ) : (
            <>
              <div
                className={
                  props.onList ? 'threeDotsMenuRowList' : 'threeDotsMenuRow'
                }
                id={`threeDotsMenu-${props.claimIndex}-row0`}
                onClick={() => {
                  props.setRemoveClaimMenuOn(true)
                  props.setThreeDotsMenuOn(false)
                }}
              >
                <img
                  src={deleteClaimImg}
                  alt="deleteClaimImg"
                  className={
                    props.onList
                      ? 'threeDotsMenuRowImgList'
                      : 'threeDotsMenuRowImg'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-img0`}
                />

                <p
                  className={
                    props.onList
                      ? 'threeDotsMenuRowTextList red'
                      : 'threeDotsMenuRowText red'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-text0`}
                  data-textattribute="button-137"
                >
                  {getText('button-137', user.settings)}
                </p>
              </div>
              <div
                className={
                  props.onList ? 'threeDotsMenuRowList' : 'threeDotsMenuRow'
                }
                id={`threeDotsMenu-${props.claimIndex}-row1`}
                onClick={handleDuplicateClaim}
              >
                <img
                  src={duplicateClaimImg}
                  alt="duplicateClaimImg"
                  className={
                    props.onList
                      ? 'threeDotsMenuRowImgList'
                      : 'threeDotsMenuRowImg'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-img1`}
                />

                <p
                  className={
                    props.onList
                      ? 'threeDotsMenuRowTextList'
                      : 'threeDotsMenuRowText'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-text1`}
                  data-textattribute="button-138"
                >
                  {getText('button-138', user.settings)}
                </p>
              </div>
              <div
                className={
                  props.onList ? 'threeDotsMenuRowList' : 'threeDotsMenuRow'
                }
                id={`threeDotsMenu-${props.claimIndex}-row2`}
                onClick={handleCopyToClipboard}
              >
                <img
                  src={copyToClipboardImg}
                  alt="copyToClipboardImg"
                  className={
                    props.onList
                      ? 'threeDotsMenuRowImgList'
                      : 'threeDotsMenuRowImg'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-img2`}
                />

                <p
                  className={
                    props.onList
                      ? 'threeDotsMenuRowTextList'
                      : 'threeDotsMenuRowText'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-text2`}
                  data-textattribute="button-139"
                >
                  {getText('button-139', user.settings)}
                </p>
              </div>
              {props.claimType === ClaimType.claim && (
                <div
                  className={
                    props.onList ? 'threeDotsMenuRowList' : 'threeDotsMenuRow'
                  }
                  id={`threeDotsMenu-${props.claimIndex}-row3`}
                  onClick={handleConvertClaimToTree}
                >
                  <img
                    src={convertToTreeImg}
                    alt="convertToTreeImg"
                    className={
                      props.onList
                        ? 'threeDotsMenuRowImgList'
                        : 'threeDotsMenuRowImg'
                    }
                    id={`threeDotsMenu-${props.claimIndex}-img3`}
                  />

                  <p
                    className={
                      props.onList
                        ? 'threeDotsMenuRowTextList'
                        : 'threeDotsMenuRowText'
                    }
                    id={`threeDotsMenu-${props.claimIndex}-text3`}
                    data-textattribute="button-140"
                  >
                    {getText('button-140', user.settings)}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div
            className={
              props.onList
                ? 'moveTileHandleinvisibleLayerList'
                : 'moveTileHandleinvisibleLayer'
            }
          ></div>
          <div
            className={props.onList ? 'moveTileHandleList' : 'moveTileHandle'}
          >
            <img
              src={moveTileImg}
              alt="moveTileImg"
              className="moveTileImg"
              id={`moveTileImg-${props.claimIndex}`}
            />
          </div>
          <div
            className={
              props.onList
                ? 'moveTileTransparentCoverList'
                : 'moveTileTransparentCover'
            }
          ></div>
          <div
            className={
              props.onList
                ? 'moveTileHandleBackgroundList'
                : 'moveTileHandleBackground'
            }
          ></div>

          <div
            className={
              props.onList
                ? 'threeDotsButtonContainerList'
                : 'threeDotsButtonContainer'
            }
            id={`threeDotsButtonContainer-${props.claimIndex}`}
            onClick={() => props.setThreeDotsMenuOn(true)}
          >
            <img
              src={dotsMenuImg}
              alt="dotsMenuImg"
              className="threeDotsButton"
              id={`removeClaimImgPreview-${props.claimIndex}`}
            />
          </div>
        </>
      )}
    </>
  )
}
