import { useState, useEffect } from 'react'

import SingleRequirement from './SingleRequirement'

import plus from '../../../../../../resources/images/002-plusSignThinBlue.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../../models/independentClaim'
import { SnapshotSelectorObject } from '../../../../../../models/generalTypes'
import { AccessRole, UndoRedoType } from '../../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
  stringAndRoundTo2Decimals,
} from '../../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import InfoClickComponent from '../../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import Button from '../../../../../../Components/Buttons/Button/Button'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { LiabilityRequirement } from '../../../../../../models/liabilityRequirement'
import { freemiumState } from '../../../../../../states/FreemiumState'

type Props = {
  claimIndex: number
  errors: string[]
  ownRole: AccessRole
}

export default function Liability(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )

  const liability = (
    scenarioSnapshot.currentSnapshot.claims[
      props.claimIndex
    ] as IndependentClaim
  ).liability

  const liabilityRequirements = (
    scenarioSnapshot.currentSnapshot.claims[
      props.claimIndex
    ] as IndependentClaim
  ).liabilityRequirements

  const user = useRecoilValue(userState)

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function handleAddRequirement() {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `addLiabilityRequirement-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: 'add',
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: 'liabilityRequirements',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    const nextRequirementIndex =
      (
        tempScenarioSnapshot.currentSnapshot.claims[
          props.claimIndex
        ] as IndependentClaim
      ).liabilityRequirements.length + 1

    ;(
      tempScenarioSnapshot.currentSnapshot.claims[
        props.claimIndex
      ] as IndependentClaim
    ).liabilityRequirements.push(
      LiabilityRequirement.EmptyLiabilityRequirement(
        //id='newRequirementDefaultTitle'
        //data-textattribute='title-120'
        `${getText('title-120', user.settings)} ${nextRequirementIndex}`,
      ),
    )

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    //Mixpanel 39 (All)
    logActivity(freemium.isFreemium, 'Added liability requirement')
  }

  return (
    <>
      <div className="liabilityContainer">
        <div>
          <div className="infoElementContainer">
            <InfoClickComponent
              infoClickText={getText('infoButton-17', user.settings)}
              infoClickTextAttribute="infoButton-17"
              idName="liability"
              hoverElement={hoverElement}
              setHoverElement={setHoverElement}
              whiteCircle
            />
          </div>
          <div className="liabilityTitleContainer">
            <p
              className="libailityTitle"
              id={`liabilityTitle-${props.claimIndex}`}
              data-textattribute="title-68"
            >
              {getText('title-68', user.settings)}
            </p>
            <p
              className="liabilityHelperText"
              id={`liabilityHelperText-${props.claimIndex}`}
              data-textattribute="description-38"
            >
              {getText('description-38', user.settings)}
            </p>
          </div>
          <div className="requirementsContainer">
            {liabilityRequirements.map(
              (requirementObject, requirementIndex) => (
                <SingleRequirement
                  key={`SingleRequirement${props.claimIndex}-${requirementIndex}`}
                  claimIndex={props.claimIndex}
                  requirementIndex={requirementIndex}
                  errors={props.errors}
                  ownRole={props.ownRole}
                />
              ),
            )}
          </div>
          {props.ownRole !== AccessRole.VIEWER && (
            <div className="addLiabilityContainer">
              <Button
                buttonText={getText('button-28', user.settings)}
                buttonTextAttribute="button-28"
                buttonType="outlined"
                type="button"
                id={`addLiabilityRequirement-${props.claimIndex}`}
                icon={plus}
                className="addRequirementButton"
                onClick={handleAddRequirement}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </div>
          )}
          <div
            className="finalLiabilityTextContainer"
            id={`finalLiabilityTextContainer-${props.claimIndex}`}
            data-textattribute="description-39a,description-39b"
          >
            {getText('description-39a', user.settings)}{' '}
            <strong data-openreplay-obscured>
              {liability * 100 < 0.01 && liability !== 0
                ? getText('description-39b', user.settings)
                : stringAndRoundTo2Decimals(liability * 100, user.settings)}
              %
            </strong>
          </div>
        </div>
        <div className="liabilityArrow"></div>
      </div>
    </>
  )
}
