import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import HoverComponent from '../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import { IndependentClaim } from '../../../../../../models/independentClaim'

import * as objects from '../../../../../../Modules/Objects/DropDownObjects'
import {
  roundNumberTo,
  stringAndRoundTo2Decimals,
} from '../../../../../../services/commonFunctions'
import { formattedNumToString } from '../../../../../../services/formatNum'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'

type Props = {
  claimIndex: number
}

export default function BarChart(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const currentClaim = scenarioSnapshot.currentSnapshot.claims[
    props.claimIndex
  ] as IndependentClaim
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const [widths, setWidths] = useState<number[]>([]) // an array that holds the widths of each element of the bar -- array of numbers
  const [colors, setColors] = useState(objects.percentagesColors) // an array that holds the colors of each element of the bar -- array of str
  const [partyName, setPartyName] = useState('') // a state that defines if the party is 'client' or 'opposing party' -- str
  const [liabilityOn, setLiabilityOn] = useState(
    currentClaim.quantumScenarios.filter((item) => item.liabilityOn).length > 0,
  )

  const getQuantumAmountsFromSnapshot = () => {
    return currentClaim.quantumScenarios
      .filter((quantum, index) => index > 0 && !quantum.liabilityOn)
      .map((quantum) => quantum.amount)
  }

  const [outOfCourtAmounts, setOutOfCourtAmounts] = useState<
    (number | undefined)[]
  >(currentClaim.quantumScenarios.map((item) => item.outOfCourtAmount))
  const user = useRecoilValue(userState)
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  useEffect(() => {
    if (currentClaim.type === 'claim') {
      //data-textattribute='hover-62'
      setPartyName(getText('hover-62', user.settings, partiesFormat))
    } else if (currentClaim.type === 'counterclaim') {
      //data-textattribute='hover-63'
      setPartyName(getText('hover-63', user.settings, partiesFormat))
    }
    // eslint-disable-next-line
  }, [currentClaim.type])

  useEffect(() => {
    const tempProbabilities: number[] = []
    const tempColors: string[] = []

    currentClaim.quantumScenarios.forEach((item, index) => {
      tempProbabilities.push(item.probability * 100)
      if (!item.liabilityOn) {
        tempColors.push(objects.percentagesColors[index])
      } else {
        tempColors.push(objects.percentagesColors[5])
      }
    })

    setOutOfCourtAmounts(
      currentClaim.quantumScenarios.map((item) => item.outOfCourtAmount),
    )
    setLiabilityOn(
      currentClaim.quantumScenarios.filter((item) => item.liabilityOn).length >
        0,
    )
    setWidths(tempProbabilities)
    setColors(tempColors)

    //eslint-disable-next-line
  }, [currentClaim.quantumScenarios])

  return (
    <>
      <div
        className="barContainer"
        id={`barContainer-${props.claimIndex}`}
        data-openreplay-obscured
      >
        <div className="tagsection" id={`tagsection-${props.claimIndex}`}>
          {widths &&
            widths.map((item, tagBarIndex) => {
              let tempAmount =
                tagBarIndex === 0
                  ? currentClaim.amount
                  : liabilityOn && tagBarIndex === widths.length - 1
                  ? undefined
                  : tagBarIndex < currentClaim.quantumScenarios.length
                  ? getQuantumAmountsFromSnapshot()[tagBarIndex - 1]
                  : undefined

              return (
                <div
                  key={`${props.claimIndex}-barTag${tagBarIndex}`}
                  className="tag"
                  style={{
                    background: colors[tagBarIndex],
                    width:
                      item === 0 ? 4 + '%' : item <= 7 ? 7 + '%' : item + '%',
                  }}
                  id={`barTag-${props.claimIndex}_${tagBarIndex}`}
                  onMouseEnter={() => {
                    secondaryHover.current = `barChartHover${tagBarIndex}-${props.claimIndex}`
                    setTimeout(() => {
                      if (
                        secondaryHover.current ===
                        `barChartHover${tagBarIndex}-${props.claimIndex}`
                      ) {
                        setHoverElement(
                          `barChartHover${tagBarIndex}-${props.claimIndex}`,
                        )
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  {hoverElement ===
                  `barChartHover${tagBarIndex}-${props.claimIndex}` ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '40px', left: '20px' }}
                      key={`barChartHover${tagBarIndex}-${props.claimIndex}`}
                      id={`barChartHoverComponentContainer-${props.claimIndex}_${tagBarIndex}`}
                    >
                      <HoverComponent
                        hoverText={
                          liabilityOn && tagBarIndex === widths.length - 1
                            ? // Liability <1
                              //the last bar which is the liability
                              `${stringAndRoundTo2Decimals(
                                item,
                                user.settings,
                              )}${getText('hover-9b', user.settings)}`
                            : tempAmount !== undefined
                            ? //the rest of the bars if the have amount

                              `${stringAndRoundTo2Decimals(
                                item,
                                user.settings,
                              )}${getText(
                                'hover-10b',
                                user.settings,
                              )} ${partyName} ${getText(
                                'hover-10c',
                                user.settings,
                              )} ${stringAndRoundTo2Decimals(
                                Math.abs(tempAmount),
                                user.settings,
                              )} ${scenarioSnapshot.currentSnapshot.currency}.
                                  ${
                                    outOfCourtAmounts[tagBarIndex]
                                      ? //the rest of the bars if they have outOfCourtAmount
                                        ` ${getText(
                                          'hover-11a',
                                          user.settings,
                                        )} ${stringAndRoundTo2Decimals(
                                          outOfCourtAmounts[tagBarIndex],
                                          user.settings,
                                        )} ${
                                          scenarioSnapshot.currentSnapshot
                                            .currency
                                        } ${getText(
                                          'hover-11b',
                                          user.settings,
                                          partiesFormat,
                                        )}`
                                      : ''
                                  }`
                            : //the rest of the bars if the don't have amount
                              getText('hover-12', user.settings)
                        }
                        textAttribute={
                          tagBarIndex === widths.length - 1
                            ? 'hover-9b'
                            : tempAmount !== undefined
                            ? `hover-10b, hover-10c${
                                outOfCourtAmounts[tagBarIndex] !== undefined
                                  ? ', hover-11a, hover-11b'
                                  : null
                              }`
                            : 'hover-12'
                        }
                        id={`barChartHoverComponentContainer-${props.claimIndex}_${tagBarIndex}`}
                        style={{
                          width: '230px',
                          padding: '4px 20px',
                        }}
                      />
                    </div>
                  ) : null}
                  <p
                    className="tagText"
                    id={`tagText-${props.claimIndex}_${tagBarIndex}`}
                    style={
                      item === 0
                        ? { color: 'rgb(128, 128, 128)', fontWeight: 400 }
                        : undefined
                    }
                  >
                    {formattedNumToString(
                      roundNumberTo(item, 2),
                      user.settings,
                    ) + '%'}
                  </p>
                </div>
              )
            })}
        </div>
      </div>
      <div className="barChartHelperTextContainer">
        <p
          className="barChartHelperText"
          id={`barChartHelperText-${props.claimIndex}`}
          data-textattribute="description-37"
        >
          {getText('description-37', user.settings)}
        </p>
      </div>
    </>
  )
}
