import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { onlineState } from '../../../states/OnlineState'
import { convertDateTimeToLocal } from '../../../services/dateFunctions'
import { Link } from 'react-router-dom'
import { FreemiumListItem } from '../../../models/freemiumGeneralTypes'
import Button from '../../Buttons/Button/Button'
import renameImg from '../../../resources/images/027-renameSign.svg'
import deleteImg from '../../../resources/images/029-deleteBucketSign.svg'
import addUserButtonImg from '../../../resources/images/166-addUserButtonImg.svg'
import tutorialImg from '../../../resources/images/221-tutorialImg.svg'
import { getText } from '../../../services/textFunctions'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import DeleteWindow from '../../DeleteWindow/DeleteWindow'
import PremiumFeatureIcon from '../../PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  index: number
  item: FreemiumListItem
  getNavigateLink: (uuid: string) => string
  isMyScenariosPage?: boolean
  statusDiv?: (uuid: string) => React.ReactElement
  renamingIndex: number | undefined
  nameInput: string
  setNameInput: (value: string) => void
  handleRenameItem: (
    item: FreemiumListItem,
    index: number,
    rename: boolean,
  ) => void
  handleDeleteItem: () => void
  openFreemiumMessage: (messageType: FreemiumMessageType) => void
  isDeleting: boolean
  setDeletingIndex: (value: number | undefined) => void
  deleteWindowCoordinates: number[] | undefined
  showPremiumIcon: boolean
}

const ItemPart = (props: Props) => {
  const user = useRecoilValue(userState)
  const [online, setOnline] = useRecoilState(onlineState)

  function showNotOnline() {
    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
    }
  }

  return (
    <div
      key={`caseContainer${props.index}`}
      className="caseContainer"
      id={`caseContainer${props.index}`}
      data-openreplay-obscured
    >
      {props.renamingIndex === props.index ? (
        <div className="caseTitleContainer-wrap">
          <div className="caseTitleContainer" style={{ width: '550px' }}>
            <input
              className="renameCaseInput"
              value={props.nameInput}
              id={`renameInput${props.index}`}
              maxLength={140}
              onChange={(e) => props.setNameInput(e.target?.value)}
            />
            <Button
              id={`renameItemSaveButton${props.index}`}
              buttonText={getText('button-7', user.settings)}
              buttonTextAttribute="button-7"
              buttonType="contained"
              type="button"
              onClick={() =>
                props.handleRenameItem(props.item, props.index, true)
              }
              className="saveCaseButton"
              small
              side="left"
              NoUpperCase={true}
            />
          </div>
        </div>
      ) : (
        <div>
          <Link
            to={!online.networkOn ? '' : props.getNavigateLink(props.item.uuid)}
            className={`caseTitle`}
            onClick={() => {
              showNotOnline()
            }}
          >
            {!props.isMyScenariosPage && props.item.tutorialId && (
              <img
                src={tutorialImg}
                alt="tutorialImg"
                style={{ marginRight: '10px' }}
              />
            )}
            <WhiteTooltip
              textAttribute={'tooltip-00'}
              id={`caseTitle${props.index}-tooltip`}
              title={props.item.title}
            >
              <p className={`caseTitleText`} id={`caseTitle${props.index}`}>
                {props.item.title}
              </p>
            </WhiteTooltip>
          </Link>
        </div>
      )}
      <div className="caseLeftPart">
        {!props.isMyScenariosPage && (
          <div
            className={
              props.renamingIndex === props.index
                ? 'caseDetailsContainer hide caseDetailsContainer-small caseDetailsContainer-shareCase'
                : 'caseDetailsContainer caseDetailsContainer-small caseDetailsContainer-shareCase'
            }
          >
            {!props.item.tutorialId && (
              <>
                <div className="caseDetail">
                  <div
                    id={`addUserButton${props.index}`}
                    className="addUserButton"
                    onClick={() =>
                      props.openFreemiumMessage(FreemiumMessageType.General)
                    }
                  >
                    <img
                      src={addUserButtonImg}
                      alt="addUserButtonImg"
                      className="addUserButtonImg"
                    />
                  </div>
                </div>
                <PremiumFeatureIcon targetId={`addUserButton${props.index}`} />
              </>
            )}
          </div>
        )}
        <div className="caseDetailsContainer caseDetailsContainer-LastEdited-Date caseDetailsContainer-small ">
          <p className="caseDetail">
            {props.item.tutorialId ? 'Eperoto Support' : 'You'}
          </p>
          <p className="caseDetail caseDetailTime">
            {convertDateTimeToLocal(
              props.item.dateCreated,
              user.settings,
            ).substring(0, 16)}
          </p>
        </div>
        {props.isMyScenariosPage && props.statusDiv && (
          <div className="caseDetailsContainer caseDetailsContainer-status caseDetailsContainer-small">
            {props.statusDiv(props.item.uuid)}
          </div>
        )}
        <div className="caseMenuContainer freemium caseDetailsContainer-actions caseDetailsContainer-small">
          {!props.item.tutorialId && (
            <div className="caseMenuSubContainer only-one">
              <WhiteTooltip
                title={
                  props.isMyScenariosPage
                    ? getText('tooltip-4', user.settings)
                    : getText('tooltip-5', user.settings)
                }
                textAttribute={
                  props.isMyScenariosPage ? `tooltip-4` : 'tooltip-5'
                }
                id="renameCaseScenarioTooltip"
                style={{ marginRight: '20px' }}
              >
                <img
                  src={renameImg}
                  alt="renameImg"
                  id={`renameCase${props.index}`}
                  onClick={() =>
                    props.handleRenameItem(props.item, props.index, false)
                  }
                />
              </WhiteTooltip>
              {props.showPremiumIcon && (
                <PremiumFeatureIcon targetId={`renameCase${props.index}`} />
              )}
              <WhiteTooltip
                title={
                  props.isMyScenariosPage
                    ? getText('tooltip-8', user.settings)
                    : getText('tooltip-9', user.settings)
                }
                textAttribute={
                  props.isMyScenariosPage ? `tooltip-8` : 'tooltip-9'
                }
                id="deleteCaseScenarioTooltip"
              >
                <img
                  src={deleteImg}
                  alt="deleteImg"
                  id={`deleteCase${props.index}`}
                  onClick={() => {
                    if (props.item.tutorialId) {
                      props.openFreemiumMessage(FreemiumMessageType.General)
                    } else {
                      props.setDeletingIndex(props.index)
                    }
                  }}
                />
              </WhiteTooltip>
              {props.showPremiumIcon && (
                <PremiumFeatureIcon targetId={`deleteCase${props.index}`} />
              )}
              {props.isDeleting && (
                <DeleteWindow
                  id={props.isMyScenariosPage ? 'scenario' : 'case'}
                  claimType={
                    props.isMyScenariosPage
                      ? 'scenario_permanent'
                      : 'case_permanent'
                  }
                  claimName={props.item.title}
                  deleteFunction={() => {
                    props.handleDeleteItem()
                  }}
                  cancelFunction={() => {
                    props.setDeletingIndex(undefined)
                  }}
                  position={props.deleteWindowCoordinates ?? [0, 0]}
                  fixed
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ItemPart
