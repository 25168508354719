import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import LCDChip from './LCDChip'
import {
  AccessRole,
  AvailableLanguages,
  LCDMenuChoice,
  UndoRedoType,
} from '../../../../../models/enums'
import { SnapshotSelectorObject } from '../../../../../models/generalTypes'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import { freemiumState } from '../../../../../states/FreemiumState'
import { recoveryModeState } from '../../../../../states/RecoveryModeState'
import { viewerState } from '../../../../../states/ViewerState'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'

type Props = {
  ownRole: AccessRole
}

export default function LegalCostsDistributionChips(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const legalCostsDistributionSettings =
    scenarioSnapshot.currentSnapshot.legalCostsDistributionSettings
  const freemium = useRecoilValue(freemiumState)
  const user = useRecoilValue(userState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)

  function handleDistributionMenu(id: LCDMenuChoice) {
    if (props.ownRole === AccessRole.VIEWER) {
      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
      return
    }
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `lcdChipText-${scenarioSnapshot.currentSnapshot.legalCostsDistributionSettings.menuChoice}`,
      undoRedoType: UndoRedoType.button,
      value: id,
      key: 'legalCostsDistributionSettings',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)

    logActivity(
      freemium.isFreemium,
      'Changed legal costs distribution menu choice',
      { lcdMenuChoice: id },
    )
  }

  return (
    <div
      className="lcdChipsContainer"
      id="lcdChipsContainer"
      style={
        user.settings.language === AvailableLanguages.english
          ? { maxWidth: '510px' }
          : user.settings.language === AvailableLanguages.norwegian
          ? { maxWidth: '664px' }
          : user.settings.language === AvailableLanguages.danish
          ? { maxWidth: '695px' }
          : { maxWidth: '710px' }
      }
    >
      <LCDChip
        selected={
          legalCostsDistributionSettings.menuChoice === 'standardDistribution'
        }
        id={LCDMenuChoice.standardDistribution}
        text={getText('button-168', user.settings)}
        textAttribute={'button-168'}
        onChange={handleDistributionMenu}
        ownRole={props.ownRole}
      />
      <LCDChip
        selected={
          legalCostsDistributionSettings.menuChoice === 'eachPartyPaysOwn'
        }
        id={LCDMenuChoice.eachPartyPaysOwn}
        text={getText('button-169', user.settings)}
        textAttribute={'button-169'}
        onChange={handleDistributionMenu}
        ownRole={props.ownRole}
      />
      <LCDChip
        selected={
          legalCostsDistributionSettings.menuChoice === 'advancedDistribution'
        }
        id={LCDMenuChoice.advancedDistribution}
        text={getText('button-170', user.settings)}
        textAttribute={'button-170'}
        onChange={handleDistributionMenu}
        ownRole={props.ownRole}
      />
    </div>
  )
}
