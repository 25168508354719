// import { logActivity } from './commonFunctions'
import { microsoftSsoCheckUser } from './requests'

export async function checkInWithMicrosoft(
  email: string,
  attempt: number = 0,
): Promise<boolean> {
  let res = await microsoftSsoCheckUser(email)

  if (!('errorCode' in res)) {
    console.log(
      'Checked in with Microsoft SSO, user exists',
      new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date()),
    )
    return true
  } else {
    console.log(res)
    if (
      res.errorCode === 401 &&
      res.message &&
      (res.message === 'user no longer in Microsoft' ||
        res.message === 'SSO secret not valid')
    ) {
      attempt += 1

      console.log('attempt')
      console.log(attempt)

      if (attempt <= 2) {
        await new Promise((resolve) => setTimeout(resolve, 30000)) // 30 seconds
        return checkInWithMicrosoft(email, attempt) // Recursive retry
      } else {
        if (res.message === 'SSO secret not valid') {
          console.log(
            'Checked in with Microsoft SSO, SSO secret not valid',
            new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }).format(new Date()),
          )
        } else if (res.message === 'user no longer in Microsoft') {
          console.log(
            'Checked in with Microsoft SSO, user no longer in Microsoft',
            new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }).format(new Date()),
          )
        }

        return false
      }
    }
  }
  return true
}

export const validateMicrosoftCode = (code: string | null) => {
  // Example regex pattern: alphanumeric characters, dashes, underscores, and dots
  const regex = /^[A-Za-z0-9_\-.]+$/

  // Check if code matches the pattern and is not empty
  return typeof code === 'string' && code.trim() !== '' && regex.test(code)
}
