import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { userState } from '../../../../../../../../states/UserState'
import { getText } from '../../../../../../../../services/textFunctions'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { stringAndRoundTo2Decimals } from '../../../../../../../../services/commonFunctions'

type Props = {
  id: number
  sortedDataForTable: any
  currentSnapshot: ScenarioSnapshot
}

export default function LegalCostsBy(props: Props) {
  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
   { amount_received , amount_received_out_of_court , claim_name, claim_type, claim_type_id, interest_date, interest_rate, interest_received, outcome_probability, scenario_name, tree_name}
    17 - name
    
    */

  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const disputedAmount = findDisputedAmount(scenarioSnapshot.currentSnapshot)

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  return (
    <>
      <div className="hoverBackground" data-openreplay-obscured>
        <div className="hoverTextContainer">
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsBy1"
            data-textattribute="description-62"
            data-openreplay-obscured
          >
            {getText('description-62', user.settings, partiesFormat)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][3],
                user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsBy2"
            data-textattribute="description-63"
          >
            {getText('description-63', user.settings)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(disputedAmount, user.settings)}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsBy3"
            data-textattribute="description-64a, description-64b, description-64c"
            data-openreplay-obscured
          >
            {getText('description-64a', user.settings)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][15] * 100,
                user.settings,
              )}{' '}
              %
            </span>{' '}
            {getText('description-64b', user.settings, partiesFormat)}{' '}
            <span>
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][13] * 100,
                user.settings,
              )}{' '}
              %
            </span>{' '}
            {getText('description-64c', user.settings, partiesFormat)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][6],
                user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
          {props.sortedDataForTable[props.id][1] === 2 ||
          props.sortedDataForTable[props.id][1] === 3 ? (
            <p
              className="hoverText"
              style={{ fontWeight: 'bold', color: 'red' }}
              id="detailedResultsHoverComponentDescription-legalCostsBy4"
              data-textattribute="description-65"
              data-openreplay-obscured
            >
              {getText('description-65', user.settings, partiesFormat)}
            </p>
          ) : null}
        </div>
      </div>
    </>
  )
}
