import React, { RefObject, useState } from 'react'
import { ListItem, UserForAccess } from '../../../models/generalTypes'
import renameImg from '../../../resources/images/027-renameSign.svg'
import renameImgDisabled from '../../../resources/images/192-renameSignDisabled.svg'
import deleteImg from '../../../resources/images/029-deleteBucketSign.svg'
import deleteImgDisabled from '../../../resources/images/194-deleteBucketSignDisabled.svg'
import duplicateImg from '../../../resources/images/028-duplicateSign.svg'
import duplicateImgDisabled from '../../../resources/images/193-duplicateSignDisabled.svg'
import addUserButtonImg from '../../../resources/images/166-addUserButtonImg.svg'
import addUserImg from '../../../resources/images/136-addUser.svg'
import tutorialImg from '../../../resources/images/221-tutorialImg.svg'
import copyPasteClipboardImg from '../../../resources/images/225-copy_to_clipboard.svg'
import copyPasteClipboardImgDisabled from '../../../resources/images/262-copy_to_clipboardDisabled.svg'
import Button from '../../Buttons/Button/Button'
import { CircularProgress } from '@mui/material'
import { getText } from '../../../services/textFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { onlineState } from '../../../states/OnlineState'
import UserThumbnail from '../../userThumbnail/UserThumbnail'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import { convertDateTimeToLocal } from '../../../services/dateFunctions'
import DeleteWindow from '../../DeleteWindow/DeleteWindow'
import FullScreenPopMessage from '../../popUps/FullScreenPopMessage'
import { Link } from 'react-router-dom'
import useWindowSize from '../../../customHooks/useWindowSize'
import { AvailableLanguages } from '../../../models/enums'

type Props = {
  index: number
  item: ListItem
  isRenaming: boolean
  isDeleting: boolean
  addNewCaseContainerRef: RefObject<HTMLDivElement>
  caseTitleContainerRef: RefObject<HTMLDivElement>
  nameInput: string
  setNameInput: (value: string) => void
  requestLoading: string | undefined
  setRequestLoading: (value: string | undefined) => void
  handleRenameItem: (
    fromRenameButton: boolean,
    index?: number,
    value?: string,
  ) => void
  handleDuplicate: (index: number) => void
  handleDeleteItem: () => void
  handleRestoreItem: (index: number) => void
  handleCopyItem: (index: number) => void
  getNavigateLink: (uuid: string) => string
  onItemClick: (item: ListItem) => void
  onDeleteItemClick: (item: ListItem, index: number) => void
  isActionDisabled: (
    item: ListItem | undefined,
    action: 'add' | 'rename' | 'copy' | 'duplicate' | 'delete',
  ) => boolean
  showUsersShare: boolean
  isMyScenariosPage?: boolean
  statusDiv?: (uuid: string) => React.ReactElement
  deleteWindowCoordinates: number[] | undefined
  setDeleteItemIndex: (value: number) => void
  showDeleted?: boolean
  disabledActionItemIndex: number
  setDisabledActionItemIndex: (value: number) => void
  handleDeleteWindowPosition: (index?: number, disabledAction?: boolean) => void
  copyItemMessage?: { index: number; message: string }
}

const ItemPart = (props: Props) => {
  const user = useRecoilValue(userState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [anotherUserEditing, setAnotherUserEditing] = useState('')
  const { width } = useWindowSize()

  function showNotOnline() {
    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
    }
  }

  const getActiveUsers = () => {
    const res: UserForAccess[] = []

    props.item.accessedByUsers.forEach((userObj) => {
      if (
        res.findIndex((existing) => existing.username === userObj.username) ===
        -1
      ) {
        res.push(userObj)
      }
    })

    return res
  }

  const isAnotherUserActive = () => {
    return getActiveUsers().filter((u) => u.username !== user.email).length > 0
  }

  return (
    <div
      key={`caseContainer${props.index}`}
      className="caseContainer"
      id={`caseContainer${props.index}`}
      data-openreplay-obscured
    >
      {props.isRenaming ? (
        <div className="caseTitleContainer-wrap">
          <div
            className="caseTitleContainer"
            ref={props.caseTitleContainerRef}
            style={{ width: '550px' }}
          >
            <input
              className="renameCaseInput"
              value={props.nameInput}
              id={`renameInput${props.index}`}
              maxLength={140}
              onChange={(e) => {
                props.setNameInput(e.target?.value)
              }}
            />
            <Button
              id={`renameItemSaveButton${props.index}`}
              buttonText={
                props.requestLoading === 'renameItemSaveButton' ? (
                  <div className="circularProgressDiv" id={'popUp7'}>
                    <div id={'popUp8'}>
                      <CircularProgress size={14} />
                      <div
                        className="hiddenTextForSize"
                        style={{
                          height: 0,
                          visibility: 'hidden',
                        }}
                        id={`hiddenRenameItemSaveButton${props.index}`}
                        data-textattribute="button-7"
                      >
                        {getText('button-7', user.settings)}
                      </div>
                    </div>
                  </div>
                ) : (
                  getText('button-7', user.settings)
                )
              }
              buttonTextAttribute="button-7"
              buttonType="contained"
              type="button"
              onClick={() => props.handleRenameItem(true)}
              className="saveCaseButton"
              small
              side="left"
              NoUpperCase={true}
            />
          </div>
        </div>
      ) : (
        <div>
          <Link
            to={
              props.showDeleted || !online.networkOn
                ? ''
                : props.getNavigateLink(props.item.uuid)
            }
            className={`caseTitle ${props.showDeleted ? 'nonActive' : ''}`}
            onClick={() => {
              if (!props.showDeleted) {
                if (props.isMyScenariosPage && isAnotherUserActive()) {
                  setAnotherUserEditing(
                    getActiveUsers()[0].first_name +
                      ' ' +
                      getActiveUsers()[0].last_name,
                  )
                }

                showNotOnline()
              }
            }}
          >
            {!props.isMyScenariosPage && props.item.tutorialId && (
              <img
                src={tutorialImg}
                alt="tutorialImg"
                style={{ marginRight: '10px' }}
              />
            )}
            <WhiteTooltip
              textAttribute={'tooltip-00'}
              id={`caseTitle${props.index}-tooltip`}
              title={props.item.title}
            >
              <p
                className={`caseTitleText ${
                  props.showDeleted ? 'nonActive' : ''
                }`}
                id={`caseTitle${props.index}`}
              >
                {props.item.title}
              </p>
            </WhiteTooltip>
            {props.item.hasRecoverySnapshot && <p>*</p>}
            <div
              style={
                width <= 950
                  ? {
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }
                  : { display: 'flex', flexDirection: 'row' }
              }
            >
              {props.item.accessedByUsers.length > 0 &&
                getActiveUsers().map((userObj, index) => (
                  <div
                    key={userObj.username}
                    className={`userThumbnailObjectContainer active ${
                      width <= 950 ? 'wrapped' : ''
                    }`}
                    style={{
                      marginLeft: '10px',
                    }}
                  >
                    {getActiveUsers().length < 3 || index === 0 ? (
                      <UserThumbnail
                        firstName={userObj.first_name}
                        lastName={userObj.last_name}
                        data-textattribute={`tooltip-44a, ${
                          props.isMyScenariosPage
                            ? 'tooltip-44b'
                            : 'tooltip-44c'
                        }`}
                        fullText={`${userObj.first_name} ${
                          userObj.last_name
                        } ${getText('tooltip-44a', user.settings)} ${
                          props.isMyScenariosPage
                            ? getText('tooltip-44b', user.settings)
                            : getText('tooltip-44c', user.settings)
                        }.`}
                        users={[]}
                        activeUser
                      />
                    ) : (
                      index === 1 && (
                        <UserThumbnail
                          firstName={''}
                          lastName={''}
                          data-textattribute={`tooltip-44a, ${
                            props.isMyScenariosPage
                              ? 'tooltip-44b'
                              : 'tooltip-44c'
                          }`}
                          fullText={`${getActiveUsers()
                            .slice(1)
                            .map(
                              (userObj2) =>
                                userObj2.first_name + ' ' + userObj2.last_name,
                            )
                            .join(', ')} ${getText(
                            'tooltip-44d',
                            user.settings,
                          )} ${
                            props.isMyScenariosPage
                              ? getText('tooltip-44b', user.settings)
                              : getText('tooltip-44c', user.settings)
                          }.`}
                          users={getActiveUsers().slice(1)}
                          activeUser
                          empty
                        />
                      )
                    )}
                  </div>
                ))}
            </div>
          </Link>
        </div>
      )}
      <div className="caseLeftPart">
        {!props.isMyScenariosPage &&
        props.showUsersShare &&
        !props.showDeleted ? (
          <div
            className={
              props.isRenaming
                ? 'caseDetailsContainer hide caseDetailsContainer-small caseDetailsContainer-shareCase'
                : 'caseDetailsContainer caseDetailsContainer-small caseDetailsContainer-shareCase'
            }
          >
            {/* Change width to the same width as the "owner-width" */}
            {!props.item.tutorialId && (
              <div
                className="caseDetail"
                style={
                  props.item.users.length > 2 ? { flexWrap: 'wrap' } : undefined
                }
              >
                {props.item.users
                  .filter((userObject) => userObject.username !== user.email)
                  .map((userObject: UserForAccess, index: number) => {
                    return index < 3 ? (
                      <div
                        className="userThumbnailObjectContainer"
                        key={`userThumbnailObjectContainer${index}`}
                        onClick={() => props.onItemClick(props.item)}
                        style={
                          props.item.users.length > 2
                            ? {
                                marginLeft: '3px',
                                marginBottom: '3px',
                              }
                            : undefined
                        }
                      >
                        <UserThumbnail
                          firstName={userObject.first_name}
                          lastName={userObject.last_name}
                          users={props.item.users.filter(
                            (userObject) => userObject.username !== user.email,
                          )}
                        />
                      </div>
                    ) : index === 3 &&
                      props.item.users.filter(
                        (userObject) => userObject.username !== user.email,
                      ).length > 4 ? (
                      <div
                        className="userThumbnailObjectContainer"
                        key={`userThumbnailObjectContainer${index}`}
                      >
                        <UserThumbnail
                          firstName=""
                          lastName=""
                          users={props.item.users.filter(
                            (userObject) => userObject.username !== user.email,
                          )}
                          empty
                        />
                      </div>
                    ) : index === 3 ? (
                      <div
                        className="userThumbnailObjectContainer"
                        key={`userThumbnailObjectContainer${index}`}
                      >
                        <UserThumbnail
                          firstName={userObject.first_name}
                          lastName={userObject.last_name}
                          users={props.item.users.filter(
                            (userObject) => userObject.username !== user.email,
                          )}
                        />
                      </div>
                    ) : null
                  })}

                {props.item.users.length === 1 &&
                props.item.users[0].username === user.email ? (
                  <>
                    <WhiteTooltip
                      title={getText('tooltip-3', user.settings)}
                      textAttribute="tooltip-3"
                      id="shareWithTooltip"
                    >
                      <img
                        src={addUserImg}
                        alt="addUserImg"
                        className="addUserImg"
                        onClick={() => props.onItemClick(props.item)}
                      />
                    </WhiteTooltip>
                  </>
                ) : (
                  <div
                    className="addUserButton"
                    onClick={() => props.onItemClick(props.item)}
                    style={
                      props.item.users.length > 3
                        ? {
                            marginBottom: '3px',
                          }
                        : undefined
                    }
                  >
                    <img
                      src={addUserButtonImg}
                      alt="addUserButtonImg"
                      className="addUserButtonImg"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
        <div className="caseDetailsContainer caseDetailsContainer-LastEdited-Date caseDetailsContainer-small ">
          <p className="caseDetail">{props.item.createdBy}</p>
          <p className="caseDetail caseDetailTime">
            {convertDateTimeToLocal(
              props.item.dateCreated,
              user.settings,
            ).substring(0, 16)}
          </p>
        </div>
        <div className="caseDetailsContainer caseDetailsContainer-Creation-Date caseDetailsContainer-small">
          <p className="caseDetail" data-textattribute="description-145">
            {props.showDeleted
              ? props.item.deletedBy ??
                getText('description-145', user.settings)
              : props.item.lastEditedBy}
          </p>
          <p
            className="caseDetail caseDetailTime"
            data-textattribute="description-146"
          >
            {props.showDeleted
              ? props.item.dateDeleted
                ? convertDateTimeToLocal(
                    props.item.dateDeleted,
                    user.settings,
                  ).substring(0, 16)
                : getText('description-146', user.settings)
              : convertDateTimeToLocal(
                  props.item.lastEdited,
                  user.settings,
                ).substring(0, 16)}
          </p>
        </div>
        {props.isMyScenariosPage && props.statusDiv && !props.showDeleted ? (
          <div className="caseDetailsContainer caseDetailsContainer-status caseDetailsContainer-small">
            {props.statusDiv(props.item.uuid)}
          </div>
        ) : null}
        <div className="caseMenuContainer caseDetailsContainer-actions caseDetailsContainer-small">
          <div className="caseMenuSubContainer">
            {!props.showDeleted && (
              <>
                <WhiteTooltip
                  title={
                    props.isMyScenariosPage
                      ? getText('tooltip-4', user.settings)
                      : getText('tooltip-5', user.settings)
                  }
                  textAttribute={
                    props.isMyScenariosPage ? `tooltip-4` : 'tooltip-5'
                  }
                  id="renameCaseScenarioTooltip"
                >
                  <img
                    src={
                      props.isActionDisabled(props.item, 'rename')
                        ? renameImgDisabled
                        : renameImg
                    }
                    alt="renameImg"
                    id={`renameCase${props.index}`}
                    onClick={() => {
                      if (!props.isActionDisabled(props.item, 'rename')) {
                        props.handleRenameItem(false, props.index)
                      } else {
                        props.handleDeleteWindowPosition(props.index, true)
                      }
                    }}
                  />
                </WhiteTooltip>
                {props.isMyScenariosPage && (
                  <WhiteTooltip
                    title={getText('tooltip-51', user.settings)}
                    textAttribute={`tooltip-51`}
                    id="renameCaseScenarioTooltip"
                  >
                    <img
                      src={
                        props.isActionDisabled(props.item, 'copy')
                          ? copyPasteClipboardImgDisabled
                          : copyPasteClipboardImg
                      }
                      alt="copyPasteClipboardImg"
                      id={`copyCase${props.index}`}
                      onClick={() => {
                        if (!props.isActionDisabled(props.item, 'copy')) {
                          props.handleCopyItem(props.index)
                        } else {
                          props.handleDeleteWindowPosition(props.index, true)
                        }
                      }}
                    />
                  </WhiteTooltip>
                )}
                <WhiteTooltip
                  title={
                    props.isMyScenariosPage
                      ? getText('tooltip-6', user.settings)
                      : getText('tooltip-7', user.settings)
                  }
                  textAttribute={
                    props.isMyScenariosPage ? `tooltip-6` : 'tooltip-7'
                  }
                  id="duplicateCaseScenarioTooltip"
                >
                  <img
                    src={
                      props.isActionDisabled(props.item, 'duplicate')
                        ? duplicateImgDisabled
                        : duplicateImg
                    }
                    alt="duplicateImg"
                    id={`duplicateCase${props.index}`}
                    onClick={() => {
                      if (!props.isActionDisabled(props.item, 'duplicate')) {
                        props.handleDuplicate(props.index)
                      } else {
                        props.handleDeleteWindowPosition(props.index, true)
                      }
                    }}
                  />
                </WhiteTooltip>
              </>
            )}
            <WhiteTooltip
              title={
                props.isMyScenariosPage
                  ? getText('tooltip-8', user.settings)
                  : getText('tooltip-9', user.settings)
              }
              textAttribute={
                props.isMyScenariosPage ? `tooltip-8` : 'tooltip-9'
              }
              id="deleteCaseScenarioTooltip"
            >
              <img
                src={
                  props.isActionDisabled(props.item, 'delete')
                    ? deleteImgDisabled
                    : deleteImg
                }
                alt="deleteImg"
                id={`deleteCase${props.index}`}
                onClick={() => props.onDeleteItemClick(props.item, props.index)}
              />
            </WhiteTooltip>
            {props.showDeleted && (
              <Button
                buttonType="contained"
                id={`putBackButton-${props.index}`}
                buttonText={getText('button-132', user.settings)}
                buttonTextAttribute={'button-132'}
                type={'button'}
                style={
                  user.settings.language === AvailableLanguages.norwegian
                    ? { fontSize: '11px' }
                    : user.settings.language === AvailableLanguages.swedish
                    ? { fontSize: '10px' }
                    : undefined
                }
                verySmall
                onClick={() => props.handleRestoreItem(props.index)}
                loading={props.requestLoading === `restore-${props.index}`}
                loadingWidth="75px"
                loadingHeight="29px"
                disabled={props.isActionDisabled(props.item, 'delete')}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            )}

            {props.isDeleting ||
            props.disabledActionItemIndex === props.index ? (
              <DeleteWindow
                id={props.isMyScenariosPage ? 'scenario' : 'case'}
                claimType={
                  props.disabledActionItemIndex > -1
                    ? isAnotherUserActive()
                      ? props.isMyScenariosPage
                        ? 'scenario_anotherUserActive'
                        : 'case_anotherUserActive'
                      : 'disabledAction'
                    : props.showDeleted
                    ? props.isMyScenariosPage
                      ? 'scenario_permanent'
                      : 'case_permanent'
                    : props.isMyScenariosPage
                    ? 'scenario'
                    : 'case'
                }
                claimName={props.item.title}
                deleteFunction={() => {
                  props.handleDeleteItem()
                }}
                cancelFunction={() => {
                  props.setDeleteItemIndex(-1)
                  props.setDisabledActionItemIndex(-1)
                }}
                position={props.deleteWindowCoordinates ?? [0, 0]}
                fixed
                requestLoading={props.requestLoading}
              />
            ) : null}
            {props.copyItemMessage &&
              props.copyItemMessage.index === props.index && (
                <div
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '50px',
                    backgroundColor: 'white',
                    padding: '5px',
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  // className="copyMessage"
                >
                  {props.copyItemMessage.message}
                </div>
              )}
          </div>
        </div>
      </div>
      {anotherUserEditing && (
        <FullScreenPopMessage
          id="anotherEditingScenarioPopUp"
          zIndex={2000}
          backGroundColor="#6f6f6f" //tuesdayGray
          blur
          titleTextAttribute="title-217"
          warningTitle={`${anotherUserEditing} ${getText(
            'title-217',
            user.settings,
          )}`}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-noInternet"
                data-textattribute="description-157"
              >
                {getText('description-157', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <p
              className="warningSubText"
              id="popUpWarningSubText-noInternet"
              data-textattribute="description-128a, description-128b"
            >
              {getText('description-128a', user.settings)}{' '}
              <a
                href="mailto:support@eperoto.com"
                className="link"
                id={'popUp11'}
                data-textattribute="description-12b"
              >
                {getText('description-12b', user.settings)}
              </a>{' '}
              {getText('description-128b', user.settings)}
            </p>
          }
          cancelFunction={() => setAnotherUserEditing('')}
        />
      )}
    </div>
  )
}

export default ItemPart
