import React, { useEffect, useState } from 'react'
import {
  ResultsObject,
  ResultsSettings,
  ValuesBreakdown,
} from '../../../../../../models/generalTypes'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import {
  findHighestValueOfValuesBreakdown,
  findValueBreakdown,
  shouldShowValueBreakdown,
} from '../../../../../../services/resultsFunctions'
import { keysIn } from 'lodash'
import {
  getText,
  getTextFromValueBreakdown,
} from '../../../../../../services/textFunctions'
import ValueBreakdownGraph from './ValueBreakdownGraph'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import {
  getStyleFromWhitelabel,
  snapshotHasIncurredCosts,
} from '../../../../../../services/reportFunctions'
import { reverseCase } from '../../../../../../services/reverseCaseFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  type: 'positive' | 'negative'
  resultsSettings: ResultsSettings
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  resultsViewParty: 'client' | 'opposing'
}

export default function ValueBreakdownGraphContainer(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const reversedSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)

  const [valuesBreakdown, setValuesBreakdown] = useState<
    ValuesBreakdown | undefined
  >(undefined)
  const [highestValue, setHighestValue] = useState(0)

  const partiesFormat = [
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot.partyFormatOwn
      : reversedSnapshot.partyFormatOwn,
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot.partyFormatOther
      : reversedSnapshot.partyFormatOther,
  ]

  const resultsSnapshot =
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reversedSnapshot

  const [sortedValues, setSortedValues] = useState<string[] | undefined>(
    undefined,
  )

  useEffect(() => {
    const valuesBreakdownFromResults = props.resultsFromBackend.values_breakdown
    if (valuesBreakdownFromResults) {
      setValuesBreakdown(
        findValueBreakdown(valuesBreakdownFromResults, props.type),
      )
      setHighestValue(
        findHighestValueOfValuesBreakdown(valuesBreakdownFromResults),
      )
    }
  }, [props.type, props.resultsFromBackend])

  useEffect(() => {
    if (valuesBreakdown !== undefined) {
      let tempSortedValues = keysIn(valuesBreakdown)
        .filter(
          (key) => valuesBreakdown[key as keyof ValuesBreakdown] !== undefined,
        )
        .sort((a, b) => {
          let valueA = valuesBreakdown[a as keyof ValuesBreakdown] ?? 0
          let valueB = valuesBreakdown[b as keyof ValuesBreakdown] ?? 0

          if (props.type === 'positive') {
            return valueB - valueA
          }
          return valueA - valueB
        })

      setSortedValues(tempSortedValues)
    }
    // eslint-disable-next-line
  }, [props.type, valuesBreakdown])

  return (
    <div
      className="valueBreakdownContainer"
      id={`valueBreakdownContainer-${props.type}${
        props.pdfPreview ? '-forReport' : ''
      }`}
    >
      {valuesBreakdown &&
      sortedValues &&
      shouldShowValueBreakdown(valuesBreakdown, props.type) ? (
        <>
          <p
            className="valueBreakdownTitle"
            id={`valueBreakdownTitle-${props.type}${
              props.pdfPreview ? '-forReport' : ''
            }`}
            data-textattribute={
              props.type === 'positive' ? 'title-277' : 'title-278'
            }
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.medium,
              'noRoboto',
            )}
          >
            {props.type === 'positive'
              ? getText('title-277', user.settings)
              : getText('title-278', user.settings)}
          </p>
          <div
            className="valueBreakdownComponent"
            id={`valueBreakdownComponent-${props.type}${
              props.pdfPreview ? '-forReport' : ''
            }`}
          >
            <div className="valueBreakdownTextContainer">
              {sortedValues.map((valueBreakdownKey, index) => {
                if (
                  valuesBreakdown[
                    valueBreakdownKey as keyof ValuesBreakdown
                  ] !== undefined
                ) {
                  return (
                    <p
                      className="valueBreakdownText"
                      key={`valueBreakdownText-${valueBreakdownKey}`}
                      id={`valueBreakdownText-${valueBreakdownKey}${
                        props.pdfPreview ? '-forReport' : ''
                      }`}
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.medium,
                        'noRoboto',
                      )}
                    >
                      {getTextFromValueBreakdown(
                        valueBreakdownKey as keyof ValuesBreakdown,
                        user.settings,
                        partiesFormat,
                        props.resultsSettings.settings.includeIncurredCosts ||
                          !snapshotHasIncurredCosts(
                            scenarioSnapshot.currentSnapshot,
                          ),
                      )}
                    </p>
                  )
                }
                return (
                  <div
                    className="emptyValueBreakdown"
                    key={`valueBreakdownText-${valueBreakdownKey}`}
                  ></div>
                )
              })}
            </div>
            <div
              className={`valueBreakdownGraph ${props.type}`}
              id={`valueBreakdownGraph-${props.type}${
                props.pdfPreview ? '-forReport' : ''
              }`}
            >
              <ValueBreakdownGraph
                values={valuesBreakdown}
                type={props.type}
                resultsSettings={props.resultsSettings}
                highestValue={highestValue}
                resultsFromBackend={props.resultsFromBackend}
                sortedValues={sortedValues}
                pdfPreview={props.pdfPreview}
                reportWhitelabel={props.reportWhitelabel}
                resultsSnapshot={resultsSnapshot}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
