import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { ReportWhitelabel } from '../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../services/reportFunctions'
import { getText } from '../../../../../services/textFunctions'
import {
  findDisputedAmount,
  findTotalCounterClaimedAmount,
  getLCDPartyDescriptions,
} from '../../../../../services/disputedAmountFunctions'
import { useMemo, useState } from 'react'
import { userState } from '../../../../../states/UserState'
import SingleSegment from '../../../../../Components/LegalFeesDistribution/LegalFeesDistributionComponents/SingleSegment'

type Props = {
  reportWhitelabel?: ReportWhitelabel
  forReport?: boolean
}

export default function LCDPreviewContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const user = useRecoilValue(userState)

  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  const [disputedAmount, setDisputedAmount] = useState(0)
  const [totalCounterClaimedAmount, setTotalCounterClaimedAmount] = useState(0)

  useMemo(() => {
    setTotalCounterClaimedAmount(findTotalCounterClaimedAmount(currentSnapshot))
    setDisputedAmount(findDisputedAmount(currentSnapshot))
  }, [currentSnapshot])
  return (
    <div
      className={`legalCostsDistributionPreviewAppContainer ${
        props.forReport ? '' : 'noReport'
      }`}
    >
      <div
        className="legalCostsDistributionPreviewAppInnerContainer"
        id="legalCostsDistributionPreviewAppContainer"
      >
        <div className="lFDAppHeader" style={{ marginBottom: 20 }}>
          <p
            className="lFDAppTitle"
            id="lFDAppTitle"
            data-attribute="label-25"
            style={
              props.forReport
                ? getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )
                : undefined
            }
          >
            {`${getText(
              getLCDPartyDescriptions(
                disputedAmount,
                totalCounterClaimedAmount,
              )[0],
              user.settings,
              partiesFormat,
            )} (${currentSnapshot.currency})`}
          </p>
        </div>
        {currentSnapshot.legalFeesDistribution.map((feeDistribution, index) => (
          <SingleSegment
            key={`${feeDistribution.id}-${index}`}
            singleSegmentIndex={
              currentSnapshot.legalFeesDistribution.length - 1 - index
            }
            totalCounterClaimedAmount={totalCounterClaimedAmount}
            disputedAmount={disputedAmount}
            pdfPreview
            reportWhitelabel={props.reportWhitelabel}
            forNewLCD={props.forReport !== true}
          />
        ))}
        {getLCDPartyDescriptions(
          disputedAmount,
          totalCounterClaimedAmount,
        )[0] !==
          getLCDPartyDescriptions(
            disputedAmount,
            totalCounterClaimedAmount,
          )[1] && (
          <div className="lFDAppHeader" style={{ marginBottom: 20 }}>
            <p
              className="lFDAppTitle"
              id="lFDAppTitle"
              data-attribute="title-..."
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
            >
              {`${getText(
                getLCDPartyDescriptions(
                  disputedAmount,
                  totalCounterClaimedAmount,
                )[1],
                user.settings,
                partiesFormat,
              )} (${currentSnapshot.currency})`}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
