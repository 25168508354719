import { useRecoilValue } from 'recoil'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'

type Props = {
  changeSelectionArea: (type: 'positive' | 'negative') => void
  noSelectionArea: 'positive' | 'negative' | undefined
  tree?: boolean
}
export default function ChooseSelectionArea(props: Props) {
  const user = useRecoilValue(userState)
  return (
    <>
      <div className={`chooseSelectionArea ${props.tree ? 'tree' : ''}`}>
        <p className="selectAreaText" data-textattribute="description-294">
          {getText('description-294', user.settings)}
        </p>
        <p
          className="selectPositiveNegativeAreaText"
          onClick={() => props.changeSelectionArea('positive')}
          data-textattribute="description-295"
        >
          {getText('description-295', user.settings)}
        </p>
        <p
          className="selectPositiveNegativeAreaText"
          onClick={() => props.changeSelectionArea('negative')}
          data-textattribute="description-296"
        >
          {getText('description-296', user.settings)}
        </p>
        {props.noSelectionArea === 'positive' && (
          <p className="noSelectionArea" data-textattribute="message-131">
            {getText('message-131', user.settings)}
          </p>
        )}
        {props.noSelectionArea === 'negative' && (
          <p className="noSelectionArea" data-textattribute="message-132">
            {getText('message-132', user.settings)}
          </p>
        )}
      </div>
    </>
  )
}
