import { useRecoilValue } from 'recoil'
import { getText } from '../services/textFunctions'
import { userState } from '../states/UserState'

type Props = {
  children: React.ReactElement
  type: 'firstTrialDate' | 'secondTrialDate'
}

export default function DateInputHOC(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <div
      className="dateInputHOCContainer"
      id={`dateInputHOCContainer-${props.type}`}
    >
      <div
        className="dateInputContainer"
        id={`dateInputContainer-${props.type}`}
      >
        {props.children}
        <p
          className="dateInputDescription"
          id={`dateInputDescription-${props.type}`}
          data-textattribute="description-297"
          //newText
        >
          {getText('description-297', user.settings)}
        </p>
      </div>
    </div>
  )
}
