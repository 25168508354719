import { useRecoilValue } from 'recoil'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { scenarioIdentityState } from '../../../../../../../../states/ScenarioIdentityState'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import {
  getClientDomain,
  isDevEnv,
} from '../../../../../../../../services/commonFunctions'
import eperotoLogo from '../../../../../../../../resources/images/100-eperoto_Headlines.svg'
import ReportCurrentDate from './ReportCurrentDate'
import { reportState } from '../../../../../../../../states/ReportState'
import { evershedsReportState } from '../../../../../../../../states/EvershedsReportState'

type Props = {
  reportWhitelabel?: ReportWhitelabel
}

export default function ReportTitleContainer(props: Props) {
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const user = useRecoilValue(userState)
  const report = useRecoilValue(reportState)
  const evershedsReport = useRecoilValue(evershedsReportState)

  return (
    <div
      className={`caseDetailsContainer ${evershedsReport ? 'eversheds' : ''}`}
      data-openreplay-obscured
    >
      <p
        className="caseNameTitle"
        id="caseNameTitle"
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportTitle,
          FontLabels.regular,
          'noRoboto',
        )}
      >
        {scenarioIdentity.caseName}
      </p>
      <p
        className="scenarioNameTitle"
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.regular,
          'noRoboto',
        )}
        id="scenarioNameTitle"
        data-textattribute="description-94"
        data-openreplay-obscured
      >
        {getText('description-94', user.settings)}{' '}
        {scenarioIdentity.scenarioName}
      </p>
      <p
        className="caseNameSubtitle"
        id="caseNameSubtitle"
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.regular,
          'noRoboto',
        )}
        data-textattribute="description-93"
      >
        {getText('description-93', user.settings)}
      </p>
      {evershedsReport && (
        <ReportCurrentDate
          date={report.reportData.date}
          evershedsReport={evershedsReport}
        />
      )}
      <div
        className={`eperotoLogoAndDateContainer ${
          evershedsReport ? 'eversheds' : ''
        }`}
      >
        <img
          src={
            user.companyLogo && props.reportWhitelabel
              ? isDevEnv()
                ? `http://localhost:8000${user.companyLogo}`
                : `${'https://'}${getClientDomain()}${user.companyLogo}`
              : eperotoLogo
          }
          alt="eperotoLogo"
          className="eperotoLogo"
          id="eperotoLogoReportImage"
        />
        {!evershedsReport && (
          <ReportCurrentDate
            date={report.reportData.date}
            evershedsReport={evershedsReport}
          />
        )}
      </div>
    </div>
  )
}
