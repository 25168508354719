import {
  AvailableLanguages,
  Currency,
  currencyFromString,
  DateFormatOption,
  dateFormatOptionFromString,
  PartyFormat,
} from './enums'
import { SortDirection, SortOption } from './generalTypes'

export class UserSettings {
  formats: {
    numberFormat: {
      decimalSeparator: ' ' | ',' | '.'
      thousandSeparator: ' ' | ',' | '.'
    }
    dateFormat: DateFormatOption
    partyFormat: keyof typeof PartyFormat
    opposingPartyFormat: keyof typeof PartyFormat
    currency: Currency
  }
  sortings: {
    caseSorting: [SortOption, SortDirection]
    scenarioSorting: [SortOption, SortDirection]
  }
  notifications: {
    sharedCase: boolean
    removedCase: boolean
    changeRoleCase: boolean
    checklistReminder: boolean
  }
  language: AvailableLanguages

  constructor(
    formats: {
      numberFormat: {
        decimalSeparator: ',' | '.'
        thousandSeparator: ' ' | ',' | '.'
      }
      dateFormat: DateFormatOption
      partyFormat: keyof typeof PartyFormat
      opposingPartyFormat: keyof typeof PartyFormat
      currency: Currency
    },
    sortings: {
      caseSorting: [SortOption, SortDirection]
      scenarioSorting: [SortOption, SortDirection]
    },
    notifications: {
      sharedCase: boolean
      removedCase: boolean
      changeRoleCase: boolean
      checklistReminder: boolean
    },
    language: AvailableLanguages,
  ) {
    this.formats = { ...formats }
    this.sortings = { ...sortings }
    this.notifications = { ...notifications }
    this.language = language
  }

  static FromJson(settings_data?: any): UserSettings {
    if (settings_data) {
      return {
        formats: {
          numberFormat: {
            decimalSeparator:
              settings_data.formats.number_format.decimal_separator,
            thousandSeparator:
              settings_data.formats.number_format.thousand_separator,
          },
          dateFormat: dateFormatOptionFromString(
            settings_data.formats.date_format,
          ),
          partyFormat: settings_data.formats.party_format,
          opposingPartyFormat: settings_data.formats.opposing_party_format,
          currency: currencyFromString(settings_data.formats.currency),
        },
        notifications: {
          sharedCase: settings_data.notifications.shared_case,
          removedCase: settings_data.notifications.removed_case,
          changeRoleCase: settings_data.notifications.change_role_case,
          checklistReminder: settings_data.notifications.checklist_reminder,
        },
        sortings: {
          caseSorting: [
            settings_data.sortings.case_sorting[0],
            settings_data.sortings.case_sorting[1],
          ],
          scenarioSorting: [
            settings_data.sortings.scenario_sorting[0],
            settings_data.sortings.scenario_sorting[1],
          ],
        },
        language: settings_data.language,
      }
    }

    return {
      formats: {
        numberFormat: {
          decimalSeparator: ',',
          thousandSeparator: ' ',
        },
        dateFormat: DateFormatOption.YYYY_MM_DD,
        partyFormat: 'Client',
        opposingPartyFormat: 'Opposing_Party',
        currency: Currency.SEK,
      },
      notifications: {
        sharedCase: true,
        removedCase: true,
        changeRoleCase: true,
        checklistReminder: true,
      },
      sortings: {
        caseSorting: ['dateCreated', 'up'],
        scenarioSorting: ['dateCreated', 'up'],
      },
      language: AvailableLanguages.english,
    }
  }

  static DefaultFreemiumUserSettings(): UserSettings {
    return {
      formats: {
        numberFormat: {
          decimalSeparator: ',',
          thousandSeparator: '.',
        },
        dateFormat: DateFormatOption.DD_MM_YYYY,
        partyFormat: 'Client',
        opposingPartyFormat: 'Opposing_Party',
        currency: Currency.EUR,
      },
      notifications: {
        sharedCase: true,
        removedCase: true,
        changeRoleCase: true,
        checklistReminder: true,
      },
      sortings: {
        caseSorting: ['title', 'up'],
        scenarioSorting: ['title', 'up'],
      },
      language: AvailableLanguages.english,
    }
  }

  static ToJson(settings: UserSettings) {
    return {
      formats: {
        number_format: {
          decimal_separator: settings.formats.numberFormat.decimalSeparator,
          thousand_separator: settings.formats.numberFormat.thousandSeparator,
        },
        date_format: settings.formats.dateFormat.toString(),
        party_format: settings.formats.partyFormat.toString(),
        opposing_party_format: settings.formats.opposingPartyFormat.toString(),
        currency: settings.formats.currency.toString(),
      },
      sortings: {
        case_sorting: [...settings.sortings.caseSorting],
        scenario_sorting: [...settings.sortings.scenarioSorting],
      },
      notifications: {
        shared_case: settings.notifications.sharedCase,
        removed_case: settings.notifications.removedCase,
        change_role_case: settings.notifications.changeRoleCase,
        checklist_reminder: settings.notifications.checklistReminder,
      },
      language: settings.language,
    }
  }
}
