import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { userState } from '../../../../../../../../states/UserState'
import { getText } from '../../../../../../../../services/textFunctions'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { stringAndRoundTo2Decimals } from '../../../../../../../../services/commonFunctions'
type Props = {
  id: number
  sortedDataForTable: any
  currentSnapshot: ScenarioSnapshot
}
export default function LegalCostsTo(props: Props) {
  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
   { amount_received , amount_received_out_of_court , claim_name, claim_type, claim_type_id, interest_date, interest_rate, interest_received, outcome_probability, scenario_name, tree_name}
    17 - name
    
    */
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const disputedAmount = findDisputedAmount(scenarioSnapshot.currentSnapshot)
  const user = useRecoilValue(userState)

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]
  return (
    <>
      <div className="hoverBackground" data-openreplay-obscured>
        <div className="hoverTextContainer">
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsTo1"
            data-textattribute="description-70"
            data-openreplay-obscured
          >
            {getText('description-70', user.settings, partiesFormat)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][3],
                user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsTo2"
            data-textattribute="description-71"
          >
            {getText('description-71', user.settings)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(disputedAmount, user.settings)}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
          <p
            className="hoverText"
            id="detailedResultsHoverComponentDescription-legalCostsTo3"
            data-textattribute="description-72a,description-72b, description-72c"
            data-openreplay-obscured
          >
            {getText('description-72a', user.settings)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][15] * 100,
                user.settings,
              )}{' '}
              %
            </span>{' '}
            {getText('description-72b', user.settings, partiesFormat)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                props.sortedDataForTable[props.id][14] * 100,
                user.settings,
              )}{' '}
              %
            </span>{' '}
            {getText('description-72c', user.settings, partiesFormat)}{' '}
            <span className="robotoNumbersBold">
              {stringAndRoundTo2Decimals(
                Math.abs(props.sortedDataForTable[props.id][7]),
                user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </span>
            .
          </p>
        </div>
      </div>
    </>
  )
}
