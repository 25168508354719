import { useRecoilValue } from 'recoil'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { freemiumState } from '../states/FreemiumState'

type Props = {
  children: React.ReactElement
}

const CaptchaHOC = (props: Props) => {
  const freemium = useRecoilValue(freemiumState)

  return freemium.isFreemium ? (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeY05gpAAAAANrDxewSWzXKhTNq42hcRD-x3yR1"
      useRecaptchaNet={false}
      useEnterprise={false}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      {props.children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{props.children}</>
  )
}

export default CaptchaHOC
