import { useRecoilValue } from 'recoil'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import {
  roundNumberTo,
  getMaxForProb,
} from '../../../../../../../services/commonFunctions'
import addAwardedAmountGray from '../../../../../../../resources/images/146-addAwardedAmount.svg'
import addAwardedAmountPurple from '../../../../../../../resources/images/149-addEvent.svg'
import InputNum from '../../../../../../../Components/Inputs/InputNum/InputNum'
import { useState } from 'react'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'
import { AvailableLanguages } from '../../../../../../../models/enums'

type Props = {
  active: boolean
  highlighted: boolean
  treeIndex: number
  nodeId: NodeId
  eventIndex: number
  eventDetails: EventDetails
  nodeSelected: boolean
  startAnimation: boolean
  eventId: EventId
  nodeProbabilities: number[]
  shakeScenario: number | undefined
  connectionCreatesLoop: EventId | NodeId | undefined
  handleChangeEventProbability: (e: any) => void
  handleAddNode: (e: any) => void
}

export default function EventAwardedAmountContainer(props: Props) {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const [addChildButtonHover, setAddChildButtonHover] = useState(false)

  return (
    <>
      <div
        className={`treeEventProbabilityContainer ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${props.active ? 'active' : ''} ${
          props.startAnimation ? 'fadeout' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventProbabilityContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        <InputNum
          label={getText('label-31', user.settings)}
          labelTextAttribute="label-31"
          defaultZero
          width={
            user.settings.language === AvailableLanguages.norwegian
              ? 108
              : user.settings.language === AvailableLanguages.danish
              ? 112
              : user.settings.language === AvailableLanguages.swedish
              ? 95
              : 90
          }
          smallField
          id={`treeEventProbabilityInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          name={`treeEventProbabilityInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          value={roundNumberTo(props.eventDetails.probability * 100, 2)}
          readOnly={
            props.nodeProbabilities &&
            props.eventIndex === props.nodeProbabilities.length - 1
          }
          borderColor={
            props.nodeProbabilities &&
            props.eventIndex === props.nodeProbabilities.length - 1
              ? 'transparent'
              : undefined
          }
          tagcolor={props.highlighted ? '#ff703e' : undefined}
          maxValue={getMaxForProb(
            props.nodeProbabilities,
            props.eventIndex,
            true,
          )}
          maxNumberOfDecimals={0}
          backgroundColor={
            props.nodeProbabilities &&
            props.eventIndex === props.nodeProbabilities.length - 1
              ? 'transparent'
              : 'white'
          }
          treeProbability
          onChange={props.handleChangeEventProbability}
          className={`treeEventProbabilityInput ${
            props.nodeProbabilities &&
            props.eventIndex === props.nodeProbabilities.length - 1
              ? 'readOnly'
              : ''
          } ${
            props.shakeScenario === props.eventIndex ? 'shakeScenario' : ''
          } robotoNumbers`}
          suffix="%"
          tabIndex={10 * props.eventIndex + 7}
        />

        <div
          className="treeEventAddNodeButtonContainer"
          onMouseEnter={() => setAddChildButtonHover(true)}
          onMouseLeave={() => setAddChildButtonHover(false)}
        >
          <div
            className={`treeEventAddNodeButton ${
              props.active ? 'active' : ''
            } ${props.nodeSelected || props.highlighted ? 'selected' : ''} ${
              props.startAnimation ? 'fadeout' : ''
            } ${krogerusColors ? 'krogerus' : ''}`}
            id={`treeEventAddNodeButton-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            onClick={props.handleAddNode}
          >
            <img
              src={
                addChildButtonHover
                  ? addAwardedAmountPurple
                  : addAwardedAmountGray
              }
              alt="plus"
              className="treeEventAddNodeButtonImg"
              id={`treeEventAddNodeButtonImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            />
            <div
              className={
                addChildButtonHover
                  ? 'treeEventAddNodeButtonText purple'
                  : 'treeEventAddNodeButtonText'
              }
              id={`treeEventAddNodeButtonText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              data-textattribute="button-84"
            >
              {getText('button-84', user.settings)}
            </div>
            {props.connectionCreatesLoop === props.eventId ? (
              <div className="connectionCreatesLoopMessageContainer shake">
                <p
                  className="connectionCreatesLoopMessageText"
                  id={`connectionCreatesLoopMessageText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                >
                  This connection creates a loop
                </p>
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    </>
  )
}
