import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import clientImg from '../../../../../../resources/images/279-clientSymbol.svg'
import krogerusClientImg from '../../../../../../resources/images/303-clientSymbolKrogerus.svg'
import opposingPartyImg from '../../../../../../resources/images/280-opposingPartySymbol.svg'
import krogerusOpposingPartyImg from '../../../../../../resources/images/304-krogerusOpposingPartySymbol.svg'
import { getText } from '../../../../../../services/textFunctions'
import { userState } from '../../../../../../states/UserState'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import tickedImg from '../../../../../../resources/images/115-tickPurple.svg'
import cannotUntickImg from '../../../../../../resources/images/017-tickedGrayGray.svg'
import unTickedImg from '../../../../../../resources/images/018-untickedGrayBlue.svg'
import { saveResultsSettings } from '../../../../../../services/requests'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { freemiumState } from '../../../../../../states/FreemiumState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../services/commonFunctions'
import { FreemiumMessageType } from '../../../../../../models/freemiumEnums'
import PremiumFeatureIcon from '../../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { useEffect, useState } from 'react'
import { ResultsSettings } from '../../../../../../models/generalTypes'
import useWindowSize from '../../../../../../customHooks/useWindowSize'
import {
  snapshotHasIncurredCosts,
  snapshotHasLegalCosts,
} from '../../../../../../services/reportFunctions'
import { WhiteTooltip } from '../../../../../../Components/tooltip/TooltipComponent'
import { activeTabsState } from '../../../../../../states/ActiveTabsState'
import { ToolTabs } from '../../../../../../models/enums'

type Props = {
  resultsViewParty: 'client' | 'opposing'
  setResultsViewParty: (value: 'client' | 'opposing') => void
}

const OptionsComponent = (props: Props) => {
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)

  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)
  const [showIncurredCostsPopUp, setShowIncurredCostsPopUp] = useState<
    undefined | 'noIncurredCosts' | 'oldExecutive' | 'noLegalCosts'
  >(undefined)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const windowSize = useWindowSize()

  useEffect(() => {
    window.addEventListener('mousedown', closeIncurredCostsPopUp)

    return () => {
      window.removeEventListener('mousedown', closeIncurredCostsPopUp)
    }
    // eslint-disable-next-line
  }, [])

  function closeIncurredCostsPopUp(e: any) {
    if (!e.target.id.includes(`incurredCostsPopUp`)) {
      setShowIncurredCostsPopUp(undefined)
    }
  }

  async function changeRoundedResults() {
    //Mixpanel 189 (All)
    logActivity(freemium.isFreemium, 'Pressed Round Figures in the Results')

    if (freemium.isFreemium) {
      setFreemium({
        ...freemium,
        showMessage: FreemiumMessageType.General,
      })
    } else {
      saveResultsSettings(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
        {
          ...resultsSettings,
          settings: {
            ...resultsSettings.settings,
            roundedResults: !resultsSettings.settings.roundedResults,
          },
        },
      )
      setResultsSettings({
        ...resultsSettings,
        settings: {
          ...resultsSettings.settings,
          roundedResults: !resultsSettings.settings.roundedResults,
        },
      })
    }
  }

  async function changeIncurredCosts() {
    //Mixpanel 189 (All)
    logActivity(
      freemium.isFreemium,
      'Pressed Incurred Costs Option in the Results',
    )

    if (freemium.isFreemium) {
      setFreemium({
        ...freemium,
        showMessage: FreemiumMessageType.General,
      })
    } else {
      if (
        resultsSettings.settings.executiveSummary === 'new' &&
        snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot)
      ) {
        saveResultsSettings(
          scenarioIdentity.caseId,
          scenarioIdentity.scenarioId,
          {
            ...resultsSettings,
            settings: {
              ...resultsSettings.settings,
              includeIncurredCosts:
                !resultsSettings.settings.includeIncurredCosts,
            },
          },
        )
        setResultsSettings({
          ...resultsSettings,
          settings: {
            ...resultsSettings.settings,
            includeIncurredCosts:
              !resultsSettings.settings.includeIncurredCosts,
          },
        })
      } else {
        if (!snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
          setShowIncurredCostsPopUp('noLegalCosts')
        } else if (
          !snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot)
        ) {
          setShowIncurredCostsPopUp('noIncurredCosts')
        } else {
          setShowIncurredCostsPopUp('oldExecutive')
        }
      }
    }
  }

  function switchExecutiveSummary(version: 'new' | 'old') {
    let tempResultsSettings = deepCloneObject(
      resultsSettings,
    ) as ResultsSettings
    tempResultsSettings.settings.executiveSummary = version
    setResultsSettings(tempResultsSettings)
    if (!freemium.isFreemium) {
      saveResultsSettings(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
        tempResultsSettings,
      )
    }
    setTimeout(() => {
      setShowIncurredCostsPopUp(undefined)
    }, 10)
    logActivity(freemium.isFreemium, 'Changed executive summary to ' + version)
  }

  function getStyleForButton() {
    return windowSize.width < 1150
      ? windowSize.width > 836
        ? {
            maxWidth: Math.max((windowSize.width / 1150) * 200 - 40, 90),
          }
        : { maxWidth: 90 }
      : undefined
  }

  function getTextForPopUp() {
    if (showIncurredCostsPopUp === 'noIncurredCosts') {
      //data-textattribute='description-282'
      return getText('description-282', user.settings)
    } else if (showIncurredCostsPopUp === 'oldExecutive') {
      //data-textattribute='description-283'
      return getText('description-283', user.settings)
    } else if (showIncurredCostsPopUp === 'noLegalCosts') {
      //data-textattribute='description-284'
      return getText('description-284', user.settings)
    }
    return ''
  }
  function getActionTextForPopUp() {
    if (showIncurredCostsPopUp === 'noIncurredCosts') {
      //data-textattribute='button-166'
      return getText('button-166', user.settings)
    } else if (showIncurredCostsPopUp === 'oldExecutive') {
      //data-textattribute='button-163'
      return getText('button-163', user.settings)
    } else if (showIncurredCostsPopUp === 'noLegalCosts') {
      //data-textattribute='description-199'
      return getText('description-199', user.settings)
    }
    return ''
  }

  function onCLickPopUp() {
    if (showIncurredCostsPopUp === 'oldExecutive') {
      //Mixpanel 189 (All)
      logActivity(
        freemium.isFreemium,
        'Changed to new executive summary from the incurred costs pop up',
      )
      return switchExecutiveSummary('new')
    }
    logActivity(
      freemium.isFreemium,
      'Navigated to legal costs from the incurred costs pop up',
    )
    return setActiveTabs({
      tab: ToolTabs.legalCosts,
      claim: activeTabs.claim,
    })
  }

  return (
    <>
      <WhiteTooltip
        id={'incurredCostsTooltip'}
        textAttribute={
          showIncurredCostsPopUp === undefined &&
          snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot) &&
          resultsSettings.settings.executiveSummary === 'new'
            ? 'tooltip-69'
            : undefined
        }
        title={
          showIncurredCostsPopUp === undefined &&
          snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot) &&
          resultsSettings.settings.executiveSummary === 'new'
            ? getText('tooltip-69', user.settings)
            : ''
        }
        data-openreplay-obscured
      >
        <div
          id="incurredCostsButton"
          className={`incurredCostsButton ${
            resultsSettings.settings.includeIncurredCosts ? 'active' : ''
          }`}
          onClick={changeIncurredCosts}
          data-openreplay-obscured
        >
          {freemium.isFreemium && (
            <PremiumFeatureIcon targetId={`incurredCostsButtonText`} />
          )}
          <div
            className="incurredCostsButtonTickContainer"
            id="incurredCostsButtonTickContainer"
          >
            {resultsSettings.settings.includeIncurredCosts === true &&
            snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot) ? (
              <img
                src={
                  resultsSettings.settings.executiveSummary === 'new'
                    ? tickedImg
                    : cannotUntickImg
                }
                alt="tickedImg"
                className="tickImg"
                id={`tickedButton-incurredCostsButton`}
                onClick={changeIncurredCosts}
              />
            ) : (
              <img
                src={unTickedImg}
                alt="unTickedImg"
                className="tickImg"
                id={`untickedButton-incurredCostsButton`}
                onClick={changeIncurredCosts}
              />
            )}
          </div>
          <p
            className={`incurredCostsButtonText ${
              resultsSettings.settings.executiveSummary === 'old' ||
              !snapshotHasIncurredCosts(scenarioSnapshot.currentSnapshot)
                ? 'inactive'
                : ''
            }`}
            id="incurredCostsButtonText"
            data-textattribute="button-167"
            data-openreplay-obscured
          >
            {getText('button-167', user.settings)}
          </p>
          {showIncurredCostsPopUp && (
            <div
              className="incurredCostsPopUpContainer"
              id="incurredCostsPopUpContainer"
            >
              <p className="incurredCostsPopUpText" id="incurredCostsPopUpText">
                {getTextForPopUp()}
              </p>
              <p
                className="oldNewSwitchText"
                id="incurredCostsPopUpSwitch"
                onClick={onCLickPopUp}
              >
                {getActionTextForPopUp()}
              </p>
            </div>
          )}
        </div>
      </WhiteTooltip>
      <div
        id="roundedResultsButton"
        className={`roundedResultsButton ${
          resultsSettings.settings.roundedResults ? 'active' : ''
        }`}
        onClick={changeRoundedResults}
        data-openreplay-obscured
      >
        {freemium.isFreemium && (
          <PremiumFeatureIcon targetId={`roundedResultsButtonText`} />
        )}
        <div
          className="roundedResultsButtonTickContainer"
          id="roundedResultsButtonTickContainer"
        >
          {resultsSettings.settings.roundedResults === true ? (
            <img
              src={tickedImg}
              alt="tickedImg"
              className="tickImg"
              id={`tickedButton-roundedResultsButton`}
              onClick={changeRoundedResults}
            />
          ) : (
            <img
              src={unTickedImg}
              alt="unTickedImg"
              className="tickImg"
              id={`untickedButton-roundedResultsButton`}
              onClick={() => changeRoundedResults}
            />
          )}
        </div>
        <p
          className="roundedResultsButtonText"
          id="roundedResultsButtonText"
          data-textattribute="button-162"
          data-openreplay-obscured
        >
          {getText('button-162', user.settings)}
        </p>
      </div>
      <div className={`resultsPartySwitchContainer`}>
        <div
          className={`resultsPartySwitch first ${
            props.resultsViewParty === 'client' ? 'selected' : ''
          }`}
          onClick={() => props.setResultsViewParty('client')}
        >
          <img
            className="img"
            src={krogerusColors ? krogerusClientImg : clientImg}
            alt="clientImg"
          />
          <p
            className="resultsPartyText"
            id="partyViewSwitchButtonText-client"
            style={getStyleForButton()}
            data-openreplay-obscured
          >
            {getText('title-260', user.settings, partiesFormat)}
          </p>
        </div>
        <div
          className={`resultsPartySwitch ${
            props.resultsViewParty === 'opposing' ? 'selected' : ''
          }`}
          onClick={() => props.setResultsViewParty('opposing')}
        >
          <img
            className="img"
            src={krogerusColors ? krogerusOpposingPartyImg : opposingPartyImg}
            alt="opposingPartyImg"
          />
          <p
            className="resultsPartyText"
            id="partyViewSwitchButtonText-opposingParty"
            style={getStyleForButton()}
            data-openreplay-obscured
          >
            {getText('title-261', user.settings, partiesFormat)}
          </p>
        </div>
      </div>
    </>
  )
}

export default OptionsComponent
