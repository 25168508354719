import useWebSocket from 'react-use-websocket'
import {
  FreemiumListItem,
  FreemiumSortOption,
} from '../../models/freemiumGeneralTypes'
import { getSocketUrl } from '../../services/socket_services'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import useWindowSize from '../../customHooks/useWindowSize'
import { useNavigate, useParams } from 'react-router-dom'
import { Params, SortDirection } from '../../models/generalTypes'
import {
  getFreemiumCases,
  getFreemiumScenarios,
} from '../../freemiumServices/freemiumRequests'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../../services/commonFunctions'
import LoadingCasesPlaceHolder from '../../pages/myCases_myScenarios/myCasesComponents/LoadingCasesPlaceHolder'
import FreemiumTopPart from './freemiumComponents/FreemiumTopPart'
import EmptyMessage from '../../pages/myCases_myScenarios/myCasesComponents/EmptyMessage'
import FreemiumMyCasesTitles from '../../pages/myCases_myScenarios/myCasesComponents/FreemiumMyCasesTitles'
import FreemiumItemPart from './freemiumComponents/FreemiumItemPart'
import FreemiumBottomPart from './freemiumComponents/FreemiumBottomPart'
import {
  deleteFreemiumCase,
  saveFreemiumCase,
  saveFreemiumPreferences,
} from '../../freemiumServices/freemiumStorage'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import { DateTime } from 'luxon'
import {
  getCaseFromStorage,
  getScenarioFromStorage,
} from '../../freemiumServices/freemiumServices'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import { freemiumState } from '../../states/FreemiumState'
import {
  FREEMIUM_CASE_ID,
  FREEMIUM_SCENARIO_ID,
  FREEMIUM_SNAPSHOT_ID,
} from '../../freemiumServices/freemiumConstants'
import { User } from '../../models/user'
import { UserChecklist } from '../../models/userChecklist'
import { FreemiumMessageType } from '../../models/freemiumEnums'

type Props = {
  createItemList: (res: any) => FreemiumListItem[]
  createItem: (res: any, noIdentity?: boolean) => FreemiumListItem
  itemName: 'Scenario' | 'Case'
  navigateToNextPage: (uuid: string) => void
  getNavigateLink: (uuid: string) => string
  isMyScenariosPage: boolean
  statusDiv?: (uuid: string) => React.ReactElement
  caseName?: string
  tutorialId?: string
}

const FreemiumListPageTemplate = (props: Props) => {
  const [list, setList] = useState<FreemiumListItem[]>([])
  const [searchList, setSearchList] = useState<FreemiumListItem[]>([])
  const [loading, setLoading] = useState(true)
  const [searchKey, setSearchKey] = useState('')
  const [widthOfCaseContainer, setWidthOfCaseConainer] = useState<
    number | undefined
  >(
    document.getElementById('caseContainer')
      ? document.getElementById('caseContainer')!.offsetWidth
      : undefined,
  )
  const [resize, setResize] = useState(false)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const windowSize = useWindowSize()
  const params = useParams<Params>()
  const navigate = useNavigate()
  const [nameInput, setNameInput] = useState('')
  const [renamingIndex, setRenamingIndex] = useState<number | undefined>(
    undefined,
  )
  const [deletingIndex, setDeletingIndex] = useState<number | undefined>(
    undefined,
  )
  const [user, setUser] = useRecoilState(userState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const [deleteWindowCoordinates, setDeleteWindowCoordinates] = useState<
    [number, number] | undefined
  >(undefined)

  const currentSortOption = 'title'
  const currentSortDirection = props.isMyScenariosPage
    ? user.settings.sortings.scenarioSorting[1]
    : user.settings.sortings.caseSorting[1]

  useWebSocket(getSocketUrl(`freemium`), {
    onOpen: () => {
      console.log('opened')
    },
    reconnectInterval: 3000,
    reconnectAttempts: 3,
    onError(event) {
      console.log(event)
    },
    shouldReconnect: () => {
      return true
    },
  })

  useEffect(() => {
    setLoading(true)
    getItems().then((res) => {
      if (res) {
        updateSortSearch(res)
        setLoading(false)
        setResize(true)
      }
    })

    // eslint-disable-next-line
  }, [params.caseId])

  useEffect(() => {
    if (props.isMyScenariosPage && !loading) {
      //Mixpanel 10 (Freemium)
      logActivity(true, 'Opened a case to see the scenarios')
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    handleDeleteWindowPosition()
    findWidthOfCaseContainer()
    if (resize) {
      findWidthOfCaseContainer()
      setResize(false)
    }

    // eslint-disable-next-line
  }, [windowSize, resize, deletingIndex])

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
      document.removeEventListener('keydown', onKeyPress, false)
    }
    // eslint-disable-next-line
  }, [renamingIndex, nameInput, list, searchList])

  const onKeyPress = (e: any) => {
    if (e.key === 'Escape') {
      setRenamingIndex(undefined)
      setDeleteWindowCoordinates(undefined)
      setNameInput('')
    } else if (e.key === 'Enter') {
      if (renamingIndex !== undefined) {
        handleRenameItem(searchList[renamingIndex], renamingIndex, true)
      }
    }
  }

  const handleDeleteWindowPosition = () => {
    if (document.getElementById(`deleteCase${deletingIndex}`)) {
      let deleteButton = document.getElementById(`deleteCase${deletingIndex}`)
      let viewportOffset = deleteButton?.getBoundingClientRect()

      if (viewportOffset) {
        // these are relative to the viewport, i.e. the window
        let top = viewportOffset.top + 22
        let left = viewportOffset.left + 29
        let h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setDeleteWindowCoordinates([top, left])
      }
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (e.target) {
      const id = (e.target as HTMLImageElement).id
      if (id.includes('renameCase') && renamingIndex !== undefined) {
        // id = 'alertForRenameCase'
        alert(
          `${
            props.itemName === 'Scenario'
              ? // text-attribute='alert-1'
                getText('alert-1', user.settings)
              : // text-attribute='alert-2'
                getText('alert-2', user.settings)
          }`,
        )
        return
      } else if (
        id.includes('renameInput') ||
        id.includes('renameItemSaveButton')
      ) {
        return
      }
    }

    setRenamingIndex(undefined)
    setNameInput('')
  }

  const getItems = async () => {
    let res
    if (props.isMyScenariosPage) {
      res = await getFreemiumScenarios(params.caseId!)
    } else {
      res = await getFreemiumCases()
    }

    if ('errorCode' in res) {
      if (res.errorCode !== 404 || !props.isMyScenariosPage) {
        setHandlingErrors(
          findHandlingErrorState(
            res,
            handlingErrors,
            props.isMyScenariosPage
              ? 'getFreemiumScenarios'
              : 'getFreemiumCases',
            true,
          ),
        )

        return undefined
      }
    }

    return props.createItemList(res)
  }

  const sortBy = (
    option: FreemiumSortOption,
    changeDirection: boolean,
    listToSort?: FreemiumListItem[],
  ) => {
    const comparator = (
      a: FreemiumListItem,
      b: FreemiumListItem,
      direction: SortDirection,
    ) => {
      let coption: keyof FreemiumListItem = option
      if (
        a[coption]!.toString().toLowerCase() >
        b[coption]!.toString().toLowerCase()
      )
        return direction === 'up' ? 1 : -1
      if (
        a[coption]!.toString().toLowerCase() <
        b[coption]!.toString().toLowerCase()
      )
        return direction === 'up' ? -1 : 1
      return 0
    }

    const tempDirection: SortDirection = changeDirection
      ? option === currentSortOption
        ? currentSortDirection === 'up'
          ? 'down'
          : 'up'
        : 'up'
      : currentSortDirection

    let tempList = listToSort ? [...listToSort] : [...searchList]
    tempList = tempList.sort((a: FreemiumListItem, b: FreemiumListItem) =>
      comparator(a, b, tempDirection),
    )

    if (changeDirection) {
      let newUser: User = deepCloneObject(user)
      newUser.settings = {
        ...newUser.settings,
        sortings: {
          caseSorting: props.isMyScenariosPage
            ? [...newUser.settings.sortings.caseSorting]
            : [option, tempDirection],
          scenarioSorting: props.isMyScenariosPage
            ? [option, tempDirection]
            : [...newUser.settings.sortings.scenarioSorting],
        },
      }

      setUser(newUser)
      saveFreemiumPreferences({
        checklist: newUser.checklist,
        settings: newUser.settings,
      })
    }

    return tempList
  }

  const handleSearchList = (key: string, newList?: FreemiumListItem[]) => {
    const filterFunc = (item: FreemiumListItem) => {
      return item.title.toLowerCase().includes(key)
    }

    return newList ? newList.filter(filterFunc) : list.filter(filterFunc)
  }

  function findWidthOfCaseContainer() {
    if (document.getElementById('caseContainer'))
      setWidthOfCaseConainer(
        document.getElementById('caseContainer')!.offsetWidth,
      )
  }

  const handleAddNewItem = () => {
    if (props.isMyScenariosPage) {
      const storageCase = getCaseFromStorage()
      const storageScenario = getScenarioFromStorage()

      if (!storageScenario && storageCase && list.length === 0) {
        storageCase.scenarioId = FREEMIUM_SCENARIO_ID
        storageCase.scenarioName = 'My First Scenario'
        storageCase.snapshotId = FREEMIUM_SNAPSHOT_ID
        storageCase.snapshot = ScenarioSnapshot.EmptySnapshotFreemium(
          'Client',
          'Opposing_Party',
          user.settings.formats.currency,
        )
        storageCase.scenarioDateCreated = DateTime.now().toISO()
        saveFreemiumCase(storageCase)
        //Mixpanel 3 (Freemium)
        logActivity(true, 'Created a scenario')

        navigate(`/mycases/${FREEMIUM_CASE_ID}/${FREEMIUM_SCENARIO_ID}/tool`)
        return
      }
    } else {
      const storageCase = getCaseFromStorage()

      if (!storageCase) {
        saveFreemiumCase({
          caseName: 'My First Case',
          caseId: FREEMIUM_CASE_ID,
          scenarioName: 'My First Scenario',
          scenarioId: FREEMIUM_SCENARIO_ID,
          snapshotId: FREEMIUM_SNAPSHOT_ID,
          snapshot: ScenarioSnapshot.EmptySnapshotFreemium(
            'Client',
            'Opposing_Party',
            user.settings.formats.currency,
          ),
          caseDateCreated: DateTime.now().toISO(),
          scenarioDateCreated: DateTime.now().toISO(),
        })

        const newChecklist = new UserChecklist(
          user.checklist.introVideo,
          user.checklist.accessTutorial,
          true,
          user.checklist.analyzeScenario,
          user.checklist.downloadReport,
        )
        saveFreemiumPreferences({
          checklist: newChecklist,
          settings: user.settings,
        })
        let newUser = deepCloneObject(user) as User
        newUser.checklist = newChecklist
        setUser(newUser)

        //Mixpanel 2 (Freemium)
        logActivity(true, 'Created a case')

        navigate(`/mycases/${FREEMIUM_CASE_ID}/${FREEMIUM_SCENARIO_ID}/tool`)
        return
      }
    }

    openFreemiumMessage(
      props.isMyScenariosPage
        ? FreemiumMessageType.Add_Scenario
        : FreemiumMessageType.Add_Case,
    )
  }

  const showPremiumIcon = () => {
    const storageCase = getCaseFromStorage()
    return (
      (props.isMyScenariosPage &&
        (storageCase !== undefined || list.length > 0)) ||
      !props.isMyScenariosPage
    )
  }

  const showPremiumIconForAdd = () => {
    const storageCase = getCaseFromStorage()
    const storageScenario = getScenarioFromStorage()
    return (
      (props.isMyScenariosPage &&
        (storageScenario !== undefined || list.length > 0)) ||
      (!props.isMyScenariosPage && storageCase !== undefined)
    )
  }

  const handleRenameItem = (
    item: FreemiumListItem,
    index: number,
    rename: boolean,
  ) => {
    if (item.tutorialId) {
      openFreemiumMessage(FreemiumMessageType.General)
    } else {
      if (rename) {
        const storageCase = getCaseFromStorage()
        if (storageCase) {
          if (props.isMyScenariosPage) {
            storageCase.scenarioName = nameInput

            //Mixpanel 7 (Freemium)
            logActivity(true, 'Renamed a scenario')
          } else {
            storageCase.caseName = nameInput

            //Mixpanel 6 (Freemium)
            logActivity(true, 'Renamed a case')
          }
          saveFreemiumCase(storageCase)

          let tempList = [...list]
          const indexToList = list.findIndex(
            (item) =>
              item.uuid ===
              (props.isMyScenariosPage
                ? storageCase.scenarioId
                : storageCase.caseId),
          )
          tempList[indexToList].title = nameInput

          updateSortSearch(tempList)
        }

        setRenamingIndex(undefined)
        setNameInput('')
      } else {
        setRenamingIndex(index)
        setNameInput(item.title)
        setTimeout(() => {
          document.getElementById(`renameInput${index}`)?.focus()
        }, 100)
      }
    }
  }

  const handleDeleteItem = () => {
    if (props.isMyScenariosPage) {
      const storageScenario = getScenarioFromStorage()
      if (storageScenario) {
        delete storageScenario.scenarioId
        delete storageScenario.scenarioName
        delete storageScenario.scenarioDateCreated
        delete storageScenario.snapshotId
        delete storageScenario.snapshot

        saveFreemiumCase(storageScenario)

        //Mixpanel 5 (Freemium)
        logActivity(true, 'Deleted a scenario')
      }
    } else {
      deleteFreemiumCase()

      //Mixpanel 4 (Freemium)
      logActivity(true, 'Deleted a case')
    }

    let tempList = [...list]
    tempList.splice(
      list.findIndex((item) => item.uuid === searchList[deletingIndex!].uuid),
      1,
    )
    updateSortSearch(tempList)
    setDeletingIndex(undefined)
    setDeleteWindowCoordinates(undefined)
  }

  const updateSortSearch = (
    tempList: FreemiumListItem[],
    uuid?: string,
    noSearchKey?: boolean,
  ) => {
    setList([...tempList])

    tempList = handleSearchList(noSearchKey ? '' : searchKey, tempList)
    tempList = sortBy(currentSortOption, false, tempList)

    setSearchList([...tempList])

    if (uuid) {
      return tempList.findIndex((item) => item.uuid === uuid)
    }
  }

  const openFreemiumMessage = (messageType: FreemiumMessageType) => {
    setFreemium({ ...freemium, showMessage: messageType })
  }

  return (
    <>
      <div className="loginBackground">
        {loading ? (
          <LoadingCasesPlaceHolder />
        ) : (
          <div className="myCasesBox">
            <div className="myCasesBox-content-container">
              <FreemiumTopPart
                itemName={props.itemName}
                setSearchList={setSearchList}
                setSearchKey={setSearchKey}
                sortBy={sortBy}
                handleSearchList={handleSearchList}
                currentSortOption={currentSortOption}
                isMyScenariosPage={props.isMyScenariosPage}
                tutorialId={
                  props.isMyScenariosPage && list.length === 0
                    ? props.tutorialId
                    : props.isMyScenariosPage
                    ? list[0].tutorialId
                    : undefined
                }
                openFreemiumMessage={openFreemiumMessage}
              />
              {list.length > 0 ? (
                <>
                  <FreemiumMyCasesTitles
                    widthOfCaseContainer={widthOfCaseContainer}
                    isMyScenariosPage={props.isMyScenariosPage}
                    setSearchList={setSearchList}
                    sortBy={sortBy}
                    sortOption={currentSortOption}
                    sortDirection={currentSortDirection}
                    hasStorageCase={
                      list.filter((item) => item.tutorialId === undefined)
                        .length > 0
                    }
                  />
                </>
              ) : (
                <EmptyMessage
                  usersListWhenNoScenarios={undefined}
                  type={props.itemName}
                />
              )}

              <div className="myCasesListContainer">
                {searchList.map((item, index) => (
                  <FreemiumItemPart
                    key={index}
                    index={index}
                    item={item}
                    getNavigateLink={props.getNavigateLink}
                    isMyScenariosPage={props.isMyScenariosPage}
                    statusDiv={props.statusDiv}
                    renamingIndex={renamingIndex}
                    nameInput={nameInput}
                    setNameInput={setNameInput}
                    handleRenameItem={handleRenameItem}
                    handleDeleteItem={handleDeleteItem}
                    isDeleting={deletingIndex === index}
                    setDeletingIndex={setDeletingIndex}
                    deleteWindowCoordinates={deleteWindowCoordinates}
                    openFreemiumMessage={openFreemiumMessage}
                    showPremiumIcon={
                      (props.isMyScenariosPage &&
                        params.caseId !== FREEMIUM_CASE_ID) ||
                      item.tutorialId !== undefined
                    }
                  />
                ))}
              </div>
              <FreemiumBottomPart
                handleAddNewItem={handleAddNewItem}
                itemName={props.itemName}
                isMyScenariosPage={props.isMyScenariosPage}
                widthOfCaseContainer={widthOfCaseContainer}
                isTutorial={list.length > 0 && list[0].tutorialId !== undefined}
                openFreemiumMessage={openFreemiumMessage}
                showPremiumIcon={showPremiumIcon()}
                showPremiumIconForAdd={showPremiumIconForAdd()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default FreemiumListPageTemplate
