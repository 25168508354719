import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import {
  calculateTickValues,
  getAxisDomain,
  roundNumberTo,
} from '../../../../../../../../services/commonFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import BarSvg from './BarSvg'
import { useEffect } from 'react'
import { getText } from '../../../../../../../../services/textFunctions'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'

type Props = {
  maxProbability: number
  minAmount: number
  maxAmount: number
  pdfPreview?: boolean
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  stepSize: number
  reportWhitelabel?: ReportWhitelabel
  graphData: number[][]
  tooltipData: number[][][]
  expectedValue: number
}

export default function SpotGraphComponent(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  function factor() {
    let arrayOfValues = calculateTickValues(
      props.minAmount,
      props.maxAmount,
      props.expectedValue,
    )
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (roundNumberTo(arrayOfValues[i], 2).toString().length > maxLength) {
        maxLength = roundNumberTo(arrayOfValues[i], 2).toString().length
      }
    }

    let factor = 80
    if (maxLength > 15) {
      factor = 150
    } else if (maxLength > 14) {
      factor = 120
    } else if (maxLength > 10) {
      factor = 110
    } else if (maxLength > 8) {
      factor = 100
    } else if (maxLength > 4) {
      factor = 90
    }
    return factor
  }

  useEffect(() => {
    const axisLabelElement1 = document.getElementById(
      'chart-axis-2-axisLabel-0',
    )
    const axisLabelElement2 = document.getElementById(
      'chart-axis-0-axisLabel-0',
    )
    if (axisLabelElement1) {
      const tspanElement = axisLabelElement1.getElementsByTagName('tspan')[0] // First tspan

      tspanElement.setAttribute('x', '570')
      tspanElement.setAttribute('y', '180')
    }
    if (axisLabelElement2) {
      const tspanElement = axisLabelElement2.getElementsByTagName('tspan')[0] // First tspan

      tspanElement.setAttribute('y', '40')
    }
  }, [
    // eslint-disable-next-line
    document.getElementById('chart-axis-2-axisLabel-0'),
    // eslint-disable-next-line
    document.getElementById('chart-axis-0-axisLabel-0'),
  ])

  function findZeroIndex() {
    return props.tooltipData.findIndex((data) => {
      for (let array of data) {
        if (array[0] === 0) {
          return true
        }
      }
      return false
    })
  }

  function findXaxisLabels(x: number) {
    if (x === props.expectedValue) {
      return getText('title-275', user.settings)
    }
    if (
      x ===
      calculateTickValues(
        props.minAmount,
        props.maxAmount,
        props.expectedValue,
      ).sort((a, b) => (a >= b ? 1 : -1))[0]
    ) {
      x -= 0.0001
    }
    if (
      x ===
      calculateTickValues(
        props.minAmount,
        props.maxAmount,
        props.expectedValue,
      ).sort((a, b) => (a >= b ? -1 : 1))[0]
    ) {
      x += 0.0001
    }

    return `${stringAndRoundBigNumbers(
      x,
      user.settings,
      resultsSettings.settings.roundedResults,
    )}${
      props.pdfPreview &&
      x !==
        calculateTickValues(
          props.minAmount,
          props.maxAmount,
          props.expectedValue,
        ).sort((a, b) => (a >= b ? 1 : -1))[0] &&
      x !==
        calculateTickValues(
          props.minAmount,
          props.maxAmount,
          props.expectedValue,
        ).sort((a, b) => (a >= b ? -1 : 1))[0] &&
      props.resultsFromBackend &&
      props.resultsFromBackend.model_used === 'statistical'
        ? '*'
        : ''
    }`
  }

  return (
    <>
      <VictoryChart
        domain={{
          y: [
            0,
            props.maxProbability < 0.97
              ? roundNumberTo(props.maxProbability + 0.03, 2)
              : roundNumberTo(props.maxProbability, 2),
          ],
          x: getAxisDomain([[props.maxAmount], [props.minAmount]]),
        }}
        data-openreplay-hidden
        containerComponent={
          props.pdfPreview ? (
            <VictoryContainer />
          ) : (
            <VictoryVoronoiContainer
              //id='distributionGraphLabel'
              data-textattribute="label-00"
              labels={(item) => {
                if (props.tooltipData[item.datum.eventKey][0] === undefined) {
                  return ''
                }

                let text = ''
                for (let [index, outcome] of props.tooltipData[
                  item.datum.eventKey
                ].entries()) {
                  if (index !== 0) {
                    text += '\n'
                  }
                  text += `${stringAndRoundBigNumbers(
                    outcome[0],
                    user.settings,
                    resultsSettings.settings.roundedResults,
                  )} ${props.currentSnapshot.currency}\t\t${
                    outcome[1] * 100 > 0 && outcome[1] * 100 < 0.01
                      ? '~ 0'
                      : stringAndRoundBigNumbers(
                          outcome[1] * 100,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          false,
                          true,
                        )
                  }%`
                }

                //data-textattribute="title-249"
                if (props.tooltipData[item.datum.eventKey].length > 1) {
                  text += `\n\n${getText('title-249', user.settings)}: ${
                    item.datum[1] * 100 > 0 && item.datum[1] * 100 < 0.01
                      ? '~ 0'
                      : stringAndRoundBigNumbers(
                          item.datum[1] * 100,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          false,
                          true,
                        )
                  }%`
                }

                return text
              }}
              labelComponent={
                <VictoryTooltip
                  dy={-5}
                  dx={0}
                  id={'distributionBarTooltip'}
                  constrainToVisibleArea
                  style={{
                    fontSize: (props.widthOfDiv / factor()) * 1.2,
                    fontFamily: 'roboto-local',
                    fill: '#201a2d',
                    display: (data: { datum?: number[] }) =>
                      data.datum![2] === 0 ? 'none' : 'inline',
                  }}
                  flyoutPadding={{
                    top: 3,
                    bottom: 3,
                    left: 10,
                    right: 10,
                  }}
                  flyoutStyle={{
                    fill: (data) => (data.datum[0] >= 0 ? 'white' : 'white'),
                    stroke: '#201a2d',
                    strokeWidth: 0.3,
                    alignContent: 'start',
                    display: (data) =>
                      data.datum[2] === 0 ? 'none' : 'inline',
                  }}
                />
              }
            />
          )
        }
        width={props.widthOfDiv}
        height={props.heightOfDiv - 33}
      >
        <VictoryAxis
          label={`${getText('label-17', user.settings).toLowerCase()} (%)`}
          style={{
            axis: { stroke: '#756f6a' },
            grid: { stroke: 'grey', opacity: 0.3 },
            ticks: { stroke: 'grey', size: 2 },
            axisLabel: {
              angle: 0,
              padding: 0,
              fontSize: props.widthOfDiv / factor(),
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).fontFamily ?? 'poppins-local',

              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).color ?? 'black',
            },
            tickLabels: {
              fontSize: props.widthOfDiv / factor(),
              fontFamily: 'roboto-local',
              padding: 5,
              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'roboto',
                ).color ?? 'black',
            },
          }}
          dependentAxis
          tickFormat={(y) => `${Math.round(y * 100)}%`}
        />
        <VictoryBar
          alignment="start"
          data={props.graphData}
          y={1}
          x={0}
          style={{
            data: {
              fill: (data) =>
                data.datum[0] > 0
                  ? '#201a2d'
                  : data.datum[0] === 0
                  ? 'grey'
                  : '#ff703e',
              strokeWidth: 0,
            },
          }}
          dataComponent={
            <BarSvg
              maxAmount={props.maxAmount}
              minAmount={props.minAmount}
              widthOfDiv={props.widthOfDiv}
              sampleData={props.graphData}
              customWidth={3}
              spotGraph
              data-openreplay-hidden
              zeroIndex={findZeroIndex()}
            />
          }
        />

        <VictoryAxis
          label={`${getText('label-107', user.settings)} (${
            props.currentSnapshot.currency
          })`}
          tickValues={calculateTickValues(
            props.minAmount,
            props.maxAmount,
            props.expectedValue,
          )}
          tickFormat={(x) => findXaxisLabels(x)}
          style={{
            axis: { stroke: '#756f6a' },
            ticks: {
              stroke: (tickObject: any) => {
                return tickObject.tickValue === props.expectedValue
                  ? 'black'
                  : 'grey'
              },
              size: 5,
              strokeWidth: (tickObject: any) =>
                tickObject.tickValue === props.expectedValue ? 2 : 1,
            },
            axisLabel: {
              angle: 0,
              fontSize: props.widthOfDiv / factor(),
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).fontFamily ?? 'poppins-local',

              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                ).color ?? 'black',
            },
            tickLabels: {
              fontSize: props.widthOfDiv / factor(),
              angle: -45,
              padding: 1,
              textAnchor: 'end',
              fontFamily: 'roboto-local',
              fontWeight: (data: { [key: string]: any }) =>
                data.tickValue === 0 || data.tickValue === props.expectedValue
                  ? 'bold'
                  : 400,
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'roboto',
                ).color ?? 'black',
            },
          }}
        />
      </VictoryChart>
    </>
  )
}
