import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  deepCloneObject,
  logActivity,
  stringAndRoundTo2Decimals,
} from '../../../services/commonFunctions'
import { AccessRole, UndoRedoType } from '../../../models/enums'
import addSegmentImg from '../../../resources/images/233-add-segment.svg'
import segmentTriangleImg from '../../../resources/images/241-LFDtriangle.svg'
import NewSingleSegment from './NewSingleSegment'
import RangesBarBackground from './SingleSegmentComponents/RangesBarBackground'
import RangesBarHandle from './SingleSegmentComponents/RangesBarHandle'
import { SnapshotSelectorObject } from '../../../models/generalTypes'
import { changeGlobalSnapshot } from '../../../services/changeGlobalSnapshot'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { ScenarioSnapshot } from '../../../models/scenarioSnapshot'
import RangesBarAmount from './SingleSegmentComponents/RangesBarAmount'
import {
  calculateUniqueAwardedAmounts,
  findAbsoluteSegmentHeights,
} from '../../../services/disputedAmountFunctions'
import LimitSegment from './LimitSegment'
import { freemiumState } from '../../../states/FreemiumState'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import { userState } from '../../../states/UserState'
import { getText } from '../../../services/textFunctions'

type Props = {
  totalCounterClaimedAmount: number
  disputedAmount: number
  isFirstLoad: boolean
  setIsFirstLoad: (isFirstLoad: boolean) => void
  pdfPreview?: boolean
  ownRole?: AccessRole
}

export default function LegalCostsDistributionTool(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const user = useRecoilValue(userState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )

  const currentSnapshot = scenarioSnapshot.currentSnapshot as ScenarioSnapshot
  const feeDistributions = currentSnapshot.legalFeesDistribution
  const [tempFeeDistributions, setTempFeeDistributions] =
    useState(feeDistributions)

  const [segmentAddHover, setSegmentAddHover] = useState<number | undefined>(
    undefined,
  )
  const [segmentAmountHover, setSegmentAmountHover] = useState<
    number | undefined
  >(undefined)
  const [segmentEditMode, setSegmentEditMode] = useState<number | undefined>(
    undefined,
  )
  const [previousSegmentHeights, setPreviousSegmentHeights] = useState<
    { segmentIndex: number; top: number; height: number }[] | undefined
  >(undefined)
  const [tempSegmentHeight, setTempSegmentHeight] = useState<
    number | undefined
  >(undefined)
  const [tempSegmentAmount, setTempSegmentAmount] = useState<
    number | undefined
  >(undefined)
  const [absoluteSegmentHeights, setAbsoluteSegmentHeights] = useState<
    { segmentIndex: number; top: number; height: number }[]
  >([])
  const rangesBarHeight = 500

  const [awardedAmounts, setAwardedAmounts] = useState<number[]>([])

  useEffect(() => {
    let tempAwardedAmounts = calculateUniqueAwardedAmounts(
      scenarioSnapshot.currentSnapshot,
    )

    setAwardedAmounts(tempAwardedAmounts)
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.claims])

  useEffect(() => {
    setTempFeeDistributions(feeDistributions)

    // eslint-disable-next-line
  }, [feeDistributions])
  useEffect(() => {
    setAbsoluteSegmentHeights(
      findAbsoluteSegmentHeights(tempFeeDistributions, rangesBarHeight),
    )

    // eslint-disable-next-line
  }, [tempFeeDistributions])

  function handleChangeSegment(action: 'add' | 'remove', index: number) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `rangesBarSegment-${feeDistributions.length - 1 - index}`,
      undoRedoType: UndoRedoType.button,
      value: [action, index],
      key: 'legalFeesDistribution',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)
    if (props.isFirstLoad) {
      //Mixpanel 30 (All)
      logActivity(
        freemium.isFreemium,
        `${action}s a segment in legal costs distribution`,
      )
      props.setIsFirstLoad(false)
    }
  }

  return (
    <>
      <RangesBarBackground
        totalCounterClaimedAmount={props.totalCounterClaimedAmount}
        disputedAmount={props.disputedAmount}
        rangesBarHeight={rangesBarHeight}
      />

      <div className="rangesBarContainer" data-openreplay-obscured>
        {feeDistributions
          .filter(
            (feeDistribution) =>
              feeDistribution !== feeDistributions[feeDistributions.length - 1],
          )
          .reverse()
          .map((singleSegment, index) => (
            <div
              className="rangesBarSegmentContainer"
              key={`rangesBar-${feeDistributions.length - 2 - index}`}
              data-openreplay-obscured
            >
              <div
                className="singleSegmentOuterContainer"
                style={{
                  position: 'absolute',
                  height:
                    absoluteSegmentHeights.length > 0 &&
                    absoluteSegmentHeights[index] !== undefined
                      ? absoluteSegmentHeights[index].height
                      : 0,
                  top:
                    absoluteSegmentHeights.length > 0 &&
                    absoluteSegmentHeights[index] !== undefined
                      ? absoluteSegmentHeights[index].top
                      : 0,
                  zIndex: 1,
                  left: 39,
                }}
              >
                {index === 0 && (
                  <LimitSegment
                    feeDistributions={feeDistributions}
                    singleSegmentIndex={index}
                    totalCounterClaimedAmount={props.totalCounterClaimedAmount}
                    disputedAmount={props.disputedAmount}
                    limitSegment={'top'}
                    //data-textattribute="hover-62, hover-63"
                    awardedTo={
                      props.disputedAmount + props.totalCounterClaimedAmount < 0
                        ? 'hover-63'
                        : 'hover-62'
                    }
                  />
                )}
                {index < feeDistributions.length - 2 && (
                  <NewSingleSegment
                    feeDistributions={feeDistributions}
                    key={`singleSegment${index}`}
                    singleSegmentIndex={feeDistributions.length - 2 - index}
                    totalCounterClaimedAmount={props.totalCounterClaimedAmount}
                    disputedAmount={props.disputedAmount}
                    isFirstLoad={props.isFirstLoad}
                    setIsFirstLoad={props.setIsFirstLoad}
                    ownRole={props.ownRole}
                    absoluteSegmentHeight={absoluteSegmentHeights[index]}
                  />
                )}
                {index === feeDistributions.length - 2 && (
                  <LimitSegment
                    feeDistributions={feeDistributions}
                    singleSegmentIndex={index}
                    totalCounterClaimedAmount={props.totalCounterClaimedAmount}
                    disputedAmount={props.disputedAmount}
                    limitSegment={'bottom'}
                    //data-textattribute="hover-62, hover-63"
                    awardedTo={
                      props.totalCounterClaimedAmount < 0
                        ? 'hover-63'
                        : 'hover-62'
                    }
                  />
                )}
              </div>
              <div
                className="rangesBarSegment"
                id={`rangesBarSegment-${feeDistributions.length - 2 - index}`}
                style={{
                  height:
                    absoluteSegmentHeights.length > 0 &&
                    absoluteSegmentHeights[index] !== undefined
                      ? absoluteSegmentHeights[index].height
                      : 0,
                  top:
                    absoluteSegmentHeights.length > 0 &&
                    absoluteSegmentHeights[index] !== undefined
                      ? absoluteSegmentHeights[index].top
                      : 0,
                }}
                onMouseEnter={() => {
                  if (
                    tempSegmentHeight === undefined &&
                    index !== feeDistributions.length - 2 &&
                    feeDistributions.length < 14
                  ) {
                    setSegmentAddHover(index)
                  }
                }}
                onMouseOver={(e: any) => {
                  if (
                    e.target.className.includes('rangeBarFakeHandle') ||
                    e.target.className.includes('rangeBarHandle') ||
                    e.target.className.includes('rangeBarAmountText')
                  ) {
                    setSegmentAddHover(undefined)
                  }
                }}
                onMouseLeave={() => setSegmentAddHover(undefined)}
                data-openreplay-obscured
              >
                <img
                  src={segmentTriangleImg}
                  alt="segmentTriangleImg"
                  className="segmentTriangleImg"
                  id={`segmentTriangleImg-${
                    feeDistributions.length - 2 - index
                  }`}
                />
                {segmentAddHover === index && (
                  <div
                    className="addSegmentContainer"
                    id={`addSegmentContainer-${
                      feeDistributions.length - 2 - index
                    }`}
                    data-openreplay-obscured
                  >
                    <img
                      src={addSegmentImg}
                      alt="addSegmentImg"
                      className="addSegmentImg"
                      id={`addSegmentImg-${
                        feeDistributions.length - 2 - index
                      }`}
                      onClick={() => handleChangeSegment('add', index)}
                    />
                  </div>
                )}
                <RangesBarAmount
                  previousSegmentHeights={previousSegmentHeights}
                  totalCounterClaimedAmount={props.totalCounterClaimedAmount}
                  disputedAmount={props.disputedAmount}
                  tempSegmentHeight={tempSegmentHeight}
                  tempSegmentAmount={tempSegmentAmount}
                  handleChangeSegment={handleChangeSegment}
                  rangeBarIndex={index}
                  singleSegment={singleSegment}
                  segmentAmountHover={segmentAmountHover}
                  setSegmentAmountHover={setSegmentAmountHover}
                  segmentEditMode={segmentEditMode}
                  setSegmentEditMode={setSegmentEditMode}
                />

                {index !== 0 && index !== feeDistributions.length - 2 && (
                  <RangesBarHandle
                    previousSegmentHeights={previousSegmentHeights}
                    setPreviousSegmentHeights={setPreviousSegmentHeights}
                    absoluteSegmentHeights={absoluteSegmentHeights}
                    setAbsoluteSegmentHeights={setAbsoluteSegmentHeights}
                    rangesBarHeight={rangesBarHeight}
                    setTempSegmentHeight={setTempSegmentHeight}
                    setTempSegmentAmount={setTempSegmentAmount}
                    rangeBarIndex={index}
                    disputedAmount={props.disputedAmount}
                    totalCounterClaimedAmount={props.totalCounterClaimedAmount}
                    tempSegmentAmount={tempSegmentAmount}
                    tempFeeDistributions={tempFeeDistributions}
                    setTempFeeDistributions={setTempFeeDistributions}
                  />
                )}
              </div>
            </div>
          ))}
        {awardedAmounts.length <= 10 &&
          awardedAmounts.map((amount, index) => {
            return (
              <div key={index}>
                <WhiteTooltip
                  textAttribute={'tooltip-70'}
                  id={`awardedAmountLCDIndicator-${index}-tooltip`}
                  title={`${getText(
                    'tooltip-70',
                    user.settings,
                  )} ${stringAndRoundTo2Decimals(amount, user.settings)} ${
                    scenarioSnapshot.currentSnapshot.currency
                  }`}
                >
                  <div
                    className="awardedAmountLCDIndicator"
                    id={`awardedAmountLCDIndicator-${index}`}
                    key={index}
                    style={{
                      bottom:
                        amount - props.totalCounterClaimedAmount ===
                        props.disputedAmount
                          ? rangesBarHeight - 8
                          : amount - props.totalCounterClaimedAmount === 0
                          ? -4.5
                          : ((amount - props.totalCounterClaimedAmount) /
                              props.disputedAmount) *
                              rangesBarHeight -
                            8,
                    }}
                  />
                </WhiteTooltip>
              </div>
            )
          })}
      </div>
    </>
  )
}
