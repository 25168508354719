import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { User } from '../../../../../../../../models/user'
import { getText } from '../../../../../../../../services/textFunctions'
import {
  findDisputedAmount,
  findTotalClaimedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../../../../services/disputedAmountFunctions'
import {
  calculateZOPA,
  removeUnderscore,
  stringAndRoundTo2Decimals,
} from '../../../../../../../../services/commonFunctions'
import {
  snapshotHasEnforcementRisk,
  getStyleFromWhitelabel,
  snapshotHasIncurredCosts,
  snapshotHasLegalCosts,
} from '../../../../../../../../services/reportFunctions'
import './all_fonts'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { useEffect, useState } from 'react'
import LegalCostsPreviewComponentDocument from './LegalCostsPreviewComponentDocument'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { convertDateTimeToLocal } from '../../../../../../../../services/dateFunctions'
import {
  AvailableLanguages,
  translateTypeOfInstance,
} from '../../../../../../../../models/enums'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  visibleElements: ExecutiveSummaryVisibleElements
  user: User
  reportWhitelabel?: ReportWhitelabel
  zopaGraphImg: string
  legalCostsDescriptions: ReportLegalCostsType
  roundedResults: boolean
}

export default function TextForExecutiveSummaryDocument(props: Props) {
  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  const [zopaExists, setZopaExists] = useState(true)

  const oneOutcome =
    props.resultsFromBackend.minmax.max.financial_outcome ===
    props.resultsFromBackend.minmax.min.financial_outcome

  const clientSettlementAmount =
    props.resultsFromBackend.settlement_amount ??
    props.resultsFromBackend.value_of_claim

  useEffect(() => {
    if (props.resultsFromBackend.reversed) {
      const calculationZOPA = calculateZOPA(props.resultsFromBackend, 'old')
      if (calculationZOPA === 'noZOPA') {
        setZopaExists(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  const styles = StyleSheet.create({
    executiveSummaryTextContainer: {
      marginTop: '-2px',
    },
    executiveSummaryParagraph: {
      marginTop: '12px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      lineHeight: 1.25,
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '395px',
    },
    executiveSummaryParagraphFirst: {
      marginTop: '0px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      lineHeight: 1.25,
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '395px',
    },
    executiveSummaryParagraphLast: {
      position: 'relative',
      marginBottom: '13px',
      marginTop: '16px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      lineHeight: 1.25,
      maxWidth: '395px',
    },
    valueOfCaseContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '430px',
    },
    zopaContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '430px',
    },
    valueOfCaseImg: {
      width: '25px',
    },
    valueOfCaseText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      marginLeft: '0px',
      marginTop: '7px',
    },
    paragraph: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      maxWidth: '450px',
      fontSize: '8px',
    },
    paragraphNumber: {
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      maxWidth: '450px',
      fontSize: '8px',
    },
    sup: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      fontSize: '4px',
      marginBottom: '5px',
    },
    span: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
      fontSize: '8px',
    },
    spanNumber: {
      fontFamily: 'Roboto-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'roboto',
        true,
      ).color,
      fontSize: '7.7px',
    },
    executiveSummarySideTitleContainer: {
      maxWidth: '90px',
      minWidth: '90px',
    },
    executiveSummarySideTitleFirst: {
      paddingTop: '7px',
      maxWidth: '70px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Bold',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
    },
    executiveSummarySideTitle: {
      maxWidth: '70px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Bold',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).color,
    },
    zopaGraphImg: {
      width: '470px',
      marginTop: 8,
      marginLeft: -65,
    },
  })

  const courtName =
    props.currentSnapshot.court !== 'Other'
      ? removeUnderscore(
          translateTypeOfInstance(
            props.currentSnapshot.court!,
            AvailableLanguages.english,
            props.user.settings.language,
          ).toLowerCase(),
        )
      : //data-textattribute="description-193"
        getText('description-193', props.user.settings)

  return (
    <View style={styles.executiveSummaryTextContainer} wrap={false}>
      {props.visibleElements.valueOfCase ? (
        <View style={styles.executiveSummaryParagraphFirst}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitleFirst}
              data-textattribute="title-235"
            >
              {getText('title-235', props.user.settings)}
            </Text>
          </View>
          <View style={styles.valueOfCaseContainer}>
            <Text
              style={styles.valueOfCaseText}
              id="valueOfCaseTextForPdf"
              data-textaatribute="description-192a, description-192b, description-192c, description-192d, description-192e"
            >
              {getText('description-192a', props.user.settings)}{' '}
              <Text style={styles.span}>
                {getText('description-192b', props.user.settings)}{' '}
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.value_of_claim,
                  props.user.settings,
                  props.roundedResults,
                )}
                {props.resultsFromBackend &&
                props.resultsFromBackend.model_used === 'statistical'
                  ? '*'
                  : ''}{' '}
                {props.currentSnapshot.currency}{' '}
                {getText(
                  'description-192c',
                  props.user.settings,
                  partiesFormat,
                )}
              </Text>
              {getText('description-192d', props.user.settings)} {courtName}
              {getText('description-192e', props.user.settings)}
            </Text>
          </View>
        </View>
      ) : null}
      {props.visibleElements.settlement ? (
        <View style={{ ...styles.executiveSummaryParagraph }}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-241"
            >
              {getText('title-241', props.user.settings)}
            </Text>
          </View>
          <View style={styles.valueOfCaseContainer}>
            <Text>
              {snapshotHasLegalCosts(props.currentSnapshot) ? (
                snapshotHasIncurredCosts(props.currentSnapshot) ? (
                  <>
                    <Text
                      style={styles.span}
                      data-textattribute="description-194a"
                    >
                      {getText(
                        'description-194a',
                        props.user.settings,
                        partiesFormat,
                      )}{' '}
                      {stringAndRoundBigNumbers(
                        clientSettlementAmount,
                        props.user.settings,
                        props.roundedResults,
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.currentSnapshot.currency}.
                    </Text>
                    <Text
                      style={styles.paragraph}
                      data-textattribute=",description-194b,description-194c, description-194d"
                    >
                      {getText('description-194b', props.user.settings)}
                      {props.currentSnapshot.hasInsurance
                        ? ` ${getText('description-194c', props.user.settings)}`
                        : ''}{' '}
                      {getText('description-194d', props.user.settings)}{' '}
                      {courtName}.
                    </Text>
                  </>
                ) : (
                  <Text data-textattribute="description-196">
                    {getText(
                      'description-196',
                      props.user.settings,
                      partiesFormat,
                    )}
                  </Text>
                )
              ) : (
                <Text data-textattribute="description-198">
                  {getText(
                    'description-198',
                    props.user.settings,
                    partiesFormat,
                  )}
                </Text>
              )}
            </Text>
          </View>
        </View>
      ) : null}
      {props.visibleElements.zopa === true &&
      props.resultsFromBackend.reversed !== undefined &&
      !oneOutcome ? (
        <View style={{ ...styles.executiveSummaryParagraph }}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={{ ...styles.executiveSummarySideTitle, width: 40 }}
              data-textattribute="title-251"
            >
              <Text>{getText('title-251', props.user.settings)}</Text>
            </Text>
          </View>
          <View style={styles.zopaContainer}>
            <Text
              data-textattribute="description-202"
              style={{ marginBottom: '2px' }}
            >
              {getText('description-202', props.user.settings, partiesFormat)}
            </Text>

            {props.resultsFromBackend.reversed && (
              <View>
                <View>
                  <Text style={{ marginBottom: '2px' }}>
                    {getText(
                      'description-203c',
                      props.user.settings,
                      partiesFormat,
                    )}{' '}
                    {clientSettlementAmount >= 0
                      ? getText('description-204a', props.user.settings)
                      : getText('description-204b', props.user.settings)}{' '}
                    <Text>
                      {stringAndRoundBigNumbers(
                        clientSettlementAmount,
                        props.user.settings,
                        props.roundedResults,
                        true,
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.currentSnapshot.currency}
                    </Text>{' '}
                    {clientSettlementAmount >= 0
                      ? getText('description-206a', props.user.settings)
                      : getText('description-206b', props.user.settings)}{' '}
                    {getText(
                      'description-207a',
                      props.user.settings,
                      partiesFormat,
                    )}{' '}
                    {getText('description-208', props.user.settings)}
                  </Text>
                </View>
                <View>
                  <Text style={{ marginBottom: '2px' }}>
                    {getText(
                      'description-209',
                      props.user.settings,
                      partiesFormat,
                    )}{' '}
                    {props.resultsFromBackend.reversed!.settlement_amount >= 0
                      ? getText('description-204a', props.user.settings)
                      : getText('description-204b', props.user.settings)}{' '}
                    <Text>
                      {stringAndRoundBigNumbers(
                        props.resultsFromBackend.reversed!.settlement_amount,
                        props.user.settings,
                        props.roundedResults,
                        true,
                      )}
                      {props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''}{' '}
                      {props.currentSnapshot.currency}
                    </Text>{' '}
                    {props.resultsFromBackend.reversed!.settlement_amount >= 0
                      ? getText('description-206a', props.user.settings)
                      : getText('description-206b', props.user.settings)}{' '}
                    {getText(
                      'description-207b',
                      props.user.settings,
                      partiesFormat,
                    )}{' '}
                    {getText('description-208', props.user.settings)}
                  </Text>
                </View>
                {!zopaExists && (
                  <Text>{getText('description-210', props.user.settings)}</Text>
                )}
                <Text>
                  {getText(
                    'description-209b',
                    props.user.settings,
                    partiesFormat,
                  )}
                </Text>
              </View>
            )}
            <Image src={props.zopaGraphImg} style={styles.zopaGraphImg} />
          </View>
        </View>
      ) : null}
      {props.visibleElements.bestWorstScenario ? (
        <View
          style={styles.executiveSummaryParagraph}
          id="bestScenarioParagraphForPdf"
          data-textaatribute="description-59a,description-59b,description-59c,description-59d,description-59e, description-59f"
        >
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-236"
            >
              {getText('title-236', props.user.settings)}
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              {getText('description-59a', props.user.settings)}{' '}
              <Text style={styles.span}>
                {getText('description-59b', props.user.settings)}
              </Text>{' '}
              {getText('description-59c', props.user.settings)}{' '}
              {getText('description-59f', props.user.settings)}{' '}
              <Text style={styles.spanNumber}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.max.financial_outcome,
                  props.user.settings,
                  props.roundedResults,
                )}{' '}
                <Text style={styles.span}>
                  {props.currentSnapshot.currency}
                </Text>
              </Text>{' '}
              {getText('description-59g', props.user.settings, partiesFormat)}
            </Text>

            <Text
              style={styles.paragraph}
              id="worstScenarioParagraphForPdf"
              data-textaatribute="description-60a,description-60b,description-60c,description-60d,description-60e, description-60f"
            >
              {getText('description-60a', props.user.settings)}{' '}
              <Text style={styles.span}>
                {getText('description-60b', props.user.settings)}
              </Text>{' '}
              {getText('description-60c', props.user.settings)}{' '}
              {getText('description-60f', props.user.settings)}{' '}
              <Text style={styles.spanNumber}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.min.financial_outcome,
                  props.user.settings,
                  props.roundedResults,
                )}{' '}
                <Text style={styles.span}>
                  {props.currentSnapshot.currency}
                </Text>
              </Text>{' '}
              {getText('description-60g', props.user.settings, partiesFormat)}
            </Text>
          </View>
        </View>
      ) : null}
      {props.visibleElements.requestedAmounts ? (
        <View style={styles.executiveSummaryParagraph}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-237"
            >
              {getText('title-237', props.user.settings)}
            </Text>
          </View>
          <View>
            <Text
              style={styles.paragraph}
              id="claimedAmountParagraphForPdf"
              data-textaatribute="description-47"
            >
              {getText('description-47', props.user.settings, partiesFormat)}{' '}
              <Text style={styles.spanNumber}>
                {stringAndRoundTo2Decimals(
                  findTotalClaimedAmount(props.currentSnapshot),
                  props.user.settings,
                )}{' '}
                <Text style={styles.span}>
                  {props.currentSnapshot.currency}
                </Text>
              </Text>
              .<Text style={styles.sup}>1</Text>
            </Text>
            <Text
              style={styles.paragraph}
              id="counterClaimedAmountParagraphForPdf"
              data-textaatribute="description-48"
            >
              {getText('description-48', props.user.settings, partiesFormat)}{' '}
              <Text style={styles.spanNumber}>
                {stringAndRoundTo2Decimals(
                  Math.abs(
                    findTotalCounterClaimedAmount(props.currentSnapshot),
                  ),
                  props.user.settings,
                )}{' '}
                <Text style={styles.span}>
                  {props.currentSnapshot.currency}
                </Text>
              </Text>
              .
              <View style={styles.sup}>
                <Text style={styles.sup}>2</Text>
              </View>
            </Text>
            <Text
              style={styles.paragraph}
              id="disputedAmountParagraphForPdf"
              data-textaatribute="description-49"
            >
              {getText('description-49', props.user.settings)}{' '}
              <Text style={styles.spanNumber}>
                {stringAndRoundTo2Decimals(
                  findDisputedAmount(props.currentSnapshot),
                  props.user.settings,
                )}{' '}
                <Text style={styles.span}>
                  {props.currentSnapshot.currency}
                </Text>
              </Text>
              .<Text style={styles.sup}>(1+2)</Text>
            </Text>
          </View>
        </View>
      ) : null}
      {props.visibleElements.proceedings &&
      (props.currentSnapshot.firstTrialDate ||
        props.currentSnapshot.secondTrialDate) ? (
        <View style={styles.executiveSummaryParagraph}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-238"
            >
              {getText('title-238', props.user.settings)}
            </Text>
          </View>
          <View>
            {props.currentSnapshot.firstTrialDate && (
              <Text
                style={styles.paragraph}
                id="firstTrialParagraphForPdf"
                data-textaatribute={
                  props.currentSnapshot.probSecondTrial! > 0
                    ? 'description-50'
                    : 'description-51'
                }
              >
                {props.currentSnapshot.probSecondTrial! > 0
                  ? getText('description-50', props.user.settings)
                  : getText('description-51', props.user.settings)}{' '}
                <Text style={styles.span}>
                  {convertDateTimeToLocal(
                    props.currentSnapshot.firstTrialDate!,
                    props.user.settings,
                    true,
                  )}
                </Text>
                .
              </Text>
            )}
            {props.currentSnapshot.probSecondTrial! > 0 &&
            props.currentSnapshot.secondTrialDate ? (
              <Text
                style={styles.paragraph}
                id="secondTrialParagraphForPdf"
                data-textaatribute="description-55a,description-55b"
              >
                {getText('description-55a', props.user.settings)}{' '}
                {stringAndRoundTo2Decimals(
                  props.currentSnapshot.probSecondTrial ?? 0,
                  props.user.settings,
                )}
                {getText('description-55b', props.user.settings)}{' '}
                <Text style={styles.span}>
                  {convertDateTimeToLocal(
                    props.currentSnapshot.secondTrialDate!,
                    props.user.settings,
                    true,
                  )}
                </Text>
                .
              </Text>
            ) : null}
          </View>
        </View>
      ) : null}
      {props.visibleElements.legalCosts ? (
        <View style={styles.executiveSummaryParagraph}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-239"
            >
              {getText('title-239', props.user.settings)}
            </Text>
          </View>
          <LegalCostsPreviewComponentDocument
            currentSnapshot={props.currentSnapshot}
            user={props.user}
            reportWhitelabel={props.reportWhitelabel}
            legalCostsDescriptions={props.legalCostsDescriptions}
          />
        </View>
      ) : null}
      {snapshotHasEnforcementRisk(props.currentSnapshot) &&
      props.visibleElements.enforcement ? (
        <View style={styles.executiveSummaryParagraph}>
          <View style={styles.executiveSummarySideTitleContainer}>
            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title-240a"
            >
              {getText('title-240a', props.user.settings)}
            </Text>

            <Text
              style={styles.executiveSummarySideTitle}
              data-textattribute="title240b"
            >
              {getText('title-240b', props.user.settings)}
            </Text>
          </View>
          <View>
            <Text
              style={styles.paragraph}
              id="enforcementParagraphForPDf"
              data-textaatribute="description-58a,description-58b,description-58c,description-58d"
            >
              {getText('description-58a', props.user.settings, partiesFormat)}{' '}
              <Text style={styles.span}>
                {props.currentSnapshot.bankruptcyPercentage}
                {getText('description-58b', props.user.settings)}{' '}
              </Text>
              {getText('description-58c', props.user.settings, partiesFormat)}{' '}
              <Text style={styles.span}>
                {getText('description-58d', props.user.settings)}
              </Text>
              .
            </Text>
          </View>
        </View>
      ) : null}
      {/* {props.visibleElements.possibleOutcomes ? (
        <View style={styles.eReportTextParagraph}>
          {props.resultsFromBackend &&
          props.resultsFromBackend.model_used === 'statistical' ? (
            <Text
              style={styles.paragraph}
              id="possibleOutcomesParagraphForPdf"
              data-textaatribute="description-175a, description-175b, description-175c, description-175d,description-175e"
            >
              {getText('description-175a', props.user.settings)}{' '}
              <Text style={styles.span}>
                {getText('description-175b', props.user.settings)}{' '}
                {stringAndRoundTo2Decimals(
                  props.resultsFromBackend.no_of_possible_outcomes,
                  props.user.settings,
                )}{' '}
                {getText('description-175c', props.user.settings)}
              </Text>{' '}
              {getText('description-175d', props.user.settings)}{' '}
              {stringAndRoundTo2Decimals(
                props.resultsFromBackend.no_of_outcomes,
                props.user.settings,
              )}{' '}
              {getText('description-175e', props.user.settings)}
            </Text>
          ) : (
            <Text
              style={styles.paragraph}
              id="possibleOutcomesParagraphForPdf"
              data-textaatribute="description-45a,description-45b"
            >
              {getText('description-45a', props.user.settings)}
              <Text style={styles.span}>
                {' '}
                {stringAndRoundTo2Decimals(
                  props.resultsFromBackend.no_of_possible_outcomes,
                  props.user.settings,
                )}{' '}
                {getText('description-45b', props.user.settings)}
              </Text>
            </Text>
          )}
        </View>
      ) : null} */}

      {props.visibleElements.valueOfCase &&
      props.resultsFromBackend &&
      props.resultsFromBackend.model_used === 'statistical' ? (
        <View
          style={styles.executiveSummaryParagraph}
          id="asteriskParagraphForPdf"
        >
          <Text
            style={styles.paragraph}
            id="asteriskParagraphForPdfText"
            data-textaatribute="description-176"
          >
            {getText('description-176', props.user.settings)}{' '}
            <Text style={styles.paragraphNumber}>
              {stringAndRoundTo2Decimals(
                props.resultsFromBackend.difference_from_value ?? 0,
                props.user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </Text>
          </Text>
        </View>
      ) : null}
    </View>
  )
}
