import {
  ResultsObject,
  ResultsSettings,
} from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'

import { Text, View, StyleSheet } from '@react-pdf/renderer'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { User } from '../../../../../../../../models/user'
import { getText } from '../../../../../../../../services/textFunctions'
import './all_fonts'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import ClaimsAndLegalCostsDocument from './executiveSummary3DocumentComponents/ClaimsAndLegalCostsDocument'
import ExpectedValueDocument from './executiveSummary3DocumentComponents/ExpectedValueDocument'
import BestWorstScenarioDocument from './executiveSummary3DocumentComponents/BestWorstScenarioDocument'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import ZopaDocument from './executiveSummary3DocumentComponents/ZopaDocument'
import { stringAndRoundTo2Decimals } from '../../../../../../../../services/commonFunctions'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  visibleElements: ExecutiveSummaryVisibleElements
  user: User
  reportWhitelabel?: ReportWhitelabel
  zopaGraphImg: string
  legalCostsDescriptions: ReportLegalCostsType
  resultsSettings: ResultsSettings
}

export default function ExecutiveSummary3Document(props: Props) {
  const oneOutcome =
    props.resultsFromBackend.minmax.max.financial_outcome ===
    props.resultsFromBackend.minmax.min.financial_outcome

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  const styles = StyleSheet.create({
    executiveSummaryLegendContainer: {
      marginTop: '20px',
    },
    sup: {
      fontSize: '4.5px',
      position: 'absolute',
      top: '0px',
      left: '-2px',
      color: '#8b8b8b',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
    },
    legendText: {
      fontSize: '6.5px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
    },
    legendTextNumber: {
      fontSize: '6px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontFamily: 'Roboto-Light',
    },
  })

  return (
    <>
      <View wrap={true}>
        {props.visibleElements.claimsAndLegalCosts && (
          <ClaimsAndLegalCostsDocument
            user={props.user}
            currentSnapshot={props.currentSnapshot}
            legalCostsDescriptions={props.legalCostsDescriptions}
            reportWhitelabel={props.reportWhitelabel}
            resultsSettings={props.resultsSettings}
          />
        )}
        {props.visibleElements.expectedValue && (
          <ExpectedValueDocument
            user={props.user}
            currentSnapshot={props.currentSnapshot}
            reportWhitelabel={props.reportWhitelabel}
            resultsFromBackend={props.resultsFromBackend}
            firstElement={props.visibleElements.claimsAndLegalCosts === false}
            resultsSettings={props.resultsSettings}
          />
        )}
        {props.visibleElements.newBestWorstScenario && (
          <BestWorstScenarioDocument
            user={props.user}
            currentSnapshot={props.currentSnapshot}
            reportWhitelabel={props.reportWhitelabel}
            resultsFromBackend={props.resultsFromBackend}
            resultsSettings={props.resultsSettings}
            firstElement={
              props.visibleElements.expectedValue === false &&
              props.visibleElements.claimsAndLegalCosts === false
            }
          />
        )}
        {props.visibleElements.newZopa &&
          props.resultsFromBackend.reversed !== undefined &&
          !oneOutcome && (
            <ZopaDocument
              user={props.user}
              reportWhitelabel={props.reportWhitelabel}
              zopaGraphImg={props.zopaGraphImg}
              firstElement={
                props.visibleElements.expectedValue === false &&
                props.visibleElements.claimsAndLegalCosts === false &&
                props.visibleElements.newBestWorstScenario === false
              }
            />
          )}
        <View style={styles.executiveSummaryLegendContainer}>
          {props.visibleElements &&
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
            ] && (
              <View>
                <Text style={styles.sup}>1</Text>
                <Text style={styles.legendText}>
                  {' '}
                  {getText('description-257', props.user.settings)}
                </Text>
              </View>
            )}
          {props.visibleElements &&
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
            ] && (
              <View>
                <Text style={styles.sup}>2</Text>
                <Text style={styles.legendText}>
                  {' '}
                  {getText('description-258', props.user.settings)}
                </Text>
              </View>
            )}

          {props.visibleElements &&
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.newBestWorstScenario
            ] && (
              <View>
                <Text style={styles.sup}>3</Text>
                <Text style={styles.legendText}>
                  {' '}
                  {getText(
                    'description-259',
                    props.user.settings,
                    partiesFormat,
                  )}
                </Text>
              </View>
            )}
          {props.resultsFromBackend &&
            props.resultsFromBackend.model_used === 'statistical' && (
              <Text style={styles.legendText}>
                {getText('description-176', props.user.settings)}{' '}
                <Text style={styles.legendTextNumber}>
                  {stringAndRoundTo2Decimals(
                    props.resultsFromBackend.difference_from_value ?? 0,
                    props.user.settings,
                  )}{' '}
                  {props.currentSnapshot.currency}.{' '}
                </Text>
              </Text>
            )}
        </View>
      </View>
    </>
  )
}
