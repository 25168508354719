import englishFlagImg from '../../../resources/images/321-english.svg'
import norwegianFlagImg from '../../../resources/images/319-norway.svg'
import danishFlagImg from '../../../resources/images/322-danish.svg'
import swedishFlagImg from '../../../resources/images/320-sweden.svg'
import { useRecoilState } from 'recoil'
import { userState } from '../../../states/UserState'
import { deepCloneObject, logActivity } from '../../../services/commonFunctions'
import { User } from '../../../models/user'
import { saveUserSettings } from '../../../services/requests'
import { AvailableLanguages } from '../../../models/enums'
import { useEffect } from 'react'

type Props = {
  availableLanguages: AvailableLanguages[]
  languageDropdownOn: boolean
  setLanguageDropdownOn: (languageDropdownOn: boolean) => void
}

export default function ChangeLanguage(props: Props) {
  const [user, setUser] = useRecoilState(userState)

  useEffect(() => {
    document.addEventListener('click', (e: any) => closeDropdown(e))
    return () => {
      document.removeEventListener('click', (e: any) => closeDropdown(e))
    }
    // eslint-disable-next-line
  }, [])

  function closeDropdown(e: any) {
    if (e.target.id.includes('changeLanguage')) return
    props.setLanguageDropdownOn(false)
  }

  const languageImgs = {
    [AvailableLanguages.norwegian]: norwegianFlagImg,
    [AvailableLanguages.english]: englishFlagImg,
    [AvailableLanguages.swedish]: swedishFlagImg,
    [AvailableLanguages.danish]: danishFlagImg,
  }
  const languageCode = {
    [AvailableLanguages.norwegian]: 'NO',
    [AvailableLanguages.english]: 'EN',
    [AvailableLanguages.swedish]: 'SV',
    [AvailableLanguages.danish]: 'DA',
  }
  const languageTitles = {
    [AvailableLanguages.norwegian]: 'Norsk (BETA)',
    [AvailableLanguages.swedish]: 'Svenska (BETA)',
    [AvailableLanguages.english]: 'English',
    [AvailableLanguages.danish]: 'Dansk (BETA)',
  }

  function changeLanguage(language: AvailableLanguages) {
    let tempUser = deepCloneObject(user) as User
    tempUser.settings.language = language
    setUser(tempUser)
    saveUserSettings(tempUser.settings)
    logActivity(false, `Changed language to '${language}'`)
    setTimeout(() => {
      props.setLanguageDropdownOn(false)
    }, 20)
  }

  return (
    <div
      className="changeLanguageContainer"
      id="changeLanguageContainer"
      onClick={() => props.setLanguageDropdownOn(true)}
    >
      <img
        src={languageImgs[user.settings.language]}
        alt="languageFlagImg"
        className="languageFlagImg"
        id="changeLanguageCurrentImg"
      />
      <p className="languagePrefix" id="changeLanguageCurrentLanguagePrefix">
        {languageCode[user.settings.language]}
      </p>

      {props.languageDropdownOn && (
        <div
          className="languageDropdownContainer"
          id="changeLanguageDropdownContainer"
        >
          {props.availableLanguages.map((language) => {
            return (
              <div
                className="languageDropdownItem"
                id={`changeLanguageDropdownItem${language}`}
                key={language}
                onClick={() => {
                  changeLanguage(language)
                }}
              >
                <img
                  src={languageImgs[language]}
                  alt="languageFlagImg"
                  className="languageFlagImg"
                  id={`changeLanguageFlagImg${language}`}
                />
                <p
                  className="languagePrefix"
                  id={`changeLanguagePrefix${language}`}
                >
                  {languageTitles[language]}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
