import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getText } from '../../../../../../../../../services/textFunctions'
import { stringAndRoundBigNumbers } from '../../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  ResultsObject,
  ResultsSettings,
} from '../../../../../../../../../models/generalTypes'
import { calculateValuesForBestWorst } from '../../../../../../../../../services/disputedAmountFunctions'
import {
  getStyleFromWhitelabel,
  snapshotHasIncurredCosts,
  snapshotHasLegalCosts,
} from '../../../../../../../../../services/reportFunctions'
import {
  findOwnLegalCostsForOutcome,
  includedIncurredCosts,
  resultsNotZero,
} from '../../../../../../../../../services/resultsFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  user: User
  currentSnapshot: ScenarioSnapshot
  reportWhitelabel?: ReportWhitelabel
  resultsFromBackend: ResultsObject
  resultsSettings: ResultsSettings
  firstElement: boolean
}

export default function BestWorstScenarioDocument(props: Props) {
  const styles = StyleSheet.create({
    bestWorstScenarioContainer: {
      marginTop: props.firstElement ? 20 : 30,
    },
    bestWorstScenarioTitle: {
      fontSize: '11px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    bestWorstScenarioProbabilitiesContaier: {},
    scenarioProbability: {},
    scenarioProbabilityText: {
      fontSize: '8px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
    },
    span: {
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontSize: '8px',
      fontFamily: 'Roboto-Light',
    },
    sup: {
      fontSize: '4px',
      position: 'absolute',
      top: '-1px',
      left: '142px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      fontFamily: 'Roboto',
    },
    bestWorstScenarioTableContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3px',
      border: '0.8px',
      borderColor: '#8b8b8b',
      borderRadius: '3px',
      width: '420px',
      padding: '2px 5px 4px 5px',
    },
    bestWorstScenarioTableRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginTop: '0.5px',
      marginBottom: '0.5px',
      position: 'relative',
    },

    column1: {
      width: '150px',
      fontSize: '8px',
      textAlign: 'right',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).color ?? '#8b8b8b',
      position: 'relative',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    column2: {
      width: '140px',
      fontSize: '8px',
      textAlign: 'right',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.lightItalic,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light-Italic',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    column2Value: {
      width: '140px',
      fontSize: '8px',
      textAlign: 'right',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    column3: {
      width: '140px',
      fontSize: '8px',
      textAlign: 'right',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.lightItalic,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light-Italic',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    column3Value: {
      width: '140px',
      fontSize: '8px',
      textAlign: 'right',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'roboto',
        true,
      ).color,
    },
    financialOutcome: {},
    financialOutcomeTextContainer: {
      alignSelf: 'flex-end',
    },
    financialOutcomeText: {
      fontSize: '9px',
      fontFamily: 'Roboto-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
  })

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  function getTextForProbability(type: 'best' | 'worst') {
    const notZeroResults = resultsNotZero(props.resultsFromBackend)
    if (
      props.resultsFromBackend.result.graph.length < 2 ||
      notZeroResults === false
    ) {
      return <></>
    }
    const {
      amountForBest,
      singleAmountForBest,
      probabilityForBest,
      amountForWorst,
      singleAmountForWorst,
      probabilityForWorst,
    } = calculateValuesForBestWorst(props.resultsFromBackend)
    if (type === 'best') {
      return (
        <Text style={styles.scenarioProbabilityText}>
          <Text style={styles.span}>
            {stringAndRoundBigNumbers(
              probabilityForBest,
              props.user.settings,
              props.resultsSettings.settings.roundedResults,
              true,
            )}
          </Text>
          {getText('description-271', props.user.settings)}{' '}
          {amountForBest +
            includedIncurredCosts(
              'client',
              'both',
              props.resultsSettings.settings.includeIncurredCosts,
              props.currentSnapshot,
            ) >=
          0
            ? `${getText('description-272', props.user.settings)} ${
                singleAmountForBest === false
                  ? `${getText('description-274', props.user.settings)} `
                  : ' '
              }`
            : `${getText('description-273', props.user.settings)} ${
                singleAmountForBest === false
                  ? `${getText('description-275', props.user.settings)} `
                  : ' '
              }`}
          <Text style={styles.span}>
            {stringAndRoundBigNumbers(
              amountForBest +
                includedIncurredCosts(
                  'client',
                  'both',
                  props.resultsSettings.settings.includeIncurredCosts,
                  props.currentSnapshot,
                ),
              props.user.settings,
              props.resultsSettings.settings.roundedResults,
              true,
            )}
          </Text>{' '}
          {props.currentSnapshot.currency}
        </Text>
      )
    } else {
      return (
        <Text style={styles.scenarioProbabilityText}>
          <Text style={styles.span}>
            {stringAndRoundBigNumbers(
              probabilityForWorst,
              props.user.settings,
              props.resultsSettings.settings.roundedResults,
              true,
            )}
          </Text>
          {getText('description-271', props.user.settings)}{' '}
          {amountForWorst +
            includedIncurredCosts(
              'client',
              'both',
              props.resultsSettings.settings.includeIncurredCosts,
              props.currentSnapshot,
            ) >=
          0
            ? `${getText('description-272', props.user.settings)} ${
                singleAmountForWorst === false
                  ? `${getText('description-275', props.user.settings)} `
                  : ' '
              }`
            : `${getText('description-273', props.user.settings)} ${
                singleAmountForWorst === false
                  ? `${getText('description-274', props.user.settings)} `
                  : ' '
              }`}
          <Text style={styles.span}>
            {stringAndRoundBigNumbers(
              amountForWorst +
                includedIncurredCosts(
                  'client',
                  'both',
                  props.resultsSettings.settings.includeIncurredCosts,
                  props.currentSnapshot,
                ),
              props.user.settings,
              props.resultsSettings.settings.roundedResults,
              true,
            )}
          </Text>{' '}
          {props.currentSnapshot.currency}
        </Text>
      )
    }
  }

  return (
    <>
      <View style={styles.bestWorstScenarioContainer} wrap={false}>
        <Text style={styles.bestWorstScenarioTitle}>
          {getText('title-266', props.user.settings, partiesFormat)}
        </Text>
        <View style={styles.bestWorstScenarioProbabilitiesContaier}>
          <Text style={styles.scenarioProbability}>
            {getTextForProbability('best')}
          </Text>
          <Text style={styles.scenarioProbability}>
            {getTextForProbability('worst')}
          </Text>
        </View>
        <View style={styles.bestWorstScenarioTableContainer}>
          <View style={styles.bestWorstScenarioTableRow}>
            <View style={styles.column1}></View>
            <Text style={{ ...styles.column2 }}>
              {getText('title-268', props.user.settings)}
            </Text>
            <Text style={{ ...styles.column3 }}>
              {getText('title-269', props.user.settings)}
            </Text>
          </View>
          <View style={styles.bestWorstScenarioTableRow}>
            <Text style={styles.column1}>
              {getText('title-58', props.user.settings)}:
            </Text>
            <Text style={styles.column2Value}>
              {stringAndRoundBigNumbers(
                props.resultsFromBackend.minmax.max.amount_received,
                props.user.settings,
                props.resultsSettings.settings.roundedResults,
              )}
            </Text>
            <Text style={styles.column3Value}>
              {stringAndRoundBigNumbers(
                props.resultsFromBackend.minmax.min.amount_received,
                props.user.settings,
                props.resultsSettings.settings.roundedResults,
              )}
            </Text>
          </View>
          {(props.resultsFromBackend.minmax.max.interest_received !== 0 ||
            props.resultsFromBackend.minmax.min.interest_received !== 0) && (
            <View style={styles.bestWorstScenarioTableRow}>
              <Text style={styles.column1}>
                {getText('title-51', props.user.settings)}:
              </Text>
              <Text style={styles.column2Value}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.max.interest_received,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
              <Text style={styles.column3Value}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.min.interest_received,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
            </View>
          )}
          {snapshotHasLegalCosts(props.currentSnapshot) && (
            <>
              <View style={styles.bestWorstScenarioTableRow}>
                <Text style={styles.column1}>
                  {props.resultsSettings.settings.includeIncurredCosts ||
                  !snapshotHasIncurredCosts(props.currentSnapshot)
                    ? getText('title-17', props.user.settings)
                    : getText('title-280', props.user.settings)}
                  :
                </Text>
                <Text style={styles.column2Value}>
                  {stringAndRoundBigNumbers(
                    findOwnLegalCostsForOutcome(
                      props.currentSnapshot,
                      props.resultsFromBackend.minmax.max,
                    ) +
                      includedIncurredCosts(
                        'client',
                        'both',
                        props.resultsSettings.settings.includeIncurredCosts,
                        props.currentSnapshot,
                      ),
                    props.user.settings,
                    props.resultsSettings.settings.roundedResults,
                  )}
                </Text>
                <Text style={styles.column3Value}>
                  {stringAndRoundBigNumbers(
                    findOwnLegalCostsForOutcome(
                      props.currentSnapshot,
                      props.resultsFromBackend.minmax.min,
                    ) +
                      includedIncurredCosts(
                        'client',
                        'both',
                        props.resultsSettings.settings.includeIncurredCosts,
                        props.currentSnapshot,
                      ),
                    props.user.settings,
                    props.resultsSettings.settings.roundedResults,
                  )}
                </Text>
              </View>
              {props.resultsFromBackend.minmax.max
                .legal_fees_covered_by_other !== 0 ||
              props.resultsFromBackend.minmax.min
                .legal_fees_covered_by_other !== 0 ? (
                <View style={styles.bestWorstScenarioTableRow}>
                  <Text
                    style={{
                      ...styles.column1,
                    }}
                  >
                    {getText('title-270', props.user.settings)}:
                  </Text>
                  <Text style={styles.sup}>3</Text>

                  <Text style={styles.column2Value}>
                    {stringAndRoundBigNumbers(
                      props.resultsFromBackend.minmax.max
                        .legal_fees_covered_by_other,
                      props.user.settings,
                      props.resultsSettings.settings.roundedResults,
                    )}
                  </Text>
                  <Text style={styles.column3Value}>
                    {stringAndRoundBigNumbers(
                      props.resultsFromBackend.minmax.min
                        .legal_fees_covered_by_other,
                      props.user.settings,
                      props.resultsSettings.settings.roundedResults,
                    )}
                  </Text>
                </View>
              ) : null}
              {props.resultsFromBackend.minmax.max
                .legal_fees_covered_by_insurance !== 0 ||
              props.resultsFromBackend.minmax.min
                .legal_fees_covered_by_insurance !== 0 ? (
                <View style={styles.bestWorstScenarioTableRow}>
                  <Text
                    style={styles.column1}
                    hyphenationCallback={(word) => [word]}
                  >
                    {getText('title-104', props.user.settings)}:
                  </Text>
                  <Text style={styles.column2Value}>
                    {stringAndRoundBigNumbers(
                      props.resultsFromBackend.minmax.max
                        .legal_fees_covered_by_insurance,
                      props.user.settings,
                      props.resultsSettings.settings.roundedResults,
                    )}
                  </Text>
                  <Text style={styles.column3Value}>
                    {stringAndRoundBigNumbers(
                      props.resultsFromBackend.minmax.min
                        .legal_fees_covered_by_insurance,
                      props.user.settings,
                      props.resultsSettings.settings.roundedResults,
                    )}
                  </Text>
                </View>
              ) : null}
            </>
          )}
          {props.resultsFromBackend.minmax.max.amount_received_out_of_court !==
            0 ||
          props.resultsFromBackend.minmax.min.amount_received_out_of_court !==
            0 ? (
            <View style={styles.bestWorstScenarioTableRow}>
              <Text style={styles.column1}>
                {getText('title-207', props.user.settings)}:
              </Text>
              <Text style={styles.column2Value}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.max
                    .amount_received_out_of_court,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
              <Text style={styles.column3Value}>
                {stringAndRoundBigNumbers(
                  props.resultsFromBackend.minmax.min
                    .amount_received_out_of_court,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
            </View>
          ) : null}
          {props.resultsFromBackend.minmax.max.enforcement_loss !== 0 ||
          props.resultsFromBackend.minmax.min.enforcement_loss !== 0 ? (
            <View style={styles.bestWorstScenarioTableRow}>
              <Text style={styles.column1}>
                {getText('title-65', props.user.settings)}:
              </Text>
              <Text style={styles.column2Value}>
                {stringAndRoundBigNumbers(
                  -props.resultsFromBackend.minmax.max.enforcement_loss,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
              <Text style={styles.column3Value}>
                {stringAndRoundBigNumbers(
                  -props.resultsFromBackend.minmax.min.enforcement_loss,
                  props.user.settings,
                  props.resultsSettings.settings.roundedResults,
                )}
              </Text>
            </View>
          ) : null}
          <View style={styles.bestWorstScenarioTableRow}>
            <View style={styles.column1}></View>
            <View
              style={{ ...styles.column2Value, ...styles.financialOutcome }}
            >
              <View style={styles.financialOutcomeTextContainer}>
                <Text style={styles.financialOutcomeText}>
                  {stringAndRoundBigNumbers(
                    props.resultsFromBackend.minmax.max.financial_outcome +
                      includedIncurredCosts(
                        'client',
                        'both',
                        props.resultsSettings.settings.includeIncurredCosts,
                        props.currentSnapshot,
                      ),
                    props.user.settings,
                    props.resultsSettings.settings.roundedResults,
                  )}{' '}
                  {props.currentSnapshot.currency}
                </Text>
              </View>
            </View>
            <View
              style={{ ...styles.column3Value, ...styles.financialOutcome }}
            >
              <View style={styles.financialOutcomeTextContainer}>
                <Text style={styles.financialOutcomeText}>
                  {stringAndRoundBigNumbers(
                    props.resultsFromBackend.minmax.min.financial_outcome +
                      includedIncurredCosts(
                        'client',
                        'both',
                        props.resultsSettings.settings.includeIncurredCosts,
                        props.currentSnapshot,
                      ),
                    props.user.settings,
                    props.resultsSettings.settings.roundedResults,
                  )}{' '}
                  {props.currentSnapshot.currency}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}
