import { atom } from 'recoil'
import { ResultsSettings } from '../models/generalTypes'

export const resultsSettingsState = atom<ResultsSettings>({
  key: 'resultsSettingsState',
  default: {
    settings: {
      includeIncurredCosts: false,
      roundedResults: true,
      selectionGraphSettings: {
        position: { client: [0.2, 0.4], opposing: [0.2, 0.4] },
        showRanges: true,
      },
      executiveSummary: 'new',
    },
    scenarioId: '',
  },
})
