import React, { useEffect, useRef, useState } from 'react'
import { IndependentClaim } from '../../../../../../models/independentClaim'
import {
  AccessRole,
  AmountSignum,
  ClaimType,
  ClaimsPreviewStyle,
  Currency,
} from '../../../../../../models/enums'
import { formattedNumToString } from '../../../../../../services/formatNum'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { editTreeNodeFromUndoState } from '../../../../../../states/EditTreeNodeFromUndo'
import ClaimTile3DotsMenu from '../ClaimTile3DotsMenu'
import circleMenuImg from '../../../../../../resources/images/104-circleWithArrow.svg'
import circleMenuImgExp from '../../../../../../resources/images/255-circleWithArrow_expanded.svg'
import { getText } from '../../../../../../services/textFunctions'
import {
  findClaimedAmountOfClaim,
  findCounterClaimedAmountOfClaim,
  findWeightedValueOfClaim,
  roundNumberTo,
} from '../../../../../../services/commonFunctions'
import DeleteClaimWindowForPreview from '../../../../../../Components/DeleteWindow/DeleteWindowForPreview'
import fitty from 'fitty'
import Draggable from 'react-draggable'
import {
  getClaimWeightedValue,
  getQuantumScenarioAmountWithInterest,
} from '../../../../../../services/claimFunctions'
import { convertDateTimeToLocal } from '../../../../../../services/dateFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Sorting = {
  option: 'ascending' | 'descending' | 'none'
  value: 'title' | 'client' | 'opposing' | 'weighted' | 'none'
}

type Props = {
  claimInfo: IndependentClaim
  claimIndex: number
  claimType: ClaimType
  currency: Currency
  isActive: boolean
  setActiveClaim: (param: number) => void
  claimPreviewId: string
  errors: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  menuAlwaysOpen?: boolean
  ownRole?: AccessRole
  claimPositionHighlight: number | undefined
  claimIsDragging: number | undefined
  handleDraggingClaim: (data: any) => void
  handleChangeClaimOrder: () => void
  expanded: boolean
  setExpanded: (value: boolean, id: string) => void
  sorting: Sorting
  changeSortingOption: (
    newValue: 'title' | 'client' | 'opposing' | 'weighted' | 'none',
    remove?: boolean,
  ) => void
  isClaimMenuOn: boolean
  setIsClaimMenuOn: (value: boolean, index: number) => void
}

const IndependentClaimList = (props: Props) => {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)
  const [hoverClaimTile, setHoverClaimTile] = useState(false)
  const [threeDotsMenuOn, setThreeDotsMenuOn] = useState(false)
  const [removeClaimMenuOn, setRemoveClaimMenuOn] = useState(false)
  const nodeRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [oldClaimInfo, setOldClaimInfo] = useState(props.claimInfo)
  const [claimedAmount, setClaimedAmount] = useState(0)
  const [counterClaimedAmount, setCounterClaimedAmount] = useState(0)
  const [weightedValue, setWeightedValue] = useState(0)

  useEffect(() => {
    setClaimedAmount(
      findClaimedAmountOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    setCounterClaimedAmount(
      findCounterClaimedAmountOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    setWeightedValue(
      findWeightedValueOfClaim(
        scenarioSnapshot.currentSnapshot,
        props.claimIndex,
      ),
    )
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.interestViewOption, props.claimInfo])

  useEffect(() => {
    fitty('.listPreviewInfoValue', {
      minSize: 8,
      maxSize: 16,
    })
  }, [props.expanded])

  useEffect(() => {
    props.setIsClaimMenuOn(
      threeDotsMenuOn || removeClaimMenuOn,
      props.claimIndex,
    )

    // eslint-disable-next-line
  }, [threeDotsMenuOn, removeClaimMenuOn])

  useEffect(() => {
    if (
      props.claimInfo.name !== oldClaimInfo.name &&
      props.claimInfo.id === oldClaimInfo.id &&
      props.sorting.value === 'title'
    ) {
      props.changeSortingOption('none', true)
    }

    if (
      getClaimWeightedValue(props.claimInfo) !==
        getClaimWeightedValue(oldClaimInfo) &&
      props.claimInfo.id === oldClaimInfo.id &&
      (props.sorting.value === 'client' ||
        props.sorting.value === 'opposing' ||
        props.sorting.value === 'weighted')
    ) {
      props.changeSortingOption('none', true)
    }

    setOldClaimInfo(props.claimInfo)

    // eslint-disable-next-line
  }, [props.claimInfo])

  const onSelectClaim = (e: any) => {
    const classesToIgnore = [
      'threeDotsButton',
      'threeDotsButtonContainerList',
      'threeDotsMenuRowList',
      'threeDotsMenuRowTextList',
      'collapse',
      'non-collapse',
      'expandImgText',
      'expandImg',
    ]

    if (
      e.target &&
      !classesToIgnore.includes(e.target.className) &&
      !isDragging
    ) {
      props.setActiveClaim(props.claimIndex)
      setEditTreeNodeFromUndo(undefined)
    }

    if (
      document.getElementById(`singleClaimPreviewContainer-${props.claimIndex}`)
    ) {
      document.getElementById(
        `singleClaimPreviewContainer-${props.claimIndex}`,
      )!.style.zIndex = '1'
    }
  }

  const unHoverClaim = () => {
    setHoverClaimTile(false)
  }

  const hasClaimAmount =
    props.claimInfo.type === ClaimType.claim &&
    props.claimInfo.amount !== undefined
  const hasCounterClaimAmount =
    props.claimInfo.type === ClaimType.counterclaim &&
    props.claimInfo.amount !== undefined

  const isExternalConsequenceOn =
    props.claimInfo.quantumScenarios.filter(
      (quantumScenario) => quantumScenario.outOfCourtAmount,
    ).length > 0 && props.claimInfo.hasOutOfCourt

  const showInterestOrDate = () => {
    if (
      !props.claimInfo.isInterest ||
      props.claimInfo.interestRate === undefined
    ) {
      return 'interest'
    } else if (
      props.claimInfo.interestDate === undefined ||
      props.claimInfo.interestDate === 'Invalid date'
    ) {
      return 'date'
    }

    return 'both'
  }

  return (
    <Draggable
      nodeRef={nodeRef as React.RefObject<HTMLElement>}
      handle=".moveTileTransparentCoverList, .listPreviewNameContainer"
      onDrag={(e: any, data) => {
        if (data.x !== 0 || data.y !== 0) {
          setIsDragging(true)
        }

        props.handleDraggingClaim(data)
      }}
      onStop={() => {
        setTimeout(() => {
          setIsDragging(false)
        }, 20)

        props.handleChangeClaimOrder()
      }}
      defaultPosition={{ x: 0, y: 0 }}
      position={{ x: 0, y: 0 }}
    >
      <div
        className={`listPreviewMainContainer ${
          props.expanded ? 'expanded' : ''
        } ${hoverClaimTile ? 'hovered' : ''}`}
        onClick={onSelectClaim}
        onMouseEnter={() => setHoverClaimTile(!props.isClaimMenuOn)}
        onMouseLeave={() => setHoverClaimTile(false)}
        id={`singleClaimPreviewContainer-${props.claimIndex}`}
        ref={nodeRef}
        style={{
          zIndex:
            (hoverClaimTile && !props.isClaimMenuOn) ||
            threeDotsMenuOn ||
            removeClaimMenuOn
              ? 1
              : 0,
        }}
        data-openreplay-obscured
      >
        <div className="listPreviewNumber">{props.claimIndex + 1}</div>
        <div
          className={`listPreviewNameContainer ${
            props.isActive ? 'selected' : ''
          } ${
            props.expanded
              ? isExternalConsequenceOn
                ? 'expanded consequence-on'
                : 'expanded'
              : ''
          }  ${krogerusColors ? 'krogerus' : ''}`}
        >
          <p
            className={`listPreviewName ${props.expanded ? 'expanded' : ''}`}
            id={`listPreviewName-${props.claimIndex}`}
          >
            {props.claimInfo.name}
          </p>
        </div>
        <div
          className={`listPreviewInfoContainer ${
            hoverClaimTile ? 'isHovered' : ''
          } ${
            props.expanded
              ? isExternalConsequenceOn
                ? 'expanded consequence-on'
                : 'expanded'
              : ''
          }`}
        >
          <div
            className={`listPreviewInfoRow ${
              props.expanded || hoverClaimTile ? 'isHovered' : ''
            }`}
          >
            {props.expanded && (
              <div className="listPreviewSingleInfo start">
                {(showInterestOrDate() === 'both' ||
                  showInterestOrDate() === 'interest') && (
                  <p
                    className={`listPreviewInfoValueNoFitty robotoNumbers`}
                    id={`interestRatePreview-${props.claimIndex}`}
                  >
                    {showInterestOrDate() === 'both'
                      ? formattedNumToString(
                          props.claimInfo.interestRate!,
                          user.settings,
                        ) + '%'
                      : getText('title-89', user.settings)}
                  </p>
                )}
                {(showInterestOrDate() === 'both' ||
                  showInterestOrDate() === 'date') && (
                  <p
                    className={`listPreviewInfoValueNoFitty date robotoNumbers ${
                      showInterestOrDate() === 'date' ? 'opposing' : ''
                    }`}
                    id={`interestDatePreview-${props.claimIndex}`}
                  >
                    {showInterestOrDate() === 'both'
                      ? convertDateTimeToLocal(
                          props.claimInfo.interestDate!,
                          user.settings,
                          true,
                        )
                      : getText('title-90', user.settings)}
                  </p>
                )}
              </div>
            )}
            <div className="listPreviewSingleInfo">
              <p
                className={`listPreviewInfoValue robotoNumbers ${
                  hasClaimAmount ? 'client' : ''
                } ${krogerusColors ? 'krogerus' : ''}`}
                id={`clientAmountPreview-${props.claimIndex}`}
              >
                {hasClaimAmount
                  ? formattedNumToString(
                      roundNumberTo(claimedAmount, 2),
                      user.settings,
                    ) +
                    ' ' +
                    props.currency.toString()
                  : '-'}
              </p>
            </div>
            <div className="listPreviewSingleInfo">
              <p
                className={`listPreviewInfoValue robotoNumbers ${
                  hasCounterClaimAmount ? 'opposing' : ''
                }  ${krogerusColors ? 'krogerus' : ''}`}
                id={`opposingPartyAmountPreview-${props.claimIndex}`}
              >
                {hasCounterClaimAmount
                  ? formattedNumToString(
                      Math.abs(roundNumberTo(counterClaimedAmount, 2)),
                      user.settings,
                    ) +
                    ' ' +
                    props.currency.toString()
                  : '-'}
              </p>
            </div>
            <div className="listPreviewSingleInfo">
              <p
                className={`listPreviewInfoValue robotoNumbers`}
                id={`weightedValuePreview-${props.claimIndex}`}
              >
                {formattedNumToString(
                  roundNumberTo(weightedValue, 2),
                  user.settings,
                ) +
                  ' ' +
                  props.currency.toString()}
              </p>
            </div>
          </div>
          {props.expanded && (
            <>
              <div className={`listPreviewInfoRow noTitle`}>
                <div className="listPreviewSingleInfo center">
                  <p
                    className={`listPreviewInfoValue`}
                    id={`listPreviewInfoValueTitle-Liability-${props.claimIndex}`}
                    data-textattribute="title-68"
                  >
                    {getText('title-68', user.settings)}
                  </p>
                </div>
                {props.claimInfo.quantumScenarios
                  .filter((q) => !q.liabilityOn)
                  .map((quantumScenario, index) => (
                    <div key={index} className="listPreviewSingleInfo center">
                      <p
                        className={`listPreviewInfoValue robotoNumbers ${
                          props.claimInfo.type === ClaimType.claim
                            ? 'client'
                            : 'opposing'
                        }  ${krogerusColors ? 'krogerus' : ''}`}
                        id={`claimAmountPreview-${props.claimIndex}_${index}`}
                      >
                        {formattedNumToString(
                          quantumScenario.amount
                            ? Math.abs(
                                roundNumberTo(
                                  getQuantumScenarioAmountWithInterest(
                                    quantumScenario.amount,
                                    scenarioSnapshot.currentSnapshot
                                      .interestViewOption,
                                    props.claimInfo.interestRate,
                                    props.claimInfo.interestDate,
                                    scenarioSnapshot.currentSnapshot
                                      .firstTrialDate,
                                    scenarioSnapshot.currentSnapshot
                                      .secondTrialDate,
                                  ),
                                  2,
                                ),
                              )
                            : 0,
                          user.settings,
                        ) +
                          ' ' +
                          props.currency.toString()}
                      </p>
                    </div>
                  ))}
                {Array(
                  5 -
                    props.claimInfo.quantumScenarios.filter(
                      (q) => !q.liabilityOn,
                    ).length,
                )
                  .fill(0)
                  .map((el, index) => (
                    <div
                      key={index}
                      className="listPreviewSingleInfo center"
                    ></div>
                  ))}
                {!hoverClaimTile && <div className="greyArea"></div>}
              </div>
              <div className={`listPreviewInfoRow noTitle small`}>
                <div className="listPreviewSingleInfo center">
                  <p
                    className={`listPreviewInfoValue robotoNumbers small`}
                    id={`liabilityPercentagePreview-${props.claimIndex}`}
                  >
                    {formattedNumToString(
                      roundNumberTo(props.claimInfo.liability * 100, 2),
                      user.settings,
                    )}
                    {'%'}
                  </p>
                </div>
                {props.claimInfo.quantumProbabilities.map(
                  (quantumProbability, index) => (
                    <div key={index} className="listPreviewSingleInfo center">
                      <p
                        className={`listPreviewInfoValue  robotoNumbers small`}
                        id={`claimProbabilityPreview-${props.claimIndex}_${index}`}
                      >
                        {formattedNumToString(
                          roundNumberTo(quantumProbability, 2),
                          user.settings,
                        )}
                        {'%'}
                      </p>
                    </div>
                  ),
                )}
                {Array(
                  5 -
                    props.claimInfo.quantumScenarios.filter(
                      (quantumScenario) => !quantumScenario.liabilityOn,
                    ).length,
                )
                  .fill(0)
                  .map((el, index) => (
                    <div
                      key={index}
                      className="listPreviewSingleInfo center"
                    ></div>
                  ))}
                {!hoverClaimTile && <div className="greyArea"></div>}
              </div>
              {isExternalConsequenceOn && (
                <div className={`listPreviewInfoRow noTitle`}>
                  <div className="listPreviewSingleInfo center"></div>
                  {props.claimInfo.quantumScenarios
                    .filter((q) => !q.liabilityOn)
                    .map((quantumScenario, index) => (
                      <div key={index} className="listPreviewSingleInfo center">
                        <p
                          className={`listPreviewInfoValue robotoNumbers consequence ${
                            quantumScenario.outOfCourtAmount === undefined
                              ? 'noValue'
                              : 'client'
                          }  ${krogerusColors ? 'krogerus' : ''}`}
                          id={`externalValuePreview-${props.claimIndex}_${index}`}
                        >
                          {quantumScenario.outOfCourtAmount !== undefined &&
                          quantumScenario.outOfCourtSignum !== AmountSignum.off
                            ? formattedNumToString(
                                quantumScenario.outOfCourtAmount as number,
                                user.settings,
                              ) +
                              ' ' +
                              props.currency.toString()
                            : '-'}
                        </p>
                      </div>
                    ))}
                  {Array(5 - props.claimInfo.quantumProbabilities.length)
                    .fill(0)
                    .map((el, index) => (
                      <div
                        key={index}
                        className="listPreviewSingleInfo center"
                      ></div>
                    ))}
                  {!hoverClaimTile && <div className="greyArea"></div>}
                </div>
              )}
            </>
          )}
        </div>
        {props.errors > 0 && <div className="errorsList">{props.errors}</div>}
        {!props.menuAlwaysOpen &&
          (hoverClaimTile || threeDotsMenuOn) &&
          !removeClaimMenuOn &&
          props.ownRole !== AccessRole.VIEWER && (
            <ClaimTile3DotsMenu
              claimIndex={props.claimIndex}
              threeDotsMenuOn={threeDotsMenuOn}
              setThreeDotsMenuOn={setThreeDotsMenuOn}
              setRemoveClaimMenuOn={setRemoveClaimMenuOn}
              claimType={ClaimType.claim}
              setActiveClaim={props.setActiveClaim}
              onList
              unHoverClaim={unHoverClaim}
            />
          )}
        {props.claimPositionHighlight === props.claimIndex && (
          <div
            className={`claimPositionHighlightList ${
              props.claimPositionHighlight > props.claimIsDragging!
                ? 'bottom'
                : 'top'
            } ${props.expanded ? 'expanded' : ''}`}
            id={`claimPositionHighlight-${props.claimIndex}`}
          ></div>
        )}
        {!props.menuAlwaysOpen &&
          (hoverClaimTile || threeDotsMenuOn) &&
          !removeClaimMenuOn && (
            <div
              className={`expandImg ${
                props.expanded
                  ? isExternalConsequenceOn
                    ? 'expanded consequence'
                    : 'expanded'
                  : ''
              }`}
              onClick={() => {
                props.setExpanded(!props.expanded, props.claimInfo.id)
              }}
            >
              <p
                className={`expandImgText`}
                id={`moreInfoButtonText-on-${props.claimIndex}`}
                data-textattribute={
                  props.expanded ? 'submenu-10' : 'submenu-11'
                }
              >
                {getText(
                  props.expanded ? 'submenu-10' : 'submenu-11',
                  user.settings,
                )}
              </p>
              <img
                src={props.expanded ? circleMenuImgExp : circleMenuImg}
                alt="circleMenuImg"
                className={props.expanded ? 'collapse' : 'non-collapse'}
                id={`moreInfoButtonOn-${props.claimIndex}`}
              />
            </div>
          )}
        {!props.menuAlwaysOpen && removeClaimMenuOn && (
          <div
            className="claimRemoveMenuContainer"
            id={`claimRemoveMenuContainer-${props.claimIndex}`}
          >
            <DeleteClaimWindowForPreview
              claimIndex={props.claimIndex}
              claimType={props.claimType}
              deleteFunction={() => {
                props.removeClaim(
                  props.claimIndex,
                  `removeClaimImgPreview-${props.claimIndex}`,
                  `removeClaimImgPreview-${props.claimIndex}`,
                )
                setRemoveClaimMenuOn(false)
              }}
              cancelFunction={() => {
                setRemoveClaimMenuOn(false)
              }}
              previewStyle={
                props.expanded
                  ? ClaimsPreviewStyle.expanded
                  : ClaimsPreviewStyle.list
              }
            />
          </div>
        )}
      </div>
    </Draggable>
  )
}

export default IndependentClaimList
