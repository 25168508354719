import { UserSettings } from '../../models/userSettings'
import {
  roundNumberTo,
  stringAndRoundTo2Decimals,
} from '../../services/commonFunctions'
import { formattedNumToString } from '../../services/formatNum'

export function stringAndRoundBigNumbers(
  value: number,
  settings: UserSettings,
  roundedResults: boolean,
  absoluteValue?: boolean,
  isPercentage?: boolean,
) {
  if (roundedResults) {
    if (isPercentage) {
      if (value < 1 || value > 99) {
        return formattedNumToString(roundNumberTo(value, 1), settings)
      } else {
        return formattedNumToString(roundNumberTo(value, 0), settings)
      }
    }
    if (absoluteValue) {
      return formattedNumToString(
        roundToNearestCustom(Math.abs(value)),
        settings,
      )
    } else {
      return formattedNumToString(roundToNearestCustom(value), settings)
    }
  } else {
    return stringAndRoundTo2Decimals(value, settings, absoluteValue)
  }
}

export function roundToNearestCustom(number: number): number {
  /**
   * Rounds a given number to 3 significant number.
   * This function also correctly handles negative numbers.
   */
  const negativeNumber = number < 0

  const absNumber = Math.abs(Math.round(number))

  const numberOfdigits = JSON.stringify(absNumber).length

  if (absNumber >= 10) {
    if (negativeNumber) {
      return roundNumberTo(
        -Math.round(Math.abs(number) / 10 ** (numberOfdigits - 3)) *
          10 ** (numberOfdigits - 3),
        4,
      )
    }
    return roundNumberTo(
      Math.round(number / 10 ** (numberOfdigits - 3)) *
        10 ** (numberOfdigits - 3),
      4,
    )
  } else {
    return roundNumberTo(number, 1)
  }
}
