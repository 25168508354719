import React, { JSX, useEffect, useState } from 'react'
import Button from '../../../../Components/Buttons/Button/Button'
import { createTutorial, getTutorial } from '../../../../services/requests'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../states/UserState'
import { UserRole } from '../../../../models/enums'
import { freemiumState } from '../../../../states/FreemiumState'
import { getFreemiumTutorial } from '../../../../freemiumServices/freemiumRequests'

type Props = {
  parsedText: JSX.Element[]
  templateTitle: string
}
export default function TutorialTemplateTextComponent(props: Props) {
  const [loading, setLoading] = useState(false)
  const buttonIndex = useState(getTutorialButtonIndex())[0]
  const resultText = useState<string>(
    buttonIndex > -1
      ? props.parsedText[buttonIndex].props.children.props['data-resulttext']
      : '',
  )[0]
  const tutorialId = useState<string>(
    buttonIndex > -1
      ? props.parsedText[buttonIndex].props.children.props['data-tutorial']
      : '',
  )[0]
  const [filteredParsedText, setFilteredParsedText] = useState([
    ...props.parsedText,
  ])
  const [navigateTo, setNavigateTo] = useState('')
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const loadingButton = (
    <Button
      key={'loadNewTutorial'}
      id={'loadNewTutorial'}
      buttonText={''}
      buttonTextAttribute={'button-0'}
      buttonType="contained"
      type="button"
      className="addNewCaseButton"
      style={{ marginTop: '20px' }}
      loadingWidth="260px"
      loadingHeight="38px"
      loading={true}
      side="left"
      NoUpperCase={true}
      small={false}
    />
  )

  const fullButton = (
    <Button
      key={'loadNewTutorial'}
      id={'loadNewTutorial'}
      buttonText={
        buttonIndex > -1
          ? props.parsedText[buttonIndex].props.children.props[
              'data-buttontext'
            ]
          : ''
      }
      buttonTextAttribute={'button-0'}
      buttonType="contained"
      type="button"
      className="addNewCaseButton"
      style={{ marginTop: '20px' }}
      loading={loading}
      onClick={() => {
        setLoading(true)
        createTutorial(tutorialId).then((res) => {
          if (!('errorCode' in res)) {
            setNavigateTo(`/mycases/${res.data.newGroupId}`)
          }

          setLoading(false)
        })
      }}
      side="left"
      NoUpperCase={true}
      small={false}
    />
  )

  function getTutorialButtonIndex() {
    return props.parsedText.findIndex(
      (pt: any) =>
        pt.type === 'p' &&
        pt.props &&
        pt.props.children &&
        pt.props.children.type === 'button' &&
        pt.props.children.props['data-tutorial'],
    )
  }

  useEffect(() => {
    if (tutorialId) {
      if (freemium.isFreemium) {
        getFreemiumTutorial(tutorialId).then((res) => {
          if (!('errorCode' in res)) {
            if (res.data.groupid) {
              setNavigateTo(`/mycases/${res.data.groupid}`)
            }
          }
        })
      } else {
        getTutorial(tutorialId).then((res) => {
          if (!('errorCode' in res)) {
            if (res.data.groupid) {
              setNavigateTo(`/mycases/${res.data.groupid}`)
            }
          }
        })
      }
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let filtered = [...props.parsedText]

    if (buttonIndex === -1) {
      return
    }

    if (user.role === UserRole.APP_VIEWER) {
      filtered[buttonIndex] = <></>
      return
    }

    if (loading) {
      filtered[buttonIndex] = loadingButton
    } else if (!loading && navigateTo !== '') {
      filtered[buttonIndex] = (
        <div style={{ marginTop: '20px' }} key={'newTutorialResultText'}>
          <Link className="customLink" to={navigateTo}>
            {resultText}
          </Link>
        </div>
      )
    } else {
      filtered[buttonIndex] = fullButton
    }

    setFilteredParsedText([...filtered])

    // eslint-disable-next-line
  }, [loading, navigateTo])

  return (
    <>
      <div
        className="userManualTextComponentContainer"
        id={'userManualTextComponentContainer'}
      >
        <div className="userManualTextTitle">{props.templateTitle}</div>
        <div className="userManualTextContainer">{filteredParsedText}</div>
        <div className="userManualTextEmpty"></div>
      </div>
    </>
  )
}
