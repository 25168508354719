import React, { JSX, useEffect, useState } from 'react'
import { getWordpress } from '../../../../services/requests'
import TutorialTemplateSideMenu from '../../tutorialTemplatePages/userManualComponents/TutorialTemplateSideMenu'
import TutorialTemplateTextComponent from '../../tutorialTemplatePages/userManualComponents/TutorialTemplateTextComponent'
import parse from 'html-react-parser'
import { getTextFromParsedArray } from '../../../../services/wordpressFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../../services/commonFunctions'
import { freemiumState } from '../../../../states/FreemiumState'
import { AxiosResponse } from 'axios'
import { getFreemiumWordpress } from '../../../../freemiumServices/freemiumRequests'

type Props = {
  loadingGif: string
}

export default function UserManual(props: Props) {
  const [parsedText, setParsedText] = useState<
    undefined | React.ReactElement[]
  >(undefined)
  const [loadingText, setloadingText] = useState(true)
  const [menuHighlight, setMenuHighlight] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [listOfTitles, setListOfTitles] = useState<string[][]>([])
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (freemium.isFreemium) {
      getFreemiumWordpress('manual').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress', true),
        )
        if (!('errorCode' in res)) {
          setManual(res)
        }
      })
    } else {
      getWordpress('manual').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress', true),
        )
        if (!('errorCode' in res)) {
          setManual(res)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const setManual = (res: AxiosResponse) => {
    const wordPressObject = res.data

    setParsedText(
      parse(wordPressObject[0]['content']['rendered']) as React.ReactElement[],
    )
    initiliazeListOfTitles(
      parse(wordPressObject[0]['content']['rendered']) as React.ReactElement[],
    )
    setTimeout(() => {
      setloadingText(false)
    }, 1200)
    //Mixpanel 83
    logActivity(freemium.isFreemium, 'Watched User Manual')
  }

  function initiliazeListOfTitles(parsedTextArray: JSX.Element[]) {
    let tempListOfTitles: string[][] = []

    for (let i = 0; i < (parsedTextArray as any).length; i++) {
      if (
        parsedTextArray[i].type &&
        (parsedTextArray[i].type === 'h1' || parsedTextArray[i].type === 'h2')
      ) {
        let titleType = parsedTextArray[i].type
        let titleText = getTextFromParsedArray(parsedTextArray[i])
        let titleId = parsedTextArray[i].props.id
        tempListOfTitles.push([titleType, titleText, titleId])
      }
    }

    setListOfTitles(tempListOfTitles)
  }

  function scrollTo(id: string) {
    const offset = 200

    const element = document.getElementById(id)
    const container = document.getElementById(
      'userManualTextComponentContainer',
    )
    if (element && container) {
      container!.scroll({
        top: element!.offsetTop - offset,
        behavior: 'smooth',
      })
    }
  }

  const onScroll = (e: any) => {
    setScrollTop(
      document.getElementById('userManualTextComponentContainer')!.scrollTop,
    )
    if (
      listOfTitles.length > 0 &&
      document.getElementById(listOfTitles[0][2])
    ) {
      for (let i = 0; i < listOfTitles.length; i++) {
        let element = document.getElementById(listOfTitles[i][2])
        if (element) {
          if (scrollTop < element!.offsetTop + element!.offsetHeight - 400) {
            if (i === 0) {
              setMenuHighlight(0)
            } else {
              setMenuHighlight(i - 1)
            }
            break
          }
        }
      }
    }
  }

  useEffect(() => {
    const container = document.getElementById(
      'userManualTextComponentContainer',
    )

    if (container) {
      container.addEventListener('scroll', onScroll)
    }

    return () => {
      if (container) {
        container!.removeEventListener('scroll', onScroll)
      }
    }
    // eslint-disable-next-line
  }, [loadingText, scrollTop])

  return (
    <>
      {loadingText ? (
        <div className="loadingUserManualTextContainer">
          <img className="loadingGif" src={props.loadingGif} alt="loadingGif" />
        </div>
      ) : (
        <>
          <div className="sideMenuAndTextContainer">
            <TutorialTemplateSideMenu
              menuHighlight={menuHighlight}
              listOfTitles={listOfTitles}
              scrollTo={scrollTo}
            />
            <TutorialTemplateTextComponent
              parsedText={parsedText!}
              templateTitle="User Manual"
            />
          </div>
        </>
      )}
    </>
  )
}
