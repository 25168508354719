import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { userState } from '../../../../../../../../states/UserState'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { getText } from '../../../../../../../../services/textFunctions'
import IncludeOptionComponent from '../../IncludeOptionComponent'
import { getComplexValueText } from '../../../../../../../../services/commonFunctions'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { includedIncurredCosts } from '../../../../../../../../services/resultsFunctions'

type Props = {
  reportWhitelabel?: ReportWhitelabel
  backendResults: ResultsObject
  resultsViewParty: 'client' | 'opposing'
  setShowIncludeOption?: (
    showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  visibleElements?: ExecutiveSummaryVisibleElements
  showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined
  handleIncludeExcludeElement: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
  resultsSnapshot: ScenarioSnapshot
}

export default function ExpectedValue(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const fade =
    props.visibleElements &&
    !props.visibleElements[ExecutiveSummaryVisibleElementsEnum.expectedValue]

  return (
    <div
      className={`expectedValueContainer ${
        props.visibleElements ? 'forReport' : ''
      }`}
      onMouseEnter={
        props.visibleElements
          ? () =>
              props.setShowIncludeOption!(
                ExecutiveSummaryVisibleElementsEnum.expectedValue,
              )
          : undefined
      }
      onMouseLeave={
        props.visibleElements
          ? () => props.setShowIncludeOption!(undefined)
          : undefined
      }
      data-openreplay-obscured
    >
      {props.visibleElements &&
      props.showIncludeOption ===
        ExecutiveSummaryVisibleElementsEnum.expectedValue ? (
        <IncludeOptionComponent
          includeElement={
            props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.expectedValue
            ]
          }
          includeElementName={ExecutiveSummaryVisibleElementsEnum.expectedValue}
          handleIncludeExcludeElement={props.handleIncludeExcludeElement}
        />
      ) : null}
      <p
        className={`expectedValueTitle ${fade ? 'fade' : ''}`}
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.bold,
          'noRoboto',
        )}
        data-textattribute="title-271"
        id={`expectedValueTitle${props.visibleElements ? '-forReport' : ''}`}
        data-openreplay-obscured
      >
        {getText('title-271', user.settings)}{' '}
        <span
          className={`${fade ? 'fade' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionTitle,
            FontLabels.bold,
            'noRoboto',
          )}
          data-openreplay-obscured
        >
          {stringAndRoundBigNumbers(
            props.backendResults.value_of_claim +
              includedIncurredCosts(
                'client',
                'both',
                resultsSettings.settings.includeIncurredCosts,
                props.resultsSnapshot,
              ),
            user.settings,
            resultsSettings.settings.roundedResults,
          )}
          {props.backendResults &&
          props.backendResults.model_used === 'statistical'
            ? '*'
            : ''}{' '}
          {props.resultsSnapshot.currency}
        </span>
      </p>
      <p
        className={`expectedValueDescription ${fade ? 'fade' : ''}`}
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.regular,
          'noRoboto',
        )}
        id={`expectedValueDescription1${
          props.visibleElements ? '-forReport' : ''
        }`}
        data-textattribute={`${
          props.resultsViewParty === 'client'
            ? 'description-261'
            : 'description-262'
        }`}
      >
        {props.resultsViewParty === 'client'
          ? getText('description-261', user.settings, partiesFormat)
          : getText('description-262', user.settings, partiesFormat)}{' '}
        {getText('description-261b', user.settings)}{' '}
        {getComplexValueText(
          props.resultsSnapshot,
          user.settings,
          resultsSettings.settings.includeIncurredCosts,
        )}
      </p>
    </div>
  )
}
